import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";

/**
 * Functional component to return the agent row for the leaderboard.
 * 
 * On compoent load, a series of listeners are setup on the database to stream down bookings within a 
 * given set of dates.
 */
function Agent(props) {
    const [dailyTarget, setDailyTarget] = useState(0);
    const [weeklyTarget, setWeeklyTarget] = useState(0);
    const [monthlyTarget, setMonthlyTarget] = useState(0);

    const [dailyTotal, setDailyTotal] = useState(0);
    const [weeklyTotal, setWeeklyTotal] = useState(0);
    const [monthlyTotal, setMonthlyTotal] = useState(0);

    const [dailyMargin, setDailyMargin] = useState(0);
    const [weeklyMargin, setWeeklyMargin] = useState(0);
    const [monthlyMargin, setMonthlyMargin] = useState(0);

    const [dailyMarginPercentage, setDailyMarginPercentage] = useState(0);
    const [weeklyMarginPercentage, setWeeklyMarginPercentage] = useState(0);
    const [monthlyMarginPercentage, setMonthlyMarginPercentage] = useState(0);

    const [dailyBookings, setDailyBookings] = useState([]);
    const [weeklyBookings, setWeeklyBookings] = useState([]);
    const [monthlyBookings, setMonthlyBookings] = useState([]);

    /**
     * Get the agentID from the props
     */
    const {
        agentID,
        details,
        rangeDaily,
        rangeWeekly,
        rangeMonthly,
        updateDaily,
        updateWeekly,
        updateMonthly,
    } = props;

    /**
     * On component load, load the agents targets
     */
    useEffect(() => {
        /**
         * Setup a listener on the users targets document
         */
        const unsubscribe = db.doc(`targets/${agentID}`)
            .onSnapshot((agentDoc) => {
                /**
                 * Check to see if the agent has some targets set first
                 */
                if (agentDoc.exists) {
                    /**
                     * If there is a document, pull the target details from it
                     */
                    const { daily, weekly, monthly } = agentDoc.data();
                    /**
                     * Set the targets into the state
                     */
                    setDailyTarget(daily);
                    setWeeklyTarget(weekly);
                    setMonthlyTarget(monthly);
                }
            });
        /**
         * Remove the listener on compoent unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * On component load, setup a listener on the database for the bookings for this agent this month
     */
    useEffect(() => {
        /**
         * Create some timestamps to query the database with
         */
        const startTimestamp = firebase.firestore.Timestamp.fromMillis(rangeMonthly?.start);
        const endTimestamp = firebase.firestore.Timestamp.fromMillis(rangeMonthly?.end);
        /**
         * Setup the database listener
         */
        const unsubscribe = db.collection("bookings")
            .where("agent", "==", agentID)
            .where("booked", ">=", startTimestamp)
            .where("booked", "<=", endTimestamp)
            .onSnapshot((bookingDocs) => {
                /**
                 * Loop over the bookings found within these dates
                 */
                bookingDocs.docChanges().forEach((change) => {
                    /**
                     * Pull the margin data for the booking
                     */
                    const { booked, margins } = change.doc.data();
                    /**
                     * Get the booking date as a millisecond timestamp
                     */
                    const bookingAsMillis = moment.unix(booked?.seconds).valueOf();
                    /**
                     * Booking record added
                     */
                    if (change.type === "added") {
                        /**
                         * Add the booking into the monthly array
                         */
                        setMonthlyBookings((monthlyBookings) => [
                            ...monthlyBookings, {
                                id: change.doc.id,
                                ...margins,
                            }
                        ]);
                        /**
                         * Does the booked date fall inside the current week
                         */
                        if ((bookingAsMillis > rangeWeekly.start) && (bookingAsMillis < rangeWeekly.end)) {
                            /**
                             * Add the booking into the weekly array
                             */
                            setWeeklyBookings((weeklybookings) => [
                                ...weeklybookings, {
                                    id: change.doc.id,
                                    ...margins,
                                }
                            ]);
                        }

                        /**
                         * Does the booked date fall inside the current day
                         */
                        if ((bookingAsMillis >= rangeDaily.start) && (bookingAsMillis < rangeDaily.end)) {
                            /**
                             * Add the booking into the daily array
                             */
                            setDailyBookings((dailyBookings) => [
                                ...dailyBookings, {
                                    id: change.doc.id,
                                    ...margins,
                                }
                            ]);
                        }
                    }
                    /**
                     * Booking record updated
                     */
                    if (change.type === "modified") {
                        /**
                         * Update the monthly bookings
                         */
                        setMonthlyBookings((monthlyBookings) => {
                            let updatedMonthlyBookings = [...monthlyBookings];
                            for (let i in monthlyBookings) {
                                if (monthlyBookings[i].id === change.doc.id) {
                                    updatedMonthlyBookings[i] = {
                                        id: change.doc.id,
                                        ...margins,
                                    };
                                    break;
                                }
                            }
                            return updatedMonthlyBookings;
                        });
                        /**
                         * Does the booked date fall inside the current week
                         */
                        if ((bookingAsMillis > rangeWeekly.start) && (bookingAsMillis < rangeWeekly.end)) {
                            /**
                             * Add the booking into the weekly array
                             */
                            setWeeklyBookings((weeklyBookings) => {
                                let updatedWeeklyBookings = [...weeklyBookings];
                                for (let i in weeklyBookings) {
                                    if (weeklyBookings[i].id === change.doc.id) {
                                        updatedWeeklyBookings[i] = {
                                            id: change.doc.id,
                                            ...margins,
                                        };
                                        break;
                                    }
                                }
                                return updatedWeeklyBookings;
                            });
                        }
                        /**
                         * Does the booked date fall inside the current day
                         */
                        if ((bookingAsMillis > rangeDaily.start) && (bookingAsMillis < rangeDaily.end)) {
                            /**
                             * Add the booking into the daily array
                             */
                            setDailyBookings((dailyBookings) => {
                                let updatedDailyBookings = [...dailyBookings];
                                for (let i in dailyBookings) {
                                    if (dailyBookings[i].id === change.doc.id) {
                                        updatedDailyBookings[i] = {
                                            id: change.doc.id,
                                            ...margins,
                                        };
                                        break;
                                    }
                                }
                                return updatedDailyBookings;
                            });
                        }
                    }
                    /**
                     * Booking record removed
                     */
                    if (change.type === "removed") {
                        setMonthlyBookings((monthlyBookings) => {
                            monthlyBookings.filter((booking) => booking.id !== change.doc.id);
                        });
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * When the daily bookings are updated
     */
    useEffect(() => {
        /**
         * Setup a float for storing the total costings
         */
        let totalSales = 0.00;
        let totalMargin = 0.00;
        /**
         * Loop through each of the bookings found
         */
        dailyBookings.forEach((booking) => {
            /**
             * Get the customer_price and the supplier_invoice values
             */
            const { customer_price, supplier_invoices } = booking;
            /**
             * Make sure there is a value for both variables
             */
            if ((customer_price > 0) && (supplier_invoices > 0)) {
                /**
                 * Add the customer_price and bookingMargin to the running totals for today
                 */
                totalSales = totalSales + Number.parseFloat(customer_price);
                totalMargin = totalMargin + Number.parseFloat(customer_price - supplier_invoices);
            }
        });
        /**
         * Set the new total into the state
         */
        setDailyTotal(Number(totalSales));
        setDailyMargin(Number(totalMargin));
        /**
         * Push the figures up the props for the total along the bottom of the table
         */
        updateDaily(agentID, Number(totalSales), Number(totalMargin));
        /**
         * Work out the margin as a percentage of the target
         */
        const marginPercentage = Number.parseInt(((totalMargin / dailyTarget) * 100));
        /**
         * Then set that into the state
         */
        setDailyMarginPercentage(marginPercentage);
    }, [dailyBookings, dailyTarget]);

    /**
     * When the weekly bookings are updated
     */
    useEffect(() => {
        /**
         * Setup a float for storing the total costings
         */
        let totalSales = 0.00;
        let totalMargin = 0.00;
        /**
         * Loop through each of the bookings found
         */
        weeklyBookings.forEach((booking) => {
            /**
             * Get the customer_price and the supplier_invoice values
             */
            const { customer_price, supplier_invoices } = booking;
            /**
             * Make sure there is a value for both variables
             */
            if ((customer_price > 0) && (supplier_invoices > 0)) {
                /**
                 * Add the customer_price and bookingMargin to the running totals for this week
                 */
                totalSales = totalSales + Number.parseFloat(customer_price);
                totalMargin = totalMargin + Number.parseFloat(customer_price - supplier_invoices);
            }
        });
        /**
         * Set the new total into the state
         */
        setWeeklyTotal(Number(totalSales));
        setWeeklyMargin(Number(totalMargin));
        /**
         * Push the figures up the props for the total along the bottom of the table
         */
        updateWeekly(agentID, Number(totalSales), Number(totalMargin));
        /**
         * Work out the margin as a percentage of the target
         */
        const marginPercentage = Number.parseInt(((totalMargin / weeklyTarget) * 100));
        /**
         * Then set that into the state
         */
        setWeeklyMarginPercentage(marginPercentage);
    }, [weeklyBookings, weeklyTarget]);

    /**
     * When the monthly bookings are updated
     */
    useEffect(() => {
        /**
         * Setup a float for storing the total costings
         */
        let totalSales = 0.00;
        let totalMargin = 0.00;
        /**
         * Loop through each of the bookings found
         */
        monthlyBookings.forEach((booking) => {
            /**
             * Get the customer_price and the supplier_invoice values
             */
            const { customer_price, supplier_invoices } = booking;
            /**
             * Make sure there is a value for both variables
             */
            if ((customer_price > 0) && (supplier_invoices > 0)) {
                /**
                 * Add the customer_price and bookingMargin to the running totals for this month
                 */
                totalSales = totalSales + Number.parseFloat(customer_price);
                totalMargin = totalMargin + Number.parseFloat(customer_price - supplier_invoices);
            }
        });
        /**
         * Set the new total into the state
         */
        setMonthlyTotal(Number(totalSales));
        setMonthlyMargin(Number(totalMargin));
        /**
         * Push the figures up the props for the total along the bottom of the table
         */
        updateMonthly(agentID, Number(totalSales), Number(totalMargin));
        /**
         * Work out the margin as a percentage of the target
         */
        const marginPercentage = Number.parseInt(((totalMargin / monthlyTarget) * 100));
        /**
         * Then set that into the state
         */
        setMonthlyMarginPercentage(marginPercentage);
    }, [monthlyBookings, monthlyTarget]);


    return (
        <tr>
            <td>
                <p>{details.first_name} {details.last_name}</p>
            </td>
            <td>
                <p>£{dailyTotal.toLocaleString()}</p>
            </td>
            <td>
                <p className={[dailyMarginPercentage > 100 ? "is-complete" : ""]}>
                    £{dailyMargin.toLocaleString()}

                    {/* If the user has a target set */}
                    {(dailyTarget > 0) &&
                        <small>({dailyMarginPercentage || 0}%)</small>
                    }
                </p>
            </td>
            <td>
                <p>£{weeklyTotal.toLocaleString()}</p>
            </td>
            <td>
                <p className={[weeklyMarginPercentage > 100 ? "is-complete" : ""]}>
                    £{weeklyMargin.toLocaleString()}

                    {/* If the user has a target set */}
                    {(weeklyTarget > 0) &&
                        <small>({weeklyMarginPercentage || 0}%)</small>
                    }
                </p>
            </td>
            <td>
                <p>£{monthlyTotal.toLocaleString()}</p>
            </td>
            <td>
                <p className={[monthlyMarginPercentage > 100 ? "is-complete" : ""]}>
                    £{monthlyMargin.toLocaleString()}

                    {/* If the user has a target set */}
                    {(monthlyTarget > 0) &&
                        <small>({monthlyMarginPercentage || 0}%)</small>
                    }
                </p>
            </td>
        </tr>
    );
}

export default Agent;
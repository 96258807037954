import { useState, useEffect, useRef } from 'react'
import firebase from 'firebase'

// Constants
import { DISCOUNT_TYPES } from '../../../../../assets/data/exports'

// Utility functions
import { db } from '../../../../../utils/firebase'

// UI components
import Window from '../../../../structure/window/window'
import WindowCard from '../../../../ui/window-card/window-card'
import Datepicker from '../../../../ui/datepicker/datepicker'
import Select from '../../../../ui/select/select'
import Button from '../../../../ui/button/button'
import Input from '../../../../ui/inputs/input'

// Returns the markup and html for the option view window
export default function OptionView({ courseID, close, type, window }) {
    const [saving, setSaving] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    // Window fields
    const [discountID, setWindowID] = useState(null)
    const [title, setTitle] = useState('')
    const [size, setSize] = useState(null)
    const [discountType, setDiscountType] = useState(null)
    const [value, setValue] = useState(null)

    // Store a boolean for the mounted state
    const isMounted = useRef(false)

    // On component load
    useEffect(() => {
        // Was there a window object passed in?
        if (type === 'edit' && window.id) {
            // Pull the details from the document
            const { id, title, start_seconds, end_seconds, value, size } = window

            // Convert the dates to those we can set into the state
            const startUNIX = start_seconds * 1000
            const endUNIX = end_seconds * 1000

            // Set the state up
            setWindowID(id)
            setTitle(title || '')
            setStartDate(startUNIX)
            setEndDate(endUNIX)
            setSize(size)
            setValue(value)
            setDiscountType(window.type)
        }

        // Toggle the mounted state
        setTimeout(() => {
            isMounted.current = true
        }, 500)
    }, [window])

    // Save the resort discount
    const saveResortDiscount = async () => {
        // Update the state
        setSaving(true)

        // If we were editing a price window instead
        if (type === 'edit') {
            await db.doc(`CMS_courses/${courseID}/discounts/${discountID}`).set({
                title,
                size,
                value,
                type: discountType,
                start_date: firebase.firestore.Timestamp.fromMillis(startDate),
                end_date: firebase.firestore.Timestamp.fromMillis(endDate),
            }, { merge:true })
        } else {
            await db.collection(`CMS_courses/${courseID}/discounts`).add({
                title,
                size,
                value,
                type: discountType,
                start_date: firebase.firestore.Timestamp.fromMillis(startDate),
                end_date: firebase.firestore.Timestamp.fromMillis(endDate),
            })
        }

        // Reset the state
        setSaving(true)
        close()
    }

    return (
        <Window title={type === 'new' ? 'Add Global Note' : 'Edting Note'} className='price-window' close={() => close()}>
            <WindowCard stacked={true} title='Discount title' subtitle='Discount details for displaying in the config panels'>
                <div className="input-grid">
                    <Input
                        value={title}
                        onChange={setTitle}
                        placeholder='e.g. 1 in 8 go free' />
                </div>
            </WindowCard>

            <WindowCard stacked={true} title='Date range' subtitle='Choose the start & end dates this discount will be available'>
                <div className="input-grid cols-2">
                    <Datepicker
                        value={startDate}
                        onSelect={(date) => setStartDate(date)}
                        placeholder='Start date' />

                    <Datepicker
                        value={endDate}
                        onSelect={(date) => setEndDate(date)}
                        placeholder='End date' />
                </div>
            </WindowCard>

            <WindowCard title='Discount configuration' subtitle='Configure the available percent/group based discount'>
                <div className='input-grid cols-2'>
                    <Select 
                        selected={discountType}
                        placeholder='Choose type'
                        options={DISCOUNT_TYPES}
                        onSelect={({ option }) => setDiscountType(option)} />

                    <Input 
                        value={value}
                        onChange={setValue}
                        placeholder='Value'
                        symbol={discountType === 'percent' ? '%' : null} />
                </div>
            </WindowCard>

            <div className="ui-window-actions alt-layout">
                <Button
                    label="Save discount"
                    loadingText="Saving..."
                    loading={saving}
                    disabled={!title || !startDate || !endDate || !discountType}
                    onClick={() => saveResortDiscount()} />
            </div>
        </Window>
    )
}
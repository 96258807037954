import React, { useRef, useEffect, useState } from "react"
import "./select.scss"

/**
 * Caret for the side of the dropdown inputfield
 */
import { CaretDownIcon, TimesIcon } from "../../../utils/svgs"

/**
 * Use the input component
 */
import Input from "../inputs/input"

/**
 * Functional component for the select component single-select options
 *
 * @param {object} props Props passed into the select component
 * @returns HTML markup and functionality for the select component
 */
export default function SearchSelect(props) {
	const [userInput, setUserInput] = useState("")
	const [showingDropdown, setShowingDropdown] = useState(false)
	const [filteredOptions, setFilteredOptions] = useState({})
	const [chosenItem, setChosenItem] = useState({})

	// Get  some parameters from the props
	const { selected, disableSelected, keepDropdownOpen } = props

	/**
	 * Close the dropdown list and return the value back up the props
	 */
	const optionSelected = (option) => {
		/**
		 * Hide the dropdown
		 */
		!keepDropdownOpen && setShowingDropdown(false)
		/**
		 * Store the item locallay
		 */
		setChosenItem(option)
		/**
		 * Push the update up the props
		 */
		props.onSelect(option)
	}

	/**
	 * Listen for a click outside the dropdown ref
	 *
	 * @type {const}
	 */
	const ClickedOutside = (dropdownRef) => {
		useEffect(() => {
			/**
			 * Check for the click to be outside the select field
			 */
			const checkForOutsideClick = (event) => {
				/**
				 * Is the click outside?
				 */
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					/**
					 * Hide the dropdown
					 */
					setShowingDropdown(false)
				}
			}
			/**
			 * Bind on the component load
			 */
			document.addEventListener("mousedown", checkForOutsideClick)
			return () => {
				/**
				 * Unbind on component unload
				 */
				document.removeEventListener("mousedown", checkForOutsideClick)
			}
		}, [dropdownRef])
	}

	/**
	 * Dropdown ref
	 */
	const dropdownRef = useRef(null)

	/**
	 * Bind onto the click event
	 */
	ClickedOutside(dropdownRef)

	/**
	 * With any update to the userInput string
	 */
	useEffect(() => {
		/**
		 * Push the new string up the props
		 */
		props.onTextChange && props.onTextChange(userInput)
		/**
		 * When there is a value in the userInput field
		 */
		if (userInput.length > 0) {
			/**
			 * Loop through the options and see if we can match a value to the users input
			 */
			let filteredOptions = {}
			for (const key in props.options) {
				if (props.options[key].toUpperCase().includes(userInput.toUpperCase())) {
					filteredOptions[key] = `${props.options[key]}${props.withKey ? ` (${key})` : ""}`
				} else if (key.toUpperCase().includes(userInput.toUpperCase())) {
					filteredOptions[key] = `${props.options[key]}${props.withKey ? ` (${key})` : ""}`
				}
			}
			/**
			 * Are there any filtered options to show
			 */
			if (Object.keys(filteredOptions).length > 0) {
				/**
				 * If so update the state with them
				 */
				setFilteredOptions(filteredOptions)
			}
		} else {
			/**
			 * If there is no input, just reset the filtered state
			 */
			setFilteredOptions({})
		}
	}, [userInput])

	/**
	 * Clear the inputs from the select fiel by passing the function up the props
	 */
	const clearInput = (e) => {
		/**
		 * Stop the click bubbling up to the select element
		 */
		e.stopPropagation()
		/**
		 * Call the clear input function from the props
		 */
		setUserInput("")
		setChosenItem({})
		setFilteredOptions({})
		props.clearInput()
	}

	return (
		<div
			className={["ui-select-wrapper", props.className, props.error && "has-error"].join(" ")}
			ref={dropdownRef}>
			{/* Placeholder to sit above the field when there is a value */}
			{(props.placeholder || props.label) && props.showLabel && <div className={["ui-input-upper-placeholder", props.value?.length > 0 && "active"].join(" ")}>{props.label || props.placeholder}</div>}

			{/* Input section of the input */}
			<div
				className={["ui-select-upper no-styles", props.value && "has-value", chosenItem?.option && "has-local-value", showingDropdown && "dropdown-showing"].join(" ")}
				onClick={() => setShowingDropdown((showingDropdown) => !showingDropdown)}>
				{(!props.showClear || (props.showClear && !chosenItem?.option)) && (
					<Input
						className={showingDropdown && "dropdown-showing"}
						label={props.label}
						placeholder={props.placeholder}
						value={props.value}
						activeOnHover={props.activeOnHover}
						onChange={setUserInput}
					/>
				)}

				{props.showClear && chosenItem?.option && (
					<Input
						className={showingDropdown && "dropdown-showing"}
						label={props.label}
						placeholder={props.placeholder}
						value={chosenItem?.value}
						activeOnHover={props.activeOnHover}
						onChange={setUserInput}
					/>
				)}

				{/* Dropdown chevron  */}
				{(!props.hideArrow || (props.showClear && !chosenItem?.option)) && (
					<div className="ui-select-icon">
						<CaretDownIcon />
					</div>
				)}

				{/* If we want to show an "X" to clear the input, and there is a value to clear */}
				{props.showClear && chosenItem?.option && (
					<div
						className="ui-select-icon clear-input"
						onClick={(e) => clearInput(e)}>
						<TimesIcon />
					</div>
				)}
			</div>

			{/* Select dropdown, visible once clicked */}
			<div className={["ui-select-dropdown", showingDropdown && "is-showing"].join(" ")}>
				{/* Print out all the options passed in */}
				{Object.keys(filteredOptions).length === 0 &&
					Object.entries(props.options).map(([option, value]) => (
						<div
							key={option}
							className={["ui-select-option", disableSelected && "selected-are-disabled", selected && selected[option] && "is-selected"].join(" ")}
							onClick={() => optionSelected({ option, value })}>
							{value}
						</div>
					))}

				{/* Are there any results in the filtered options list? */}
				{Object.keys(filteredOptions).length > 0 &&
					Object.entries(filteredOptions).map(([option, value]) => (
						<div
							key={option}
							className={["ui-select-option", disableSelected && "selected-are-disabled", selected && selected[option] && "is-selected"].join(" ")}
							onClick={() => optionSelected({ option, value })}>
							{value}
						</div>
					))}
			</div>

			{/* Is there an error or note to display */}
			{props.error ? <small>{props.error}</small> : props.note && <small>{props.note}</small>}
		</div>
	)
}

import './row.scss'

// UI components
import { ArrowRightIcon, PencilIcon, TrashIcon } from '../../../../../utils/svgs-v2'
import Badge from '../../../../ui/badge/badge'

// Returns the markup for a price row
export default function NoteRow({ details, edit, checkDelete }) {
    return (
        <div className={['resort-note-row'].join(' ').trim()}>
            <div className='price-row-details'>
                <div className='price-option-period'>
                    {details.period_state === 'PAST' && <Badge type='DEFAULT' label='Previous' />}
                    {details.period_state === 'ACTIVE' && <Badge type='POSITIVE' label='Active' />}
                    {details.period_state === 'UPCOMING' && <Badge type='INFO' label='Upcoming' />}
                </div>

                <div className='price-option-dates'>
                    <p>{details.start_date}</p>
                    <ArrowRightIcon />
                    <p>{details.end_date}</p>
                </div>

                <div className='price-option-actions'>
                    <div className='price-option-action' onClick={() => edit(details.id, details)}>
                        <PencilIcon />
                    </div>

                    <div className='price-option-action' onClick={() => checkDelete(details.id)}>
                        <TrashIcon />
                    </div>
                </div>
            </div>
            <div className='price-row-name'>
                <p>{details.text}</p>
            </div>
        </div>
    )
}
import { useEffect, useState } from 'react'
import { db } from '../../../utils/firebase'
import moment from 'moment'
import './quote.scss'

// UI components
import Button from '../../../components/ui/button/button'
import { TrashIcon } from '../../../utils/svgs-v2'
import QuoteDocument from './components/document'

// Returns the HTML markup for a quote row
export default function QuoteRow({ details, deleteQuote, deleting }) {
    const [agent, setAgent] = useState('')

    // Create a date string for the created date
    const createdAt = moment(details.created?._seconds || details.created?.seconds, "X").format("MMM D[,] YYYY")

    // On component load
    useEffect(() => {
        // Fetch the agent details attached to the quote
        db.doc(`users/${details.agent}`)
            .get().then((agentDoc) => {
                // Set the agent data into the state
                setAgent(agentDoc.data())
            })
    }, [])

    return (
        <tr>
            <td>{createdAt}</td>
            <td>
                {agent?.first_name && `${agent?.first_name} ${agent?.last_name}`}
                {!agent?.first_name && <small className="no-value">No agent assigned</small>}
            </td>
            <td className='client-quote-references'>
                {details.packages?.map((quoteObject) => (
                    <QuoteDocument quoteID={details.id} quotePackage={quoteObject} />
                ))}
            </td>
            <td className="is-button">
                <Button
                    small={true}
                    loading={deleting}
                    icon={<TrashIcon />}
                    className='no-loading-margin'
                    onClick={() => deleteQuote(details.id)} />
            </td>
        </tr>
    )
}
import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import { ROOM_TYPES } from "../../../utils/exports/room-types";
import firebase from "firebase";
import moment from "moment";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Textarea from "../../../components/ui/inputs/textarea";
import Window from "../../../components/structure/window/window";

function Hotel(props) {
    const [booking, setBooking] = useState({});
    const [resort, setResort] = useState({});
    const [hotel, setHotel] = useState({});
    const [rooms, setRooms] = useState("");

    const [approving, setApproving] = useState(false);
    const [approvalModal, setApprovalModal] = useState(false);
    const [approvalComments, setApprovalComments] = useState("");

    const [rejecting, setRejecting] = useState(false);
    const [rejectionModal, setRejectionModal] = useState(false);
    const [rejectionComments, setRejectionComments] = useState("");

    /**
     * Get the bookingID from the props
     */
    const { bookingID, hotelID, complete } = props;

    /**
     * Get the group size by adding both golfers and non golfers
     */
    const group_size = Number(booking.golfers + booking.non_golfers);
    /**
     * Get the check in date
     */
    const arrival = moment(hotel.check_in?.seconds, "X").format("DD/MM/YYYY");
    /**
     * Get the departure date by adding the nights to the original check in date
     */
    const departure = moment(hotel.check_in?.seconds, "X").add(hotel.nights, "days").format("DD/MM/YYYY");

    /**
     * When the bookingID is updated
     */
    useEffect(() => {
        pullTransferDetails();
    }, [bookingID, hotelID]);

    /**
     * Pull the details for the transfer from the databse to display on the front-end
     */
    const pullTransferDetails = async () => {
        /**
         * Get the booking details from the database
         */
        await db.doc(`bookings/${bookingID}`)
            .get().then((bookingDoc) => {
                /**
                 * Set the booking data into the state
                 */
                setBooking({ ...bookingDoc.data() });
            });
        /**
         * Then get the hotel details from the database
         */
        await db.doc(`bookings/${bookingID}/hotels/${hotelID}`)
            .get().then((hotelDoc) => {
                /**
                 * Get the resortID for the hotel
                 */
                const { resortID, rooms } = hotelDoc.data();
                /**
                 * Set the hotel data into the state
                 */
                setHotel({ ...hotelDoc.data() });
                /**
                 * Setup an array to store the various room types
                 */
                let room_options = {};
                /**
                 * Loop through the rooms on the hotel document
                 */
                rooms.forEach((room) => {
                    /**
                     * Either increment the count by 1 or set it to 1 if it doesn't exist yet
                     */
                    room_options[ROOM_TYPES[room.room_type]] = room_options[ROOM_TYPES[room.room_type]] + 1 || 1;
                });
                /**
                 * Setup a new array for storing the room types
                 */
                let rooms_string = [];
                /**
                 * Loop through the rooms on the hotel document
                 */
                for (const [room, count] of Object.entries(room_options)) {
                    rooms_string.push(`${count} x ${room}`);
                }
                /**
                 * Set the rooms into the state
                 */
                setRooms(rooms_string);
                /**
                 * Then get the hotel partner document
                 */
                return db.doc(`hotels/${resortID}`)
                    .get().then((resortDoc) => {
                        /**
                         * Set the hotel data into the state
                         */
                        setResort({ ...resortDoc.data() });
                    });
            });
    }

    /**
     * Approve the reservation
     */
    const approveReservation = async () => {
        /**
         * Update the state to show it's loading
         */
        setApproving(true);
        setApprovalModal(false);
        /**
         * Update the booking document
         */
        await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).set({
            booking_status: "approved",
            booking_status_comments: approvalComments || "",
        }, { merge: true });
        /**
         * Add a log tot he booking to reflect the approval
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            type: "HOTEL_BOOKING_APPROVED",
            badge: "SUCCESS",
            message: `The hotel (${resort?.name}) for this booking was approved`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setApproving(false);
        /**
         * Show the completed thank you message
         */
        complete();
    }

    /**
     * Reject the reservation
     */
    const rejectReservation = async () => {
        /**
         * Update the state to show it's loading
         */
        setRejecting(true);
        setRejectionModal(false);
        /**
         * Update the booking document
         */
        await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).set({
            booking_status: "rejected",
            booking_status_comments: rejectionComments,
        }, { merge: true });
        /**
         * Add a log tot he booking to reflect the rejection
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            type: "HOTEL_BOOKING_REJECTED",
            badge: "ERROR",
            message: `The hotel (${resort?.name}) for this booking was rejected with the following reason: ${rejectionComments}`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setRejecting(false);
        /**
         * Show the completed thank you message
         */
        complete();
    }

    return (
        <>
            <div className="reservation-block">
                {/* <div className="reservation-table">
                    <table className="booking-table">
                        <tbody>
                            <tr>
                                <td>Hotel:</td>
                                <td>
                                    {resort?.name}
                                </td>
                            </tr>
                            <tr>
                                <td>Arrival:</td>
                                <td>
                                    {arrival}
                                </td>
                            </tr>
                            <tr>
                                <td>Nights:</td>
                                <td>
                                    {hotel?.nights}
                                </td>
                            </tr>
                            <tr>
                                <td>Group size:</td>
                                <td>
                                    {group_size}
                                </td>
                            </tr>
                            <tr>
                                <td>Rooms:</td>
                                <td>
                                    {rooms}
                                </td>
                            </tr>
                            <tr>
                                <td>Price:</td>
                                <td>
                                    {resort?.currency === "usd" ? "$" : (resort?.currency === "eur" ? "€" : "£")}
                                    {hotel?.total_cost?.toLocaleString()}
                                </td>
                            </tr>
                            <tr>
                                <td>Comments:</td>
                                <td>
                                    {hotel.comments && `${hotel.comments}`}
                                    {!hotel.comments && <small>No additional comments</small>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}

                <div className="reservation-actions">
                    <Button
                        label="Approve booking"
                        onClick={() => setApprovalModal(true)} />
                    <Button
                        className="OUTLINE"
                        label="Reject booking"
                        onClick={() => setRejectionModal(true)} />
                </div>
            </div>

            {rejectionModal &&
                <Window
                    title="Rejection reason"
                    className="slim reservation-modal"
                    close={() => setRejectionModal(false)}>

                    <Textarea
                        placeholder="Please suggest an alternative:"
                        value={rejectionComments}
                        onChange={setRejectionComments} />

                    <Button
                        fullWidth={true}
                        label="Send rejection reason"
                        disabled={!rejectionComments}
                        loading={rejecting}
                        loadingText="Sending rejection..."
                        onClick={() => rejectReservation()} />
                </Window>
            }

            {approvalModal &&
                <Window
                    title="Additional comments"
                    className="slim reservation-modal"
                    close={() => setApprovalModal(false)}>

                    <Textarea
                        placeholder="Any additional comments:"
                        value={approvalComments}
                        onChange={setApprovalComments} />

                    <Button
                        fullWidth={true}
                        label="Send approval"
                        loading={approving}
                        loadingText="Sending approval..."
                        onClick={() => approveReservation()} />
                </Window>
            }
        </>
    );
}

export default Hotel;
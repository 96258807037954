import { useContext } from 'react'
import { QuoteContext } from '../context/context';

// UI components
import Button from '../../../components/ui/button/button'
import { CheckIcon } from '../../../utils/svgs-v2'

// Returns the HTML markup for the final stage of the quote generation
export default function Sent({ resetStages }) {
    // Pull the clear context function
    const { ctxClearEntireState } = useContext(QuoteContext)

    // Clear the quote context and reset to statrt again
    const clearAndReset = async () => {
        // Clear the quote context
        ctxClearEntireState()

        // Then swap the state back to the start
        resetStages()
    }

    return (
        <>
            <div className='generate-quote-header'>
                <div className='generate-quote-title quote-sent'>Quote Sent! <CheckIcon /></div>
                <div className='generate-quote-subtitle'>The client has been email and the quote saved</div>
            </div>

            <div className='quote-action-buttons'>
                <Button
                    label='Start again'
                    onClick={() => clearAndReset()} />
            </div>
        </>
    )
}
import React from "react"
import "./client.scss"

// UI components
import Button from "../ui/button/button"
import { NavLink } from "react-router-dom"

// Returns the HTML for a client table row
export default function Client({ id, details, maskDetails, showClientWindow }) {
    // Store the client details in variables
    let clientEmail = details.email || null
    let clientPhone = details.phone || null

    // If we have the requirement to mask particular client details
    if (maskDetails) {
        // Mask the entire email address
        // clientEmail = clientEmail?.replace(/./g, '*') || null

        // Do the same for the phone number
        // clientPhone = clientPhone?.replace(/./g, '*') || null
    }

    return (
        <tr>
            <td>
                {maskDetails && (
                    <>{details.first_name && `${details.first_name} ${details.last_name}`}</>
                )}

                {!maskDetails && (
                    <NavLink to={`client/${id}`}>
                        {details.first_name && `${details.first_name} ${details.last_name}`}
                    </NavLink>
                )}

                {!details.first_name && <small className="no-value">No name set</small>}
            </td>
            <td>
                {details.email && <>{clientEmail}</>}
                {!details.email && <small className="no-value">No email set</small>}
            </td>
            <td>
                {details.phone && <>{clientPhone}</>}
                {!details.phone && <small className="no-value">No number set</small>}
            </td>
            <td>
                {details.stableford_points && <>{details.stableford_points} (£{details.stableford_points / 10})</>}
                {!details.stableford_points && <>0</>}
            </td>

            {!maskDetails && (
                <td className="is-button">
                    <Button
                        small={true}
                        label="View client"
                        onClick={() => showClientWindow(id)} />
                </td>
            )}
        </tr>
    );
}
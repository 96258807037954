import { useEffect, useState } from 'react'

// Firebase utilities
import { db } from '../../../../utils/firebase'

// Router library
import { Redirect } from 'react-router-dom'

// UI components
import Tile from '../../../../components/structure/tile/tile'
import Title from '../../../../components/structure/title/title'
import Navigation from '../../../../components/ui/navigation/navigation'
import HolidayDetails from '../../../../components/cms/holiday/details/details'
import HolidayCourses from '../../../../components/cms/holiday/courses/courses'
import HolidayPricing from '../../../../components/cms/holiday/pricing/pricing'
import HolidayDiscounts from '../../../../components/cms/holiday/discounts/discounts'
import HolidayNotes from '../../../../components/cms/holiday/notes/notes'

// Returns a screen tile to edit the properties against a holiday
export default function EditHoliday({ match }) {
    const [missing, setMissing] = useState(false)
    const [activeTab, setActiveTab] = useState('DETAILS')

    // Keep a state store of the holiday data being streamed from the database
    const [holiday, setHoliday] = useState({})

    // Deconstruct the ID from the params
    const { id } = match.params

    // When the ID property is updated
    useEffect(() => {
        // Mount a listener onto the document for this 
        const unsubscribe = db.doc(`CMS_holidays/${id}`).onSnapshot((holidayDoc) => {
            // Make sure the holiday exists first
            !holidayDoc.exists && setMissing(true)

            // If it does, deconstruct the data from the document
            setHoliday({ id: holidayDoc.id, ...holidayDoc.data() })
        });

        // Unmount the listener when the componenet itself is unmounted
        return () => unsubscribe()
    }, [id])

    // No ID or document found? Redirect back to the manage page
    if (!id || missing) {
        return <Redirect to="/manage/holidays" push />
    }

    return (
        <Tile fullPage={true}>
            {/* If we are still waiting on holiday data */}
            {!holiday?.title && 'loading'}

            {/* If we now have holiday data available */}
            {holiday?.title &&
                <>
                    <Title>
                        <h1>{holiday.title}</h1>
                        <p>
                            <a href={`https://www.golfholidaysdirect.com/${holiday.url}`} target="_blank" rel="noreferrer">{holiday.url}</a>
                        </p>
                    </Title>

                    {/* Tabbed navigation between screens */}
                    <Navigation links={[
                        {
                            id: "DETAILS",
                            label: "Details",
                        },
                        {
                            id: "COURSES",
                            label: "Courses",
                        },
                        {
                            id: "PRICING",
                            label: "Pricing",
                        },
                        {
                            id: "CUSTOM_PRICING",
                            label: "Custom Pricing",
                        },
                        {
                            id: "DISCOUNTS",
                            label: "Discounts",
                        },
                        {
                            id: "NOTES",
                            label: "Notes",
                        }]}
                        active={activeTab}
                        onClick={(tabID) => setActiveTab(tabID)}
                    />

                    {/* Page routes for the holiday */}
                    {activeTab === 'DETAILS' && <HolidayDetails holidayID={id} holiday={holiday} />}
                    {activeTab === 'COURSES' && <HolidayCourses holiday={holiday} />}
                    {activeTab === 'PRICING' && <HolidayPricing holiday={holiday} />}
                    {activeTab === 'CUSTOM_PRICING' && <HolidayPricing holiday={holiday} agent={true} />}
                    {activeTab === 'DISCOUNTS' && <HolidayDiscounts holiday={holiday} />}
                    {activeTab === 'NOTES' && <HolidayNotes holiday={holiday} />}
                </>
            }
        </Tile>
    )
}
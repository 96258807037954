import './holidays.scss'

// Algolia library
import algoliasearch from "algoliasearch";
import {
    InstantSearch,
    connectSearchBox,
    connectHits,
    connectStateResults,
} from "react-instantsearch-dom";

// UI components
import Tile from "../../../components/structure/tile/tile";
import Title from "../../../components/structure/title/title";
import Table from "../../../components/structure/table/table";
import Input from "../../../components/ui/inputs/input";
import { SearchIcon } from "../../../utils/svgs";
import HolidayRow from "../../../components/cms/holiday/holidayRow";

// Returns the container markup for the CMS holidays streamed in
export default function Holidays() {
    // Create a new instance of the Algolia search client
    const searchClient = algoliasearch(
        "HY764WYKNV",
        "059a9cd79a33ec2b2822acf2ffdfd4c9",
    );

    // Create a custom input field for the search
    const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
        <form noValidate action="" role="search">
            <Input
                value={currentRefinement}
                icon={<SearchIcon />}
                fullEvent={true}
                placeholder="Quick search"
                onChange={(e) => refine(e.currentTarget.value)} />
        </form>
    );

    // And then attach it to the Aloglia instance
    const CustomSearchBox = connectSearchBox(SearchBox);

    // Create a custom flow for returning and displaying the hits against a search
    const Hits = ({ hits }) => {
        // Were any hits returned?
        if (hits.length > 0) {
            // Print them into the DOM
            return hits.map((hit) => (
                <HolidayRow key={hit.objectID} id={hit.objectID} details={hit} />
            ));
        } else {
            // Otherwise just return null
            return null;
        }
    };

    // Attach this custom hits component to Aloglia
    const CustomHits = connectHits(Hits);

    // Create a custom component for showing where there are no results against a search
    const Results = connectStateResults(
        ({ searchState, searchResults, children }) =>
            searchResults && searchResults.nbHits !== 0 ? (
                <CustomHits />
            ) : (
                <tr>
                    <td colSpan={8}>
                        <small>No results have been found {searchState.query ? `for ${searchState.query}.` : ""}</small>
                    </td>
                </tr>
            )
    );

    return (
        <Tile fullPage={true}>
            {/* Wrap the container in the Algolia instant search wrapper */}
            <InstantSearch searchClient={searchClient} indexName="cms_holidays">
                <Title className="flex">
                    <h1>Holidays</h1>

                    {/* Mount the input field for Algolia */}
                    <CustomSearchBox />
                </Title>

                <Table
                    className="bookings-table"
                    headings={[
                        "Name",
                        "URL",
                    ]}>

                    <Results />
                </Table>
            </InstantSearch>
        </Tile>
    )
}
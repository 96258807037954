import { useState, useEffect } from "react"
import { db } from "../../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"
import Badge from "../../../components/ui/badge/badge"

// Returns the HTML markup for a deleted enquiry row
export default function EnquiryRow({ enquiry }) {
	// Pull the relevant details from the enquiry
	const { type, created, byUser, fromUser, toUser, enquiry: enquiryID } = enquiry

	// States
	const [agentAction, setAgentAction] = useState("")
	const [agentFrom, setAgentFrom] = useState("")
	const [agentTo, setAgentTo] = useState("")
	const [site, setSite] = useState("")
	const [clientName, setClientName] = useState("")
	const [clientID, setClientID] = useState("")
	const [location, setLocation] = useState("")

	// On component load
	useEffect(() => {
		const fetchDetails = async () => {
			db.doc(`enquiries/${enquiryID}`)
				.get()
				.then((doc) => {
					setSite(doc.data().site)
					setLocation(doc.data().location)

					db.doc(`clients/${doc.data().client}`)
						.get()
						.then((clientDoc) => {
							setClientName(`${clientDoc.data().first_name} ${clientDoc.data().last_name}`)
							setClientID(clientDoc.id)
						})
				})

			db.doc(`users/${byUser}`)
				.get()
				.then((doc) => {
					setAgentAction(`${doc.data().first_name} ${doc.data().last_name}`)
				})

			if (fromUser) {
				await db
					.doc(`users/${fromUser}`)
					.get()
					.then((doc) => {
						setAgentFrom(`${doc.data().first_name} ${doc.data().last_name}`)
					})
			}

			if (toUser) {
				await db
					.doc(`users/${toUser}`)
					.get()
					.then((doc) => {
						setAgentTo(`${doc.data().first_name} ${doc.data().last_name}`)
					})
			}

			// await db
			// 	.doc(`clients/${baseEnquiry.client}`)
			// 	.get()
			// 	.then((clientDoc) => {
			// 		setClientName(`${clientDoc.data().first_name} ${clientDoc.data().last_name}`)
			// 	})
		}
		fetchDetails()
	}, [])

	// Format the removed date
	const actionOn = moment(created?.seconds, "X").format("MMM D[,] YYYY [at] HH:mm:ss")

	// "Site", "Client Name", "Location/Event", "Timestamp", "Type", "Action by", "From agent", "To agent"
	return (
		<tr>
			<td>{site}</td>
			<td>
				<NavLink to={`client/${clientID}`}>{clientName}</NavLink>
			</td>
			<td>{location}</td>
			<td>{actionOn}</td>
			<td>
				{type === "INITIAL_ASSIGNMENT" && (
					<Badge
						type="INFO"
						label="Initial Assignment"
					/>
				)}

				{type === "REASSIGNED" && (
					<Badge
						type="AMBER"
						label="Re-assignment"
					/>
				)}
			</td>
			<td>{agentAction}</td>
			<td>
				{!agentFrom && <small>No data</small>}

				{agentFrom && <>{agentFrom}</>}
			</td>
			<td>{agentTo}</td>
		</tr>
	)
}

import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../utils/providers/auth";
import { CALENDAR_MONTHS } from "../../utils/exports/months";
import { auth, db } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "./stats.scss";

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Select from "../../components/ui/select/select";
import Button from "../../components/ui/button/button";
import Table from "../../components/structure/table/table";
import Booking from "../../components/stats/booking";
import Checkbox from "../../components/ui/checkbox/checkbox";

/**
 * Functional component to return the dashboard for viewing travellers by various dates
 */
function Stats() {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);

  const [agents, setAgents] = useState({});
  const [years, setYears] = useState({});

  const [agent, setAgent] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [type, setType] = useState("");
  const [flightsOnly, setFlightsOnly] = useState(false);

  const [flightsCost, setFlightsCost] = useState(0);
  const [customerPrice, setCustomerPrice] = useState(0);
  const [paidByClient, setPaidByClient] = useState(0);
  const [supplierInvoices, setSupplierInvoices] = useState(0);
  const [margins, setMargins] = useState(0);
  const [commission, setCommission] = useState(0);

  const [newMargins, setNewMargins] = useState(0);
  const [newCommission, setNewCommission] = useState(0);

  const [paxFlying, setPaxFlying] = useState(0);
  const [paxNotFlying, setPaxNotFlying] = useState(0);

  /**
   *
   */
  const totalMarginStatsFigure = useRef({});
  const totalCommisionStatsFigure = useRef({});

  const paxWithFlights = useRef({})
  const paxWithoutFlights = useRef({})

  /**
   * Get the current user auth from the context
   */
  const { user } = useContext(AuthContext);

  /**
   *
   */
  const clearLocalFigures = () => {
    /**
     *
     */
    totalMarginStatsFigure.current = {};
    totalCommisionStatsFigure.current = {};
    paxWithFlights.current = {};
    paxWithoutFlights.current = {};

    /**
     *
     */
    setNewMargins(0);
    setNewCommission(0);
    setPaxFlying(0);
    setPaxNotFlying(0);
  };

  /**
   * On load for the context
   */
  useEffect(() => {
    /**
     * Is this user an admin? If they are, they should have access to all agents stats,
     * otherswise only allow access to their own
     */
    if (user?.is_admin) {
      /**
       * Pull a list of agents from the use list
       */
      db.collection("users")
        .orderBy("first_name")
        .get()
        .then((agentDocs) => {
          let agentsArr = [];
          /**
           * Loop through the agent documents found
           */
          agentDocs.forEach((agentDoc) => {
            /**
             * Deconstruct the agent details
             */
            const { first_name, last_name } = agentDoc.data();
            /**
             * Add them into the array
             */
            agentsArr[agentDoc.id] = `${first_name} ${last_name}`;
          });
          /**
           * Push the user into the state
           */
          setAgents(agentsArr);
        });
    } else {
      let agentsArr = [];
      /**
       * Assign only this agents user ID into the array
       */
      agentsArr[auth.currentUser.uid] = `${user?.first_name} ${user?.last_name}`;
      /**
       * Set this single-item array into the state
       */
      setAgents(agentsArr);
    }
  }, []);

  /**
   * On component load
   */
  useEffect(() => {
    /**
     * Create an empty object for storing the years
     */
    let yearsObj = {};
    /**
     * Get next year as a number
     */
    let nextYear = Number(moment().startOf(year).add(2, "years").format("YYYY"));
    /**
     * Run through a for loop to print out the years
     */
    for (let i = nextYear; i >= 2018; i--) {
      yearsObj = {
        [i]: i,
        ...yearsObj,
      };
    }
    /**
     * Set the years into the state
     */
    setYears(yearsObj);
  }, []);

  /**
   * Fetch the travellers from the database
   */
  const fetchTravellers = async () => {
    /**
     * Clear the local figures
     */
    clearLocalFigures();

    /**
     * Show a loading spinner
     */
    setLoading(true);

    if (year && !month && !agent && user.is_admin) {
      byYear();
    } else if (year && month && !agent && user.is_admin) {
      byYearAndMonth();
    } else if (year && month && agent) {
      byYearMonthAndAgent();
    } else {
      setLoading(false)
    }
  };

  const byYear = async () => {
    /**
     * Get the milliseconds timestamps from the chosen filters
     */
    const fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf();
    const fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf();
    /**
     * Build some firestore timestamps from the milliseconds
     */
    const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart);
    const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd);
    /**
     * Setup a variable to store the field we are going to be searching the database with
     */
    let searchBy = "check_in";
    /**
     * Are we fetching the bookings by travel or booked date?
     */
    if (type === "travel_date") {
      searchBy = "check_in";
    } else if (type === "booked_date") {
      searchBy = "booked";
    }
    /**
     * Pull all the bookings by their travel date
     */
    await db
      .collection("bookings")
      .where(`${searchBy}`, ">=", startTimestamp)
      .where(`${searchBy}`, "<=", endTimestamp)
      .where("removed", "==", false)
      .orderBy(`${searchBy}`)
      .get()
      .then((bookingDocs) => {
        /**
         * Create an empty array for storing the bookings
         */
        let bookingsArr = [];
        /**
         * Setup some variables for storing the totals
         */
        let totalFlightsCost = 0;
        let totalCustomerPrices = 0;
        let totalPaidByClients = 0;
        let totalSupplierInvoices = 0;
        let totalSalesMargins = 0;
        let totalCommissions = 0;
        /**
         * Loop through the bookings that have been found that match
         */
        bookingDocs.forEach((bookingDoc) => {
          /**
           * Deconstruct some balances from the booking document
           */
          const { paid_by_client, margins } = bookingDoc.data();
          /**
           * Pull the margins from the
           */
          const { customer_price, flights_cost, supplier_invoices, agents_margin } = margins || {};

          // Are we looking for only those bookings with a flights value attached?
          if ((flightsOnly && flights_cost > 0) || (!flightsOnly && flights_cost >= 0)) {
            /**
             * Check to make sure there is a customer price and supplier invoice figures
             */
            if (margins && customer_price > 0 && supplier_invoices > 0) {
              /**
               * work out the sales margin for the booking
               */
              const salesMargin = Number.parseFloat(customer_price - supplier_invoices);
              /**
               * Add the values from this booking
               */
              totalFlightsCost = totalFlightsCost + Number.parseFloat(flights_cost || 0);
              totalCustomerPrices = totalCustomerPrices + Number.parseFloat(customer_price || 0);
              totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0);
              totalSupplierInvoices = totalSupplierInvoices + Number.parseFloat(supplier_invoices || 0);
              totalSalesMargins = totalSalesMargins + salesMargin;
              totalCommissions = totalCommissions + Number.parseFloat(agents_margin || 0);
              /**
               * Push the booking data into the array
               */
              bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() });
            } else {
              /**
               * Push the booking data into the array
               */
              bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data(), missing_values: true });
            }
          }
        });
        /**
         * Set the bookings into the state
         */
        setBookings(bookingsArr);
        /**
         * Set the totals into the state
         */
        setFlightsCost(totalFlightsCost || 0);
        setCustomerPrice(totalCustomerPrices || 0);
        setPaidByClient(totalPaidByClients || 0);
        setSupplierInvoices(totalSupplierInvoices || 0);
        setMargins(totalSalesMargins || 0);
        setCommission(totalCommissions || 0);
      });
    /**
     * Reset the state
     */
    setLoading(false);
  };

  const byYearAndMonth = async () => {
    /**
     * Get the milliseconds timestamps from the chosen filters
     */
    const fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf();
    const fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf();
    /**
     * Build some firestore timestamps from the milliseconds
     */
    const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart);
    const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd);
    /**
     * Setup a variable to store the field we are going to be searching the database with
     */
    let searchBy = "check_in";
    /**
     * Are we fetching the bookings by travel or booked date?
     */
    if (type === "travel_date") {
      searchBy = "check_in";
    } else if (type === "booked_date") {
      searchBy = "booked";
    }

    // Query the database for all the bookings that match the requirements
    await db
      .collection("bookings")
      .where(`${searchBy}`, ">=", startTimestamp)
      .where(`${searchBy}`, "<=", endTimestamp)
      .where("removed", "==", false)
      .orderBy(`${searchBy}`)
      .get()
      .then((bookingDocs) => {
        /**
         * Create an empty array for storing the bookings
         */
        let bookingsArr = [];
        /**
         * Setup some variables for storing the totals
         */
        let totalFlightsCost = 0;
        let totalCustomerPrices = 0;
        let totalPaidByClients = 0;
        let totalSupplierInvoices = 0;
        let totalSalesMargins = 0;
        let totalCommissions = 0;
        /**
         * Loop through the bookings that have been found that match
         */
        bookingDocs.forEach((bookingDoc) => {
          /**
           * Deconstruct some balances from the booking document
           */
          const { paid_by_client, margins } = bookingDoc.data();

          // Pull the margins from the booking
          const { customer_price, flights_cost, supplier_invoices, agents_margin } = margins || {};

          // Are we looking for only those bookings with a flights value attached?
          if ((flightsOnly && flights_cost > 0) || (!flightsOnly && flights_cost >= 0)) {
            // Check to make sure there is a customer price and supplier invoice figures
            if (margins && customer_price > 0 && supplier_invoices > 0) {
              // Work out the sales margin for the booking
              const salesMargin = Number.parseFloat(customer_price - supplier_invoices);

              // Add the values from this booking
              totalFlightsCost = totalFlightsCost + Number.parseFloat(flights_cost || 0);
              totalCustomerPrices = totalCustomerPrices + Number.parseFloat(customer_price || 0);
              totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0);
              totalSupplierInvoices = totalSupplierInvoices + Number.parseFloat(supplier_invoices || 0);
              totalSalesMargins = totalSalesMargins + salesMargin;
              totalCommissions = totalCommissions + Number.parseFloat(agents_margin || 0);

              // Push the booking data into the array
              bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() });
            } else {
              // Push the booking into the array without the additional values
              bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data(), missing_values: true });
            }
          }
        });
        /**
         * Set the bookings into the state
         */
        setBookings(bookingsArr);
        /**
         * Set the totals into the state
         */
        setFlightsCost(totalFlightsCost || 0);
        setCustomerPrice(totalCustomerPrices || 0);
        setPaidByClient(totalPaidByClients || 0);
        setSupplierInvoices(totalSupplierInvoices || 0);
        setMargins(totalSalesMargins || 0);
        setCommission(totalCommissions || 0);
      });
    /**
     * Reset the state
     */
    setLoading(false);
  };

  const byYearMonthAndAgent = async () => {
    /**
     * Get the milliseconds timestamps from the chosen filters
     */
    const fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf();
    const fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf();
    /**
     * Build some firestore timestamps from the milliseconds
     */
    const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart);
    const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd);
    /**
     * Setup a variable to store the field we are going to be searching the database with
     */
    let searchBy = "check_in";
    /**
     * Are we fetching the bookings by travel or booked date?
     */
    if (type === "travel_date") {
      searchBy = "check_in";
    } else if (type === "booked_date") {
      searchBy = "booked";
    }
    /**
     * Pull all the bookings by their travel date
     */
    await db
      .collection("bookings")
      .where(`${searchBy}`, ">=", startTimestamp)
      .where(`${searchBy}`, "<=", endTimestamp)
      .where("agent", "==", agent.option)
      .where("removed", "==", false)
      .orderBy(`${searchBy}`)
      .get()
      .then((bookingDocs) => {
        /**
         * Create an empty array for storing the bookings
         */
        let bookingsArr = [];
        /**
         * Setup some variables for storing the totals
         */
        let totalFlightsCost = 0;
        let totalCustomerPrices = 0;
        let totalPaidByClients = 0;
        let totalSupplierInvoices = 0;
        let totalSalesMargins = 0;
        let totalCommissions = 0;
        /**
         * Loop through the bookings that have been found that match
         */
        bookingDocs.forEach((bookingDoc) => {
          /**
           * Deconstruct some balances from the booking document
           */
          const { paid_by_client, margins } = bookingDoc.data();
          /**
           * Pull the margins from the
           */
          const { customer_price, flights_cost, supplier_invoices, agents_margin } = margins || {};

          // Are we looking for only those bookings with a flights value attached?
          if ((flightsOnly && flights_cost > 0) || (!flightsOnly && flights_cost >= 0)) {
            /**
             * Check to make sure there is a customer price and supplier invoice figures
             */
            if (margins && customer_price > 0 && supplier_invoices > 0) {
              /**
               * work out the sales margin for the booking
               */
              const salesMargin = Number.parseFloat(customer_price - supplier_invoices);
              /**
               * Add the values from this booking
               */
              totalFlightsCost = totalFlightsCost + Number.parseFloat(flights_cost || 0);
              totalCustomerPrices = totalCustomerPrices + Number.parseFloat(customer_price || 0);
              totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0);
              totalSupplierInvoices = totalSupplierInvoices + Number.parseFloat(supplier_invoices || 0);
              totalSalesMargins = totalSalesMargins + salesMargin;
              totalCommissions = totalCommissions + Number.parseFloat(agents_margin || 0);
              /**
               * Push the booking data into the array
               */
              bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() });
            } else {
              /**
               * Push the booking data into the array
               */
              bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data(), missing_values: true });
            }
          }
        });
        /**
         * Set the bookings into the state
         */
        setBookings(bookingsArr);
        /**
         * Set the totals into the state
         */
        setFlightsCost(totalFlightsCost || 0);
        setCustomerPrice(totalCustomerPrices || 0);
        setPaidByClient(totalPaidByClients || 0);
        setSupplierInvoices(totalSupplierInvoices || 0);
        setMargins(totalSalesMargins || 0);
        setCommission(totalCommissions || 0);
      });
    /**
     * Reset the state
     */
    setLoading(false);
  };

  const allHeadings = [
    "Booked",
    "Reference",
    "Site",
    "Lead pax",
    "Email",
    "Travelling",
    "No. of pax",
    "Location",
    "Flights",
    "Holiday cost",
    "Paid by client",
    "Supplier invoice",
    "Sales margin",
    "Commission",
    "Confirmed",
    "Resorts paid",
    "",
  ];

  const restrictedHeadings = [
    "Booked",
    "Reference",
    "Site",
    "Lead pax",
    "Travelling",
    "No. of pax",
    "Location",
    "Flights",
    "Holiday cost",
    "Paid by client",
    "Supplier invoice",
    "Sales margin",
    "Commission",
    "Confirmed",
    "Resorts paid",
    "",
  ];

  const updateLocalFigures = (id, type, figure) => {
    if (isNaN(figure)) return

    if (type === "MARGIN") {
      totalMarginStatsFigure.current[id] = figure;
    } else if (type === "COMMISION") {
      totalCommisionStatsFigure.current[id] = figure;
    }

    setNewMargins(
      Object.values(totalMarginStatsFigure.current)
        .reduce((a, b) => a + b, 0)
        ?.toLocaleString()
    );
    setNewCommission(
      Object.values(totalCommisionStatsFigure.current)
        .reduce((a, b) => a + b, 0)
        ?.toLocaleString()
    );
  };

  const updateLocalPAX = (id, pax = 0, flying = false) => {
    if (flying) {
      paxWithFlights.current[id] = pax;
    } else {
      paxWithoutFlights.current[id] = pax;
    }

    setPaxFlying(
      Object.values(paxWithFlights.current)
        .reduce((a, b) => a + b, 0)
        ?.toLocaleString()
    );
    setPaxNotFlying(
      Object.values(paxWithoutFlights.current)
        .reduce((a, b) => a + b, 0)
        ?.toLocaleString()
    );
  }

  return (
    <Tile fullPage={true}>
      <Title className="flex has-select-field">
        <h1>Stats</h1>
      </Title>

      <div className="page-filters extra-stats">
        <Select
          label="Agent:"
          placeholder="Agent:"
          value={agent}
          selected={agent.option}
          activeOnHover={true}
          onSelect={(option) => setAgent(option)}
          options={agents}
        />

        <Select
          label="Month:"
          placeholder="Month:"
          value={month}
          activeOnHover={true}
          onSelect={(option) => setMonth(option.option)}
          options={CALENDAR_MONTHS}
        />

        <Select
          label="Year:"
          placeholder="Year:"
          value={year}
          activeOnHover={true}
          onSelect={(option) => setYear(option.option)}
          options={years}
        />

        <Select
          label="Search by:"
          placeholder="Search by:"
          value={type}
          selected={type}
          activeOnHover={true}
          onSelect={(option) => setType(option.option)}
          options={{
            travel_date: "Travel date",
            booked_date: "Booked date",
          }}
        />

        <Checkbox
          checked={flightsOnly}
          onClick={() => setFlightsOnly(!flightsOnly)}
          label="Only inc. flights"
        />

        <div>
          <Button
            filters={true}
            disabled={((!agent || !month || !year) && (!month || !year) && !year) || !type}
            label="Fetch stats"
            loadingText="Fetching stats..."
            loading={loading}
            onClick={() => fetchTravellers()}
          />
        </div>
      </div>

      <Table
        className="travellers-table"
        headings={user?.is_super_admin ? allHeadings : restrictedHeadings}
        noResults={Object.entries(bookings).length === 0}
        noResultsMessage={"No results matching those filters"}
      >
        {bookings.map((booking) => (
          <Booking
            key={booking?.id}
            id={booking?.id}
            details={booking}
            show_email={user?.is_super_admin}
            update_local_totals={(id, type, figure) => updateLocalFigures(id, type, figure)}
            update_local_pax={(id, pax, flying) => updateLocalPAX(id, pax, flying)}
          />
        ))}

        <tr className="totals-row">
          <td colSpan={user?.is_super_admin ? "6" : "5"}>Totals:</td>
          <td>{paxFlying} (F) - {paxNotFlying} (NF)</td>
          <td></td>
          <td>£{flightsCost?.toLocaleString() || 0}</td>
          <td>£{customerPrice?.toLocaleString() || 0}</td>
          <td>£{paidByClient?.toLocaleString() || 0}</td>
          <td>£{supplierInvoices?.toLocaleString() || 0}</td>
          <td>£{newMargins || 0}</td>
          <td>£{newCommission || 0}</td>
        </tr>
      </Table>
    </Tile>
  );
}

export default Stats;

import React, { useContext } from "react";
import { ModalContext } from "../../../utils/providers/modal";
import "./modal.scss";

/**
 * Modal component
 */
import ModalBox from "../../ui/modal/modal";

/**
 * Functional component wrapping the modal element to display on page
 */
function Modal() {
    /**
     * Get the modal data from the context
     */
    const { modal, dismissModal } = useContext(ModalContext);

    /**
     * Dismiss the modal before running the next function passed in
     * 
     * @type {const}
     */
    const nextAction = () => {
        /**
         * Dismiss the modal
         */
        dismissModal();
        /**
         * Then call the next function that was passed in via context
         */
        modal.next.action();
    }

    /**
     * Dismiss the modal before running the cancel function passed in
     * 
     * @type {const}
     */
    const cancelledAction = () => {
        /**
         * Dismiss the modal
         */
        dismissModal();
        /**
         * Then call the cancelled function that was passed in via context
         */
        modal.cancel.action();
    }

    /**
     * Is there a modal to display (is there a title for one)
     */
    if (modal.title) {
        return (
            <div className="app-modal-view">
                <div className="app-modal-box">
                    <ModalBox
                        type={modal.type}
                        title={modal.title}
                        body={modal.body}
                        cancel={{
                            label: modal.cancel?.label,
                            action: () => cancelledAction(),
                        }}
                        next={{
                            label: modal.next?.label,
                            action: () => nextAction(),
                        }} />
                </div>
            </div>
        );
    } else {
        /**
         * If there isn't
         */
        return false;
    }
}

export default Modal;
import React, { useState } from "react";
import { db } from "../../../utils/firebase";

/**
 * UI components
 */
import Input from "../../../components/ui/inputs/input";
import Select from "../../../components/ui/select/select";
import Button from "../../../components/ui/button/button";
import Window from "../../../components/structure/window/window";
import WindowCard from "../../../components/ui/window-card/window-card";

/**
 * Functional component to return the form for adding an internal partner
 */
function AddPartner(props) {
    const [type, setType] = useState({});
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [currency, setCurrency] = useState({});
    const [saving, setSaving] = useState(false);

    /**
     * Save the new partner details into the databse
     */
    const saveNewPartner = async () => {
        /**
         * Show the saving spinner
         */
        setSaving(true);
        /**
         * Add the new partner into the database
         */
        await db.collection(type.option).add({
            name,
            email,
            currency: currency.option,
        });
        /**
         * Reset the state
         */
        setSaving(false);
        props.close();
    }

    return (
        <Window
            title="Add partner"
            className="slim"
            close={() => props.close()}>

            <WindowCard>
                <div className="input-grid cols-1">
                    <Select
                        label="Type:"
                        placeholder="Partner type:"
                        value={type.value}
                        onSelect={(option) => setType(option)}
                        options={{
                            hotels: "Hotel",
                            courses: "Course",
                            transfers: "Transfer",
                        }} />

                    <Input
                        type="text"
                        placeholder="Name:"
                        value={name}
                        onChange={setName} />

                    <Input
                        type="email"
                        placeholder="Email:"
                        value={email}
                        onChange={setEmail} />

                    <Select
                        label="Currency:"
                        placeholder="Currency:"
                        value={currency.value}
                        onSelect={(option) => setCurrency(option)}
                        options={{
                            gbp: "Pound (£)",
                            eur: "Euro (€)",
                            usd: "United States Dollar ($)",
                        }} />

                    <Button
                        label="Save partner"
                        loading={saving}
                        loadingText="Saving..."
                        onClick={() => saveNewPartner()}
                        disabled={!type.value || !name || !email || !currency.value} />
                </div>
            </WindowCard>
        </Window>
    )
}

export default AddPartner;
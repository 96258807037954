import { useState, useContext } from 'react'
import * as mailTypes from '../../../utils/exports/mail-types'
import { QuoteContext } from '../context/context'
import { db, auth } from '../../../utils/firebase'
import firebase from 'firebase'

// Utility functions
import { sendEmail } from '../../../utils/mail'

// UI components
import Button from '../../../components/ui/button/button'

// Returns the HTML markup and functionality to send the PDF quotes to the client
export default function Summary({ progressStage }) {
    const [sending, setSending] = useState(false)

    // Pull some getting & setting methods from the quote context
    const { ctxCompletedPackages, ctxClientID, ctxClientFirstName, ctxClientLastName, ctxClientEmail } = useContext(QuoteContext)

    // Process the request to send through the quote(s) to the client
    const processQuoteSend = async () => {
        // Set the state
        setSending(true)

        // Get the sales agent details
        const agent = await db.doc(`users/${auth.currentUser.uid}`)
            .get().then((agentDoc) => {
                // Pull the name and email
                const { first_name, last_name, phone, email } = agentDoc.data()

                // Return them for inclusion in the sending function
                return { id: agentDoc.id, name: `${first_name} ${last_name}`, first_name, phone, email }
            })

        // Loop through the completed packages and send the email
        for (const quoteObject of ctxCompletedPackages) {
            // Send the client email
            await sendEmail({
                template: mailTypes.GENERATED_QUOTE.template,
                subject: `Golf Holidays Direct Quote - Ref: ${quoteObject.reference}`,
                from: {
                    name: agent.id === 'CGOHCdUwFAOe13OSTY2D9qdO2LX2' ? 'Golf Holidays Direct' : agent.name,
                    email: agent.id === 'CGOHCdUwFAOe13OSTY2D9qdO2LX2' ? 'sales@golfholidaysdirect.com' : agent.email,
                },
                user: {
                    name: `${ctxClientFirstName} ${ctxClientLastName}`,
                    email: ctxClientEmail,
                },
                mail: {
                    name: `${ctxClientFirstName} ${ctxClientLastName}`,
                    agent: agent.name,
                    agent_phone: agent.phone,
                    agent_email: agent.email,
                },
                attachments: [{
                    type: 'application/pdf',
                    name: `${quoteObject.reference} - Golf Holidays Direct Quote.pdf`,
                    content: quoteObject.pdf_bytes,
                }],
            })
    
            // Write a log onto the new client account for the quote send
            await db.collection(`clients/${ctxClientID}/logs`).add({
                badge: 'INFO',
                type: 'QUOTE_SENT',
                user: auth.currentUser.uid,
                message: `The generated quote (${quoteObject.reference}) was sent to ${ctxClientEmail}`,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            })
        }

        // Reset the state
        setSending(false)
        progressStage()
    }

    return (
        <>
            <div className='generate-quote-header'>
                <div className='generate-quote-title'>Quote Summary</div>
                <div className='generate-quote-subtitle'>Enter the clients email address below to have it sent to them &amp; saved</div>
            </div>

            <div className='quote-action-buttons'>
                <Button
                    label={`Send quote${ctxCompletedPackages.length > 1 ? 's' : ''}`}
                    loading={sending}
                    loadingText='Sending quote...'
                    onClick={() => processQuoteSend()} />
            </div>
        </>
    )
}
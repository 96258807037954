import styles from "./configure.module.scss"
import { useState, useEffect } from "react"

// UI components
import Button from "../../../../../components/ui/button/button"
import Select from "../../../../../components/ui/select/select"
import { LoadingIcon } from "../../../../../utils/svgs"
import Passenger from "./passenger/passenger"
import SeatChooser from "./seats/chooser"

// Returns the HTMl markup for configuring a flight
export default function Configure({ bookingID, seats = null, extras = null, passengers, makeBooking, booking }) {
	// Get the keys from the seats as the flight numbers
	const flightNumbers = seats ? Object.keys(seats) : []

	const [selectedPassenger, setSelectedPassenger] = useState(null)
	const [chosenSeat, setChosenSeat] = useState(null)
	const [activeFlightNumber, setActiveFlightNumber] = useState(flightNumbers[0])

	// Get an array of all the seats occupied by our passengers
	const occupiedSeats = passengers.map((passenger) => passenger.seat) || []

	let flightOptions = {}
	flightNumbers.map((flightNumber) => {
		flightOptions[flightNumber] = flightNumber
	})

	useEffect(() => {
		setChosenSeat(null)
		setSelectedPassenger(null)
	}, [activeFlightNumber])

	return (
		<div className={styles.container}>
			<h1>Choose Seats & Extras</h1>

			<div className={styles.grid}>
				<div className={styles.passengers}>
					{passengers?.map((passenger, index) => (
						<Passenger
							key={passenger.id}
							index={index}
							bookingID={bookingID}
							passenger={passenger}
							editing={selectedPassenger === passenger.id}
							toggleEdit={() => setSelectedPassenger(passenger.id)}
							toggleSelect={(value) => setSelectedPassenger(value)}
							flightNumbers={flightNumbers}
							activeFlightNumber={activeFlightNumber}
							extras={extras}
							chosenSeat={chosenSeat}
							canChooseSeat={flightNumbers?.length > 0}
							clearChosenSeat={() => setChosenSeat(null)}
						/>
					))}

					<div className={styles.action}>
						<Button
							small={true}
							onClick={() => makeBooking()}
							label="Make Booking"
							loading={booking}
							loadingText="Making booking..."
						/>
					</div>
				</div>

				<div className={styles.seatChooser}>
					{seats[activeFlightNumber] && (
						<div className={styles.flightChooser}>
							<Select
								options={flightOptions}
								value={activeFlightNumber}
								onSelect={(selection) => setActiveFlightNumber(selection.option)}
							/>
						</div>
					)}

					{!seats[activeFlightNumber] && (
						<div className={styles.loading}>
							<p>Cannot choose individual seats for these flights.</p>
						</div>
					)}

					{!selectedPassenger && (
						<div className={styles.chooserWarning}>
							<p>Please select a passenger before choosing a seat</p>
						</div>
					)}

					<SeatChooser
						flightNumber={activeFlightNumber}
						seatDetails={seats[activeFlightNumber]}
						activeSeats={[...occupiedSeats, chosenSeat]}
						choose={setChosenSeat}
					/>
				</div>
			</div>
		</div>
	)
}

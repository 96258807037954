import styles from "./passengers.module.scss"

import { useState } from "react"
import { db } from "../../../../../utils/firebase"

// UI components
import Button from "../../../../../components/ui/button/button"
import Passenger from "./passenger/passenger"

// Returns the HTML for the passengers tab under flights
export default function Passengers({ bookingID, expected, passengers }) {
	const [adding, setAdding] = useState(false)
	const [removing, setRemoving] = useState(null)

	// Sort the passengers by last name (but keep lead booker first)
	passengers.sort((a, b) => {
		if (a.is_lead) return -1
		if (b.is_lead) return 1
	})

	const addPassenger = async () => {
		setAdding(true)

		await db.collection(`bookings/${bookingID}/passengers`).add({
			lead_booker: passengers.length === 0,
		})

		setAdding(false)
	}

	const removePassenger = async (passengerID) => {
		setRemoving(passengerID)

		await db.collection(`bookings/${bookingID}/passengers`).doc(passengerID).delete()

		setRemoving(null)
	}

	return (
		<div className={styles.container}>
			<Button
				small={true}
				disabled={passengers.length >= expected}
				onClick={() => addPassenger()}
				label="Add Passenger"
				loading={adding}
				loadingText="Adding"
			/>

			<br />

			{passengers?.map((passenger, index) => (
				<Passenger
					key={passenger?.id}
					index={index}
					bookingID={bookingID}
					passenger={passenger}
					remove={removePassenger}
					removing={removing === passenger.id}
				/>
			))}
		</div>
	)
}

import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import firebase from "firebase";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Textarea from "../../../components/ui/inputs/textarea";
import Window from "../../../components/structure/window/window";

function Multiple(props) {
    const [booking, setBooking] = useState({});

    const [rounds, setRounds] = useState([]);
    const [hotels, setHotels] = useState([]);

    const [approving, setApproving] = useState(false);
    const [approvalModal, setApprovalModal] = useState(false);
    const [approvalComments, setApprovalComments] = useState("");

    const [rejecting, setRejecting] = useState(false);
    const [rejectionModal, setRejectionModal] = useState(false);
    const [rejectionComments, setRejectionComments] = useState("");

    /**
     * Get the bookingID from the props
     */
    const { bookingID, multipleIDs, reservationEmail, complete } = props;

    /**
     * When the bookingID is updated
     */
    useEffect(() => {
        pullBookingDetails();
    }, [bookingID]);

    /**
     * When the IDs being passed in are updated
     */
    useEffect(() => {
        /**
         * Setup some arrays for storing the various IDs
         */
        let roundIDs = [];
        let hotelIDs = [];
        /**
         * Loop over the IDs and add them into the respective arrays
         */
        multipleIDs.forEach((documentID) => {
            /**
             * Split the ID on an underscore
             */
            const documentParts = documentID.split("_");
            /**
             * Add the ID into the approriate array
             */
            if (documentParts[0] === "ROUND") {
                roundIDs.push(documentParts[1]);
            } else if (documentParts[0] === "HOTEL") {
                hotelIDs.push(documentParts[1]);
            }
        });
        /**
         * Then set those local arrays into the state
         */
        setRounds(roundIDs);
        setHotels(hotelIDs);
    }, [multipleIDs]);

    /**
     * Pull the details for the transfer from the databse to display on the front-end
     */
    const pullBookingDetails = async () => {
        /**
         * Get the booking details from the database
         */
        await db.doc(`bookings/${bookingID}`)
            .get().then((bookingDoc) => {
                /**
                 * Set the booking data into the state
                 */
                setBooking({ ...bookingDoc.data() });
            });
    }

    /**
     * Approve the reservation
     */
    const approveReservation = async () => {
        /**
         * Update the state to show it's loading
         */
        setApproving(true);
        /**
         * Loop over all round IDs first 
         */
        await Promise.all(rounds.map(async (roundID) => {
            /**
             * Update the booking document
             */
            await db.doc(`bookings/${bookingID}/rounds/${roundID}`).set({
                booking_status: "approved",
                booking_status_comments: approvalComments,
            }, { merge: true });
        }));
        /**
         * Loop over all hotel IDs first 
         */
        await Promise.all(hotels.map(async (hotelID) => {
            /**
             * Update the booking document
             */
            await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).set({
                booking_status: "approved",
                booking_status_comments: approvalComments,
            }, { merge: true });
        }));
        /**
         * Add a log tot he booking to reflect the approval
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            type: "MULTIPLE_BOOKINGS_APPROVED",
            badge: "SUCCESS",
            message: `Multiple reservations sent to ${reservationEmail} were approved`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setApproving(false);
        /**
         * Show the completed thank you message
         */
        complete();
    }

    /**
     * Reject the reservation
     */
    const rejectReservation = async () => {
        /**
         * Update the state to show it's loading
         */
        setRejecting(true);
        /**
         * Loop over all round IDs first 
         */
        await Promise.all(rounds.map(async (roundID) => {
            /**
             * Update the booking document
             */
            await db.doc(`bookings/${bookingID}/rounds/${roundID}`).set({
                booking_status: "rejected",
                booking_status_comments: rejectionComments,
            }, { merge: true });
        }));
        /**
         * Loop over all hotel IDs first 
         */
        await Promise.all(hotels.map(async (hotelID) => {
            /**
             * Update the booking document
             */
            await db.doc(`bookings/${bookingID}/hotels/${hotelID}`).set({
                booking_status: "rejected",
                booking_status_comments: rejectionComments,
            }, { merge: true });
        }));
        /**
         * Add a log to the booking to reflect the rejection
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            type: "MULTIPLE_BOOKINGS_REJECTED",
            badge: "ERROR",
            message: `Multiple reservations sent to ${reservationEmail} were rejected`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setRejecting(false);
        /**
         * Show the completed thank you message
         */
        complete();
    }

    return (
        <>
            <div className="reservation-block">
                {/* <div className="reservation-table">
                    <table className="booking-table">
                        <tbody>

                        </tbody>
                    </table>
                </div> */}

                <div className="reservation-actions">
                    <Button
                        label="Approve booking"
                        onClick={() => setApprovalModal(true)} />
                    <Button
                        className="OUTLINE"
                        label="Reject booking"
                        onClick={() => setRejectionModal(true)} />
                </div>
            </div>

            {rejectionModal &&
                <Window
                    title="Rejection reason"
                    className="slim reservation-modal"
                    close={() => setRejectionModal(false)}>

                    <Textarea
                        placeholder="Please suggest an alternative:"
                        value={rejectionComments}
                        onChange={setRejectionComments} />

                    <Button
                        fullWidth={true}
                        label="Send rejection reason"
                        disabled={!rejectionComments}
                        loading={rejecting}
                        loadingText="Sending rejection..."
                        onClick={() => rejectReservation()} />
                </Window>
            }

            {approvalModal &&
                <Window
                    title="Additional comments"
                    className="slim reservation-modal"
                    close={() => setApprovalModal(false)}>

                    <Textarea
                        placeholder="Any additional comments:"
                        value={approvalComments}
                        onChange={setApprovalComments} />

                    <Button
                        fullWidth={true}
                        label="Send approval"
                        loading={approving}
                        loadingText="Sending approval..."
                        onClick={() => approveReservation()} />
                </Window>
            }
        </>
    );
}

export default Multiple;
import { useContext } from 'react'
import { QuoteContext } from '../context/context'

// UI components
import Button from '../../../components/ui/button/button'
import OptionPackages from '../rows/option-packages'

// Returns the HTML markup and functionality for choosing the packages based on resorts/courses
export default function Packages({ previousStage, progressStage }) {
    // Pull the selected options from the quote context
    const { ctxSelectedOptions } = useContext(QuoteContext)

    return (
        <>
            <div className='generate-quote-header'>
                <div className='generate-quote-title'>Select Package(s)</div>
                <div className='generate-quote-subtitle'>Add packages into the basket for configuring on the next slide</div>
            </div>

            <div className='quote-resort-stack'>
                {ctxSelectedOptions.map((option) => (
                    <OptionPackages key={option.objectID} details={option} />
                ))}
            </div>

            <div className='quote-action-buttons is-double'>
                <Button
                    type='secondary'
                    label='Back to resorts'
                    onClick={() => previousStage()} />

                <Button
                    label='Configure'
                    onClick={() => progressStage()} />
            </div>
        </>
    )
}
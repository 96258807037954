import { fun } from "./firebase";

/**
 * Send an email through the use of the deployed cloud function, through Mandrill
 */
export const sendEmail = async (data) => {
    /**
     * Setup a reference to the cloud function
     */
    const sendTransactional = fun.httpsCallable("sendTransactionalMail");
    /**
     * Call the cloud function with the data passed in
     */
    const sendResult = await sendTransactional(data);
    /**
     * Return the result
     */
    return sendResult;
}
import React from "react";
import "./window.scss";

/**
 * 
 */
import Button from "../../ui/button/button";
import { TimesIcon } from "../../../utils/svgs";

/**
 * 
 */
function Window(props) {
    return (
        <div className="ui-window-page">
            <div className={["ui-window-card", props.className].join(" ")}>
                {/* Title section of the window with close button */}
                <div className="ui-window-title">
                    <div className="">
                        <h1>{props.title}</h1>
                        {props.subtitle && <small>{props.subtitle}</small>}
                    </div>
                    <Button
                        icon={<TimesIcon />}
                        xsSmall={true}
                        onClick={() => props.close()} />
                </div>
                {/* Body content for the window */}
                <div className="ui-window-body">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Window;
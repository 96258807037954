import { useState, useEffect } from 'react'
import { arrayRemove, db } from '../../../utils/firebase'

// UI components
import Table from '../../../components/structure/table/table'
import QuoteRow from '../../../components/client/quotes/quote'

// Returns the markup for the clients quotes
export default function Quotes({ clientID }) {
    const [quotes, setQuotes] = useState([])
    const [deleting, setDeleting] = useState(null)

    // On component load
    useEffect(() => {
        // Fetch the quotes belonging to this client
        fetchClientQuotes()
    }, [])

    // Fetch the quotes matching this clients Id
    const fetchClientQuotes = async () => {
        // Find all the quotes attached to this user
        db.collection('quotes')
            .orderBy('created', 'desc')
            .where('client', '==', clientID)
            .get().then((quoteDocs) => {
                let quotesArr = [];
                
                // Loop over each of the quote documents
                quoteDocs.forEach((enquiry) => {
                    // Push the quote details onto the array
                    quotesArr.push({
                        id: enquiry.id,
                        ...enquiry.data(),
                    })
                })
                
                // Then set these into the state
                setQuotes(quotesArr)
            })
    }

    // Delete a quote from the database
    const deleteQuote = async (quoteID) => {
        // Set the ID into the state
        setDeleting(quoteID)

        // First remove the quote ID from the clients array
        await db.doc(`clients/${clientID}`).set({
            quotes: arrayRemove(quoteID)
        }, { merge: true })

        // And then delete the quote document itself
        await db.doc(`quotes/${quoteID}`).delete()

        // Re-fetch the quotes
        fetchClientQuotes()

        // Reset the state
        setDeleting(null)
    }

    return (
        <>
            <Table
                className="enquiries-table"
                headings={[
                    "Created",
                    "Agent",
                    "Reference(s)"
                ]}
                noResults={quotes.length === 0}
                noResultsMessage="This client hasn't got any quotes">

                {/* Print out the quotes for this user */}
                {quotes.map((quote) => (
                    <QuoteRow
                        key={quote.id}
                        id={quote.id}
                        details={quote}
                        deleting={deleting === quote.id}
                        deleteQuote={(quoteID) => deleteQuote(quoteID)} />
                ))}
            </Table>
        </>
    );
}
import { useState, useEffect } from 'react'
import { db } from '../../../../utils/firebase'

// Display a reference as a link for viewing a quote document
export default function QuoteDocument({ quoteID, quotePackage }) {
    const [link, setLink] = useState('')
    
    // Pull the link for the document on load
    useEffect(() => {
        db.doc(`quotes/${quoteID}/documents/${quotePackage.reference}`)
            .get().then((quoteDoc) => {
                setLink(quoteDoc.data()?.pdf_url)
            })
    }, [quoteID, quotePackage])

    return <a target='_blank' href={link}>{quotePackage.reference} (£{quotePackage.final_cost}pp)</a>
}
import { useState, useEffect, useRef } from "react";
import firebase from "firebase";

// Utility functions
import { db } from "../../../../../utils/firebase";

// UI components
import Window from "../../../../structure/window/window";
import WindowCard from "../../../../ui/window-card/window-card";
import Datepicker from "../../../../ui/datepicker/datepicker";
import Checkbox from "../../../../ui/checkbox/checkbox";
import Textarea from "../../../../ui/inputs/textarea";
import { PlusIcon } from "../../../../../utils/svgs";
import Button from "../../../../ui/button/button";

// Row components
import { ArrowRightIcon } from "../../../../../utils/svgs-v2";
import GroupDiscount from "./items/group-discount";
import Input from "../../../../ui/inputs/input";
import FeeType from "./items/fee-type";
import Buggies from "./items/buggies";

// Returns the markup and html for the option view window
export default function OptionView({
  courseID,
  close,
  type,
  isCustom,
  window,
}) {
  const [saving, setSaving] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [stage, setStage] = useState(1);

  // Window fields
  const [windowID, setWindowID] = useState(null);
  const [basePrice, setBasePrice] = useState(0);
  const [isDraft, setIsDraft] = useState(false);
  const [name, setName] = useState("");
  const [maxCost, setMaxCost] = useState(0);
  const [addingRoomType, setAddingRoomType] = useState(false);
  const [greenFees, setGreenFees] = useState([]);
  const [addingGroupDiscount, setAddingGroupDiscount] = useState(false);
  const [groupDiscounts, setGroupDiscounts] = useState([]);
  const [windowBuggies, setWindowBuggies] = useState([]);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [availableDays, setAvailableDays] = useState([]);

  // Markup adjustments
  const [baseMarkup, setBaseMarkup] = useState(0);
  const [adjustedMarkup, setAdjustedMarkup] = useState(0);
  const [adjustedBasePrice, setAdjustedBasePrice] = useState(0);
  const [adjustedMaxCost, setAdjustedMaxCost] = useState(0);

  // Store a boolean for the mounted state
  const isMounted = useRef(false);

  // On component load
  useEffect(() => {
    // Was there a window object passed in?
    if (type === "edit" && window.id) {
      // Pull the details from the document
      const {
        id,
        is_draft,
        name,
        adjusted_price_lowest,
        adjusted_price_highest,
        start_seconds,
        end_seconds,
        fee_types,
        group_discounts,
        buggies,
        base_markup,
        adjusted_markup,
        notes,
        available_days,
      } = window;
      console.log(available_days);

      // Convert the dates to those we can set into the state
      const startUNIX = start_seconds * 1000;
      const endUNIX = end_seconds * 1000;

      // Set the state up
      setWindowID(id);
      setIsDraft(is_draft || false);
      setName(name);
      setStartDate(startUNIX);
      setEndDate(endUNIX);
      setGreenFees(fee_types || []);
      setGroupDiscounts(group_discounts || []);
      setWindowBuggies(
        buggies || [
          {
            _id: generateID(),
            cost: null,
            base: false,
          },
        ]
      );
      setAdjustedBasePrice(adjusted_price_lowest || 0);
      setAdjustedMaxCost(adjusted_price_highest || 0);
      setBaseMarkup(base_markup || 0);
      setAdjustedMarkup(adjusted_markup || 0);
      setAdditionalNotes(notes || "");
      setAvailableDays(available_days || []);
    }

    if (type !== "edit") {
      setIsDraft(true);
      setAvailableDays(["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]);
    }

    // Toggle the mounted state
    setTimeout(() => {
      isMounted.current = true;
    }, 500);
  }, [window]);

  // When any of the price window parameters are updated
  useEffect(() => {
    // Are we mounted already?
    if (isMounted.current) {
      processPricingUpdate();
    }
  }, [greenFees, groupDiscounts, windowBuggies]);

  // Progress the stages along, conditionally
  const updateStage = (direction = "up") => {
    // Are we progressing up the stages?
    if (direction === "up") {
      // Are we coming from the initial name & type page?
      if (stage <= 3) {
        setStage((stage) => stage + 1);
      }
    } else {
      // As long as we have room to move down a stage
      if (stage >= 1) {
        setStage((stage) => stage - 1);
      }
    }
  };

  // When the startDate/endDate are updated
  useEffect(() => {
    // If we are on a new price window modal and there isn't already a windowID present
    if (!windowID) {
      // Make sure both the start & end dates are valid
      if (startDate && startDate !== 0 && endDate && endDate !== 0) {
        createNewPriceWindow();
      }
    }
  }, [startDate, endDate]);

  // Process the update to any of the pricing window parameters
  const processPricingUpdate = async () => {
    // Setup some variables to hold the base and max prices
    let baseCost = 0;
    let maximumCost = 0;

    // Are there some green fees available?
    if (greenFees && greenFees.length > 0) {
      // If there are, find the one marked with the base field
      const baseGreenFeeTypes = [...greenFees].filter(
        (feeType) => feeType.base === true
      );

      // Were there any found?
      if (baseGreenFeeTypes.length > 0) {
        // Set the "cost" field from it into the baseCost variable
        baseCost += baseGreenFeeTypes[0].cost;
      }

      // Find the green fee with the highest cost
      const maxFeeType = [...greenFees].reduce((max, feeType) =>
        feeType.cost > max.cost ? feeType : max
      );

      // Set the cost of this element into the maxCost variable
      maximumCost += maxFeeType.cost;
    }

    // Make sure there is a buggies object available
    if (windowBuggies && windowBuggies.length > 0) {
      // If the buggies should be included in the base price
      if (windowBuggies[0].base) {
        baseCost += windowBuggies[0].cost;
      }

      // Also add them into the max cost value
      maximumCost += windowBuggies[0].cost;
    }

    // Set them into the state
    setBasePrice(baseCost);
    setMaxCost(maximumCost);
  };

  // Save the price window settings
  const savePriceWindow = async () => {
    // Update the state
    setSaving(true);

    // If we were editing a price window instead
    if (type === "edit") {
      await db.doc(`CMS_courses/${courseID}/price_windows/${windowID}`).set(
        {
          is_draft: isDraft,
          name: name || null,
          start_date: firebase.firestore.Timestamp.fromMillis(startDate),
          end_date: firebase.firestore.Timestamp.fromMillis(endDate),
          price_lowest: basePrice,
          adjusted_price_lowest: adjustedBasePrice,
          price_highest: maxCost,
          adjusted_price_highest: adjustedMaxCost,
          base_markup: baseMarkup,
          adjusted_markup: adjustedMarkup,
          available_days: availableDays,
          notes: additionalNotes,
        },
        { merge: true }
      );
    }

    // Reset the state
    setSaving(true);
    close();
  };

  // Create a new price window document
  const createNewPriceWindow = async () => {
    await db
      .collection(`CMS_courses/${courseID}/price_windows`)
      .add({
        is_draft: true,
        name: name || null,
        is_custom: isCustom,
        start_date: firebase.firestore.Timestamp.fromMillis(startDate),
        end_date: firebase.firestore.Timestamp.fromMillis(endDate),
        available_days: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      })
      .then((newWindow) => {
        // Set the new window document ID into the sate
        setWindowID(newWindow.id);
        setAvailableDays(["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]);
      });
  };

  // Generate a unique ID for writing the object into firestore arrays
  const generateID = () => {
    let d = new Date().getTime();
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now();
    }
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  // Add a new green fee to the price object
  const addNewRoomType = async () => {
    // Toggle the loading state
    setAddingRoomType(true);

    // Generate a new ID for this green fee
    const typeID = generateID();

    // Add a new room_tpe object into the documents array
    await db
      .doc(`CMS_courses/${courseID}/price_windows/${windowID}`)
      .update({
        fee_types: firebase.firestore.FieldValue.arrayUnion({
          _id: typeID,
          type: null,
          cost: null,
          base: false,
        }),
      })
      .then(() => {
        // Set the new document into the state
        setGreenFees((greenFees) => [
          ...greenFees,
          {
            _id: typeID,
            type: null,
            cost: null,
            base: false,
          },
        ]);

        // Reset the state
        setAddingRoomType(false);
      });
  };

  // Update the green fee object on the pricing document
  const handleFeeTypeUpdate = async (updatedData) => {
    // Create a local copy of the greenFees array
    const updatedTypes = [...greenFees];

    // Loop over the green fees to find the one to update
    for (let i in updatedTypes) {
      if (updatedTypes[i]._id === updatedData._id) {
        updatedTypes[i] = {
          ...updatedData,
          cost: parseFloat(updatedData.cost) || null,
        };
      }
    }

    // Reset the document to reflect the changed green fees
    await db
      .doc(`CMS_courses/${courseID}/price_windows/${windowID}`)
      .update({
        fee_types: updatedTypes,
      })
      .then(() => {
        // Then remove it from the local array
        setGreenFees(updatedTypes);
      });
  };

  // Delete the green fee row from the price window config
  const deleteFeeType = async (index) => {
    // Create a local copy of the greenFees array
    const updatedTypes = [...greenFees];

    // Splice it to remove the specified index
    updatedTypes.splice(index, 1);

    // Reset the document to reflect the changed green fees
    await db
      .doc(`CMS_courses/${courseID}/price_windows/${windowID}`)
      .update({
        fee_types: updatedTypes,
      })
      .then(() => {
        // Then remove it from the local array
        setGreenFees(updatedTypes);
      });
  };

  // Add a new group discount to the price object
  const addNewGroupDiscount = async () => {
    // Toggle the loading state
    setAddingGroupDiscount(true);

    // Generate a new ID for this group discount
    const typeID = generateID();

    // Add a new group discount object into the documents array
    await db
      .doc(`CMS_courses/${courseID}/price_windows/${windowID}`)
      .update({
        group_discounts: firebase.firestore.FieldValue.arrayUnion({
          _id: typeID,
          size: null,
          type: null,
          value: null,
        }),
      })
      .then(() => {
        // Set the new document into the state
        setGroupDiscounts((groupDiscounts) => [
          ...groupDiscounts,
          {
            _id: typeID,
            size: null,
            type: null,
            value: null,
          },
        ]);

        // Reset the state
        setAddingGroupDiscount(false);
      });
  };

  // Update the group discount object on the pricing document
  const handleGroupDiscountUpdate = async (updatedData) => {
    // Create a local copy of the boardTypes array
    const updatedDiscounts = [...groupDiscounts];

    // Loop over the group discounts to find the one to update
    for (let i in updatedDiscounts) {
      if (updatedDiscounts[i]._id === updatedData._id) {
        updatedDiscounts[i] = {
          ...updatedData,
          value: parseFloat(updatedData.value) || 0,
          size: parseFloat(updatedData.size) || 0,
        };
      }
    }

    // Reset the document to reflect the changed group discounts
    await db
      .doc(`CMS_courses/${courseID}/price_windows/${windowID}`)
      .update({
        group_discounts: updatedDiscounts,
      })
      .then(() => {
        // Then update the local array
        setGroupDiscounts(updatedDiscounts);
      });
  };

  // Delete the group discount row from the price window config
  const deleteGroupDiscount = async (index) => {
    // Create a local copy of the groupDiscounts array
    const updatedDiscounts = [...groupDiscounts];

    // Splice it to remove the specified index
    updatedDiscounts.splice(index, 1);

    // Reset the document to reflect the changed board types
    await db
      .doc(`CMS_courses/${courseID}/price_windows/${windowID}`)
      .update({
        group_discounts: updatedDiscounts,
      })
      .then(() => {
        // Then update the local array
        setGroupDiscounts(updatedDiscounts);
      });
  };

  // Update the buggies object on the pricing document
  const handleBuggiesUpdate = async (updatedData) => {
    // Create a local copy of the windowBuggies array
    const updatedBuggies = [...windowBuggies];

    // Update the buggies object (first array element)
    updatedBuggies[0] = {
      ...updatedData,
      cost: parseFloat(updatedData.cost) || 0,
    };

    // Reset the document to reflect the changed room types
    await db
      .doc(`CMS_courses/${courseID}/price_windows/${windowID}`)
      .update({
        buggies: updatedBuggies,
      })
      .then(() => {
        // Then rupdate the local array
        setWindowBuggies(updatedBuggies);
      });
  };

  // Calculate the reccomended margin to ensure the base price is rounded to the nearest £5
  const calculateRoundedMargin = (newMargin) => {
    // Calculate the new price by adding the percentage value to the base price
    const newBasePrice = basePrice * (1 + newMargin / 100);

    // Round the new price to the nearest £5 with no decimal places
    const roundedBasePrice = Math.round(newBasePrice / 5) * 5;
    const baseRemainder = roundedBasePrice % 5;
    const markupAdjustedBasePrice =
      baseRemainder >= 2.5
        ? roundedBasePrice + (5 - baseRemainder)
        : roundedBasePrice - baseRemainder;

    // Calculate the new price by adding the percentage value to the base price
    const newMaxPrice = maxCost * (1 + newMargin / 100);

    // Round the new price to the nearest £5 with no decimal places
    const roundedMaxPrice = Math.round(newMaxPrice / 5) * 5;
    const maxRemainder = roundedMaxPrice % 5;
    const markupAdjustedMaxPrice =
      maxRemainder >= 2.5
        ? roundedMaxPrice + (5 - maxRemainder)
        : roundedMaxPrice - maxRemainder;

    // Calculate the difference between the new rounded price and the original base price
    const markup = markupAdjustedBasePrice - basePrice;

    // Calculate the percentage markup
    const percentageMarkup = (markup / basePrice) * 100;

    // Parse the new markup to a maximum of 3 decimal places
    const parsedNewMarkup = Number(percentageMarkup.toFixed(3));

    // Set this new and adjusted markups into the state
    setBaseMarkup(Number(newMargin));
    setAdjustedMarkup(parsedNewMarkup);
    setAdjustedBasePrice(markupAdjustedBasePrice);
    setAdjustedMaxCost(markupAdjustedMaxPrice);
  };

  // Toggle the available day for a package to be showing
  const toggleAvailableDay = (dayAbbr) => {
    // Get a copy of the available days array from the calendar
    const availableDaysCopy = [...availableDays];

    // Check to see if it is already in the array
    if (availableDaysCopy.includes(dayAbbr)) {
      // If it is, remove it
      const existingIndex = availableDaysCopy.indexOf(dayAbbr);
      availableDaysCopy.splice(existingIndex, 1);
    } else {
      // Otherwise add it in
      availableDaysCopy.push(dayAbbr);
    }

    // Then set this modified array back into the state
    setAvailableDays(availableDaysCopy);
  };

  return (
    <Window
      title={type === "new" ? "Add Price Window" : "Edting Window"}
      className="price-window"
      close={() => close()}
    >
      {stage === 1 && (
        <>
          <WindowCard
            stacked={true}
            title="Package name"
            subtitle="Enter a title for this pricing window"
          >
            <Input value={name} onChange={setName} placeholder="Package name" />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Date range"
            subtitle="Choose the start & end dates this pricing window will cover"
          >
            <div className="input-grid cols-2">
              <Datepicker
                value={startDate}
                onSelect={(date) => setStartDate(date)}
                placeholder="Start date"
              />

              <Datepicker
                value={endDate}
                onSelect={(date) => setEndDate(date)}
                placeholder="End date"
              />
            </div>
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Available Days"
            subtitle="Which days is this package available to book on?"
          >
            <div className="package-days-available-column">
              <Checkbox
                label="Monday"
                checked={availableDays.includes("MON")}
                onClick={() => toggleAvailableDay("MON")}
              />
              <Checkbox
                label="Tuesday"
                checked={availableDays.includes("TUE")}
                onClick={() => toggleAvailableDay("TUE")}
              />
              <Checkbox
                label="Wednesday"
                checked={availableDays.includes("WED")}
                onClick={() => toggleAvailableDay("WED")}
              />
              <Checkbox
                label="Thursday"
                checked={availableDays.includes("THU")}
                onClick={() => toggleAvailableDay("THU")}
              />
              <Checkbox
                label="Friday"
                checked={availableDays.includes("FRI")}
                onClick={() => toggleAvailableDay("FRI")}
              />
              <Checkbox
                label="Saturday"
                checked={availableDays.includes("SAT")}
                onClick={() => toggleAvailableDay("SAT")}
              />
              <Checkbox
                label="Sunday"
                checked={availableDays.includes("SUN")}
                onClick={() => toggleAvailableDay("SUN")}
              />
            </div>
          </WindowCard>
        </>
      )}

      {stage === 2 && (
        <>
          <WindowCard
            stacked={true}
            title="Green fee types"
            subtitle="Configure the different green fee types"
          >
            {greenFees?.map((feeType, index) => (
              <FeeType
                key={feeType._id}
                data={feeType}
                onUpdate={(update) => handleFeeTypeUpdate(update, index)}
                onDelete={() => deleteFeeType(index)}
              />
            ))}

            <Button
              xsSmall
              label="Add"
              icon={<PlusIcon />}
              loading={addingRoomType}
              loadingText="Adding..."
              disabled={!windowID}
              onClick={() => addNewRoomType()}
            />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Buggies"
            subtitle="Configure the price of a buggy if not already included (per round)"
          >
            {windowBuggies?.map((buggies) => (
              <Buggies
                data={buggies}
                onUpdate={(update) => handleBuggiesUpdate(update)}
              />
            ))}
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Additional notes"
            subtitle="Leave some notes to be shown when this price window is selected"
          >
            <Textarea
              value={additionalNotes}
              onChange={setAdditionalNotes}
              placeholder="This note is specific to this pacakge only"
            />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Markup"
            subtitle="Select a base margin to make on this package"
          >
            <div className="input-grid cols-2">
              <Input
                value={baseMarkup}
                onChange={calculateRoundedMargin}
                symbol="%"
                placeholder="Markup"
              />

              <div className="adjusted-markup-display">
                Adjusted markup of {adjustedMarkup}% will be used
              </div>
            </div>
          </WindowCard>
        </>
      )}

      {stage === 3 && (
        <WindowCard
          stacked={true}
          title="Draft"
          subtitle="Should this package be saved as a draft?"
        >
          <Checkbox
            label="Save in draft"
            checked={isDraft}
            onClick={() => setIsDraft(!isDraft)}
          />
        </WindowCard>
      )}

      {stage > 1 && (
        <WindowCard>
          <div className="price-window-range-figure-flex">
            <div className="price-window-range-total">
              <div className="price-window-range-title">Base Price</div>
              <div className="price-window-range-numbers">
                <div className="price-window-range-number">
                  £{adjustedBasePrice}
                </div>
                <div className="price-window-range-number">
                  £{basePrice} at cost
                </div>
              </div>
            </div>

            <div className="price-window-range-total">
              <ArrowRightIcon />
            </div>

            <div className="price-window-range-total">
              <div className="price-window-range-title">Highest Price</div>
              <div className="price-window-range-numbers">
                <div className="price-window-range-number">
                  £{adjustedMaxCost}
                </div>
                <div className="price-window-range-number">
                  £{maxCost} at cost
                </div>
              </div>
            </div>
          </div>
        </WindowCard>
      )}

      <div className="ui-window-actions alt-layout">
        {stage <= 2 && (
          <>
            <Button
              label="Next"
              disabled={
                !startDate || startDate === 0 || !endDate || !endDate === 0
              }
              onClick={() => updateStage()}
            />
          </>
        )}

        {stage > 2 && (
          <>
            <Button
              label="Save pricing"
              loadingText="Saving..."
              loading={saving}
              onClick={() => savePriceWindow()}
            />

            <Button
              label="Back"
              type="secondary"
              onClick={() => updateStage("back")}
            />
          </>
        )}
      </div>
    </Window>
  );
}

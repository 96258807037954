import React, { useState, useContext } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { SearchIcon } from "../../utils/svgs"
import moment from "moment"
import "./bookings.scss"

// Algolia imports
import algoliasearch from "algoliasearch"
import {
    InstantSearch,
    Configure,
    connectSearchBox,
    connectHits,
    connectStateResults,
} from "react-instantsearch-dom"

// UI components
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Input from "../../components/ui/inputs/input"
import Table from "../../components/structure/table/table"
import ViewBooking from "../../components/booking/view/view"
import Booking from "../../components/booking/booking"
import Navigation from "../../components/ui/navigation/navigation"
import RequireAttention from "./types/require-attention"

// Functional component to return the bookings table
export default function Bookings() {
    const [viewBooking, setViewBooking] = useState("")
    const [activeTab, setActiveTab] = useState("AGENT_ASSIGNED")

    // Assign bookings
    const [assignBooking, setAssignBooking] = useState("")
    const [requireAttentionCount, setRequireAttentionCount] = useState(0)

    // Pull the current user profile from the auth context
    const { user } = useContext(AuthContext)

    // Algolia search API keys 
    const searchClient = algoliasearch(
        "HY764WYKNV",
        "059a9cd79a33ec2b2822acf2ffdfd4c9",
    )

    // Custom inputfield for the algolia search
    const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
        <form noValidate action="" role="search">
            <Input
                value={currentRefinement}
                icon={<SearchIcon />}
                fullEvent={true}
                placeholder="Quick search"
                onChange={(e) => refine(e.currentTarget.value)} />
        </form>
    )

    // Connect the custom search box
    const CustomSearchBox = connectSearchBox(SearchBox)

    // Setup a custom function for returning the hits streamed down from algolia
    const Hits = ({ hits }) => {
        // If we have hits, print them
        if (hits.length > 0) {
            // Print the objects found
            return hits.map((hit) => (
                <Booking
                    key={hit.objectID}
                    id={hit.objectID}
                    details={hit}
                    maskDetails={user?.mask_details}
                    showBookingWindow={(bookingID) => setViewBooking(bookingID)} />
            ))
        } else {
            // Return nothing for the hits
            return null
        }
    };

    // Connect the custom hits to the DOM
    const CustomHits = connectHits(Hits)

    // Connect a custom results component to display either the results no a no results message
    const Results = connectStateResults(
        ({ searchState, searchResults, children }) =>
            searchResults && searchResults.nbHits !== 0 ? (
                <CustomHits />
            ) : (
                <tr>
                    <td colSpan={8}>
                        <small>No results have been found {searchState.query ? `for ${searchState.query}.` : ""}</small>
                    </td>
                </tr>
            )
    )

    return (
        <Tile fullPage={true}>
            <InstantSearch searchClient={searchClient} indexName="bookings">

                <Configure filters={`removed:FALSE`} />

                <Title className="flex">
                    <h1>All bookings</h1>

                    {/* Inputfield for algolia */}
                    <CustomSearchBox />
                </Title>

                {user?.show_for?.can_assign &&
                    <Navigation
                        links={[
                            {
                                id: "REQUIRE_ATTENTION",
                                label: "Direct Bookings",
                            }, {
                                id: "AGENT_ASSIGNED",
                                label: "Agent Assigned",
                            }
                        ]}
                        active={activeTab}
                        onClick={(tabID) => setActiveTab(tabID)} />
                }

                {activeTab === "AGENT_ASSIGNED" &&
                    <Table
                        className="bookings-table"
                        headings={[
                            "Site",
                            "Agent",
                            "Booked date",
                            "Name",
                            "Phone",
                            "Location/Event",
                            "Date from",
                            "Quoted",
                        ]}>

                        <Results />
                    </Table>
                }

                {/* Enquries that require attention (just been submitted from the website) */}
                {(user?.show_for?.can_assign && (activeTab === "REQUIRE_ATTENTION")) &&
                    <RequireAttention
                        maskDetails={user?.mask_details}
                        setAssignBooking={(bookingID) => setAssignBooking(bookingID)}
                        updateRequiredCount={(count) => setRequireAttentionCount(count)} />
                }

                {/* Do we have a bookingID in the local state to load into a window? */}
                {viewBooking &&
                    <ViewBooking
                        enquiryID={viewBooking}
                        close={() => setViewBooking("")} />
                }
            </InstantSearch>
        </Tile>
    );
}
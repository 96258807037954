import React, { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../../utils/providers/booking";
import { db } from "../../../utils/firebase";

/**
 * UI components
 */
import Table from "../../../components/structure/table/table";
import Log from "../../../components/booking/logs/log";
import { AuthContext } from "../../../utils/providers/auth";

/**
 * Functional component to return the booking hotel panel
 */
export default function Logs() {
    const [logs, setLogs] = useState([]);

    /**
     * Deconstruct data from the booking context
     */
    const {
        bookingID,
    } = useContext(BookingContext);

    // Pull the user object from the auth context
    const { user } = useContext(AuthContext)

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the database to stream the logs down for this booking
         */
        const unsubscribe = db.collection(`bookings/${bookingID}/logs`)
            .orderBy("created", "desc")
            .onSnapshot((logDocs) => {
                /**
                 * Loop over the logs
                 */
                logDocs.docChanges().forEach((change) => {
                    /**
                     * Log added
                     */
                    if (change.type === "added") {
                        setLogs((logs) => [...logs, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    }
                    /**
                     * Log updated
                     */
                    if (change.type === "modified") {
                        setLogs((logs) => {
                            let updatedLogs = [...logs];
                            for (let i in logs) {
                                if (logs[i].id === change.doc.id) {
                                    updatedLogs[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedLogs;
                        });
                    }
                    /**
                     * Log removed
                     */
                    if (change.type === "removed") {
                        setLogs((logs) =>
                            logs.filter((log) => log.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener when the component unloads
         */
        return () => unsubscribe();
    }, []);

    return (
        <>
            <Table
                className="logs-table"
                headings={[
                    "Event",
                    "When",
                    "User",
                    "Message",
                ]}>

                {logs.map((log) => (
                    <Log
                        key={log.id}
                        details={log}
                        maskDetails={user?.mask_details} />
                ))}
            </Table>
        </>
    );
}
import React, { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../../utils/providers/booking";
import { db, arrayUnion } from "../../../utils/firebase";
import firebase from "firebase";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import { PlusIcon } from "../../../utils/svgs";
import Price from "../../../components/booking/price/price";

/**
 * Functional component to return the tab for the various price lines attached to a booking
 */
function PriceLines(props) {
    const [adding, setAdding] = useState(false);

    /**
     * Get the client data from the props
     */
    const { client } = props;

    /**
     * Deconstruct data from the booking context
     */
    const {
        bookingID,
        pricesOrder,
    } = useContext(BookingContext);

    /**
     * Add a new price line to the booking
     */
    const addNewPriceLine = async () => {
        /**
         * Set the state
         */
        setAdding(true);
        /**
         * Add a new round document into the bookings "prices" subcollection and return the ID for it
         */
        const priceID = await db.collection(`bookings/${bookingID}/prices`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((priceDoc) => { return priceDoc.id });
        /**
         * Push the new price ID into the "prices" array for the booking
         */
        await db.doc(`bookings/${bookingID}`).set({
            prices_order: arrayUnion(priceID),
        }, { merge: true });
        /**
         * Reset the state
         */
        setAdding(false);
    }

    return (
        <>
            <div className="add-table">
                <Button
                    badge={true}
                    label="Add price line"
                    icon={<PlusIcon />}
                    loading={adding}
                    loadingText="Adding price line..."
                    onClick={() => addNewPriceLine()} />
            </div>

            {/* Loop through the prices in the order they are saved */}
            {pricesOrder?.map((price, index) => (
                <Price key={price} bookingID={bookingID} priceID={price} index={index} client={client} />
            ))}
        </>
    );
}

export default PriceLines;
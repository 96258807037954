import React, { useEffect, useState } from "react";
import { PenIcon, SaveIcon } from "../../../utils/svgs";
import { db } from "../../../utils/firebase";

/**
 * UI components
 */
import Input from "../../ui/inputs/input";
import Select from "../../ui/select/select";
import Window from "../../structure/window/window";
import WindowCard from "../../ui/window-card/window-card";

/**
 * Functional component to return a window full screen with the partner details
 */
function ViewPartner(props) {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [currency, setCurrency] = useState({});

    /**
     * Deconstruct the partnerID from the props
     */
    const { partnerDoc } = props;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Fetch the client details from the database
         */
        db.doc(`${partnerDoc.type}s/${partnerDoc.id}`).get().then((partnerDoc) => {
            /**
             * If the client document was found
             */
            if (partnerDoc.exists) {
                /**
                 * Deconstruct the client data from the document
                 */
                const { name, email, currency } = partnerDoc.data();
                /**
                 * Set the client data into the state
                 */
                setName(name || "");
                setEmail(email || "");
                if (currency === "gbp") {
                    setCurrency({ option: currency, value: "Pound (£)" });
                } else if (currency === "eur") {
                    setCurrency({ option: currency, value: "Euro (€)" });
                } else if (currency === "usd") {
                    setCurrency({ option: currency, value: "United States Dollar ($)" });
                }
            }
        });
    }, [partnerDoc.id]);

    /**
     * Save the new partner details into the database
     */
    const savePartnerDetails = async () => {
        /**
         * Toggle the saving state
         */
        setSaving(true);
        /**
         * Set the partner data into the database
         */
        await db.doc(`${partnerDoc.type}s/${partnerDoc.id}`).set({
            name,
            email,
            currency: currency.option,
        }, { merge: true });
        /**
         * Reset the state
         */
        setEditing(false);
        setSaving(false);
    }

    /**
     * Remove the partner details from the database
     */
    const removePartnerDetails = async () => {
        /**
         * Toggle the removing state
         */
        setRemoving(true);
        setEditing(false);
        /**
         * Delete the partner document
         */
        await db.doc(`${partnerDoc.type}s/${partnerDoc.id}`).delete();
        /**
         * Reset the state
         */
        setRemoving(false);
        props.close();
    }

    return (
        <Window
            title={name}
            className="slim"
            close={() => props.close()}>

            <WindowCard
                title="Summary"
                primaryAction={{
                    icon: editing ? <SaveIcon /> : <PenIcon />,
                    label: editing ? "Save" : "Edit",
                    onClick: editing ? () => savePartnerDetails() : () => setEditing(true),
                    loading: saving,
                    loadingText: "Saving...",
                }}>

                <div className="input-grid cols-1">
                    <Input
                        type="text"
                        label="Name:"
                        value={name}
                        onChange={setName}
                        placeholder="Name:"
                        readOnly={!editing} />

                    <Input
                        type="email"
                        label="Email:"
                        value={email}
                        onChange={setEmail}
                        placeholder="Email:"
                        readOnly={!editing} />

                    <Select
                        label="Currency:"
                        placeholder="Currency:"
                        value={currency.value}
                        onSelect={(option) => setCurrency(option)}
                        options={{
                            gbp: "Pound (£)",
                            eur: "Euro (€)",
                            usd: "United States Dollar ($)",
                        }}
                        readOnly={!editing} />
                </div>
            </WindowCard>
        </Window>
    );
}

export default ViewPartner;
import React, { useState, useEffect, useContext } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { CALENDAR_MONTHS } from "../../utils/exports/months"
import { db, auth } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./travellers.scss"

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Button from "../../components/ui/button/button"
import Table from "../../components/structure/table/table"
import Booking from "../../components/travellers/booking"

/**
 * Functional component to return the dashboard for viewing travellers by various dates
 */
function Travellers() {
	const [loading, setLoading] = useState(false)
	const [bookings, setBookings] = useState([])

	const [agents, setAgents] = useState({})
	const [years, setYears] = useState({})

	const [agent, setAgent] = useState("")
	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")

	const [customerPrice, setCustomerPrice] = useState(0)
	const [paidByClient, setPaidByClient] = useState(0)
	const [balanceOutstanding, setBalanceOutstanding] = useState(0)

	/**
	 * Get the current user auth from the context
	 */
	const { user } = useContext(AuthContext)

	/**
	 * On load for the context
	 */
	useEffect(() => {
		/**
		 * Is this user an admin? If they are, they should have access to all agents travellers,
		 * otherswise only allow access to their own
		 */
		if (user?.is_admin) {
			/**
			 * Pull a list of agents from the user list
			 */
			db.collection("users")
				.orderBy("first_name")
				.get()
				.then((agentDocs) => {
					let agentsArr = []
					/**
					 * Loop through the agent documents found
					 */
					agentDocs.forEach((agentDoc) => {
						/**
						 * Deconstruct the agent details
						 */
						const { first_name, last_name } = agentDoc.data()
						/**
						 * Add them into the array
						 */
						agentsArr[agentDoc.id] = `${first_name} ${last_name}`
					})
					/**
					 * Push the user into the state
					 */
					setAgents(agentsArr)
				})
		} else {
			let agentsArr = []
			/**
			 * Assign only this agents user ID into the array
			 */
			agentsArr[auth.currentUser.uid] = `${user?.first_name} ${user?.last_name}`
			/**
			 * Set this single-item array into the state
			 */
			setAgents(agentsArr)
		}
	}, [user])

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Create an empty object for storing the years
		 */
		let yearsObj = {}
		/**
		 * Get next year as a number
		 */
		let nextYear = Number(moment().startOf(year).add(1, "year").format("YYYY"))
		/**
		 * Run through a for loop to print out the years
		 */
		for (let i = nextYear; i >= 2018; i--) {
			yearsObj = {
				[i]: i,
				...yearsObj,
			}
		}
		/**
		 * Set the years into the state
		 */
		setYears(yearsObj)
	}, [])

	/**
	 * Fetch the travellers from the database
	 */
	const fetchTravellers = async () => {
		/**
		 * Show a loading spinner
		 */
		setLoading(true)

		// Allow the fetch if the user is an admin
		const allowFetch = user.is_admin || auth.currentUser.uid === "FJwopC1ZJJVDQG1lRlSVEUhghHu1"

		if (year && !month && !agent && allowFetch) {
			byYear()
		} else if (year && month && !agent && allowFetch) {
			byYearAndMonth()
		} else if (year && month && agent) {
			byYearMonthAndAgent()
		} else {
			setLoading(false)
		}
	}

	const byYear = async () => {
		/**
		 * Get the milliseconds timestamps from the chosen filters
		 */
		const fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf()
		const fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf()
		/**
		 * Build some firestore timestamps from the milliseconds
		 */
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)
		/**
		 * Pull all the bookings from the database that match the filters
		 */
		await db
			.collection("bookings")
			.where("check_in", ">=", startTimestamp)
			.where("check_in", "<=", endTimestamp)
			.where("removed", "==", false)
			.orderBy("check_in")
			.get()
			.then((bookingDocs) => {
				/**
				 * Create an empty array for storing the bookings
				 */
				let bookingsArr = []
				/**
				 * Setup some variables for storing the totals
				 */
				let totalCustomerPrices = 0
				let totalPaidByClients = 0
				let totalBalanceOutstanding = 0
				/**
				 * Loop through the bookings that have been found that match
				 */
				bookingDocs.forEach((bookingDoc) => {
					/**
					 * Deconstruct some balances from the booking document
					 */
					const { balance_outstanding, paid_by_client, margins } = bookingDoc.data()
					/**
					 * Add the values from this booking
					 */
					totalCustomerPrices = totalCustomerPrices + Number.parseFloat(margins?.customer_price || 0)
					totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0)
					/**
					 * Is there a balance_outstanding available on the booking?
					 */
					if (balance_outstanding && typeof Number.parseFloat(balance_outstanding) === "number") {
						/**
						 * If there is, add it to the running total
						 */
						totalBalanceOutstanding = totalBalanceOutstanding + Number.parseFloat(balance_outstanding)
					}
					/**
					 * Push the booking data into the array
					 */
					bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })
				})
				/**
				 * Set the bookings into the state
				 */
				setBookings(bookingsArr)
				/**
				 * Set the totals into the state
				 */
				setCustomerPrice(totalCustomerPrices)
				setPaidByClient(totalPaidByClients)
				setBalanceOutstanding(totalBalanceOutstanding)
			})
		/**
		 * Reset the state
		 */
		setLoading(false)
	}

	const byYearAndMonth = async () => {
		/**
		 * Get the milliseconds timestamps from the chosen filters
		 */
		const fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
		const fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		/**
		 * Build some firestore timestamps from the milliseconds
		 */
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)
		/**
		 * Pull all the bookings from the database that match the filters
		 */
		await db
			.collection("bookings")
			.where("check_in", ">=", startTimestamp)
			.where("check_in", "<=", endTimestamp)
			.where("removed", "==", false)
			.orderBy("check_in")
			.get()
			.then((bookingDocs) => {
				/**
				 * Create an empty array for storing the bookings
				 */
				let bookingsArr = []
				/**
				 * Setup some variables for storing the totals
				 */
				let totalCustomerPrices = 0
				let totalPaidByClients = 0
				let totalBalanceOutstanding = 0
				/**
				 * Loop through the bookings that have been found that match
				 */
				bookingDocs.forEach((bookingDoc) => {
					/**
					 * Deconstruct some balances from the booking document
					 */
					const { balance_outstanding, paid_by_client, margins } = bookingDoc.data()
					/**
					 * Add the values from this booking
					 */
					totalCustomerPrices = totalCustomerPrices + Number.parseFloat(margins?.customer_price || 0)
					totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0)
					/**
					 * Is there a balance_outstanding available on the booking?
					 */
					if (balance_outstanding && typeof Number.parseFloat(balance_outstanding) === "number") {
						/**
						 * If there is, add it to the running total
						 */
						totalBalanceOutstanding = totalBalanceOutstanding + Number.parseFloat(balance_outstanding)
					}
					/**
					 * Push the booking data into the array
					 */
					bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })
				})
				/**
				 * Set the bookings into the state
				 */
				setBookings(bookingsArr)
				/**
				 * Set the totals into the state
				 */
				setCustomerPrice(totalCustomerPrices)
				setPaidByClient(totalPaidByClients)
				setBalanceOutstanding(totalBalanceOutstanding)
			})
		/**
		 * Reset the state
		 */
		setLoading(false)
	}

	const byYearMonthAndAgent = async () => {
		/**
		 * Get the milliseconds timestamps from the chosen filters
		 */
		const fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
		const fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		/**
		 * Build some firestore timestamps from the milliseconds
		 */
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)
		/**
		 * Pull all the bookings from the database that match the filters
		 */
		await db
			.collection("bookings")
			.where("check_in", ">=", startTimestamp)
			.where("check_in", "<=", endTimestamp)
			.where("agent", "==", agent.option)
			.where("removed", "==", false)
			.orderBy("check_in")
			.get()
			.then((bookingDocs) => {
				/**
				 * Create an empty array for storing the bookings
				 */
				let bookingsArr = []
				/**
				 * Setup some variables for storing the totals
				 */
				let totalCustomerPrices = 0
				let totalPaidByClients = 0
				let totalBalanceOutstanding = 0
				/**
				 * Loop through the bookings that have been found that match
				 */
				bookingDocs.forEach((bookingDoc) => {
					/**
					 * Deconstruct some balances from the booking document
					 */
					const { balance_outstanding, paid_by_client, margins } = bookingDoc.data()
					/**
					 * Add the values from this booking
					 */
					totalCustomerPrices = totalCustomerPrices + Number.parseFloat(margins?.customer_price || 0)
					totalPaidByClients = totalPaidByClients + Number.parseFloat(paid_by_client || 0)
					/**
					 * Is there a balance_outstanding available on the booking?
					 */
					if (balance_outstanding && typeof Number.parseFloat(balance_outstanding) === "number") {
						/**
						 * If there is, add it to the running total
						 */
						totalBalanceOutstanding = totalBalanceOutstanding + Number.parseFloat(balance_outstanding)
					}
					/**
					 * Push the booking data into the array
					 */
					bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })
				})
				/**
				 * Set the bookings into the state
				 */
				setBookings(bookingsArr)
				/**
				 * Set the totals into the state
				 */
				setCustomerPrice(totalCustomerPrices)
				setPaidByClient(totalPaidByClients)
				setBalanceOutstanding(totalBalanceOutstanding)
			})
		/**
		 * Reset the state
		 */
		setLoading(false)
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Travellers</h1>
			</Title>

			<div className="page-filters">
				<Select
					label="Agent:"
					placeholder="Agent:"
					value={agent}
					selected={agent.option}
					activeOnHover={true}
					onSelect={(option) => setAgent(option)}
					options={agents}
				/>

				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={years}
				/>

				<div>
					<Button
						filters={true}
						disabled={(!agent || !month || !year) && (!month || !year) && !year}
						label="Fetch travellers"
						loadingText="Fetching travellers..."
						loading={loading}
						onClick={() => fetchTravellers()}
					/>
				</div>
			</div>

			<Table
				className="travellers-table"
				headings={["Date", "Name", "Location", "Total cost", "Paid by client", "Balance", "Resort paid", "Hotels", "Rounds", ""]}
				noResults={Object.entries(bookings).length === 0}
				noResultsMessage={"No results matching those filters"}>
				{bookings.map((booking) => (
					<Booking
						key={booking?.id}
						id={booking?.id}
						details={booking}
					/>
				))}

				<tr className="totals-row">
					<td colSpan="3">Totals:</td>
					<td>£{customerPrice.toLocaleString()}</td>
					<td>£{paidByClient.toLocaleString()}</td>
					<td>£{balanceOutstanding.toLocaleString()}</td>
				</tr>
			</Table>
		</Tile>
	)
}

export default Travellers

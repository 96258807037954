import { useState, useEffect, useContext } from "react";
import { ModalContext } from "../../../../utils/providers/modal";
import { AlertsContext } from "../../../../utils/providers/alerts";
import moment from "moment";
import "./pricing.scss";

// Utility functions
import { db } from "../../../../utils/firebase";

// UI components
import {
  BlockEndBottom,
  BlockEndTop,
  WarningTriangleIcon,
} from "../../../../utils/svgs-v2";
import { PlusIcon } from "../../../../utils/svgs";
import Button from "../../../ui/button/button";
import Loading from "../../../app/loading/loading";
import PriceRow from "./row/row";
import OptionView from "./view/view";

// Returns the markup for the holoiday pricing window
export default function HolidayPricing({ holiday, agent = false }) {
  const [searchDate, setSearchDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [presentationOptions, setPresentationOptions] = useState([]);
  const [backMarker, setBackMarker] = useState({});
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(null);

  // Import the modal functionality
  const { showModal } = useContext(ModalContext);

  // Import the alert functionality
  const { pushAlert } = useContext(AlertsContext);

  // On component mount
  useEffect(() => {
    // Load the pricing options
    const unsubscribe = db
      .collection(`CMS_holidays/${holiday.id}/price_windows`)
      .where("end_date", ">=", new Date())
      .onSnapshot((pricingDocs) => {
        // Reset the loading state
        setLoading(false);

        // Get a timestamp for now
        const timestampNow = moment().format("X");

        // Loop over the documents found
        pricingDocs.docChanges().forEach((optionChange) => {
          // Get the start_date and end_date fields
          const {
            is_draft,
            is_website_enabled,
            name,
            type,
            start_date,
            end_date,
            price_lowest,
            adjusted_price_lowest,
            price_highest,
            adjusted_price_highest,
            room_types,
            board_types,
            included_courses,
            buggies,
            transfers,
            group_discounts,
            is_custom,
            base_markup,
            adjusted_markup,
            available_days,
            nights,
            rounds,
            notes,
          } = optionChange.doc.data();

          // Are we looking for just this agent?
          if ((!agent && is_custom) || (agent && !is_custom)) {
            return;
          }

          // Key for identifying the correct badge to show
          let periodBadge;

          // Past options
          if (
            start_date.seconds < timestampNow &&
            end_date.seconds < timestampNow
          ) {
            periodBadge = "PAST";
          }

          // Currently active
          if (
            start_date.seconds < timestampNow &&
            end_date.seconds > timestampNow
          ) {
            periodBadge = "ACTIVE";
          }

          // Upcoming
          if (
            start_date.seconds > timestampNow &&
            end_date.seconds > timestampNow
          ) {
            periodBadge = "UPCOMING";
          }

          // Process some readable versions of the start and end dates
          const startDateString = moment(start_date.seconds, "X").format(
            "DD-MMM-YYYY"
          );
          const endDateString = moment(end_date.seconds, "X").format(
            "DD-MMM-YYYY"
          );

          // Added
          if (optionChange.type === "added") {
            setOptions((options) => [
              ...options,
              {
                id: optionChange.doc.id,
                is_draft,
                is_website_enabled,
                name,
                type,
                start_date: startDateString,
                start_seconds: start_date.seconds,
                end_date: endDateString,
                end_seconds: end_date.seconds,
                period_state: periodBadge,
                price_lowest,
                adjusted_price_lowest,
                price_highest,
                adjusted_price_highest,
                room_types,
                board_types,
                included_courses,
                buggies,
                transfers,
                group_discounts,
                base_markup,
                adjusted_markup,
                available_days,
                nights,
                rounds,
                notes,
              },
            ]);
          }

          // Modified
          if (optionChange.type === "modified") {
            setOptions((options) => {
              let updatedOptions = [...options];
              for (let i in options) {
                if (options[i].id === optionChange.doc.id) {
                  updatedOptions[i] = {
                    id: optionChange.doc.id,
                    is_draft,
                    is_website_enabled,
                    name,
                    type,
                    start_date: startDateString,
                    start_seconds: start_date.seconds,
                    end_date: endDateString,
                    end_seconds: end_date.seconds,
                    period_state: periodBadge,
                    price_lowest,
                    adjusted_price_lowest,
                    price_highest,
                    adjusted_price_highest,
                    room_types,
                    board_types,
                    included_courses,
                    buggies,
                    transfers,
                    group_discounts,
                    base_markup,
                    adjusted_markup,
                    available_days,
                    nights,
                    rounds,
                    notes,
                  };
                  break;
                }
              }
              return updatedOptions;
            });
          }

          // Removed
          if (optionChange.type === "removed") {
            setOptions((options) => {
              return options.filter(
                (priceOption) => priceOption.id !== optionChange.doc.id
              );
            });
          }
        });
      });

    // Unmount the listener on component unload
    return () => unsubscribe();
  }, []);

  // When the options are updated
  useEffect(() => {
    // Sort the options by the end_seconds field
    const sortedOptions = [...options].sort(
      (a, b) => b.end_seconds - a.end_seconds
    );

    // Set the last element as the back marker
    setBackMarker(sortedOptions[0]);

    // Create an instance of the options array
    const localisedOptions = [...options];

    // Loop over the array and add a presentation field to each pricing option
    localisedOptions.forEach((option) => {
      option.presentation_type = "price_period";
    });

    // Sort the options so the closest date is at the first
    const missingOptionsArr = localisedOptions.sort((a, b) => {
      return a.start_seconds - b.start_seconds;
    });

    // Loop over all the pricing options found
    for (let i = 0; i < missingOptionsArr.length - 1; i++) {
      // Get the current and next option
      const currentOption = missingOptionsArr[i];
      const nextOption = missingOptionsArr[i + 1];

      // Then parse the start/end seconds fields
      const currentEndDate = moment.unix(currentOption.end_seconds);
      const nextStartDate = moment.unix(nextOption.start_seconds);

      // Work out the difference between the two in days
      const difference = nextStartDate.diff(currentEndDate, "days");

      // If there is atleast 1 day gap between them
      if (difference > 1) {
        // Add a new element into the array for the missing dates
        missingOptionsArr.splice(i + 1, 0, {
          id: `${currentOption.end_seconds}${nextOption.start_seconds}`,
          presentation_type: "missing_dates",
          missing_days: difference - 1,
          start_date: currentEndDate.add(1, "days").format("DD-MMM-YYYY"),
          end_date: nextStartDate.add(-1, "days").format("DD-MMM-YYYY"),
        });
      }
    }

    // Set this new modified array into the state
    setPresentationOptions(localisedOptions);
  }, [options]);

  // Load the edit modal for this particular pricing option
  const editOption = async (optionID, optionDetails) => {
    setEditing({ id: optionID, ...optionDetails });
  };

  // Check to make sure we are good to remove the option before doing so
  const checkCopy = async (optionID) => {
    showModal({
      type: "ALERT",
      title: "Copy Action",
      body: "Would you like to copy the package globally, or add the new copy to your custom pricing list?",
      cancel: {
        label: "Copy here",
        action: () => copyPriceWindow(optionID),
      },
      next: {
        label: "Copy for me",
        action: () => copyPriceWindow(optionID, true),
      },
    });
  };

  // Copy the package globally
  const copyPriceWindow = async (optionID, is_custom = false) => {
    // Get the data for the original pricing window
    const originalData = await db
      .doc(`CMS_holidays/${holiday.id}/price_windows/${optionID}`)
      .get()
      .then((original) => {
        return original.data();
      });

    // Add the price window data into a new document
    await db
      .collection(`CMS_holidays/${holiday.id}/price_windows`)
      .add({ ...originalData, is_custom });
  };

  // Check to make sure we are good to remove the option before doing so
  const checkDeletion = async (optionID) => {
    showModal({
      type: "ALERT",
      title: "Are you sure?",
      body: "Removing this pricing option may leave gaps in the calendar",
      cancel: {
        label: "Cancel",
        action: () => {
          return null;
        },
      },
      next: {
        label: "Continue",
        action: () => processDeletion(optionID),
      },
    });
  };

  // Process the option deletion
  const processDeletion = async (optionID) => {
    await db
      .doc(`CMS_holidays/${holiday.id}/price_windows/${optionID}`)
      .delete();

    pushAlert({
      type: "SUCCESS",
      title: "Pricing option removed",
    });
  };

  return (
    <>
      <div className="holiday-pricing-container">
        {loading && <Loading />}

        {!loading && (
          <div className="holiday-pricing-options">
            <Button
              label="New price window"
              icon={<PlusIcon />}
              onClick={() => setAdding(true)}
            />

            <small>Showing {options.length} results</small>

            {presentationOptions.map((option) => (
              <>
                {option.presentation_type === "price_period" && (
                  <PriceRow
                    key={option.id}
                    details={{ ...option }}
                    searchDate={searchDate / 1000}
                    edit={(optionID, optionDetails) =>
                      editOption(optionID, optionDetails)
                    }
                    checkCopy={(optionID) => checkCopy(optionID)}
                    checkDelete={(optionID) => checkDeletion(optionID)}
                  />
                )}

                {option.presentation_type === "missing_dates" && (
                  <div
                    key={option.id}
                    className="holiday-pricing-options-missing-dates"
                  >
                    <BlockEndTop />
                    <div className="holoiday-pricing-options-missing-dates-inner">
                      <WarningTriangleIcon />
                      <p>
                        Missing <strong>{option.start_date}</strong>
                        {option.missing_days > 1 && (
                          <>
                            {" "}
                            to <strong>{option.end_date}</strong>
                          </>
                        )}
                      </p>
                    </div>
                    <BlockEndBottom />
                  </div>
                )}
              </>
            ))}

            {options.length > 0 && backMarker?.id && (
              <div className="holiday-pricing-options-back-marker">
                <BlockEndTop />
                <p>
                  Options end <strong>{backMarker.end_date}</strong>
                </p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Adding a new pricing window */}
      {adding && (
        <OptionView
          holidayID={holiday.id}
          holiday={holiday}
          close={() => setAdding(false)}
          type="new"
          isCustom={agent}
        />
      )}

      {/* Editing an existing price window */}
      {editing && (
        <OptionView
          holidayID={holiday.id}
          holiday={holiday}
          close={() => setEditing(false)}
          type="edit"
          isCustom={agent}
          window={editing}
        />
      )}
    </>
  );
}

import './loading.scss'

// UI components
import { LoadingIcon } from "../../../utils/svgs"

// Returns the markup for the loading spinner
export default function Loading({ hideLabel = false }) {
    return (
        <div className="loading-spinner">
            {!hideLabel && <p>Loading...</p>}
            <div className="loading-svg-wrap">
                <LoadingIcon />
            </div>
        </div>
    )
}
import { useEffect, useState, useRef } from 'react'

// UI compoents
import Select from '../../../../../ui/select/select'
import Input from '../../../../../ui/inputs/input'
import { TrashIcon } from '../../../../../../utils/svgs-v2'

// Returns the markup for the included course items
export default function IncludedCourse({ availableCourses, data, onUpdate, onDelete }) {
    const [ID, setID] = useState('')
    const [quantity, setQuantity] = useState(null)
    const [name, setName] = useState(null)
    const [chosenID, setChosenID] = useState(null)

    // Store a boolean for whether the component has been mounted
    const isMounted = useRef(false)

    // On component mount
    useEffect(() => {
        // Set the values into the state
        setID(data._id)
        setName(data.name)
        setQuantity(data.quantity)

        // Then mark the isMounted flag as true
        isMounted.current = true
    }, [])

    // When any of the details are updated
    useEffect(() => {
        // Make sure we're mounted before processing any updates
        if (isMounted.current) {
            // Push the updates into the parent
            onUpdate({ _id: ID, name, quantity })
        } else {
            // Otherwise mark it as mounted
            isMounted.current = true
        }

        // Find the key of the selected course
        const getKeyByValue = (object, value) => {
            for (const key in object) {
                if (object[key] === value) {
                return key
                }
            }
            return null
        }

        // Set the chosen ID
        const selectedCourseID = getKeyByValue(availableCourses, name)
        setChosenID(selectedCourseID)
    }, [quantity, name])

    return (
        <div className='price-window-room-type'>
            <div style={{ gridColumnEnd: 'span 2' }}>
                <Select 
                    selected={chosenID}
                    placeholder='Choose course'
                    options={availableCourses}
                    onSelect={({ value }) => setName(value)} />
            </div>

            <Input 
                value={quantity}
                onChange={setQuantity}
                placeholder='Quantity' />

            <div className='price-window-target-delete' onClick={() => onDelete()}>
                <TrashIcon />
            </div>
        </div>
    )
}

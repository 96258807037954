import React from "react";
import "./checkbox.scss";
import { CheckIcon } from "../../../utils/svgs";

/**
 * Functional component for the checkbox element
 *
 * @param {object} props Props passed into the checkbox component
 * @returns HTML markup and functionality for the checkbox component
 */
function Checkbox(props) {
    return (
        <div className={["ui-checkbox", props.className].join(" ")} onClick={() => props.onClick()}>
            <div className={["ui-checkbox-box", props.checked && "is-checked"].join(" ")}>
                <CheckIcon />
            </div>
            <label>{props.label}</label>
        </div>
    );
}

export default Checkbox;

import React, { useState, useEffect } from "react";
import { db } from "../../../utils/firebase";

/**
 * UI components
 */
import Input from "../../ui/inputs/input";

/**
 * Functional component to return the round component
 */
function Price(props) {
    const [name, setName] = useState("");
    const [quanity, setQuanity] = useState(0);
    const [price, setPrice] = useState(0);

    /**
     * Deconstruct the booking & price IDs from the props
     */
    const { bookingID, priceID, index } = props;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the price line document from the database
         */
        db.doc(`bookings/${bookingID}/prices/${priceID}`)
            .get().then((priceDoc) => {
                /**
                 * Deconstruct the data from the document
                 */
                const {
                    name,
                    quantity,
                    price,
                } = priceDoc.data();
                /**
                 * Update the state with these details
                 */
                setName(name || "");
                setQuanity(quantity || 0);
                setPrice(price || 0);
            });
    }, []);

    return (
        <table className="booking-table">
            <tbody>
                <tr>
                    <td colSpan="2">
                        <div className="table-room-lower-border" />
                    </td>
                </tr>
                <tr className="table-sub-heading left-align">
                    <td>Price line #{index + 1}</td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td>
                        <Input
                            type="text"
                            value={name}
                            readOnly={true} />
                    </td>
                </tr>
                <tr>
                    <td>Pricing</td>
                    <td>
                        <div className="table-data-split cols-2 weighted-style2">
                            <div className="table-data-column">
                                <p>Quantity</p>
                                <Input
                                    type="number"
                                    value={quanity}
                                    readOnly={true} />
                            </div>

                            <div className="table-data-column">
                                <p>Price</p>
                                <Input
                                    type="number"
                                    symbol="£"
                                    value={price}
                                    readOnly={true} />
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default Price;
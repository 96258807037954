import React, { useState, useContext } from "react";
import { db, auth } from "../../../utils/firebase";
import { BookingContext } from "../../../utils/providers/booking";
import firebase from "firebase";

/**
 * UI components 
 */
import Input from "../../ui/inputs/input";
import Button from "../../ui/button/button";
import Window from "../../structure/window/window";
import WindowCard from "../../ui/window-card/window-card";
import Datepicker from "../../ui/datepicker/datepicker";

/**
 * Functional component to return the add payment form
 */
function AddPayment(props) {
    const [saving, setSaving] = useState(false);
    const [amount, setAmount] = useState("");
    const [reference, setReference] = useState("");
    const [payee, setPayee] = useState("");
    const [paidMillis, setPaidMillis] = useState("");

    /**
     * Deconstruct some data from the props
     */
    const { client, bookingID } = props;

    /**
     * Save the payment data into the "payments" subcollection on the booking
     */
    const savePayment = async () => {
        /**
         * Show the loading spinner
         */
        setSaving(true);
        /**
         * Get the amount in true Number format
         */
        const amountVal = Number.parseFloat(amount);
        /**
         * Add the payment data into the collection
         */
        await db.collection(`bookings/${bookingID}/payments`).add({
            amount: amountVal,
            payee,
            reference,
            paid_date: firebase.firestore.Timestamp.fromMillis(paidMillis),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            saved_by: auth.currentUser.uid,
        });
        /**
         * Reset the state
         */
        setSaving(false);
        props.close();
    }

    return (
        <Window
            title={`Add payment for ${client}`}
            className="slim"
            close={() => props.close()}>

            <WindowCard>
                <div className="input-grid cols-2">
                    <div className="span-2">
                        <Input
                            type="text"
                            placeholder="Payee:"
                            value={payee}
                            onChange={setPayee} />
                    </div>

                    <Input
                        type="number"
                        placeholder="Amount:"
                        symbol="£"
                        value={amount}
                        onChange={setAmount} />

                    <Input
                        type="text"
                        placeholder="Reference:"
                        value={reference}
                        onChange={setReference} />

                    <div className="span-2">
                        <Datepicker
                            placeholder="Payment date:"
                            onSelect={(date) => setPaidMillis(date)} />
                    </div>

                    <div className="span-2">
                        <Button
                            label="Save payment"
                            fullWidth={true}
                            loading={saving}
                            loadingText="Saving..."
                            onClick={() => savePayment()}
                            disabled={!amount || !payee || !reference || !paidMillis} />
                    </div>
                </div>
            </WindowCard>
        </Window>
    );
}

export default AddPayment;
import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";

// UI components
import Partner from "../../../components/partner/partner";
import Table from "../../../components/structure/table/table";

// Transfers tab for the partners
export default function Transfers(props) {
    const [transfers, setTransfers] = useState([]);

    // On component load
    useEffect(() => {
        fetchCoursePartners();
    }, []);

    // Fetch the hotel partners from the database
    const fetchCoursePartners = async () => {
        // Query the database for the course partners
        db.collection("transfers")
            .orderBy("name")
            .get().then((queryDocs) => {
                // Store a local array of them to write to from the database query
                let transferDocs = []

                // Loop over each partner and add them into the local array
                queryDocs.forEach((transferDoc) => {
                    transferDocs.push({ id: transferDoc.id, ...transferDoc.data(), })
                })

                // Then set them into the state
                setTransfers(transferDocs)
            })
    }

    return (
        <Table
            className="partners-table"
            headings={[
                "Name",
                "Email",
                "",
                "",
            ]}>

            {transfers.map((partner) => (
                <Partner
                    key={partner.id}
                    id={partner.id}
                    details={partner}
                    showPartnerWindow={(partnerDoc) => props.setViewPartner(partnerDoc)} />
            ))}
        </Table>
    );
}
import React, { useState, useEffect } from "react";
import moment from "moment";
import "./datepicker.scss";

/**
 * Flatpickr libary
 */
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { CalendarIcon } from "../../../utils/svgs-v2";

/**
 * Functional component to return a datepicker inputfield
 */
function Datepicker(props) {
    const [date, setDate] = useState(null);
    const [millis, setMillis] = useState("");
    const [defaultVal, setDefaultVal] = useState(null);

    /**
     * When the date is updated
     */
    useEffect(() => {
        if (date?.date) {
            /**
             * Get the milliseconds for the date using the moment libary
             */
            const dateMillis = moment(date?.date[0]).valueOf();
            /**
             * Push this value up the props
             */
            setMillis(dateMillis);
            props.onSelect(dateMillis);
        }
    }, [date]);

    /**
     * When the value is updated down the props
     */
    useEffect(() => {
        /**
         * If there was a value passed down
         */
        if (props.value) {
            /**
             * Build a string using the milliseconds passed in
             */
            const valueString = moment(props.value, "X").format("DD/MM/YYYY");
            /**
             * Set it into the default state
             */
            setDefaultVal(valueString);
        }
    }, [props.value]);

    return (
        <div className={[
            "ui-input-wrapper",
            "is-datepicker",
            props.wrapperClass,
            props.error && "has-error",
            props.symbol && "has-symbol",
            props.value && "has-value",
            props.readOnly && "read-only",
            millis && "has-value",
            props.activeOnHover && "only-active-on-hover",
        ].join(" ")}>
            {/* Input wrapper for the input and icon should one be passed in */}
            <div className="ui-input-block">
                {/* Icon to display in the input */}
                <div className="ui-input-icon">
                    <CalendarIcon />
                </div>

                {/* Small symbol to show to the left of the input field */}
                {props.symbol && <div className="ui-input-symbol">{props.symbol}</div>}

                {/* Placeholder to sit above the field when there is a value */}
                {((props.placeholder || props.label) && props.showLabel) &&
                    <div className={["ui-input-upper-placeholder", props.value && "active"].join(" ")}>
                        {props.label || props.placeholder}
                    </div>
                }

                <Flatpickr
                    data-enable-time={props.hasTime}
                    placeholder={props.placeholder}
                    options={{
                        dateFormat: props.hasTime ? "d/m/Y at h:iK" : "d/m/Y",
                    }}
                    value={props.value || null}
                    onChange={(date) => setDate({ date })}
                    disabled={props.readOnly} />
            </div>

            {/* Is there an error or note to display */}
            {props.error ? <small>{props.error}</small> :
                props.note && <small>{props.note}</small>}
        </div>
    );
}

export default Datepicker;
import { useContext, useState, useEffect } from "react"
import { arrayUnion, auth, db } from "../../../utils/firebase"
import { QuoteContext } from "../context/context"
import firebase from "firebase"

// UI components
import Textarea from "../../../components/ui/inputs/textarea"
import Button from "../../../components/ui/button/button"
import ConfirmedPackage from "../rows/confirmed-package"
import Input from "../../../components/ui/inputs/input"
import { ArrowRightIcon } from "../../../utils/svgs-v2"
import Select from "../../../components/ui/select/select"

// Returns the HTML markups and functionality for the PDF generation page
export default function GeneratingPDF({ resetStages, progressStage }) {
	const [quoteID, setQuoteID] = useState("")

	// Custom PDF settings
	const [readyToGenerate, setReadyToGenerate] = useState(false)
	const [customIntroduction, setCustomIntroduction] = useState(
		"We’d firstly like to thank you for your recent enquiry with Golf Holidays Direct. As one of the leading providers in the UK we take pride in ensuring an unforgettable experience for all clients from the moment they enquire, so we hope you have had a pleasant experience with us so far.\n\nWithin this document, you’ll find a full breakdown of your proposed trip, a summary of all costs associated and a tentative itinerary for us to review and make changes too."
	)
	const [replaceFields, setReplaceFields] = useState([{ from: "", to: "" }])
	const [offsetYear, setOffsetYear] = useState({ option: null, value: null })

	// Pull some getting & setting methods from the quote context
	const { ctxCompletedPackages, ctxRemoveConfirmedPackage, ctxClearEntireState, setCtxClientID, ctxClientFirstName, ctxClientLastName, ctxClientEmail } = useContext(QuoteContext)

	// Map over all the packages and make sure they have a PDF link available
	const allPDFsAvailable = ctxCompletedPackages.every((quotePackage) => quotePackage.hasOwnProperty("pdf_url"))

	// Do we have an empty array in the contexts completed packages?
	if (ctxCompletedPackages.length === 0) {
		// Clear the quote context state
		ctxClearEntireState()
		resetStages()
	}

	// On component load
	useEffect(() => {
		// Create a new quote document in the database
		const createNewQuoteDocument = async () => {
			// Remove all white spaces from the client email address
			const ctxClientEmailFormatted = ctxClientEmail.replace(/\s/g, "")

			// First start by checking the client email for an account
			const clientID = await db
				.collection("clients")
				.where("email", "==", ctxClientEmailFormatted)
				.get()
				.then(async (clientDocs) => {
					// Where there any returned?
					if (clientDocs.size > 0) {
						// Return the ID of the matching client account
						return clientDocs.docs[0].id
					} else {
						// Otherwise we need to create a new client account
						const newAccountID = await db
							.collection("clients")
							.add({
								first_name: ctxClientFirstName.charAt(0).toUpperCase() + ctxClientFirstName.slice(1),
								last_name: ctxClientLastName.charAt(0).toUpperCase() + ctxClientLastName.slice(1),
								email: ctxClientEmailFormatted.toLowerCase(),
								created: firebase.firestore.FieldValue.serverTimestamp(),
							})
							.then((newClientDoc) => newClientDoc.id)

						// Write a log onto the new client account
						await db.collection(`clients/${newAccountID}/logs`).add({
							type: "CLIENT_CREATED",
							message: "Client account was created",
							created: firebase.firestore.FieldValue.serverTimestamp(),
						})

						// Then return the new client account ID
						return newAccountID
					}
				})

			// Write a log onto the new client account for the quote creation
			await db.collection(`clients/${clientID}/logs`).add({
				badge: "INFO",
				type: "QUOTE_GENERATED",
				user: auth.currentUser.uid,
				message: "A new quote was generated",
				created: firebase.firestore.FieldValue.serverTimestamp(),
			})

			// Set this ID into the state for logging later
			setCtxClientID(clientID)

			// We need to remove a set of parameters left over from the original processes
			const clonedPackagesArr = ctxCompletedPackages.map((completedPackage) => {
				// Deconstruct out the fields not required
				let { pdf_bytes, options, ...rest } = completedPackage

				// Do the same for the packages array
				const newPackages = rest.packages.map((slimPackage) => {
					let { board_types, buggies, room_types, is_custom, is_draft, notes, ...packageRest } = slimPackage
					return packageRest
				})

				// Return this modified array as the one to write into the database
				return { ...rest, packages: newPackages }
			})

			// Add the new document into the collection
			const newQuoteDocumentID = await db
				.collection("quotes")
				.add({
					agent: auth.currentUser.uid,
					client: clientID,
					created: firebase.firestore.FieldValue.serverTimestamp(),
					packages: clonedPackagesArr,
				})
				.then((newDoc) => newDoc.id)

			// Then write this quote ID into their account document
			await db.doc(`clients/${clientID}`).set(
				{
					quotes: arrayUnion(newQuoteDocumentID),
				},
				{ merge: true }
			)

			// Then set this into the state
			setQuoteID(newQuoteDocumentID)
		}
		createNewQuoteDocument()
	}, [])

	return (
		<>
			<div className="generate-quote-header">
				<div className="generate-quote-title">PDF Generation</div>
				<div className="generate-quote-subtitle">Edit & check the generated PDF(s) below before continuing to send them</div>
			</div>

			{!readyToGenerate && (
				<>
					<div className="finalised-quote-settings">
						<div className="quote-edit-header">
							<p className="quote-edit-header-title">Introduction text</p>
							<p className="quote-edit-header-subtitle">Configure the paragraphs of text displayed on the first page</p>
						</div>
						<div className="quote-introduction-edit">
							<p>
								Dear{" "}
								<strong>
									{ctxClientFirstName} {ctxClientLastName}
								</strong>
								,
							</p>

							<Textarea
								rows={8}
								value={customIntroduction}
								onChange={setCustomIntroduction}
							/>
						</div>

						<div className="quote-edit-header">
							<p className="quote-edit-header-title">Search & replace</p>
							<p className="quote-edit-header-subtitle">Replaces all instances of one value with another</p>
						</div>
						<div className="quote-global-replace">
							<div className="quote-global-replace-fields">
								<Input
									placeholder="From:"
									value={replaceFields[0].from}
									onChange={(value) => setReplaceFields([{ ...replaceFields[0], from: value }])}
								/>
								<ArrowRightIcon />
								<Input
									placeholder="To:"
									value={replaceFields[0].to}
									onChange={(value) => setReplaceFields([{ ...replaceFields[0], to: value }])}
								/>
							</div>
						</div>

						<div className="quote-edit-header year-offset-margin">
							<p className="quote-edit-header-title">Year offset</p>
							<p className="quote-edit-header-subtitle">Offset the years shown in the PDF text</p>
						</div>
						<div className="quote-global-offset">
							<Select
								placeholder="Choose offset:"
								value={offsetYear?.value}
								options={{
									0: "Same year",
									1: "+1 year",
									2: "+2 years",
									3: "+3 years",
								}}
								onSelect={setOffsetYear}
							/>
						</div>
					</div>

					<div className="quote-action-buttons">
						<Button
							label="Generate PDFs"
							onClick={() => setReadyToGenerate(true)}
						/>
					</div>
				</>
			)}

			{readyToGenerate && (
				<>
					<div className="finalised-quote-summaries">
						{ctxCompletedPackages.map((confirmedPackage, index) => (
							<ConfirmedPackage
								quoteID={quoteID}
								key={confirmedPackage._id}
								details={confirmedPackage}
								customIntroduction={customIntroduction}
								replaceFields={replaceFields}
								offsetYear={offsetYear?.option || 0}
								onDelete={() => ctxRemoveConfirmedPackage(index)}
							/>
						))}
					</div>

					<div className="quote-action-buttons is-double">
						<Button
							type="secondary"
							label="Edit settings"
							onClick={() => setReadyToGenerate(false)}
						/>

						<Button
							label="Continue to summary"
							disabled={!allPDFsAvailable}
							onClick={() => progressStage()}
						/>
					</div>
				</>
			)}
		</>
	)
}

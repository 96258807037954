import { useEffect, useState, useRef } from 'react'

// UI compoents
import Checkbox from '../../../../../ui/checkbox/checkbox'
import Input from '../../../../../ui/inputs/input'

// Returns the markup and functionality for the buggies on the price window
export default function Buggies({ data, onUpdate }) {
    const [ID, setID] = useState('')
    const [base, setBase] = useState(false)
    const [cost, setCost] = useState(null)

    // Store a boolean for whether the component has been mounted
    const isMounted = useRef(false)

    // On component mount
    useEffect(() => {
        // Set the values into the state
        setID(data._id)
        setCost(data.cost || 0)
        setBase(data.base || false)

        // Then mark the isMounted flag as true
        isMounted.current = true
    }, [])

    // When any of the details are updated
    useEffect(() => {
        // Make sure we're mounted before processing any updates
        if (isMounted.current) {
            // Push the updates into the parent
            onUpdate({ _id: ID, cost, base })
        } else {
            // Otherwise mark it as mounted
            isMounted.current = true
        }
    }, [base, cost])

    return (
        <div className='price-window-buggies'>
            <Input 
                value={cost}
                onChange={setCost}
                placeholder='Additional cost' 
                symbol='£' />

            <Checkbox
                label='Include in base'
                checked={base}
                onClick={() => setBase(!base)} />
        </div>
    )
}
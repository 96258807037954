import React, { useState } from "react";
import { PlusIcon } from "../../utils/svgs";
import "./partners.scss";

// UI components
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Navigation from "../../components/ui/navigation/navigation";
import Button from "../../components/ui/button/button";

import Hotels from "./tabs/hotels";
import Courses from "./tabs/courses";
import Transfers from "./tabs/transfers";
import AddPartner from "./add/add";
import ViewPartner from "../../components/partner/view/view";
import Grouping from "./tabs/grouping";
import AddGroup from "./add/group";
import ViewGroup from "../../components/partner/view/group";

// Partners page container
export default function Partners() {
    const [activeTab, setActiveTab] = useState("HOTELS");
    const [addingPartner, setAddingPartner] = useState(false);
    const [addingPartnerGroup, setAddingPartnerGroup] = useState(false);
    const [viewPartner, setViewPartner] = useState("");
    const [viewGroup, setViewGroup] = useState({})

    return (
        <Tile fullPage={true}>
            <Title className="flex">
                <h1>Supplier partners</h1>

                {(activeTab !== 'GROUPING') &&
                    <Button
                        small={true}
                        label="Add partner"
                        icon={<PlusIcon />}
                        onClick={() => setAddingPartner(true)} />
                }

                {(activeTab === 'GROUPING') &&
                    <Button
                        small={true}
                        label="Add partner group"
                        icon={<PlusIcon />}
                        onClick={() => setAddingPartnerGroup(true)} />
                }
            </Title>

            <Navigation
                links={[
                    {
                        id: "HOTELS",
                        label: "Hotels",
                    }, {
                        id: "COURSES",
                        label: "Courses",
                    }, {
                        id: "TRANSFERS",
                        label: "Transfers",
                    }, {
                        id: "GROUPING",
                        label: "Grouping",
                    }
                ]}
                active={activeTab}
                onClick={(tabID) => setActiveTab(tabID)} />

            {(activeTab === "HOTELS") &&
                <Hotels setViewPartner={(partnerDoc) => setViewPartner({ type: "hotel", ...partnerDoc })} />
            }

            {(activeTab === "COURSES") &&
                <Courses setViewPartner={(partnerDoc) => setViewPartner({ type: "course", ...partnerDoc })} />
            }

            {(activeTab === "TRANSFERS") &&
                <Transfers setViewPartner={(partnerDoc) => setViewPartner({ type: "transfer", ...partnerDoc })} />
            }

            {(activeTab === "GROUPING") &&
                <Grouping setViewGroup={(groupDoc) => setViewGroup(groupDoc)} />
            }

            {addingPartnerGroup &&
                <AddGroup close={() => setAddingPartnerGroup(false)} />
            }

            {addingPartner &&
                <AddPartner close={() => setAddingPartner(false)} />
            }

            {viewPartner &&
                <ViewPartner
                    partnerDoc={viewPartner}
                    close={() => setViewPartner("")} />
            }

            {viewGroup?.id &&
                <ViewGroup
                    groupDoc={viewGroup}
                    close={() => setViewGroup({})} />}
        </Tile>
    );
}
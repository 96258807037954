import React, { useState, useEffect, useContext } from "react";
import { BookingContext } from "../../../utils/providers/isolated-booking";
import ATOLLogo from "../../../assets/images/atol-protection.png";
import { db } from "../../../utils/firebase";
import moment from "moment";

/**
 * UI components
 */
import Tile from "../../../components/structure/tile/tile";
import Title from "../../../components/structure/title/title";
import Navigation from "../../../components/ui/navigation/navigation";

/**
 * Functional component to return all the payments made against the booking
 */
export default function Payments() {
  const [payments, setPayments] = useState([]);
  const [activeTab, setActiveTab] = useState("PAYMENTS");

  /**
   * Deconstruct the bookingID from the props
   */
  const { bookingID, reference } = useContext(BookingContext);

  /**
   * On component load
   */
  useEffect(() => {
    /**
     * Make a call to the database for all the payments on this booking
     */
    bookingID &&
      db
        .collection(`bookings/${bookingID}/payments`)
        .orderBy("paid_date", "desc")
        .get()
        .then((paymentDocs) => {
          /**
           * Are there any payment documents
           */
          if (paymentDocs.size > 0) {
            let paymentsArr = [];
            /**
             * Loop through them
             */
            paymentDocs.forEach((paymentDoc) => {
              /**
               * Pull some data from the document
               */
              const { amount, paid_date, payee, reference } = paymentDoc.data();
              /**
               * Create a readbable date from the timestamp
               */
              const paidDateReadable = moment(paid_date?.seconds, "X").format(
                "DD/MM/YYYY"
              );
              /**
               * Push all the data into the array
               */
              paymentsArr.push({ amount, paidDateReadable, payee, reference });
            });
            /**
             * Push the payments array into the state
             */
            setPayments(paymentsArr);
          }
        });
  }, [bookingID]);

  return (
    <Tile className="fit-width center" includeBackButton={true}>
      <Title center={true} isCardTitle={true}>
        <h1>Payments</h1>
        <p>
          You can make payments at any time towards your golf holiday, please
          use the below details to make payments and use the booking reference
          number as the reference.
        </p>
      </Title>

      <Navigation
        links={[
          {
            id: "PAYMENTS",
            label: "Payments",
          },
          {
            id: "PROTECTION",
            label: "Financial Protection",
          },
        ]}
        center={true}
        active={activeTab}
        onClick={(tabID) => setActiveTab(tabID)}
      />

      {/* Payments tab */}
      {activeTab === "PAYMENTS" && (
        <>
          <table className="payment-details">
            <tbody>
              <tr>
                <td>Name:</td>
                <td>Golf Holidays Direct</td>
              </tr>
              <tr>
                <td>Account Number:</td>
                <td>2081 7805</td>
              </tr>
              <tr>
                <td>Sort Code:</td>
                <td>20-45-45</td>
              </tr>
              <tr>
                <td>Booking ref:</td>
                <td>{reference}</td>
              </tr>
            </tbody>
          </table>

          {/* Are there any payments to show */}
          {payments.length === 0 && (
            <small className="no-payments-notice">
              No recorded payments yet, if you've paid recently please allow up
              to 24 hours for your payment to be assigned onto your account.
            </small>
          )}

          {/* If there are some payment to show */}
          {payments.length > 0 && (
            <div className="booking-payments-cover">
              <table className="booking-payments">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Payee</th>
                    <th>Amount</th>
                    <th>Reference</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment) => (
                    <tr key={payment.reference}>
                      <td>{payment.paidDateReadable}</td>
                      <td>{payment.payee}</td>
                      <td>£{payment.amount.toLocaleString()}</td>
                      <td>
                        <small>{payment.reference}</small>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      {/* Financial protection tab */}
      {activeTab === "PROTECTION" && (
        <>
          <p className="financial-protection">
            Golf Holidays Direct operates under an ATOL license (number 11357)
            which provides financial protection. We also keep monies paid to us
            in an independently managed Trust Account offering you complete
            piece of mind knowing that your money is ringfenced. Golf Holidays
            Direct offer the highest level of financial protection in the
            industry.
          </p>

          <img
            src={ATOLLogo}
            alt="ATOL Financial Protection"
            className="atol-protection-logo"
          />
        </>
      )}
    </Tile>
  );
}

import React from "react";
import "./window-card.scss";

// UJI components
import Button from "../button/button";

// Returns the markup for a modal window block
export default function WindowCard({ className, title, subtitle, primaryAction, secondaryAction, stacked, children }) {
    return (
        <div className={["ui-window-block", className, stacked ? 'is-stacked' : ''].join(" ").trim()}>
            <div className="ui-window-card-header">
                <div className='ui-window-card-actions'>
                    {title && <p className='ui-window-card-title'>{title}</p>}
                    {subtitle && <p className='ui-window-card-subtitle'>{subtitle}</p>}
                </div>

                <div className='ui-window-card-actions'>
                    {primaryAction &&
                        <Button
                        badge={true}
                        icon={primaryAction.icon}
                        label={primaryAction.label}
                        loading={primaryAction.loading}
                        loadingText={primaryAction.loadingLabel}
                        onClick={() => primaryAction.onClick()} />
                    }

                    {secondaryAction &&
                        <Button
                        badge={true}
                        icon={secondaryAction.icon}
                        label={secondaryAction.label}
                        loading={secondaryAction.loading}
                        loadingText={secondaryAction.loadingLabel}
                        onClick={() => secondaryAction.onClick()} />
                    }
                </div>
            </div>
            {children}
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import { PenIcon, SaveIcon } from "../../../utils/svgs";

/**
 * UI components
 */
import Window from "../../structure/window/window";
import Navigation from "../../ui/navigation/navigation";
import WindowCard from "../../ui/window-card/window-card";
import Input from "../../ui/inputs/input";
import Textarea from "../../ui/inputs/textarea";

/**
 * Functional component to return a window full screen with the booking details
 */
function ViewBooking(props) {
    const [activeTab, setActiveTab] = useState("SUMMARY");

    return (
        <Window
            title={`Viewing enquiry`}
            subtitle="First enquired Sep 11, 2019"
            close={() => props.close()}>

            <Navigation
                links={[
                    {
                        id: "SUMMARY",
                        label: "Summary",
                    }, {
                        id: "ENQUIRIES",
                        label: "Enquiries",
                    }, {
                        id: "BOOKINGS",
                        label: "Bookings",
                    }, {
                        id: "EMAILS",
                        label: "Email Logs",
                    }
                ]}
                active={activeTab}
                onClick={(tabID) => setActiveTab(tabID)} />

            <WindowCard
                title="Summary">
                booking
            </WindowCard>
        </Window >
    );
}

export default ViewBooking;
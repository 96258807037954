import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import moment from "moment";
import "./booking.scss";

/**
 * UI components
 */
import Badge from "../../ui/badge/badge";
import { NavLink } from "react-router-dom";

/**
 * Functional component to return the enquiry table row
 */
function Booking(props) {
    const [client, setClient] = useState({});
    const [agent, setAgent] = useState({});

    /**
     * Deconstruct the details from the props
     */
    const { id, details } = props;

    /**
     * Generate a string to represent the date_from date from the timestamps
     */
    const dateFrom = moment(details.date_from?.seconds, "X").format("MMM D[,] YYYY");
    const dateBooked = moment(details.created?.seconds, "X").format("MMM D[,] YYYY");

    /**
     * Get the start of today as seconds
     */
    const todayAsSeconds = moment().startOf("day").format("X");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the client record from the database
         */
        db.doc(`clients/${details.client}`)
            .get().then((clientDoc) => {
                /**
                 * Set the client data into the local state
                 */
                setClient(clientDoc.data());
            });
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the agent record from the database
         */
        db.doc(`users/${details.agent}`)
            .get().then((agentDoc) => {
                /**
                 * Set the agent data into the local state
                 */
                setAgent(agentDoc.data());
            });
    }, []);

    return (
        <tr>
            <td>
                {/* Is this booking removed? */}
                {((details.removed) || (details.removed && details.date_from?.seconds < todayAsSeconds)) &&
                    <Badge type="NEGATIVE" label="Removed" />
                }

                {/* Is this booking not marked as removed but is still in the past? */}
                {(!details.removed && details.date_from?.seconds < todayAsSeconds) &&
                    <Badge label="Previous booking" />
                }

                {/* Is this booking not marked as removed and is still in the future? */}
                {(!details.removed && details.date_from?.seconds > todayAsSeconds) &&
                    <Badge type="INFO" label="Active" />
                }
            </td>
            <td>
                {agent.first_name && `${agent.first_name} ${agent.last_name}`}
                {!agent.first_name && <small className="no-value">No agent assigned</small>}
            </td>
            <td>
                {dateBooked}
            </td>
            <td>
                {details.enquiry?.location}
            </td>
            <td>
                {dateFrom}
            </td>
            <td>
                £{details.enquiry?.quoted}
            </td>
            <td>
                {((!details.hotels_status) || (details.hotels_status === "unconfirmed")) &&
                    <Badge label="Unconfirmed" type="NEGATIVE" />
                }

                {(details.hotels_status === "partially") &&
                    <Badge label="Partially" type="AMBER" />
                }

                {(details.hotels_status === "confirmed") &&
                    <Badge label="Confirmed" type="POSITIVE" />
                }
            </td>
            <td>
                {((!details.rounds_status) || (details.rounds_status === "unconfirmed")) &&
                    <Badge label="Unconfirmed" type="NEGATIVE" />
                }

                {(details.rounds_status === "partially") &&
                    <Badge label="Partially" type="AMBER" />
                }

                {(details.rounds_status === "confirmed") &&
                    <Badge label="Confirmed" type="POSITIVE" />
                }
            </td>
            <td className="is-button">
                <NavLink to={`/booking/${id}`}>
                    View booking
                </NavLink>
            </td>
        </tr>
    );
}

export default Booking;
import { useState, useEffect, useRef } from "react";
import firebase from "firebase";

// Utility functions
import { db } from "../../../../../utils/firebase";

// UI components
import Window from "../../../../structure/window/window";
import WindowCard from "../../../../ui/window-card/window-card";
import Datepicker from "../../../../ui/datepicker/datepicker";
import Checkbox from "../../../../ui/checkbox/checkbox";
import Textarea from "../../../../ui/inputs/textarea";
import { PlusIcon } from "../../../../../utils/svgs";
import Button from "../../../../ui/button/button";

// Row components
import RoomType from "./items/room-type";
import BoardType from "./items/board-type";
import IncludedCourse from "./items/included-course";
import Transfer from "./items/transfer";
import Buggies from "./items/buggies";
import GroupDiscount from "./items/group-discount";
import Input from "../../../../ui/inputs/input";
import Select from "../../../../ui/select/select";
import { ArrowRightIcon } from "../../../../../utils/svgs-v2";

// Returns the markup and html for the option view window
export default function OptionView({
  holidayID,
  holiday,
  close,
  type,
  isCustom,
  window,
}) {
  const [saving, setSaving] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [stage, setStage] = useState(1);

  // Window fields
  const [windowID, setWindowID] = useState(null);
  const [basePrice, setBasePrice] = useState(0);
  const [isDraft, setIsDraft] = useState(false);
  const [isWebsiteEnabled, setIsWebsiteEnabled] = useState(false);
  const [name, setName] = useState("");
  const [packageType, setPackageType] = useState("");
  const [maxCost, setMaxCost] = useState(0);
  const [addingRoomType, setAddingRoomType] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);
  const [addingBoardType, setAddingBoardType] = useState(false);
  const [boardTypes, setBoardTypes] = useState([]);
  const [addingIncludedCourse, setAddingIncludedCourse] = useState(false);
  const [includedCourses, setIncludedCourses] = useState([]);
  const [addingTransfer, setAddingTransfer] = useState(false);
  const [transferLocations, setTransferLocations] = useState([]);
  const [windowBuggies, setWindowBuggies] = useState([]);
  const [addingGroupDiscount, setAddingGroupDiscount] = useState(false);
  const [groupDiscounts, setGroupDiscounts] = useState([]);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [availableCourses, setAvailableCourses] = useState({});
  const [availableDays, setAvailableDays] = useState([]);

  // Pre-defined fields
  const [packageNights, setPackageNights] = useState(0);
  const [packageRounds, setPackageRounds] = useState(0);

  // Markup adjustments
  const [baseMarkup, setBaseMarkup] = useState(0);
  const [adjustedMarkup, setAdjustedMarkup] = useState(0);
  const [adjustedBasePrice, setAdjustedBasePrice] = useState(0);
  const [adjustedMaxCost, setAdjustedMaxCost] = useState(0);

  // Store a boolean for the mounted state
  const isMounted = useRef(false);

  // On component load
  useEffect(() => {
    // Was there a window object passed in?
    if (type === "edit" && window.id) {
      // Pull the details from the document
      const {
        id,
        is_draft,
        is_website_enabled,
        name,
        type,
        adjusted_price_lowest,
        adjusted_price_highest,
        start_seconds,
        end_seconds,
        room_types,
        board_types,
        included_courses,
        buggies,
        transfers,
        group_discounts,
        base_markup,
        adjusted_markup,
        available_days,
        nights,
        rounds,
        notes,
      } = window;

      // Convert the dates to those we can set into the state
      const startUNIX = start_seconds * 1000;
      const endUNIX = end_seconds * 1000;

      // Set the state up
      setWindowID(id);
      setIsDraft(is_draft || false);
      setIsWebsiteEnabled(is_website_enabled || false);
      setName(name);
      setPackageType(type);
      setStartDate(startUNIX);
      setEndDate(endUNIX);
      setRoomTypes(room_types || []);
      setBoardTypes(board_types || []);
      setTransferLocations(transfers || []);
      setIncludedCourses(included_courses || []);
      setWindowBuggies(
        buggies || [
          {
            _id: generateID(),
            cost: null,
            base: false,
          },
        ]
      );
      setGroupDiscounts(group_discounts || []);
      setAdjustedBasePrice(adjusted_price_lowest || 0);
      setAdjustedMaxCost(adjusted_price_highest || 0);
      setBaseMarkup(base_markup || 0);
      setAdjustedMarkup(adjusted_markup || 0);
      setAvailableDays(available_days || []);
      setPackageNights(nights || 0);
      setPackageRounds(rounds || 0);
      setAdditionalNotes(notes || "");
    }

    if (type !== "edit") {
      setIsDraft(true);
      setAvailableDays(["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]);
    }

    // Toggle the mounted state
    setTimeout(() => {
      isMounted.current = true;
    }, 500);
  }, [window]);

  // On component mount
  useEffect(() => {
    // We want to fetch all the linked courses for this resort
    const fetchCourses = async () => {
      // Find all the courses associated with this resort
      const linkedCourses = await db
        .collection("CMS_courses")
        .where("resort_ids", "array-contains", Number(holiday.page_id))
        .get();

      // Store an array of courses available on this resort
      const resortCourses = {};

      // Then we want to map over each of the courses found to load their price_windows
      for (const linkedCourse of linkedCourses.docs) {
        // Push the course title onto the stack
        resortCourses[linkedCourse.id] = linkedCourse.data().title;
      }

      // Then set these into the state
      setAvailableCourses(resortCourses);
    };
    fetchCourses();
  }, []);

  // When any of the price window parameters are updated
  useEffect(() => {
    // Are we mounted already?
    if (isMounted.current) {
      processPricingUpdate();
    }
  }, [
    roomTypes,
    boardTypes,
    includedCourses,
    windowBuggies,
    transferLocations,
    groupDiscounts,
  ]);

  // Progress the stages along, conditionally
  const updateStage = (direction = "up") => {
    // Are we progressing up the stages?
    if (direction === "up") {
      // Are we coming from the initial name & type page?
      if (stage <= 3) {
        setStage((stage) => stage + 1);
      }
    } else {
      // As long as we have room to move down a stage
      if (stage >= 1) {
        setStage((stage) => stage - 1);
      }
    }
  };

  // When the startDate/endDate are updated
  useEffect(() => {
    // If we are on a new price window modal and there isn't already a windowID present
    if (type === "new" && !windowID) {
      // Make sure both the start & end dates are valid
      if (
        startDate &&
        startDate !== 0 &&
        endDate &&
        endDate !== 0 &&
        packageType.length > 0
      ) {
        createNewPriceWindow();
      }
    }
  }, [startDate, endDate, type]);

  // Process the update to any of the pricing window parameters
  const processPricingUpdate = async () => {
    // Setup some variables to hold the base and max prices
    let baseCost = 0;
    let maximumCost = 0;

    // Are there some room types available?
    if (roomTypes && roomTypes.length > 0) {
      // If there are, find the one marked with the base field
      const baseRoomTypes = [...roomTypes].filter(
        (roomType) => roomType.base === true
      );

      // Were there any found?
      if (baseRoomTypes.length > 0) {
        // Set the "cost" field from it into the baseCost variable
        baseCost += baseRoomTypes[0].cost;
      }

      // Find the room type with the highest cost
      const maxRoomType = [...roomTypes].reduce((max, roomType) =>
        roomType.cost > max.cost ? roomType : max
      );

      // Set the cost of this element into the maxCost variable
      maximumCost += maxRoomType.cost;
    }

    // Are there some board types available?
    if (boardTypes && boardTypes.length > 0) {
      // If there are, find the one marked with the base field
      const baseBoardTypes = [...boardTypes].filter(
        (boardType) => boardType.base === true
      );

      // Were there any found?
      if (baseBoardTypes.length > 0) {
        // Set the "cost" field from it into the baseCost variable
        baseCost += baseBoardTypes[0].cost;
      }

      // Find the board type with the highest cost
      const maxBoardType = [...boardTypes].reduce((max, boardType) =>
        boardType.cost > max.cost ? boardType : max
      );

      // Set the cost of this element into the maxCost variable
      maximumCost += maxBoardType.cost;
    }

    // Make sure there is a buggies object available
    if (windowBuggies && windowBuggies.length > 0) {
      // If the buggies should be included in the base price
      if (windowBuggies[0].base) {
        baseCost += windowBuggies[0].cost;
      }

      // Also add them into the max cost value
      maximumCost += windowBuggies[0].cost;
    }

    // Are there any transfer rows available?
    if (transferLocations && transferLocations.length > 0) {
      // If there are, find any that might be marked as included for the base price
      const baseTransfers = [...transferLocations].filter(
        (transfer) => transfer.base === true
      );

      // Were there any found?
      if (baseTransfers.length > 0) {
        // Set the "cost" field from it into the baseCost variable
        baseCost += baseTransfers[0].cost;
      }

      // Find the transfer row with the highest cost
      const maxTransferPrice = [...transferLocations].reduce((max, transfer) =>
        transfer.cost > max.cost ? transfer : max
      );

      // Set the cost of this element into the maxCost variable
      maximumCost += maxTransferPrice.cost;
    }

    // Set them into the state
    setBasePrice(baseCost);
    setMaxCost(maximumCost);
  };

  // Save the price window settings
  const savePriceWindow = async () => {
    // Update the state
    setSaving(true);

    // If we were editing a price window instead
    if (type === "edit") {
      await db.doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`).set(
        {
          is_draft: isDraft,
          is_website_enabled: isWebsiteEnabled,
          name: name || null,
          type: packageType,
          start_date: firebase.firestore.Timestamp.fromMillis(startDate),
          end_date: firebase.firestore.Timestamp.fromMillis(endDate),
          price_lowest: basePrice,
          adjusted_price_lowest: adjustedBasePrice,
          price_highest: maxCost,
          adjusted_price_highest: adjustedMaxCost,
          base_markup: baseMarkup,
          adjusted_markup: adjustedMarkup,
          available_days: availableDays,
          nights: Number(packageNights),
          rounds: Number(packageRounds),
          notes: additionalNotes,
        },
        { merge: true }
      );
    }

    // Reset the state
    setSaving(true);
    close();
  };

  // Create a new price window document
  const createNewPriceWindow = async () => {
    await db
      .collection(`CMS_holidays/${holidayID}/price_windows`)
      .add({
        is_draft: true,
        name: name || null,
        type: packageType,
        is_custom: isCustom,
        available_days: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
        start_date: firebase.firestore.Timestamp.fromMillis(startDate),
        end_date: firebase.firestore.Timestamp.fromMillis(endDate),
      })
      .then((newWindow) => {
        // Set the new window document ID into the sate
        setWindowID(newWindow.id);
        setWindowBuggies([
          {
            _id: generateID(),
            cost: null,
            base: false,
          },
        ]);
        setAvailableDays(["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]);
      });
  };

  // Generate a unique ID for writing the object into firestore arrays
  const generateID = () => {
    let d = new Date().getTime();
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now();
    }
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  // Add a new room type to the price object
  const addNewRoomType = async () => {
    // Toggle the loading state
    setAddingRoomType(true);

    // Generate a new ID for this room type
    const typeID = generateID();

    // Add a new room_tpe object into the documents array
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        room_types: firebase.firestore.FieldValue.arrayUnion({
          _id: typeID,
          type: null,
          cost: null,
          base: false,
        }),
      })
      .then(() => {
        // Set the new document into the state
        setRoomTypes((roomTypes) => [
          ...roomTypes,
          {
            _id: typeID,
            type: null,
            cost: null,
            base: false,
          },
        ]);

        // Reset the state
        setAddingRoomType(false);
      });
  };

  // Update the room type object on the pricing document
  const handleRoomTypeUpdate = async (updatedData) => {
    // Create a local copy of the roomTypes array
    const updatedTypes = [...roomTypes];

    // Loop over the room types to find the one to update
    for (let i in updatedTypes) {
      if (updatedTypes[i]._id === updatedData._id) {
        updatedTypes[i] = {
          ...updatedData,
          cost: parseFloat(updatedData.cost) || null,
        };
      }
    }

    // Reset the document to reflect the changed room types
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        room_types: updatedTypes,
      })
      .then(() => {
        // Then remove it from the local array
        setRoomTypes(updatedTypes);
      });
  };

  // Delete the room type row from the price window config
  const deleteRoomType = async (index) => {
    // Create a local copy of the roomTypes array
    const updatedTypes = [...roomTypes];

    // Splice it to remove the specified index
    updatedTypes.splice(index, 1);

    // Reset the document to reflect the changed room types
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        room_types: updatedTypes,
      })
      .then(() => {
        // Then remove it from the local array
        setRoomTypes(updatedTypes);
      });
  };

  // Add a new board type to the price object
  const addNewBoardType = async () => {
    // Toggle the loading state
    setAddingBoardType(true);

    // Generate a new ID for this board type
    const typeID = generateID();

    // Add a new room_tpe object into the documents array
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        board_types: firebase.firestore.FieldValue.arrayUnion({
          _id: typeID,
          type: null,
          cost: null,
          base: false,
        }),
      })
      .then(() => {
        // Set the new document into the state
        setBoardTypes((boardTypes) => [
          ...boardTypes,
          {
            _id: typeID,
            type: null,
            cost: null,
            base: false,
          },
        ]);

        // Reset the state
        setAddingBoardType(false);
      });
  };

  // Update the board type object on the pricing document
  const handleBoardTypeUpdate = async (updatedData) => {
    // Create a local copy of the boardTypes array
    const updatedTypes = [...boardTypes];

    // Loop over the board types to find the one to update
    for (let i in updatedTypes) {
      if (updatedTypes[i]._id === updatedData._id) {
        updatedTypes[i] = {
          ...updatedData,
          cost: parseFloat(updatedData.cost) || 0,
        };
      }
    }

    // Reset the document to reflect the changed board types
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        board_types: updatedTypes,
      })
      .then(() => {
        // Then update the local array
        setBoardTypes(updatedTypes);
      });
  };

  // Delete the board type row from the price window config
  const deleteBoardType = async (index) => {
    // Create a local copy of the boardTypes array
    const updatedTypes = [...boardTypes];

    // Splice it to remove the specified index
    updatedTypes.splice(index, 1);

    // Reset the document to reflect the changed board types
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        board_types: updatedTypes,
      })
      .then(() => {
        // Then update the local array
        setBoardTypes(updatedTypes);
      });
  };

  // Add a new included course to the price object
  const addNewIncludedCourse = async () => {
    // Toggle the loading state
    setAddingIncludedCourse(true);

    // Generate a new ID for this course
    const typeID = generateID();

    // Add a new room_tpe object into the documents array
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        included_courses: firebase.firestore.FieldValue.arrayUnion({
          _id: typeID,
          system_id: null,
          quantity: null,
          name: null,
        }),
      })
      .then(() => {
        // Set the new document into the state
        setIncludedCourses((includedCourses) => [
          ...includedCourses,
          {
            _id: typeID,
            system_id: null,
            quantity: null,
            name: null,
          },
        ]);

        // Reset the state
        setAddingIncludedCourse(false);
      });
  };

  // Update the included course object on the pricing document
  const handleIncludedCourseUpdate = async (updatedData) => {
    // Create a local copy of the includedCourses array
    const updatedCourses = [...includedCourses];

    // Loop over the courses to find the one to update
    for (let i in updatedCourses) {
      if (updatedCourses[i]._id === updatedData._id) {
        updatedCourses[i] = {
          ...updatedData,
        };
      }
    }

    // Reset the document to reflect the changed courses
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        included_courses: updatedCourses,
      })
      .then(() => {
        // Then update it in the local array
        setIncludedCourses(updatedCourses);
      });
  };

  // Delete the course row from the price window config
  const deleteIncludedCourse = async (index) => {
    // Create a local copy of the includedCourses array
    const updatedCourses = [...includedCourses];

    // Splice it to remove the specified index
    updatedCourses.splice(index, 1);

    // Reset the document to reflect the changed courses
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        included_courses: updatedCourses,
      })
      .then(() => {
        // Then remove it from the local array
        setIncludedCourses(updatedCourses);
      });
  };

  // Add a new transfer to the price object
  const addNewTransfer = async () => {
    // Toggle the loading state
    setAddingTransfer(true);

    // Generate a new ID for this transfer
    const typeID = generateID();

    // Add a new room_tpe object into the documents array
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        transfers: firebase.firestore.FieldValue.arrayUnion({
          _id: typeID,
          location: null,
          cost: null,
          base: false,
        }),
      })
      .then(() => {
        // Set the new document into the state
        setTransferLocations((transferLocations) => [
          ...transferLocations,
          {
            _id: typeID,
            location: null,
            cost: null,
            base: false,
          },
        ]);

        // Reset the state
        setAddingTransfer(false);
      });
  };

  // Update the transfer object on the pricing document
  const handleTransferUpdate = async (updatedData) => {
    // Create a local copy of the transfers array
    const updatedTypes = [...transferLocations];

    // Loop over the transfers to find the one to update
    for (let i in updatedTypes) {
      if (updatedTypes[i]._id === updatedData._id) {
        updatedTypes[i] = {
          ...updatedData,
          cost: parseFloat(updatedData.cost) || 0,
        };
      }
    }

    // Reset the document to reflect the changed transfers
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        transfers: updatedTypes,
      })
      .then(() => {
        // Then update the local array
        setTransferLocations(updatedTypes);
      });
  };

  // Delete the transfer row from the price window config
  const deleteTransfer = async (index) => {
    // Create a local copy of the transfers array
    const updatedTypes = [...transferLocations];

    // Splice it to remove the specified index
    updatedTypes.splice(index, 1);

    // Reset the document to reflect the changed transfers
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        transfers: updatedTypes,
      })
      .then(() => {
        // Then update the local array
        setTransferLocations(updatedTypes);
      });
  };

  // Update the buggies object on the pricing document
  const handleBuggiesUpdate = async (updatedData) => {
    // Create a local copy of the windowBuggies array
    const updatedBuggies = [...windowBuggies];

    // Update the buggies object (first array element)
    updatedBuggies[0] = {
      ...updatedData,
      cost: parseFloat(updatedData.cost) || 0,
    };

    // Reset the document to reflect the changed room types
    await db
      .doc(`CMS_holidays/${holidayID}/price_windows/${windowID}`)
      .update({
        buggies: updatedBuggies,
      })
      .then(() => {
        // Then rupdate the local array
        setWindowBuggies(updatedBuggies);
      });
  };

  // Calculate the reccomended margin to ensure the base price is rounded to the nearest £5
  const calculateRoundedMargin = (newMargin) => {
    // Calculate the new price by adding the percentage value to the base price
    const newBasePrice = basePrice * (1 + newMargin / 100);

    // Round the new price to the nearest £5 with no decimal places
    const roundedBasePrice = Math.round(newBasePrice / 5) * 5;
    const baseRemainder = roundedBasePrice % 5;
    const markupAdjustedBasePrice =
      baseRemainder >= 2.5
        ? roundedBasePrice + (5 - baseRemainder)
        : roundedBasePrice - baseRemainder;

    // Calculate the new price by adding the percentage value to the base price
    const newMaxPrice = maxCost * (1 + newMargin / 100);

    // Round the new price to the nearest £5 with no decimal places
    const roundedMaxPrice = Math.round(newMaxPrice / 5) * 5;
    const maxRemainder = roundedMaxPrice % 5;
    const markupAdjustedMaxPrice =
      maxRemainder >= 2.5
        ? roundedMaxPrice + (5 - maxRemainder)
        : roundedMaxPrice - maxRemainder;

    // Calculate the difference between the new rounded price and the original base price
    const markup = markupAdjustedBasePrice - basePrice;

    // Calculate the percentage markup
    const percentageMarkup = (markup / basePrice) * 100;

    // Parse the new markup to a maximum of 3 decimal places
    const parsedNewMarkup = Number(percentageMarkup.toFixed(3));

    // Set this new and adjusted markups into the state
    setBaseMarkup(Number(newMargin));
    setAdjustedMarkup(parsedNewMarkup);
    setAdjustedBasePrice(markupAdjustedBasePrice);
    setAdjustedMaxCost(markupAdjustedMaxPrice);
  };

  // Toggle the available day for a package to be showing
  const toggleAvailableDay = (dayAbbr) => {
    // Get a copy of the available days array from the calendar
    const availableDaysCopy = [...availableDays];

    // Check to see if it is already in the array
    if (availableDaysCopy.includes(dayAbbr)) {
      // If it is, remove it
      const existingIndex = availableDaysCopy.indexOf(dayAbbr);
      availableDaysCopy.splice(existingIndex, 1);
    } else {
      // Otherwise add it in
      availableDaysCopy.push(dayAbbr);
    }

    // Then set this modified array back into the state
    setAvailableDays(availableDaysCopy);
  };

  return (
    <Window
      title={type === "new" ? "Add Price Window" : "Edting Window"}
      className="price-window"
      close={() => close()}
    >
      {stage === 1 && (
        <>
          <WindowCard
            stacked={true}
            title="Package name & type"
            subtitle="Choose either per night pricing, or complete package cost"
          >
            <div className="input-grid cols-2">
              <Input
                value={name}
                onChange={setName}
                placeholder="Package name"
              />

              <Select
                selected={packageType}
                placeholder="Choose type"
                options={{ per_night: "Per Night", pre_defined: "Pre-Defined" }}
                onSelect={({ option }) => setPackageType(option)}
              />
            </div>
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Date range"
            subtitle="Choose the start & end dates this pricing window will cover"
          >
            <div className="input-grid cols-2">
              <Datepicker
                value={startDate}
                onSelect={(date) => setStartDate(date)}
                placeholder="Start date"
              />

              <Datepicker
                value={endDate}
                onSelect={(date) => setEndDate(date)}
                placeholder="End date"
              />
            </div>
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Available Days"
            subtitle="Which days is this package available to book on?"
          >
            <div className="package-days-available-column">
              <Checkbox
                label="Monday"
                checked={availableDays.includes("MON")}
                onClick={() => toggleAvailableDay("MON")}
              />
              <Checkbox
                label="Tuesday"
                checked={availableDays.includes("TUE")}
                onClick={() => toggleAvailableDay("TUE")}
              />
              <Checkbox
                label="Wednesday"
                checked={availableDays.includes("WED")}
                onClick={() => toggleAvailableDay("WED")}
              />
              <Checkbox
                label="Thursday"
                checked={availableDays.includes("THU")}
                onClick={() => toggleAvailableDay("THU")}
              />
              <Checkbox
                label="Friday"
                checked={availableDays.includes("FRI")}
                onClick={() => toggleAvailableDay("FRI")}
              />
              <Checkbox
                label="Saturday"
                checked={availableDays.includes("SAT")}
                onClick={() => toggleAvailableDay("SAT")}
              />
              <Checkbox
                label="Sunday"
                checked={availableDays.includes("SUN")}
                onClick={() => toggleAvailableDay("SUN")}
              />
            </div>
          </WindowCard>
        </>
      )}

      {stage === 2 && (
        <>
          {packageType === "pre_defined" && (
            <WindowCard
              stacked={true}
              title="Night & Rounds"
              subtitle="Set the nights and rounds for this package"
            >
              <div className="input-grid cols-2">
                <Input
                  type="number"
                  value={packageNights}
                  onChange={setPackageNights}
                  placeholder="Nights"
                />

                <Input
                  type="number"
                  value={packageRounds}
                  onChange={setPackageRounds}
                  placeholder="Rounds"
                />
              </div>
            </WindowCard>
          )}

          <WindowCard
            stacked={true}
            title="Room types"
            subtitle="Configure the different room types"
          >
            {roomTypes?.map((roomType, index) => (
              <RoomType
                key={roomType._id}
                data={roomType}
                onUpdate={(update) => handleRoomTypeUpdate(update, index)}
                onDelete={() => deleteRoomType(index)}
              />
            ))}

            <Button
              xsSmall
              label="Add"
              icon={<PlusIcon />}
              loading={addingRoomType}
              loadingText="Adding..."
              disabled={!windowID}
              onClick={() => addNewRoomType()}
            />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Board types"
            subtitle="Configure the different board types"
          >
            {boardTypes?.map((boardType, index) => (
              <BoardType
                key={boardType._id}
                data={boardType}
                onUpdate={(update) => handleBoardTypeUpdate(update, index)}
                onDelete={() => deleteBoardType(index)}
              />
            ))}

            <Button
              xsSmall
              label="Add"
              icon={<PlusIcon />}
              loading={addingBoardType}
              loadingText="Adding..."
              disabled={!windowID}
              onClick={() => addNewBoardType()}
            />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Included courses"
            subtitle="Outline the courses included in this package"
          >
            {includedCourses?.map((includedCourse, index) => (
              <IncludedCourse
                key={includedCourse._id}
                data={includedCourse}
                availableCourses={availableCourses}
                onUpdate={(update) => handleIncludedCourseUpdate(update, index)}
                onDelete={() => deleteIncludedCourse(index)}
              />
            ))}

            <Button
              xsSmall
              label="Add"
              icon={<PlusIcon />}
              loading={addingIncludedCourse}
              loadingText="Adding..."
              disabled={!windowID}
              onClick={() => addNewIncludedCourse()}
            />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Buggies"
            subtitle="Configure the price of a buggy if not already included (per round)"
          >
            {windowBuggies?.map((buggies) => (
              <Buggies
                data={buggies}
                onUpdate={(update) => handleBuggiesUpdate(update)}
              />
            ))}
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Transfers"
            subtitle="Configure the price of a transfer from various locations"
          >
            {transferLocations?.map((transfer, index) => (
              <Transfer
                key={transfer._id}
                data={transfer}
                onUpdate={(update) => handleTransferUpdate(update, index)}
                onDelete={() => deleteTransfer(index)}
              />
            ))}

            <Button
              xsSmall
              label="Add"
              icon={<PlusIcon />}
              loading={addingTransfer}
              loadingText="Adding..."
              disabled={!windowID}
              onClick={() => addNewTransfer()}
            />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Additional notes"
            subtitle="Leave some notes to be shown when this price window is selected"
          >
            <Textarea
              value={additionalNotes}
              onChange={setAdditionalNotes}
              placeholder="This note is specific to this pacakge only"
            />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Markup"
            subtitle="Select a base margin to make on this package"
          >
            <div className="input-grid cols-2">
              <Input
                value={baseMarkup}
                onChange={calculateRoundedMargin}
                symbol="%"
                placeholder="Markup"
              />

              <div className="adjusted-markup-display">
                Adjusted markup of {adjustedMarkup}% will be used
              </div>
            </div>
          </WindowCard>
        </>
      )}

      {stage === 3 && (
        <>
          <WindowCard
            stacked={true}
            title="Draft"
            subtitle="Should this package be saved as a draft?"
          >
            <Checkbox
              label="Save in draft"
              checked={isDraft}
              onClick={() => setIsDraft(!isDraft)}
            />
          </WindowCard>

          <WindowCard
            stacked={true}
            title="Bookings Enabled"
            subtitle="Should this package be enabled on the website for bookings?"
          >
            <Checkbox
              label="Enable for bookings"
              checked={isWebsiteEnabled}
              onClick={() => setIsWebsiteEnabled(!isWebsiteEnabled)}
            />
          </WindowCard>
        </>
      )}

      {stage > 1 && (
        <WindowCard>
          <div className="price-window-range-figure-flex">
            <div className="price-window-range-total">
              <div className="price-window-range-title">Base Price</div>
              <div className="price-window-range-numbers">
                <div className="price-window-range-number">
                  £{adjustedBasePrice}
                </div>
                <div className="price-window-range-number">
                  £{basePrice} at cost
                </div>
              </div>
            </div>

            <div className="price-window-range-total">
              <ArrowRightIcon />
            </div>

            <div className="price-window-range-total">
              <div className="price-window-range-title">Highest Price</div>
              <div className="price-window-range-numbers">
                <div className="price-window-range-number">
                  £{adjustedMaxCost}
                </div>
                <div className="price-window-range-number">
                  £{maxCost} at cost
                </div>
              </div>
            </div>
          </div>
        </WindowCard>
      )}

      <div className="ui-window-actions alt-layout">
        {stage <= 2 && (
          <>
            <Button
              label="Next"
              disabled={
                !startDate ||
                startDate === 0 ||
                !endDate ||
                !endDate === 0 ||
                !packageType ||
                packageType?.length === 0
              }
              onClick={() => updateStage()}
            />
          </>
        )}

        {stage > 2 && (
          <>
            <Button
              label="Save pricing"
              loadingText="Saving..."
              loading={saving}
              onClick={() => savePriceWindow()}
            />

            <Button
              label="Back"
              type="secondary"
              onClick={() => updateStage("back")}
            />
          </>
        )}
      </div>
    </Window>
  );
}

import { useState, useEffect, useContext } from 'react'
import { QuoteContext } from '../../context/context'
import moment from 'moment'
import './calendar.scss'

// UI components
import { ChevronLeftIcon, ChevronRightIcon, DoubleChevronLeftIcon, DoubleChevronRightIcon } from '../../../../utils/svgs-v2'

// Returns the markup and functionality for the calendar UI
export default function CalendarUI({ clearPackages, dateUpdated, priceWindows, chosenDates, currency }) {
    // Set the initial state to the current date
    const [date, setDate] = useState(moment())
    const [chosenDate, setChosenDate] = useState(null)

    // Get the chosen dates from the quote context
    const { ctxIncludedDates } = useContext(QuoteContext)

    // When the date is updated
    useEffect(() => {
        clearPackages()
    }, [date])

    // Calculate the start and end dates of the month
    const startOfMonth = moment(date).startOf('month').startOf('week')
    const endOfMonth = moment(date).endOf('month').endOf('week')

    // Create an array of dates for the days of the month
    const days = []
    let day = startOfMonth

    // Get a timestamp for now
    const today = moment().format('DD-MM-YYYY')

    // Loop through all the days of the month
    while (day <= endOfMonth) {
        // Get the start of the day in seconds
        const startSeconds = parseInt(day.startOf('day').format('X'))
        
        // Setup an array to store the matching pricing windows
        let matchingWindows = []

        // Is there pricing available for under a resort?
        if (priceWindows.resort && priceWindows.resort.length > 0) {
            // Find all the price windows where this day lands inside of
            matchingWindows = priceWindows.resort.filter((window) => {
                return (window.start_date.seconds <= startSeconds) && (window.end_date.seconds >= startSeconds)
            })
        } else if (priceWindows.course && priceWindows.course.length > 0) {
            // Otherwise is the pricing for a course
            matchingWindows = priceWindows.course.filter((window) => {
                return (window.start_date.seconds <= startSeconds) && (window.end_date.seconds >= startSeconds)
            })
        }

        // Were there any pricing windows found?
        if (matchingWindows.length > 0) {
            // Find the window with the lowest starting price
            const lowestPriceWindow = matchingWindows.reduce((acc, current) => {
                return current.price < acc.price ? current : acc
            })

            // Push the date and it's lowest price into the calendar days array
            days.push({
                window_day: day,
                window_price: lowestPriceWindow.price_lowest,
                is_active: chosenDate === day.startOf('day').valueOf() ? true : false,
                is_today: today === day.format('DD-MM-YYYY') ? true : false,
                is_chosen: chosenDates.includes(day.format('DD-MM-YYYY')) ? true : false,
                is_chosen_first: chosenDates[0] === day.format('DD-MM-YYYY') ? true : false,
                is_chosen_last: chosenDates[chosenDates.length - 1] === day.format('DD-MM-YYYY') ? true : false,
                is_confirmed: ctxIncludedDates.includes(day.format('DD-MM-YYYY')) ? true : false,
                is_confirmed_first: ctxIncludedDates[0] === day.format('DD-MM-YYYY') ? true : false,
                is_confirmed_last: ctxIncludedDates[ctxIncludedDates.length - 1] === day.format('DD-MM-YYYY') ? true : false,
            })
        } else {
            // Just return a null for the lowest price
            days.push({
                window_day: day,
                window_price: null,
                is_active: chosenDate === day.startOf('day').valueOf() ? true : false,
                is_today: today === day.format('DD-MM-YYYY') ? true : false,
                is_chosen: chosenDates.includes(day.format('DD-MM-YYYY')) ? true : false,
                is_chosen_first: chosenDates[0] === day.format('DD-MM-YYYY') ? true : false,
                is_chosen_last: chosenDates[chosenDates.length - 1] === day.format('DD-MM-YYYY') ? true : false,
                is_confirmed: ctxIncludedDates.includes(day.format('DD-MM-YYYY')) ? true : false,
                is_confirmed_first: ctxIncludedDates[0] === day.format('DD-MM-YYYY') ? true : false,
                is_confirmed_last: ctxIncludedDates[ctxIncludedDates.length - 1] === day.format('DD-MM-YYYY') ? true : false,
            })
        }

        day = moment(day).add(1, 'day')
    }

    const handleDateChosen = (selectedDate) => {
        setChosenDate(selectedDate.startOf('day').valueOf())
        dateUpdated(selectedDate)
    }

    return (
        <div className='calendar-ui-wrapper'>
            {/* Render the header */}
            <div className="header">
                {/* Render the previous month button */}
                <button onClick={() => setDate(moment(date).subtract(1, 'year'))}>
                    <DoubleChevronLeftIcon />
                </button>
                <button onClick={() => setDate(moment(date).subtract(1, 'month'))}>
                    <ChevronLeftIcon />
                </button>

                {/* Render the month and year */}
                <p>{moment(date).format('MMMM YYYY')}</p>

                {/* Render the next month button */}
                <button onClick={() => setDate(moment(date).add(1, 'month'))}>
                    <ChevronRightIcon />
                </button>
                <button onClick={() => setDate(moment(date).add(1, 'year'))}>
                    <DoubleChevronRightIcon />
                </button>
            </div>

            {/* Render the days of the week */}
            <div className="days-of-week">
                {moment.weekdaysShort().map((weekday) => (
                    <div key={weekday}>{weekday}</div>
                ))}
            </div>

            {/* Render the days of the month */}
            <div className="body">
                {days.map(({ window_day, window_price, is_active, is_today, is_chosen, is_chosen_first, is_chosen_last, is_confirmed, is_confirmed_first, is_confirmed_last }) => (
                    <div
                        key={window_day.toISOString()}
                        className={[
                            'day', 
                            window_day.month() !== date.month() ? 'other' : '', 
                            is_active ? 'is-active' : '',
                            is_chosen ? 'is-chosen' : '',
                            is_chosen_first ? 'is-chosen-first' : '',
                            is_chosen_last ? 'is-chosen-last' : '',
                            is_confirmed ? 'is-confirmed' : '',
                            is_confirmed_first ? 'is-confirmed-first' : '',
                            is_confirmed_last ? 'is-confirmed-last' : '',
                        ].join(' ').trim()}
                        onClick={() => handleDateChosen(window_day)}>
                        {/* Is the day today? */}
                        {is_today && <div className="today-indicator" />}

                        {/* Render the date */}
                        <div className="date">{window_day.format('D')}</div>

                        {/* If there is a price for the day available */}
                        {window_price && window_price > 0 && 
                            <div className="price">{currency}{window_price}</div>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}
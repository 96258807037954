import React, { useEffect, useState } from "react";
import { PlusIcon, TimesIcon } from "../../../utils/svgs";
import { db } from "../../../utils/firebase";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Upload from "../../../components/partner/upload/upload";

/**
 * Returns the images table for the partner with upload functionality
 */
export default function Images({ collection, id }) {
    const [partnerImages, setPartnerImages] = useState([]);
    const [uploading, setUploading] = useState(false);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the images collection for this partner
         */
        const unsubscribe = db.collection(`${collection}/${id}/images`)
            .onSnapshot((imagesSnap) => {
                /**
                 * Loop over the images found
                 */
                imagesSnap.docChanges().forEach((change) => {
                    /**
                     * Image document added
                     */
                    if (change.type === "added") {
                        setPartnerImages((images) => [...images, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    }
                    /**
                     * Image document updated
                     */
                    if (change.type === "modified") {
                        setPartnerImages((images) => {
                            let updatedImages = [...images];
                            for (let i in images) {
                                if (images[i].id === change.doc.id) {
                                    updatedImages[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedImages;
                        });
                    }
                    /**
                     * Image document removed
                     */
                    if (change.type === "removed") {
                        setPartnerImages((images) =>
                            images.filter((image) => image.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on componant unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * When clicked, remove the image 
     */
    const removePartnerImage = async (e, imageID) => {
        /**
         * Stop the click from the span tag bubbling up to the a tag
         */
        e.stopPropagation();
        /**
         * Remove the flight image document from the subcollection
         */
        await db.doc(`${collection}/${id}/images/${imageID}`).delete();
    }

    return (
        <>
            <table className="booking-table">
                <tbody>
                    <tr>
                        <td colSpan={2} className="partner-table-title">Images</td>
                    </tr>
                    <tr>
                        <td>Images</td>
                        <td className="vertical-center">
                            <Button
                                icon={<PlusIcon />}
                                label="Upload"
                                badge={true}
                                onClick={() => setUploading(true)} />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="vertical-center">
                            {/* If there are no flight images to show */}
                            {(Object.entries(partnerImages).length === 0) &&
                                <small>No uploads just yet...</small>

                            }

                            {/* If the client has uploaded some flight details */}
                            {(Object.entries(partnerImages).length > 0) &&
                                <>
                                    {partnerImages.map((image) => (
                                        <div
                                            key={image.id}
                                            className="flight-details-image-thumb" >
                                            <a href={image.public_url}
                                                rel="noreferrer"
                                                target="_blank">
                                                <img src={image.public_url} alt="Partner" />
                                            </a>

                                            <span onClick={(e) => removePartnerImage(e, image.id)}>
                                                <TimesIcon />
                                            </span>
                                        </div>
                                    ))}
                                </>
                            }
                        </td>
                    </tr>
                    <tr><td colSpan={2} className="seperator"></td></tr>
                </tbody>
            </table>

            {uploading &&
                <Upload
                    id={id}
                    collection={collection}
                    close={() => setUploading(false)} />
            }
        </>
    );
}
import styles from "./finances.module.scss"
import { useState, useEffect, useContext } from "react"
import { BookingContext } from "../../../utils/providers/booking"

// Utility functions
import firebase from "firebase"
import { db } from "../../../utils/firebase"

// UI components
import Loading from "../../../components/app/loading/loading"
import Button from "../../../components/ui/button/button"
import Badge from "../../../components/ui/badge/badge"
import Invoice from "./_components/invoice/invoice"
import { PlusIcon } from "../../../utils/svgs"

// Returns the finances tab for collating a tracking invoices
export default function Finances() {
	const [adding, setAdding] = useState(false)
	const [invoices, setInvoices] = useState([])
	const [loading, setLoading] = useState(true)
	const [bookingItems, setBookingItems] = useState([])

	// Pull the bookingID from the context
	const { bookingID } = useContext(BookingContext)

	// Create an array of all invoiceIDs from the invoices array, filtering out undefined invoiceIDs
	const invoiceIDs = bookingItems.filter((bookingItem) => bookingItem.invoiceID !== undefined).map((bookingItem) => bookingItem.invoiceID)

	// On component mount
	useEffect(() => {
		// Collect and set all the booking items
		collectBookingItems()
	}, [])

	// Collect all the hotels, rounds and transfers that would warrant an invoice
	const collectBookingItems = async () => {
		// Toggle the loading state
		setLoading(true)

		// Get all the invoices
		const invoices = await db
			.collection(`bookings/${bookingID}/supplier_invoices`)
			.get()
			.then((invoiceDocs) => invoiceDocs.docs.map((invoice) => ({ id: invoice.id, ...invoice.data() })))

		// Hotels
		const hotels = await db
			.collection(`bookings/${bookingID}/hotels`)
			.get()
			.then((hotelDocs) => hotelDocs.docs.map((hotel) => ({ ...hotel.data(), hotelID: hotel.id, finance_type: "hotel" })))

		// Map over each hotel, and fetch the associated hotel document with the resortID
		const hotelData = await Promise.all(
			hotels.map(async (hotel) => {
				// Get the hotel data
				const hotelDoc = await db.doc(`hotels/${hotel.resortID}`).get()

				// Pull the currency and email from the supplier
				const { currency, email } = hotelDoc.data()

				return { ...hotel, finance_id: hotel.hotelID, finance_currency: currency, finance_email: email }
			})
		)

		// Rounds
		const rounds = await db
			.collection(`bookings/${bookingID}/rounds`)
			.get()
			.then((roundDocs) => roundDocs.docs.map((round) => ({ ...round.data(), roundID: round.id, finance_type: "round" })))

		// Map over each of the rounds and fetch the associated course data
		const roundsData = await Promise.all(
			rounds.map(async (round) => {
				// Get the course data
				const courseDoc = await db.doc(`courses/${round.courseID}`).get()

				// Get the currency and email from the supplier
				const { currency, email } = courseDoc.data()

				return { ...round, finance_id: round.roundID, finance_currency: currency, finance_email: email }
			})
		)

		// Transfer
		const transfer = await db
			.doc(`bookings/${bookingID}`)
			.get()
			.then((bookingDoc) => {
				// Get the booking data
				const bookingData = bookingDoc.data()

				// If there is a transfer ID, return the transfer data
				if (bookingData.transfer) {
					return [{ transferID: bookingData.transfer, finance_type: "transfer" }]
				} else {
					return []
				}
			})

		// Then finally also get the transfer data
		const transferData = await Promise.all(
			transfer.map(async (transfer) => {
				// Get the transfer data
				const transferDoc = await db.doc(`transfers/${transfer.transferID}`).get()

				// Pull the currency and email from the supplier
				const { currency, email } = transferDoc.data()

				return { ...transfer, finance_id: transfer.transferID, finance_currency: currency, finance_email: email }
			})
		)

		// Combine all the data into one array
		const tempArr = [...hotelData, ...roundsData, ...transferData]
		console.log(tempArr)

		// Check the invoices for any connected items
		invoices.forEach((invoice) => {
			// If the invoice has connected items
			if (invoice.itemIDs) {
				// Loop over each connected item
				invoice.itemIDs.forEach((connectedItem) => {
					// Find the connected item in the booking items
					const connectedBookingItem = tempArr.find((bookingItem) => bookingItem.finance_id === connectedItem)

					// If the connected booking item is found
					if (connectedBookingItem) {
						// Set the invoiceID on the connected booking item
						connectedBookingItem.invoiceID = invoice.id
						connectedBookingItem.payment_status = invoice.payment_status
						connectedBookingItem.amount = invoice.amount
						connectedBookingItem.currency = invoice.currency
					}
				})
			}
		})

		// Set these into the state
		setBookingItems(tempArr)
		setInvoices(invoices)
		setLoading(false)
	}

	// Create a new supplier invoice attached to the booking
	const createNewInvoice = async () => {
		// Toggle the adding state for the spinner
		setAdding(true)

		// Add a new invoice document
		await db.collection(`bookings/${bookingID}/supplier_invoices`).add({
			created: firebase.firestore.FieldValue.serverTimestamp(),
		})

		// Collect the booking items again
		collectBookingItems()

		// Reset the adding state
		setAdding(false)
	}

	return (
		<div className={styles.container}>
			<div className={styles.table}>
				<div className="add-table">
					<Button
						badge={true}
						label="Add invoice"
						icon={<PlusIcon />}
						loading={adding}
						loadingText="Adding..."
						onClick={() => createNewInvoice()}
					/>
				</div>

				{invoices.map((invoice, index) => (
					<Invoice
						key={invoice.id}
						index={index}
						bookingID={bookingID}
						details={invoice}
						bookingItems={bookingItems}
						connectedInvoices={invoiceIDs}
						refetch={collectBookingItems}
					/>
				))}
			</div>

			<div className={styles.items}>
				<p className={styles.title}>Booking Line Items:</p>

				{loading && <Loading />}

				{!loading &&
					bookingItems.map((item, index) => (
						<div
							key={index}
							className={styles.item}>
							<p className={styles.type}>{item.finance_type}:</p>
							<p className={styles.email}>{item.finance_email}</p>
							{item.amount !== null && parseInt(item.amount) > 0 && item.currency !== null && (
								<p className={styles.price}>
									{item.amount} ({item.currency?.toUpperCase()})
								</p>
							)}

							<div className={styles.badges}>
								<Badge
									type={item.invoiceID ? "POSITIVE" : "AMBER"}
									label={item.invoiceID ? "Invoice Connected" : "No Invoice"}
								/>

								<Badge
									type={item.payment_status === "PAID" ? "POSITIVE" : "AMBER"}
									label={item.payment_status === "PAID" ? "Paid" : "Not Paid"}
								/>
							</div>
						</div>
					))}
			</div>
		</div>
	)
}

import moment from 'moment'

// Returns the HTML markup for  a global resort note
export default function ResortNote({ note, fromDate, untilDate }) {
    // Parse the note start and end dates into a moment object
    const noteStartDate = moment(note.start_date.seconds, 'X')
    const noteEndDate = moment(note.end_date.seconds, 'X')

    // Are both of the start & end dates before this chosen window?
    const isBefore = noteStartDate.isBefore(fromDate) && noteEndDate.isBefore(fromDate)
    const isAfter = noteStartDate.isAfter(untilDate) && noteEndDate.isAfter(untilDate)

    // If the note falls inbetween the chosen date range
    if (isBefore || isAfter) {
        return null
    } else {
        return (
            <div className='resort-global-note'>
                <small>{note.start} - {note.end}</small>
                <p>{note.text}</p>
            </div>
        )
    }
}
import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "./staff.scss";

/**
 * UI components
 */
import Input from "../ui/inputs/input";
import Button from "../ui/button/button";

/**
 * Functional component to return staff table row with targets
 */
function Staff(props) {
    const [saving, setSaving] = useState(false);
    const [monthly, setMonthly] = useState(0);
    const [weekly, setWeekly] = useState(0);
    const [daily, setDaily] = useState(0);

    /**
     * Deconstruct the user details from the props
     */
    const { id, details } = props;

    /**
     * Build a string for the current month & year, this will be used to save an 
     * "archived" version of the agents targets.
     * 
     * The format in the database will be, for example: 2021_12 representing December, 2021
     */
    const currentMonth = moment().format("YYYY_MM");

    /**
     * On component load
     */
    useEffect(() => {
        db.doc(`targets/${id}`)
            .get().then((targetDoc) => {
                /**
                 * Does this user have a targets document?
                 */
                if (targetDoc.exists) {
                    /**
                     * If they do, deconstruct the current values
                     */
                    const { monthly, weekly, daily } = targetDoc.data();
                    /**
                     * And then set them into the state
                     */
                    setMonthly(monthly);
                    setWeekly(weekly);
                    setDaily(daily);
                }
            });
    }, []);

    /**
     * When the monthly target is updated
     */
    useEffect(() => {
        /**
         * Split it between 4 weeks of 5 working days
         */
        setWeekly(monthly / 4);
        setDaily(monthly / 20);
    }, [monthly]);

    /**
     * Save the targets into the database for the given user
     */
    const saveTargets = async () => {
        /**
         * Show a loading spinner for the save button
         */
        setSaving(true);
        /**
         * save the targets into the database
         */
        await db.doc(`targets/${id}`).set({
            monthly: Number(monthly),
            weekly: Number(weekly),
            daily: Number(daily),
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        /**
         * Then we want to save the targets into the "archives" collection for 
         * querying down the line if we need to (looking at previous performance etc.)
         */
        await db.doc(`targets/${id}/archives/${currentMonth}`).set({
            monthly: Number(monthly),
            weekly: Number(weekly),
            daily: Number(daily),
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        /**
         * Reset the state
         */
        setSaving(false);
    }

    return (
        <tr>
            <td>{details.first_name} {details.last_name}</td>
            <td>
                <Input
                    type="number"
                    value={monthly}
                    onChange={setMonthly}
                    symbol="£" />
            </td>
            <td>
                <Input
                    type="number"
                    value={weekly}
                    symbol="£"
                    readOnly={true} />
            </td>
            <td>
                <Input
                    type="number"
                    value={daily}
                    symbol="£"
                    readOnly={true} />
            </td>
            <td>
                <Button
                    label="Save"
                    small={true}
                    loading={saving}
                    loadingText="Saving..."
                    onClick={() => saveTargets()} />
            </td>
        </tr>
    );
}

export default Staff;
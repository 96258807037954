import React, { useState, useContext } from "react";
import { SearchIcon } from "../../utils/svgs";
import { AuthContext } from "../../utils/providers/auth";
import "./clients.scss";

/**
 * Algolia
 */
import algoliasearch from "algoliasearch";
import {
    InstantSearch,
    connectSearchBox,
    connectHits,
    connectStateResults,
} from "react-instantsearch-dom";

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Input from "../../components/ui/inputs/input";
import Table from "../../components/structure/table/table";
import Client from "../../components/client/client";
import ViewClient from "../../components/client/view/view";

/**
 * Functional component to return a display of client accounts 
 */
function Clients() {
    const [viewClient, setViewClient] = useState("");

    // Get a reference to the user
    const { user } = useContext(AuthContext);

    /**
     * Algolia search API keys 
     */
    const searchClient = algoliasearch(
        "HY764WYKNV",
        "059a9cd79a33ec2b2822acf2ffdfd4c9",
    );

    /**
     * Custom inputfield for the algolia search
     */
    const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
        <form noValidate action="" role="search">
            <Input
                value={currentRefinement}
                icon={<SearchIcon />}
                fullEvent={true}
                placeholder="Quick search"
                onChange={(e) => refine(e.currentTarget.value)} />
        </form>
    );

    /**
     * Connect the custom search box
     */
    const CustomSearchBox = connectSearchBox(SearchBox);

    /**
     * Setup a custom function for returning the hits streamed down from algolia
     */
    const Hits = ({ hits }) => {
        /**
         * Are there any hits found from algolia?
         */
        if (hits.length > 0) {
            /**
             * Print the objects found
             */
            return hits.map((hit) => (
                <Client
                    key={hit.objectID}
                    id={hit.objectID}
                    details={hit}
                    maskDetails={user?.mask_details}
                    showClientWindow={(clientID) => setViewClient(clientID)} />
            ));
        } else {
            /**
             * Return nothing for the hits
             */
            return null;
        }
    };

    /**
     * Connect the custom hits to the DOM
     */
    const CustomHits = connectHits(Hits);

    /**
     * Connect a custom results component to display either the results no a no results message
     */
    const Results = connectStateResults(
        ({ searchState, searchResults, children }) =>
            searchResults && searchResults.nbHits !== 0 ? (
                <CustomHits />
            ) : (
                <tr>
                    <td colSpan={5}>
                        <small>No results have been found {searchState.query ? `for ${searchState.query}.` : ""}</small>
                    </td>
                </tr>
            )
    );

    return (
        <Tile fullPage={true}>
            <InstantSearch searchClient={searchClient} indexName="clients">
                <Title className="flex">
                    <h1>All clients</h1>

                    {/* Custom input search field */}
                    <CustomSearchBox />
                </Title>

                <Table
                    className="clients-table"
                    headings={[
                        "Name",
                        "Email",
                        "Telephone",
                        "Stableford Points",
                    ]}
                    noResultsMessage="No clients found with those details">

                    {/* Print the search results to the DOM */}
                    <Results />
                </Table>

                {/* Do we have a clientID in the local state to load into a window? */}
                {viewClient &&
                    <ViewClient
                        clientID={viewClient}
                        close={() => setViewClient("")} />
                }
            </InstantSearch>
        </Tile >
    );
}

export default Clients;
import React, { useState } from "react"
import { Redirect } from "react-router-dom"
import { db } from "../../../utils/firebase"

/**
 * UI components
 */
import Logo from "../../../assets/images/golf-holidays-direct-logo.png"
import BackgroundImage from "../../../assets/images/login-background.jpeg"
import Input from "../../../components/ui/inputs/input"
import Button from "../../../components/ui/button/button"

/**
 * Functional component for the login page
 */
function ClientLogin() {
	const [errors, setErrors] = useState({})
	const [email, setEmail] = useState("")
	const [reference, setReference] = useState("")
	const [fetching, setFetching] = useState(false)
	const [bookingID, setBookingID] = useState("")

	/**
	 * Check the booking reference and client email against our records to see whether we should
	 * be redirecting them to the itinerary page
	 */
	const checkBookingDetails = async () => {
		/**
		 * Set the state to show a spinner
		 */
		setFetching(true)
		/**
		 * Get a number representation of the reference number
		 */
		const referenceNum = Number(reference)
		/**
		 * First attempt to find a booking with the reference that was passed in
		 */
		const booking = await db
			.collection("bookings")
			.where("reference", "==", referenceNum)
			.limit(1)
			.get()
			.then((bookingDocs) => {
				/**
				 * If there was a booking document found
				 */
				if (bookingDocs.size > 0) {
					/**
					 * Pull the data we need from the document
					 */
					const { client } = bookingDocs.docs[0].data()
					/**
					 * Return this data
					 */
					return { id: bookingDocs.docs[0].id, client }
				} else {
					// Try finding it again but with the referenace as a string
					return db
						.collection("bookings")
						.where("reference", "==", reference)
						.limit(1)
						.get()
						.then((bookingDocs) => {
							/**
							 * If there was a booking document found
							 */
							if (bookingDocs.size > 0) {
								/**
								 * Pull the data we need from the document
								 */
								const { client } = bookingDocs.docs[0].data()
								/**
								 * Return this data
								 */
								return { id: bookingDocs.docs[0].id, client }
							} else {
								/**
								 * Push an error into the state
								 */
								setErrors({ global: "Reference not found, please check and try again" })
								/**
								 * Reset the state
								 */
								setFetching(false)
								/**
								 * Return a false statement to stop the checking
								 */
								return false
							}
						})
				}
			})
		/**
		 * If there was a booking returned
		 */
		if (booking) {
			/**
			 * Pull the client document from the database
			 */
			await db
				.doc(`clients/${booking.client}`)
				.get()
				.then((clientDoc) => {
					console.log(clientDoc.data())
					/**
					 * If there was a client document found
					 */
					if (clientDoc.exists && clientDoc.data().email.trim() === email.trim()) {
						/**
						 * Set the original booking ID into the state to redirect
						 */
						setBookingID(booking.id)
					} else {
						/**
						 * Push an error into the state
						 */
						setErrors({
							global: "Email not recognised on this booking, please contact your lead booker",
						})
						/**
						 * Reset the state
						 */
						setFetching(false)
						/**
						 * Return a false statement to stop the checking
						 */
						return false
					}
				})
		}
	}

	/**
	 * If we have a bookingID present in the state, we've succesfully found the booking with the
	 * users email address and reference checked against it and so can redirect them to the page
	 */
	if (bookingID) {
		return <Redirect to={`/itinerary/${bookingID}`} />
	} else {
		return (
			<section
				id="login-page"
				style={{ backgroundImage: `url("${BackgroundImage}")` }}>
				<div className="login-window">
					<img
						src={Logo}
						alt="Golf Holidays Direct"
					/>
					<p>Welcome to the Golf Holidays Direct online booking system. Please login to proceed.</p>

					{/* Login form fields */}
					<div className="login-form">
						<Input
							type="email"
							placeholder="Email:"
							value={email}
							onChange={setEmail}
						/>

						<Input
							type="text"
							placeholder="Reference:"
							value={reference}
							onChange={setReference}
						/>

						{/* If there is an error signing the user in */}
						{errors.global && <small className="login-error">{errors.global}</small>}

						<Button
							label="Login"
							loading={fetching}
							loadingText="Loading itinerary..."
							onClick={checkBookingDetails}
							disabled={!email || !reference}
						/>
					</div>
				</div>
			</section>
		)
	}
}

export default ClientLogin

import parse from 'html-react-parser'
import { useState } from 'react'

// Firebase utilities
import { auth, db } from '../../../../utils/firebase'

// UI components
import Input from '../../../ui/inputs/input'

// Returns the markup for the details tab when editing a holiday
export default function HolidayDetails({ holidayID, holiday }) {
    const [hotelID, setHotelID] = useState(holiday?.bs2_id_hotel || '')
    const [altHotelIDs, setAltHotelIDs] = useState(holiday?.bs2_id_hotel_alternates || '')

    // On input focus blur, save the new hotel ID into the document
    const saveHotelID = async () => {
        console.log("Saving...")

        let altIDs = []

        if (typeof altHotelIDs === "string" && altHotelIDs.length > 0) {
            altIDs = altHotelIDs.split(",")
        }

        await db.doc(`CMS_holidays/${holidayID}`).set({
            bs2_id_hotel: hotelID ?? null,
            bs2_id_hotel_alternates: altIDs ?? null
        }, { merge:true })

        console.log("Done!")
    }

    return (
        <>
            {auth.currentUser.uid === 'CGOHCdUwFAOe13OSTY2D9qdO2LX2' && (
                <div style={{ width: "395px" }}>
                    <Input
                        label="bs2_id_hotel"
                        showLabel={true}
                        value={hotelID}
                        onChange={setHotelID}
                        placeholder=" "
                        onBlur={() => saveHotelID()} />
                    <br />
                    <Input
                        label="bs2_id_hotel_alternates"
                        showLabel={true}
                        value={altHotelIDs}
                        onChange={setAltHotelIDs}
                        placeholder=" "
                        onBlur={() => saveHotelID()} />
                    <br />
                </div>
            )}

            <div className='holiday-details-images'>
                <a className='holiday-details-image' href={holiday.media?.primary_image} target='_blank'>
                    <img src={holiday.media?.primary_image} />
                </a>

                {holiday.media?.images?.slice(0, 8).map((image, index) => (
                    <a key={image} className='holiday-details-image' href={image} target='_blank'>
                        <img key={index} src={image} />
                    </a>
                ))}
            </div>

            <div className='holiday-details-text'>
                {parse(holiday?.description, { replace: (domNode) => {
                    if (domNode.attribs && domNode.attribs.href) {
                        return <a href={`https://www.golfholidaysdirect.com${domNode.attribs.href}`} target='_blank'>{domNode.children[0].data}</a>
                    }
                } })}
            </div>
        </>
    )
}
import "./flight.scss"
import moment from "moment"

// Supplier logos
import Norwegian from "../airlines/norwegian.png"
import EasyJet from "../airlines/easyjet.png"
import TUI from "../airlines/tui.png"
import JT2 from "../airlines/jet2.png"

// Returns the HTMl markup for a flight
export default function FlightLight({ details }) {
	const {
		supplier,
		origin_airport,
		destination_airport,
		departure_date,
		departure_time,
		arrival_date,
		arrival_time,
		outbound_flight_number,
		outbound_journey_mins,
		outbound_num_stops,
		return_home_date,
		return_home_time,
		return_home_depart_date,
		return_home_depart_time,
		return_flight_number,
		return_journey_mins,
		return_num_stops,
	} = details

	const outboundStops = outbound_num_stops > 0 ? ` • ${outbound_num_stops} stop${outbound_num_stops > 1 ? "s" : ""}` : ""
	const returnStops = return_num_stops > 0 ? ` • ${return_num_stops} stop${return_num_stops > 1 ? "s" : ""}` : ""

	return (
		<div className="flight-row is-lite">
			<div className="flight-row__header">
				<div className="flight-row__header--number">
					{["NOF", "NOR", "NOP"].includes(supplier) && (
						<img
							src={Norwegian}
							alt="Norwegian"
						/>
					)}
					{["EZJ", "EZB", "EZF"].includes(supplier) && (
						<img
							src={EasyJet}
							alt="EasyJet"
						/>
					)}
					{["THF"].includes(supplier) && (
						<img
							src={TUI}
							alt="TUI"
						/>
					)}
					{["JT2"].includes(supplier) && (
						<img
							src={JT2}
							alt="Jet2"
						/>
					)}
					<span>
						{outbound_flight_number} • {origin_airport}
						{outboundStops}
					</span>
				</div>

				<div className="flight-row__header--flight">
					<div className="flight-row__header--departure">
						<p className="flight-row__header--time">{moment(departure_time, "HHmm").format("h:mm A")}</p>
						<p className="flight-row__header--date">{moment(departure_date, "YYYYMMDD").format("Do MMMM, YYYY")}</p>
					</div>

					<div className="flight-row__header--journey">
						<div className="flight-row__header--line">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="15.755"
								height="14"
								viewBox="0 0 15.755 14">
								<path
									id="Path_18"
									data-name="Path 18"
									d="M13.152-7a3.4,3.4,0,0,1,1.723.533q.9.533.9,1.217t-.9,1.217a3.4,3.4,0,0,1-1.723.533H10.035L7.164,1.531a.428.428,0,0,1-.383.219h-1.8a.393.393,0,0,1-.342-.178A.419.419,0,0,1,4.566,1.2l1.34-4.7H3.09L1.914-1.914a.441.441,0,0,1-.355.164H.465a.4.4,0,0,1-.342-.164.509.509,0,0,1-.1-.383L.9-5.25.027-8.2a.509.509,0,0,1,.1-.383A.4.4,0,0,1,.465-8.75H1.559a.441.441,0,0,1,.355.164L3.09-7H5.906l-1.34-4.7a.433.433,0,0,1-.014-.191.363.363,0,0,1,.082-.191.472.472,0,0,1,.15-.123.423.423,0,0,1,.191-.041h1.8a.428.428,0,0,1,.383.219L10.035-7Z"
									transform="translate(-0.022 12.25)"
									fill="#a3aeb2"
								/>
							</svg>
						</div>
						<p className="flight-row__header--duration">{moment.duration(outbound_journey_mins, "minutes").humanize()}</p>
					</div>

					<div className="flight-row__header--arrival">
						<p className="flight-row__header--time">{moment(arrival_time, "HHmm").format("h:mm A")}</p>
						<p className="flight-row__header--date">{moment(arrival_date, "YYYYMMDD").format("Do MMMM, YYYY")}</p>
					</div>
				</div>
			</div>

			<div className="flight-row__splitter" />

			<div className="flight-row__header">
				<div className="flight-row__header--number">
					{["NOF", "NOR", "NOP"].includes(supplier) && (
						<img
							src={Norwegian}
							alt="Norwegian"
						/>
					)}
					{["EZJ", "EZB", "EZF"].includes(supplier) && (
						<img
							src={EasyJet}
							alt="EasyJet"
						/>
					)}
					{["THF"].includes(supplier) && (
						<img
							src={TUI}
							alt="TUI"
						/>
					)}
					{["JT2"].includes(supplier) && (
						<img
							src={JT2}
							alt="Jet2"
						/>
					)}
					<span>
						{return_flight_number} • {destination_airport}
						{returnStops}
					</span>
				</div>

				<div className="flight-row__header--flight">
					<div className="flight-row__header--departure">
						<p className="flight-row__header--time">{moment(return_home_depart_time, "HHmm").format("h:mm A")}</p>
						<p className="flight-row__header--date">{moment(return_home_depart_date, "YYYYMMDD").format("Do MMMM, YYYY")}</p>
					</div>

					<div className="flight-row__header--journey">
						<div className="flight-row__header--line is-return">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="15.755"
								height="14"
								viewBox="0 0 15.755 14">
								<path
									id="Path_18"
									data-name="Path 18"
									d="M13.152-7a3.4,3.4,0,0,1,1.723.533q.9.533.9,1.217t-.9,1.217a3.4,3.4,0,0,1-1.723.533H10.035L7.164,1.531a.428.428,0,0,1-.383.219h-1.8a.393.393,0,0,1-.342-.178A.419.419,0,0,1,4.566,1.2l1.34-4.7H3.09L1.914-1.914a.441.441,0,0,1-.355.164H.465a.4.4,0,0,1-.342-.164.509.509,0,0,1-.1-.383L.9-5.25.027-8.2a.509.509,0,0,1,.1-.383A.4.4,0,0,1,.465-8.75H1.559a.441.441,0,0,1,.355.164L3.09-7H5.906l-1.34-4.7a.433.433,0,0,1-.014-.191.363.363,0,0,1,.082-.191.472.472,0,0,1,.15-.123.423.423,0,0,1,.191-.041h1.8a.428.428,0,0,1,.383.219L10.035-7Z"
									transform="translate(-0.022 12.25)"
									fill="#a3aeb2"
								/>
							</svg>
						</div>
						<p className="flight-row__header--duration">{moment.duration(return_journey_mins, "minutes").humanize()}</p>
					</div>

					<div className="flight-row__header--arrival">
						<p className="flight-row__header--time">{moment(return_home_time, "HHmm").format("h:mm A")}</p>
						<p className="flight-row__header--date">{moment(return_home_date, "YYYYMMDD").format("Do MMMM, YYYY")}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { db } from "../../utils/firebase";
import moment from "moment";

/**
 * Functional component to return the booking with an outstanding balance
 */
export default function Booking({ id, details, maskDetails }) {
    const [agent, setAgent] = useState({});
    const [client, setClient] = useState({});

    /**
     * Generate a string to represent the balance_due date from the timestamps
     */
    const balanceDue = moment(details.balance_due?.seconds, "X").format("MMM D[,] YYYY");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the agent record from the database
         */
        db.doc(`users/${details.agent}`)
            .get().then((agentDoc) => {
                /**
                 * Set the agent data into the local state
                 */
                setAgent(agentDoc.data());
            });
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the client record from the database
         */
        db.doc(`clients/${details.client}`)
            .get().then((clientDoc) => {
                /**
                 * Set the client data into the local state
                 */
                setClient(clientDoc.data());
            });
    }, []);

    return (
        <tr>
            <td>
                {!maskDetails && (
                    <NavLink to={`client/${details.client}`}>
                        {client.first_name && `${client.first_name} ${client.last_name}`}
                        {!client.first_name && <small className="no-value">No name set</small>}
                    </NavLink>
                )}

                {maskDetails && (
                    <>
                        {client.first_name && `${client.first_name} ${client.last_name}`}
                        {!client.first_name && <small className="no-value">No name set</small>}
                    </>
                )}
            </td>
            <td>
                {!maskDetails && (
                    <>
                        {client?.phone && <>{client.phone}</>}
                        {!client?.phone && <small className="no-value">No number set</small>}
                    </>
                )}

                {maskDetails && (
                    <>
                        {client?.phone && <>{client.phone?.replace(/./g, '*')}</>}
                        {!client?.phone && <small className="no-value">No number set</small>}
                    </>
                )}
            </td>
            <td>
                {agent?.first_name && `${agent.first_name} ${agent.last_name}`}
                {!agent?.first_name && <small className="no-value">No agent assigned</small>}
            </td>
            <td>
                {(balanceDue === "Invalid date") &&
                    <small>No due date set</small>
                }

                {(balanceDue !== "Invalid date") && balanceDue}
            </td>
            <td>
                {details.balance_outstanding &&
                    <>£{details.balance_outstanding?.toLocaleString()}</>
                }

                {!details.balance_outstanding &&
                    <>£{details.margins?.customer_price?.toLocaleString()}</>
                }
            </td>
            <td className="is-button">
                <NavLink to={`booking/${id}`}>
                    View booking
                </NavLink>
            </td>
        </tr>
    );
}
import React, { useState, useEffect } from "react";
import { db } from "../../utils/firebase";
import moment from "moment";
import "./conversions.scss";

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Select from "../../components/ui/select/select";
import Table from "../../components/structure/table/table";
import ConversionsRowStaff from "../../components/conversions/conversion-row-staff";
import ConversionsRowWebsite from "../../components/conversions/conversion-row-website";

/**
 * Functional component to return the dashboard for viewing conversions
 */
export default function Conversions() {
    const [users, setUsers] = useState([]);

    const [searchPeriod, setSearchPeriod] = useState("Today");

    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);

    /**
     * When the search period has been updated
     */
    useEffect(() => {
        if (searchPeriod === "Today") {
            setStartDate(moment().startOf("day").valueOf())
            setEndDate(moment().endOf("day").valueOf())
        }

        if (searchPeriod === "Week") {
            setStartDate(moment().startOf("week").valueOf())
            setEndDate(moment().endOf("week").valueOf())
        }

        if (searchPeriod === "Month") {
            setStartDate(moment().startOf("month").valueOf())
            setEndDate(moment().endOf("month").valueOf())
        }

        if (searchPeriod === "Custom range") {
            setStartDate(0)
            setEndDate(0)
        }
    }, [searchPeriod])

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull a list of all sales agents from the databas
         */
        const unsubscribe = db.collection("users")
            .orderBy("first_name")
            .where("show_for.targets", "==", true)
            .onSnapshot((userChanges) => {
                /**
                 * Loop over the user documents found
                 */
                userChanges.docChanges().forEach((change) => {
                    /**
                     * Agent document added
                     */
                    if (change.type === "added") {
                        setUsers((users) => [...users, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    }
                    /**
                     * Agent document updated
                     */
                    if (change.type === "modified") {
                        setUsers((users) => {
                            let updatedUsers = [...users];
                            for (let i in users) {
                                if (users[i].id === change.doc.id) {
                                    updatedUsers[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedUsers;
                        });
                    }
                    /**
                     * Agent document removed
                     */
                    if (change.type === "removed") {
                        setUsers((users) => users.filter((user) => user.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener when we're done
         */
        return () => unsubscribe();
    }, []);

    return (
        <Tile fullPage={true}>
            <Title className="flex has-select-field">
                <h1>Conversions</h1>
            </Title>

            <div className={["page-filters conversion-filters"].join(" ")}>
                <Select
                    label="Search by:"
                    placeholder="Search by:"
                    value={searchPeriod}
                    selected={searchPeriod}
                    activeOnHover={true}
                    onSelect={(option) => setSearchPeriod(option.option)}
                    options={{
                        "Today": "Today",
                        "Week": "This week",
                        "Month": "This month",
                    }} />
            </div>

            <Table
                className="conversions-table"
                headings={[
                    "Website",
                    "Enquiries",
                    "Converted",
                    "",
                ]}
                noResults={!startDate || !endDate}
                noResultsMessage="No date for the data selected">

                <ConversionsRowWebsite
                    siteID="GHD"
                    name="Golf Holidays Direct"
                    startDate={startDate}
                    endDate={endDate} />

                <ConversionsRowWebsite
                    siteID="MGH"
                    name="My Golf Holidays"
                    startDate={startDate}
                    endDate={endDate} />

                <ConversionsRowWebsite
                    siteID="MGB"
                    name="My Golf Breaks"
                    startDate={startDate}
                    endDate={endDate} />

                <ConversionsRowWebsite
                    siteID="SYSTEM"
                    name="System"
                    startDate={startDate}
                    endDate={endDate} />
            </Table>

            <Table
                className="conversions-table has-extra-margin"
                headings={[
                    "Agent",
                    "Enquiries",
                    "Converted",
                    "",
                ]}
                noResults={!startDate || !endDate}
                noResultsMessage="No date for the data selected">

                {users.map((user) => (
                    <ConversionsRowStaff
                        key={user.id}
                        id={user.id}
                        details={user}
                        startDate={startDate}
                        endDate={endDate} />
                ))}
            </Table>
        </Tile>
    );
}
import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/golf-holidays-direct-logo.png";
import "./reservation.scss";

/**
 * Reservation types
 */
import Transfer from "./transfer/transfer";
import Hotel from "./hotel/hotel";
import Round from "./round/round";
import Multiple from "./multiple/multiple";

/**
 * Functional component to return the reservation page shwon to partners
 */
function Reservation() {
    const [type, setType] = useState("");
    const [bookingID, setBookingID] = useState("");
    const [hotelID, setHotelID] = useState("");
    const [roundID, setRoundID] = useState("");
    const [multipleIDs, setMultipleIDs] = useState("");
    const [reservationEmail, setReservationEmail] = useState("");
    const [complete, setComplete] = useState(false);

    /**
     * Build an object for the search parameters
     */
    const params = new URLSearchParams(window.location.search);

    /**
     * Get the reservation type from the params
     */
    const reservation_type = params.get("type");

    /**
     * Get the reservation IDs from the params
     */
    const booking_id = params.get("booking");
    const hotel_id = params.get("hotelID");
    const round_id = params.get("roundID");
    const multiple_ids = params.get("multipleIDs");
    const reservation_email = params.get("reservationEmail");

    /**
     * When the reservation type parameter is updated
     */
    useEffect(() => {
        /**
         * Set them into the state
         */
        setType(reservation_type);
        setBookingID(booking_id);
        setHotelID(hotel_id);
        setRoundID(round_id);
        setMultipleIDs(multiple_ids);
        setReservationEmail(reservation_email);
    }, [reservation_type]);

    return (
        <div className="reservation-background">
            <div className="reservation-tile">
                <img src={Logo} alt="Golf Holiday Direct" />

                {complete &&
                    <div className="reservation-intro">
                        <h1>Complete!</h1>
                        <p>Thank you for your response.</p>
                    </div>
                }

                {!complete &&
                    <>
                        <div className="reservation-intro">
                            <h1>Reservation Request</h1>
                            <p>Please either click "accept" or "reject" to update us on whether the date &amp; time suggested are available.</p>
                        </div>

                        {/* Transfer reservation type */}
                        {(type === "transfer") &&
                            <Transfer
                                bookingID={bookingID}
                                hotelID={hotelID}
                                complete={() => setComplete(true)} />
                        }

                        {/* Hotel reservation type */}
                        {(type === "hotel") &&
                            <Hotel
                                bookingID={bookingID}
                                hotelID={hotelID}
                                complete={() => setComplete(true)} />
                        }

                        {/* Round reservation type */}
                        {(type === "round") &&
                            <Round
                                bookingID={bookingID}
                                roundID={roundID}
                                complete={() => setComplete(true)} />
                        }

                        {/* Multiple reservation type */}
                        {(type === "multiple") &&
                            <Multiple
                                bookingID={bookingID}
                                multipleIDs={multipleIDs.split(",")}
                                reservationEmail={reservationEmail}
                                complete={() => setComplete(true)} />
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default Reservation;
import styles from "../passengers.module.scss"
import { db } from "../../../../../../utils/firebase"
import { useState, useEffect } from "react"

// Utilities
import Nationalities from "../_utils/nationalities.json"
import Countries from "../_utils/countries.json"

// UI components
import Button from "../../../../../../components/ui/button/button"
import Select from "../../../../../../components/ui/select/select"
import SearchSelect from "../../../../../../components/ui/select/search-select"
import Input from "../../../../../../components/ui/inputs/input"
import Badge from "../../../../../../components/ui/badge/badge"
import { SearchIcon } from "../../../../../../utils/svgs"

// Returns the HTML markup for a passenger
export default function Passenger({ bookingID, passenger, index, remove, removing }) {
	const [complete, setComplete] = useState(false)
	const [editing, setEditing] = useState(false)
	const [saving, setSaving] = useState(false)

	const {
		id: passengerID,
		lead_booker,
		title: docTitle,
		first_name,
		middle_initials,
		last_name,
		gender: docGender,
		nationality: docNationality,
		date_of_birth,
		email: docEmail,
		telephone: docTelephone,
		address,
	} = passenger

	useEffect(() => {
		setComplete(() => {
			if (!docTitle || docTitle?.length === 0) return false
			if (!first_name || first_name?.length === 0) return false
			if (!last_name) return false
			if (!docGender) return false
			if (!docNationality) return false
			if (!date_of_birth) return false
			if (!docEmail) return false
			if (!docTelephone) return false
			if (!address?.first_line) return false
			if (!address?.city) return false
			if (!address?.postcode) return false
			if (!address?.country) return false
			return true
		})
	}, [passenger])

	const [title, setTitle] = useState(docTitle ?? null)
	const [firstName, setFirstName] = useState(first_name ?? null)
	const [middleInitials, setMiddleInitials] = useState(middle_initials?.length > 0 ? middle_initials : null)
	const [lastName, setLastName] = useState(last_name ?? null)
	const [gender, setGender] = useState(docGender ?? null)
	const [nationality, setNationality] = useState(docNationality ?? null)
	const [dateOfBirth, setDateOfBirth] = useState(date_of_birth ?? null)
	const [email, setEmail] = useState(docEmail ?? null)
	const [telephone, setTelephone] = useState(docTelephone ?? null)
	const [addressLine1, setAddressLine1] = useState(address?.first_line ?? null)
	const [city, setCity] = useState(address?.city ?? null)
	const [postcode, setPostcode] = useState(address?.postcode ?? null)
	const [country, setCountry] = useState(address?.country ?? null)

	const savePassenger = async () => {
		setSaving(true)

		await db.doc(`bookings/${bookingID}/passengers/${passengerID}`).set(
			{
				title: title,
				first_name: firstName || null,
				middle_initials: middleInitials || null,
				last_name: lastName || null,
				gender: gender || null,
				nationality: nationality || null,
				date_of_birth: dateOfBirth || null,
				email: email || null,
				telephone: telephone || null,
				address: {
					first_line: addressLine1 || null,
					city: city || null,
					postcode: postcode || null,
					country: country || null,
				},
			},
			{ merge: true }
		)

		setEditing(false)
		setSaving(false)
	}

	return (
		<div className={styles.passenger}>
			<div className={styles.header}>
				<div className={styles.details}>
					<p className={styles.passengerNumber}>
						Passenger {index + 1}
						{lead_booker ? ` - Lead Booker` : ""}
					</p>
					<div className={styles.name}>
						{first_name && (
							<p>
								{docTitle ? `${docTitle} ` : ""}
								{first_name}
								{middle_initials ? ` ${middle_initials} ` : " "}
								{last_name}
							</p>
						)}

						<Badge
							label={complete ? "Complete" : "Incomplete"}
							type={complete ? "POSITIVE" : "AMBER"}
						/>
					</div>
				</div>

				<div className={styles.actions}>
					{editing && (
						<Button
							label="Save"
							small={true}
							loading={saving}
							loadingText="Saving..."
							onClick={() => savePassenger()}
						/>
					)}

					{!editing && (
						<>
							<Button
								type="secondary"
								label="Edit"
								small={true}
								onClick={() => setEditing(true)}
							/>
							<Button
								type="secondary"
								label="Delete"
								disabled={lead_booker}
								small={true}
								loading={removing}
								loadingText=""
								onClick={() => remove(passengerID)}
							/>
						</>
					)}
				</div>
			</div>

			<div className={[styles.edit, editing ? styles.isShowing : ""].join(" ")}>
				<div className={styles.fields}>
					<div className={styles.title}>
						<p>Passenger Details</p>
					</div>

					<Select
						placeholder="Title"
						showLabel={true}
						value={title}
						onSelect={(option) => setTitle(option.value)}
						options={{
							mr: "Mr",
							mrs: "Mrs",
							ms: "Ms",
							miss: "Miss",
						}}
					/>

					<Input
						type="text"
						placeholder="First Name"
						showLabel={true}
						value={firstName}
						onChange={setFirstName}
					/>

					<Input
						type="text"
						placeholder="Initials"
						showLabel={true}
						value={middleInitials}
						onChange={setMiddleInitials}
					/>

					<Input
						type="text"
						placeholder="Last Name"
						showLabel={true}
						value={lastName}
						onChange={setLastName}
					/>

					<Select
						placeholder="Gender"
						showLabel={true}
						value={gender}
						onSelect={(option) => setGender(option.value)}
						options={{
							male: "Male",
							female: "Female",
						}}
					/>

					<Select
						placeholder="Nationality"
						showLabel={true}
						value={nationality}
						onSelect={(option) => setNationality(option.value)}
						options={Nationalities}
					/>

					<Input
						type="date"
						placeholder="Date of Birth"
						showLabel={true}
						value={dateOfBirth}
						onChange={setDateOfBirth}
					/>

					<div className={styles.title}>
						<p>Contact Details</p>
					</div>

					<Input
						type="text"
						placeholder="Email"
						showLabel={true}
						value={email}
						onChange={setEmail}
					/>

					<Input
						type="text"
						placeholder="Telephone"
						showLabel={true}
						value={telephone}
						onChange={setTelephone}
					/>

					<div className={styles.title}>
						<p>Address Details</p>
					</div>

					<Input
						type="text"
						placeholder="Address Line 1"
						showLabel={true}
						value={addressLine1}
						onChange={setAddressLine1}
					/>

					<Input
						type="text"
						placeholder="City or Town"
						showLabel={true}
						value={city}
						onChange={setCity}
					/>

					<Input
						type="text"
						placeholder="Postcode"
						showLabel={true}
						value={postcode}
						onChange={setPostcode}
					/>

					<SearchSelect
						placeholder="Country"
						showLabel={true}
						icon={<SearchIcon />}
						value={country}
						onSelect={(option) => setCountry(option.value)}
						options={Countries}
					/>
				</div>
			</div>
		</div>
	)
}

import { useState, useEffect, useContext, useRef } from 'react'
import { QuoteContext } from '../../../context/context'
import moment from 'moment'

// Constants
import { ROOM_TYPES, FEE_TYPES, BOARD_TYPES } from '../../../../../assets/data/exports'

// UI components
import Checkbox from '../../../../../components/ui/checkbox/checkbox'
import Select from '../../../../../components/ui/select/select'
import Input from '../../../../../components/ui/inputs/input'
import Badge from '../../../../../components/ui/badge/badge'
import ResortNote from './notes'
import ResortDiscount from './discounts'

// Returns the HTML markup for the configuration of a chosen package
export default function Package({ details }) {
    // Package-sepcific options
    const [totalCost, setTotalCost] = useState(0)
    const [roomTypes, setRoomTypes] = useState({})
    const [boardTypes, setBoardTypes] = useState({})
    const [feeTypes, setFeeTypes] = useState({})
    const [availableCourses, setAvailableCourses] = useState({})

    // User-set options
    const [groupSize, setGroupSize] = useState(null)
    const [chosenRoomType, setChosenRoomType] = useState({})
    const [chosenBoardType, setChosenBoardType] = useState({})
    const [chosenFeeType, setChosenFeeType] = useState({})
    const [packageCourses, setPackageCourses] = useState([])
    const [hasAdditionalNights, setHasAdditionalNights] = useState(false)
    const [additionalNights, setAdditionalNights] = useState(null)
    const [additionalNightsCost, setAdditionalNightsCost] = useState(null)
    const [buggiesIncluded, setBuggiesIncluded] = useState(false)
    const [buggiesCost, setBuggiesCost] = useState(null)
    const [discountIDsChosen, setDiscountIDsChosen] = useState([])
    const [discountsChosen, setDiscountsChosen] = useState([])

    // Date spans
    const [fromDate, setFromDate] = useState('')
    const [untilDate, setUntilDate] = useState('')

    // Pull the chosen resorts from the context
    const { ctxSelectedOptions, ctxUpdatePackage } = useContext(QuoteContext)

    // Store all the array IDs
    const discountIDs = useRef([])

    // Store a reference for when the resort courses have been added
    const coursesMounted = useRef(false)

    // Get the from and until dates as moment objects
    const fromDateMoment = moment(fromDate, 'DD/MM/YYYY')
    const untilDateMoment = moment(untilDate, 'DD/MM/YYYY')

    // When the package details are updated
    useEffect(() => {
        // Are we looking at a resort type?
        if (details.price_type === 'resort') {
            // Parse the from and until dates based on dates and nights chosen
            const calculatedStartDate = moment(details.from_date, 'X').format('DD/MM/YYYY')
            const calculatedEndDate = moment(details.from_date, 'X').add(details.nights, 'days').format('DD/MM/YYYY')

            // Set these into the state
            setFromDate(calculatedStartDate)
            setUntilDate(calculatedEndDate)
        }

        // Store an array of available resort courses
        let availableResortCourses = []

        // Are we looking at a resort type?
        if (details.price_type === 'resort' && !coursesMounted.current) {
            // Get the ID of the resort
            const resortID = details.resort_id

            // Then loop through the resorts from the context to find the one matching this package
            const matchingResort = ctxSelectedOptions.find((resort) => resort.objectID === resortID)

            // As long as we found a matching one
            if (matchingResort?.title) {
                // Set it's courses into the available courses
                availableResortCourses = matchingResort.courses
            }
        }

        // Are we looking at a resort package type with availabel rooms?
        if (details.price_type === 'resort' && details.room_types?.length > 0) {
            // Map over the available room types to build an array of available options
            const includedKeys = details.room_types.map((room) => room.type)

            // Filter the global set of room types down to only the ones available
            const filteredRoomTypes = Object.keys(ROOM_TYPES)
                .filter(roomType => includedKeys.includes(roomType))
                .reduce((obj, key) => {
                    // Find the matching room type from the package
                    const matchingRoom = details.room_types.find((room) => room.type === key)

                    // Write this option into the available ones for the dropdown
                    obj[key] = `${ROOM_TYPES[key]} - ${details.parent_currency}${matchingRoom.cost}`
                    return obj
                }, {})

            // Then finally set these into the state
            setRoomTypes(filteredRoomTypes)
        }

        // Are we looking at a resort package type with available boards?
        if (details.price_type === 'resort' && details.board_types?.length > 0) {
            // Map over the available board types to build an array of available options
            const includedKeys = details.board_types.map((board) => board.type)

            // Filter the global set of board types down to only the ones available
            const filteredBoardTypes = Object.keys(BOARD_TYPES)
                .filter(boardType => includedKeys.includes(boardType))
                .reduce((obj, key) => {
                    // Find the matching board type from the package
                    const matchingBoard = details.board_types.find((board) => board.type === key)

                    // Write this option into the available ones for the dropdown
                    obj[key] = `${BOARD_TYPES[key]} - ${details.parent_currency}${matchingBoard.cost}`
                    return obj
                }, {})

            // Then finally set these into the state
            setBoardTypes(filteredBoardTypes)
        }

        // Are we looking at a resort package type?
        if (details.price_type === 'resort' && details.rounds > 0 && availableResortCourses.length > 0 && !details.chosen_courses) {
            // Create a new array of objects for the rounds 
            const selectedCourses = []

            // Create an object to store the available courses for a given resort
            const resortCourses = {}

            // First we want to loop over all of the available resort courses
            for (let index = 0; index < availableResortCourses.length; index++) {
                // Find a sutiable index to reference the courses
                const courseIndex = index % availableResortCourses.length

                // Create a key to identify the course with
                const courseKey = availableResortCourses[courseIndex]
                    .toLowerCase()
                    .replace(/[^a-z]/g, '')
                    .replace(/\s+/g, '_')

                // Set the available dropdown object to the course name/key pair too
                resortCourses[courseKey] = availableResortCourses[index]
            }

            // Setup a new for loop mapping over the rounds amount added in the package
            for (let index = 0; index < details.rounds; index++) {
                // Find a sutiable index to reference the courses
                const courseIndex = index % availableResortCourses.length

                // Create a key to identify the course with
                const courseKey = availableResortCourses[courseIndex]
                    .toLowerCase()
                    .replace(/[^a-z]/g, '')
                    .replace(/\s+/g, '_')

                // Push this course into the selected array
                selectedCourses.push({
                    key: courseKey,
                    name: availableResortCourses[courseIndex]
                })
            }

            // Set these into the state
            setPackageCourses(selectedCourses)
            setAvailableCourses(resortCourses)
        }

        // Are we looking at a resort package type?
        if (details.price_type === 'course' && details.fee_types?.length > 0) {
            // Map over the available fee types to build an array of available options
            const includedKeys = details.fee_types.map((fee) => fee.type)

            // Filter the global set of fee types down to only the ones available
            const filteredFeeTypes = Object.keys(FEE_TYPES)
                .filter(feeType => includedKeys.includes(feeType))
                .reduce((obj, key) => {
                    // Find the matching fee type from the package
                    const matchingType = details.fee_types.find((fee) => fee.type === key)

                    // Write this option into the available ones for the dropdown
                    obj[key] = `${FEE_TYPES[key]} - £${matchingType.cost}`
                    return obj
                }, {})

            // Then finally set these into the state
            setFeeTypes(filteredFeeTypes)

            // If there are any buggies cost available on the price window
            if (typeof details?.buggies !== 'undefined' && details?.buggies[0]?.cost) {
                setBuggiesCost(details?.buggies[0]?.cost || null)
            }
        }
    }, [details])

    // When any of the package options are updated
    useEffect(() => {
        // Setup a running total
        let runningTotal = 0

        if (details.price_type === 'resort' && details.room_types?.length > 0) {
            const matchingRoom = details.room_types.find((room) => room.type === chosenRoomType.option)
            if (matchingRoom?.cost) {
                runningTotal += parseFloat(matchingRoom.cost)
            }
        }

        if (details.price_type === 'resort' && details.board_types?.length > 0) {
            const matchingBoard = details.board_types.find((board) => board.type === chosenBoardType.option)
            if (matchingBoard?.cost) {
                runningTotal += parseFloat(matchingBoard.cost)
            }
        }

        if (details.price_type === 'course' && details.fee_types?.length > 0) {
            const matchingFeeType = details.fee_types.find((fee) => fee.type === chosenFeeType.option)
            if (matchingFeeType?.cost) {
                runningTotal += parseFloat(matchingFeeType.cost)
            }
        }

        if (buggiesIncluded) {
            if (!isNaN(parseFloat(buggiesCost)) && buggiesCost !== null) {
                runningTotal += parseFloat(buggiesCost)
            }
        }

        let nightsMultiplier = 1
        if (details.type === 'per_night') {
            nightsMultiplier = details.nights
        }

        // Times the total cost for the package based on the nights passed in
        runningTotal *= nightsMultiplier

        if (hasAdditionalNights) {
            if (!isNaN(parseInt(additionalNights)) && additionalNights !== null) {
                if (!isNaN(parseFloat(additionalNightsCost)) && additionalNightsCost !== null) {
                    runningTotal += (parseInt(additionalNights) * parseFloat(additionalNightsCost))
                }
            }
        }

        // If there is a selected discount in the state
        if (discountsChosen?.length > 0) {
            discountsChosen.forEach((discountChosen) => {
                // Pull the details from the object
                const { type, value } = discountChosen

                // Are we looking at a pax type of discount?
                if (type === 'pax') {
                    // Parse the required group size as an integer
                    const discountGroupSize = parseInt(value)

                    // Work out how many guests will be going for free
                    const goingFree = Math.floor(groupSize / discountGroupSize)
                    const adjustedGroupSize = groupSize - goingFree

                    // Calculate teh adjusted per-person price
                    const adjustedPrice = ((runningTotal * (adjustedGroupSize)) / groupSize)

                    // If we get a valid integer at the end of the calculation
                    if (!isNaN(adjustedPrice)) {
                        runningTotal = Math.round(adjustedPrice)
                    }
                }

                // Is it a percetnage discount?
                if (type === 'percent') {
                    // Parse the value as a float
                    const asFloat = parseFloat(value)

                    // Then divide this by 100 and subtract from 1 to give our calculation
                    const adjustedPercentage = 1 - (asFloat / 100)

                    // Are we still dealing with a calculated figure
                    if (!isNaN(adjustedPercentage)) {
                        // Use this to time against the running total
                        runningTotal = Math.round(runningTotal * adjustedPercentage)
                    }
                }
            })
        }

        // Set the running total into the state
        setTotalCost(runningTotal)

        // Update the quote context with the total cost
        ctxUpdatePackage({
            ...details,
            group_size: groupSize,
            chosen_fee_type: chosenFeeType,
            chosen_room_type: chosenRoomType,
            chosen_board_type: chosenBoardType,
            chosen_courses: packageCourses,
            buggies_included: buggiesIncluded,
            buggies_cost: parseFloat(buggiesCost),
            total_cost: runningTotal,
            discounts_applied: discountsChosen,
            additional_nights_included: hasAdditionalNights,
            additional_nights: additionalNights
        })
    }, [chosenRoomType, chosenBoardType, chosenFeeType, groupSize, packageCourses, hasAdditionalNights, additionalNights, additionalNightsCost, buggiesIncluded, buggiesCost, discountsChosen])

    // When the available room, board and fee types are updated
    useEffect(() => {
        // Is there exactly 1 option available
        if (Object.keys(roomTypes).length === 1) {
            // Set this as the chosen option
            setChosenRoomType({
                option: Object.keys(roomTypes)[0],
                value: Object.values(roomTypes)[0]
            })
        }
        // Same for the board types
        if (Object.keys(boardTypes).length === 1) {
            // Set this as the chosen option
            setChosenBoardType({
                option: Object.keys(boardTypes)[0],
                value: Object.values(boardTypes)[0]
            })
        }
        // Same for the fee types
        if (Object.keys(feeTypes).length === 1) {
            // Set this as the chosen option
            setChosenFeeType({
                option: Object.keys(feeTypes)[0],
                value: Object.values(feeTypes)[0]
            })
        }
    }, [roomTypes, boardTypes, feeTypes])

    // Handle the change event of a course
    const handleCourseChange = (index, updatedCourse) => {
        // Create a cloned copy of the array
        const clonedCourses = [...packageCourses]
        console.log('New cloned courses:', clonedCourses)

        // Update the given index with the new course details
        clonedCourses[index] = {
            key: updatedCourse.option,
            name: updatedCourse.value
        }
        console.log('Updated cloned courses:', clonedCourses)

        // Then set this back into the state
        setPackageCourses(clonedCourses)
    }

    // Push the given discount ID into the state via a ref
    const updateDiscountsRef = (discountID) => {
        // Push it into the array
        discountIDs.current.push(discountID)
    }

    // Update a chosen discount in/out of the state
    const updateChosenDiscount = (checked, discountData) => {
        // If we are marking this discount as checked
        if (checked) {
            // Push the discount into the state
            setDiscountIDsChosen([...discountIDsChosen, discountData.id])
            setDiscountsChosen([...discountsChosen, discountData])
        } else {
            // Otherwise if we are removing this discount from the array
            const refinedIDs = discountIDsChosen.filter((discount) => discount !== discountData.id)
            const refinedDiscounts = discountsChosen.filter((discount) => discount.id !== discountData.id)

            // Then set this refined list back into the state
            setDiscountIDsChosen(refinedIDs)
            setDiscountsChosen(refinedDiscounts)
        }
    }

    return (
        <div className='generate-quote-chosen-package'>
            <div className='package-name'>
                {details.parent_type === 'resort' ? <Badge label='Resort' type='INFO' /> : ''}
                {details.parent_type === 'course' ? <Badge label='Course' type='POSITIVE' /> : ''}

                <p>
                    {details.parent_title} - {details.name} {details.type === 'per_night' ? `(${details.nights} nights)` : ''}

                    {details.price_type === 'resort' ?
                        <span>{fromDate} - {untilDate}</span>
                        : null}
                </p>

                <p>{details.parent_currency}{totalCost}</p>
            </div>

            {details.global_notes?.length > 0 &&
                <div className='resort-global-notes'>
                    {details.global_notes.map((note) => (
                        <ResortNote
                            key={note.id}
                            note={note}
                            fromDate={fromDateMoment}
                            untilDate={untilDateMoment} />
                    ))}
                </div>
            }

            <div className='package-options'>
                <div className='package-option-row quote-pax'>
                    <p>Group size:</p>

                    <Input
                        type='number'
                        value={groupSize}
                        onChange={setGroupSize}
                        placeholder='Group size' />
                </div>
            </div>

            {details.price_type === 'resort' &&
                <>
                    <div className='package-options'>
                        <div className='package-option-row'>
                            <p>Room type:</p>

                            <Select
                                selected={chosenRoomType.option}
                                options={roomTypes}
                                placeholder='Choose type'
                                onSelect={setChosenRoomType} />
                        </div>
                    </div>

                    <div className='package-options'>
                        <div className='package-option-row'>
                            <p>Board type:</p>

                            <Select
                                selected={chosenBoardType.option}
                                options={boardTypes}
                                placeholder='Choose type'
                                onSelect={setChosenBoardType} />
                        </div>
                    </div>

                    {(details?.rounds && details.rounds > 0) ?
                        <div className='package-options'>
                            <p className='package-options-title'>
                                Package Rounds
                            </p>

                            {packageCourses.map((course, index) => (
                                <div className='package-option-row'>
                                    <p>Round {index + 1}:</p>

                                    <Select
                                        selected={course.key}
                                        options={availableCourses}
                                        placeholder='Choose course'
                                        onSelect={(chosen) => handleCourseChange(index, chosen)} />
                                </div>
                            ))}
                        </div>
                        : null}

                    {(details.type === 'pre_defined' || details.type === 'custom') ?
                        <div className='package-options'>
                            <div className={['package-option-row is-course-buggies', buggiesIncluded ? 'is-checked' : ''].join(' ').trim()}>
                                <p>Buggies:</p>

                                <Checkbox
                                    label='Included'
                                    checked={buggiesIncluded}
                                    onClick={() => setBuggiesIncluded(!buggiesIncluded)} />

                                <Input
                                    symbol={details.parent_currency}
                                    type='number'
                                    placeholder='Cost:'
                                    value={buggiesCost}
                                    onChange={setBuggiesCost} />
                            </div>
                        </div>
                        : null}

                    <div className='package-options'>
                        <div className={['package-option-row quote-additional-nights', hasAdditionalNights ? 'is-checked' : ''].join(' ').trim()}>
                            <Checkbox
                                checked={hasAdditionalNights}
                                onClick={() => setHasAdditionalNights(!hasAdditionalNights)}
                                label='Add nights' />

                            <Input
                                type='number'
                                value={additionalNights}
                                onChange={setAdditionalNights}
                                placeholder='Nights' />

                            <Input
                                symbol={details.parent_currency}
                                type='number'
                                value={additionalNightsCost}
                                onChange={setAdditionalNightsCost}
                                placeholder='Cost per night' />
                        </div>
                    </div>
                </>
            }

            {details.price_type === 'course' &&
                <>
                    <div className='package-options'>
                        <div className='package-option-row'>
                            <p>Green fee band:</p>

                            <Select
                                selected={chosenFeeType.option}
                                options={feeTypes}
                                placeholder='Choose type'
                                onSelect={setChosenFeeType} />
                        </div>

                        <div className={['package-option-row is-course-buggies', buggiesIncluded ? 'is-checked' : ''].join(' ').trim()}>
                            <p>Buggies:</p>

                            <Checkbox
                                label='Included'
                                checked={buggiesIncluded}
                                onClick={() => setBuggiesIncluded(!buggiesIncluded)} />

                            <Input
                                symbol='£'
                                type='number'
                                placeholder='Cost:'
                                value={buggiesCost}
                                onChange={setBuggiesCost} />
                        </div>
                    </div>
                </>
            }

            {(details.notes && details.notes.length > 0) ?
                <div className='package-options'>
                    <div className='package-option-row'>
                        <p>Package notes:</p>

                        <div className='package-notes'>
                            {details.notes}
                        </div>
                    </div>
                </div>
                : null}

            {(details.global_discounts && details.global_discounts?.length > 0) ?
                <div className={['package-options', discountIDs.current?.length === 0 ? 'is-hidden' : ''].join(' ').trim()}>
                    {discountIDs.current?.length > 0 &&
                        <p className='package-options-title'>
                            Resort Discounts
                        </p>
                    }

                    <div className='package-option-row'>
                        {discountIDs.current?.length > 0 &&
                            <p>Available to apply:</p>
                        }

                        <div className='resort-discounts'>
                            {details.global_discounts.map((discount) => (
                                <ResortDiscount
                                    key={discount.id}
                                    discount={discount}
                                    fromDate={fromDateMoment}
                                    untilDate={untilDateMoment}
                                    discountIDsChosen={discountIDsChosen}
                                    updateChosenDiscount={updateChosenDiscount}
                                    updateDiscounts={(discountID) => updateDiscountsRef(discountID)} />
                            ))}
                        </div>
                    </div>
                </div>
                : null}
        </div>
    )
}
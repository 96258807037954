import React, { useRef, useEffect, useContext } from "react";
import { ModalContext } from "../../../utils/providers/modal";
import "./modal.scss";

/**
 * Button component
 */
import Button from "../button/button";

/**
 * Icon imports
 */
import { TimesIcon, CheckIcon, InformationIcon, AlertIcon } from "../../../utils/svgs";

/**
 * Functional component for the modal element
 * 
 * @param {object} props Props passed into the modal
 * @returns HTML markup for the modal box
 */
function ModalBox(props) {
    /**
     * Get the dismissModal function from the context
     */
    const { dismissModal } = useContext(ModalContext);

    /**
     * Listen for a click outside the dropdown ref
     * 
     * @type {const}
     */
    const ClickedOutside = (modalRef) => {
        useEffect(() => {
            /**
             * Check for the click to be outside the select field
             */
            const checkForOutsideClick = (event) => {
                /**
                 * Is the click outside?
                 */
                if (modalRef.current && !modalRef.current.contains(event.target)) {
                    /**
                     * Dismiss the modal
                     */
                    dismissModal();
                }
            }
            /**
             * Bind on the component load
             */
            document.addEventListener("mousedown", checkForOutsideClick)
            return () => {
                /**
                 * Unbind on component unload
                 */
                document.removeEventListener("mousedown", checkForOutsideClick);
            }
        }, [modalRef]);
    }

    /**
     * Modal ref
     */
    const modalRef = useRef(null);
    /**
     * Bind onto the click event
     */
    ClickedOutside(modalRef);

    return (
        <div ref={modalRef} className={["ui-modal-box", props.type].join(" ")}>
            {/* Modal Icon */}
            <div className="ui-modal-icon">
                {/* Default information icon */}
                {!props.type && <InformationIcon />}

                {/* Success icon */}
                {props.type === "SUCCESS" && <CheckIcon />}

                {/* Alert icon */}
                {props.type === "ALERT" && <AlertIcon />}

                {/* Error icon */}
                {props.type === "ERROR" && <TimesIcon />}
            </div>

            {/* Modal title */}
            <p className="ui-modal-title">{props.title}</p>

            {/* Modal body */}
            <p className="ui-modal-body">{props.body}</p>

            {/* Modal buttons */}
            {(props.cancel.label || props.next.label) &&
                <div className="ui-modal-buttons">
                    {/* Cancel button */}
                    {props.cancel?.label &&
                        <Button
                            type="OUTLINE"
                            small={true}
                            label={props.cancel.label}
                            onClick={props.cancel.action} />}

                    {/* Next button */}
                    {props.next?.label &&
                        <Button
                            type={props.type}
                            small={true}
                            label={props.next.label}
                            onClick={props.next.action} />}
                </div>
            }
        </div>
    );
}

export default ModalBox;
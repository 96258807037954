import React, { useEffect, useState } from "react";
import { auth, db } from "../../../utils/firebase";
import firebase from "firebase";

// UI components
import Input from "../../../components/ui/inputs/input";
import Button from "../../../components/ui/button/button";
import Window from "../../../components/structure/window/window";
import WindowCard from "../../../components/ui/window-card/window-card";
import SearchSelect from "../../../components/ui/select/search-select";
import { SearchIcon, TrashIcon } from "../../../utils/svgs";

// Modal to show the partners included in a group
export default function ViewGroup({ groupDoc, close }) {
    const [name, setName] = useState("");
    const [saving, setSaving] = useState(false);
    const [partners, setPartners] = useState({});
    const [partnersList, setPartnersList] = useState({});

    // Pull the partner group ID from the doc
    const { id } = groupDoc;

    // When the group object is updated
    useEffect(() => {
        // Update the state
        setName(groupDoc.name)
        setPartners(groupDoc.partners)
    }, [groupDoc])

    // On component load
    useEffect(() => {
        fetchPartnersList()
    }, [])

    // Fetch all the partners that can be added to a group
    const fetchPartnersList = async () => {
        // Fetch all the hotel partners
        const hotels = await db
            .collection("hotels")
            .orderBy("name")
            .get()
            .then((hotelDocs) => {
                // Store a loca object of them
                let hotelsObj = {};
                // Loop over each and add them in
                hotelDocs.forEach((hotelDoc) => {
                    hotelsObj = {
                        ...hotelsObj,
                        [`HOTEL_${hotelDoc.id}`]: `[HOTEL] ${hotelDoc.data().name}`,
                    };
                });
                // Set the object into the state
                return hotelsObj;
            });
        // Then find all the course partners
        const courses = await db
            .collection("courses")
            .orderBy("name")
            .get()
            .then((courseDocs) => {
                // Store a local object
                let coursesObj = {};
                // Loop over each to add them in
                courseDocs.forEach((courseDoc) => {
                    coursesObj = {
                        ...coursesObj,
                        [`COURSE_${courseDoc.id}`]: `[COURSE] ${courseDoc.data().name}`,
                    };
                });
                // Return the complete list
                return coursesObj;
            });
        // And then the transper partners
        const transfers = await db
            .collection("transfers")
            .orderBy("name")
            .get()
            .then((transferDocs) => {
                // Store a local object of them
                let transfersObj = {};
                // Loop through them and add them into the object
                transferDocs.forEach((transferDoc) => {
                    transfersObj = {
                        ...transfersObj,
                        [`TRANSFER_${transferDoc.id}`]: `[TRANSFER] ${transferDoc.data().name}`,
                    };
                });
                // Then return them
                return transfersObj;
            });

        // Set them all into the state
        setPartnersList({ ...hotels, ...courses, ...transfers });
    }

    // Save the new partner group details into the database
    const savePartnerGroup = async () => {
        // Show the loading spinnner
        setSaving(true);

        // Write the data into the database
        await db.doc(`partner_groups/${id}`).update({
            name,
            partners,
            updated: firebase.firestore.FieldValue.serverTimestamp(),
            updated_by: auth.currentUser.uid,
        });

        // Reset the state and close the modal
        setSaving(false);
        close();
    }

    // Remove the partner from the local group
    const removePartnerFromGroup = (partnerKey) => {
        // Create a new instance of the partners object
        let localPartners = { ...partners }

        // Remove the key from the chosen list
        delete localPartners[partnerKey]

        // Set the new array into the 
        setPartners(localPartners)
    }
    console.log(partners)

    return (
        <Window
            title={name}
            className="slim"
            close={() => close()}>

            <WindowCard>
                <div className="input-grid cols-1">
                    <Input
                        type="text"
                        placeholder="Name:"
                        value={name}
                        onChange={setName} />

                    <SearchSelect
                        placeholder="Find partner:"
                        icon={<SearchIcon />}
                        activeOnHover={true}
                        onSelect={(partner) => setPartners((partners) => ({ ...partners, [partner.option]: partner.value }))}
                        options={partnersList}
                        selected={partners}
                        disableSelected={true}
                        keepDropdownOpen={true} />

                    <div className="partner-group-chosen-options">
                        {Object.entries(partners).map(([key, value]) => (
                            <p className="partner-group-chosen-option">
                                {value}
                                <div className="remove-partner-group-option" onClick={() => removePartnerFromGroup(key)}>
                                    <TrashIcon />
                                </div>
                            </p>
                        ))}
                    </div>

                    <Button
                        label="Update group"
                        loading={saving}
                        loadingText="Saving..."
                        onClick={() => savePartnerGroup()}
                        disabled={!name} />
                </div>
            </WindowCard>
        </Window>
    );
}
import React, { useState } from "react";
import { auth, db } from "../../../utils/firebase";
import firebase from "firebase";

/**
 * UI components & structure
 */
import Input from "../../../components/ui/inputs/input";
import Select from "../../../components/ui/select/select";
import Textarea from "../../../components/ui/inputs/textarea";
import Window from "../../../components/structure/window/window";
import WindowCard from "../../../components/ui/window-card/window-card";
import Button from "../../../components/ui/button/button";
import Datepicker from "../../../components/ui/datepicker/datepicker";

export default function Add(props) {
    const [saving, setSaving] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    const [location, setLocation] = useState("");
    const [groupSize, setGroupSize] = useState("");
    const [checkIn, setCheckIn] = useState("");
    const [nights, setNights] = useState("");
    const [rounds, setRounds] = useState("");
    const [quoted, setQuoted] = useState("");
    const [notes, setNotes] = useState("");
    const [type, setType] = useState("BEING_PROCESSED");

    /**
     * Deconstruct the client ID from the props
     */
    const { clientID } = props;

    /**
     * Save a new enquiry into the database
     */
    const saveNewEnquiry = async () => {
        /**
         * Show a loading spinner
         */
        setSaving(true);
        /**
         * Generate a date_from tiemstamp from the checkIn date milliseconds
         */
        const date_from = firebase.firestore.Timestamp.fromMillis(checkIn);
        /**
         * Do we already have a clientID from the props?
         */
        if (clientID) {
            /**
             * Then add a new enquiry
             */
            await db.collection("enquiries").add({
                agent: auth.currentUser.uid,
                client: clientID,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                date_from,
                group_size: Number(groupSize),
                location,
                nights: Number(nights),
                rounds: Number(rounds),
                quoted: Number.parseFloat(quoted),
                notes,
                site: "SYSTEM",
                status: type,
            });
        } else {
            /**
             * Check for a client account with the same email
             */
            const newClientID = await db.collection("clients")
                .where("email", "==", email)
                .get().then(async (clientDocs) => {
                    /**
                     * If there was a client document found
                     */
                    if (clientDocs.size > 0) {
                        /**
                         * Return the ID fo the first document
                         */
                        return clientDocs.docs[0].id;
                    } else {
                        /**
                         * If they don't exist we need to make a new record
                         */
                        const newAccountID = await db.collection("clients").add({
                            first_name: firstName,
                            last_name: lastName,
                            email,
                            phone,
                            created: firebase.firestore.FieldValue.serverTimestamp(),
                        }).then((newClientDoc) => { return newClientDoc.id; });
                        /**
                         * Then return this new account ID
                         */
                        return newAccountID;
                    }
                });
            /**
             * Then add a new enquiry
             */
            await db.collection("enquiries").add({
                agent: auth.currentUser.uid,
                client: newClientID,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                date_from,
                group_size: Number(groupSize),
                location,
                nights: Number(nights),
                rounds: Number(rounds),
                quoted: Number.parseFloat(quoted),
                notes,
                site: "SYSTEM",
                status: type,
            });
        }
        /**
         * Reset the state and close the window
         */
        setSaving(false);
        props.close();
    }

    return (
        <Window
            title="Add a new enquiry"
            close={() => props.close()}>

            {!clientID &&
                <WindowCard
                    title="Client details">
                    <div className="input-grid cols-2">
                        <Input
                            type="text"
                            label="First name:"
                            value={firstName}
                            onChange={setFirstName}
                            placeholder="First name:" />
                        <Input
                            type="text"
                            label="Last name:"
                            value={lastName}
                            onChange={setLastName}
                            placeholder="Last name:" />
                        <Input
                            type="tel"
                            label="Phone:"
                            value={phone}
                            onChange={setPhone}
                            placeholder="Phone:" />
                        <Input
                            type="email"
                            label="Email:"
                            value={email}
                            onChange={setEmail}
                            placeholder="Email:" />
                    </div>
                </WindowCard>
            }

            <WindowCard
                title="Enquiry details"
                className="has-top-margin">
                <div className="input-grid cols-4">
                    <Input
                        type="text"
                        label="Location:"
                        value={location}
                        onChange={setLocation}
                        placeholder="Location:"
                        wrapperClass="span-2" />
                    <Datepicker
                        type="text"
                        label="Check in:"
                        value={checkIn}
                        onSelect={(date) => setCheckIn(date)}
                        placeholder="Check in:"
                        wrapperClass="span-2" />
                    <Input
                        type="number"
                        label="Group size:"
                        value={groupSize}
                        onChange={setGroupSize}
                        placeholder="Group size:" />
                    <Input
                        type="number"
                        label="Nights:"
                        value={nights}
                        onChange={setNights}
                        placeholder="Nights:" />
                    <Input
                        type="number"
                        label="Rounds:"
                        value={rounds}
                        onChange={setRounds}
                        placeholder="Rounds:" />
                    <Input
                        type="number"
                        label="Quoted:"
                        value={quoted}
                        onChange={setQuoted}
                        placeholder="Quoted:"
                        symbol="£" />

                    <div className="span-4">
                        <Textarea
                            placeholder="Notes:"
                            value={notes}
                            onChange={setNotes} />
                    </div>

                    <div className="span-4">
                        <Select
                            value={type}
                            placeholder="Lead type:"
                            selected={type}
                            onSelect={(option) => setType(option.option)}
                            options={{
                                "HOT": "Hot",
                                "FREEPHONE": "Freephone",
                                "BEING_PROCESSED": "Processing",
                            }} />
                    </div>
                </div>
            </WindowCard>

            {/* If there is a clientID present, we only need to check enquiry details */}
            {clientID &&
                <Button
                    label="Save new enquiry"
                    loading={saving}
                    loadingText="Saving..."
                    onClick={() => saveNewEnquiry()}
                    className="window-card-button"
                    disabled={
                        !location ||
                        !checkIn ||
                        !groupSize ||
                        !nights ||
                        !rounds ||
                        !quoted
                    } />
            }

            {/* If there is no clientID present, check for client details too */}
            {!clientID &&
                <Button
                    label="Save new enquiry"
                    loading={saving}
                    loadingText="Saving..."
                    onClick={() => saveNewEnquiry()}
                    className="window-card-button"
                    disabled={
                        !firstName ||
                        !lastName ||
                        !email ||
                        !phone ||
                        !location ||
                        !checkIn ||
                        !groupSize ||
                        !nights ||
                        !rounds ||
                        !quoted
                    } />
            }
        </Window>
    );
}
import React from "react";
import "./table.scss";

/**
 * Functional component to return the barebones table structure
 */
function Table(props) {
    return (
        <table className={["ui-table", props.className].join(" ")}>
            {/* Table headings */}
            <thead>
                <tr>
                    {props.headings.map((heading, index) => (
                        <th key={index}>{heading}</th>
                    ))}
                </tr>
            </thead>

            {/* Table rows */}
            <tbody>
                {/* Are there results to print? */}
                {!props.noResults && props.children}

                {/* If there are no results to print */}
                {props.noResults &&
                    <tr>
                        <td colSpan={props.headings.length}>
                            <small>{props.noResultsMessage}</small>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
}

export default Table;
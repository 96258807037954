import React, { useEffect, useContext, useState } from "react";
import { BookingContext } from "../../../../utils/providers/isolated-booking";

/**
 * UI components
 */
import Tile from "../../../../components/structure/tile/tile";
import Title from "../../../../components/structure/title/title";
import Navigation from "../../../../components/ui/navigation/navigation";

/**
 * Tabs
 */
import Summary from "./tabs/summary";
import Hotels from "./tabs/hotels";
import Transfers from "./tabs/transfers";
import Rounds from "./tabs/rounds";
import PriceLines from "./tabs/price-lines";

/**
 * Functional component to return the itinerary page for the client
 */
export default function Itinerary(props) {
    const [activeTab, setActiveTab] = useState("SUMMARY");

    /**
     * Get the bookingID from the props
     */
    const { bookingID } = props;

    /**
     * Pull the setBookingID function from the isolated booking provider
     */
    const { setBookingID } = useContext(BookingContext);

    /**
     * When the booking ID is updated
     */
    useEffect(() => {
        /**
         * Set the bookingID into the isloated booking context provider
         */
        bookingID && setBookingID(bookingID);
    }, [bookingID]);

    return (
        <Tile className="fit-width center" includeBackButton={true}>
            <Title center={true}>
                <h1>Your itinerary</h1>
            </Title>

            <Navigation
                links={[
                    {
                        id: "SUMMARY",
                        label: "Summary",
                    }, {
                        id: "HOTELS",
                        label: "Hotels",
                    }, {
                        id: "GOLF",
                        label: "Rounds",
                    }, {
                        id: "TRANSFERS",
                        label: "Transfers & flights",
                    }, {
                        id: "PRICE_LINES",
                        label: "Price lines",
                    }
                ]}
                center={true}
                active={activeTab}
                onClick={(tabID) => setActiveTab(tabID)} />

            {(activeTab === "SUMMARY") && <Summary />}

            {(activeTab === "HOTELS") && <Hotels />}

            {(activeTab === "GOLF") && <Rounds />}

            {(activeTab === "TRANSFERS") && <Transfers />}

            {(activeTab === "PRICE_LINES") && <PriceLines />}
        </Tile>
    );
}
// NavLink import for switching to the edit page
import { NavLink } from "react-router-dom";

// Returns a single table row item for a holiday
export default function HolidayRow({ id, details }) {
    return (
        <tr>
            <td>{details?.title}</td>
            <td>{details?.url}</td>
            <td className="is-button">
                <NavLink to={`/manage/holiday/${id}`}>
                    Edit holiday
                </NavLink>
            </td>
        </tr>
    )
}
import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";

// UI components
import Table from "../../../components/structure/table/table";
import Group from "../../../components/partner/group";

// Partner grouping tab
export default function Grouping({ setViewGroup }) {
    const [groups, setGroups] = useState([])

    // On component load
    useEffect(() => {
        // Attach a listener for the partner groups
        const unsubscribe = db.collection("partner_groups")
            .orderBy("name")
            .onSnapshot((groupsSnap) => {
                // Loop over all the groups found
                groupsSnap.docChanges().forEach((change) => {
                    // When a group has been added
                    if (change.type === "added") {
                        setGroups((groups) => [
                            ...groups,
                            {
                                id: change.doc.id,
                                ...change.doc.data(),
                            }
                        ]);
                    }
                    // When a group has been updated
                    if (change.type === "modified") {
                        setGroups((groups) => {
                            let updatedGroups = [...groups];
                            for (let i in groups) {
                                if (groups[i].id === change.doc.id) {
                                    updatedGroups[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedGroups;
                        });
                    }
                    // When a group was deleted
                    if (change.type === "removed") {
                        setGroups((groups) =>
                            groups.filter((group) => group.id !== change.doc.id));
                    }
                });
            });
        // Remove the listener
        return () => unsubscribe();
    }, []);

    return (
        <div className="partner-grouping-wrap">
            <Table
                className="partners-table"
                headings={[
                    "Name",
                    "Partners",
                    "",
                ]}>

                {(groups.length === 0) &&
                    <tr>
                        <td colspan="2">
                            <small>Loading...</small>
                        </td>
                    </tr>
                }

                {groups.map((group) => (
                    <Group
                        key={group.id}
                        id={group.id}
                        details={group}
                        showGroupWindow={(groupDoc) => setViewGroup(groupDoc)} />
                ))}
            </Table>
        </div>
    );
}
// Take in a resort/course object and return the relevant currency symbol
export const printCurrencySymbol = (currencyString) => {
    if (currencyString === 'gbp') {
        return '£'
    } else if (currencyString === 'eur') {
        return '€'
    } else if (currencyString === 'usd') {
        return '$'
    } else {
        return '£'
    }
}
import React, { useContext } from "react";
import { BookingContext } from "../../../utils/providers/isolated-booking";

/**
 * UI components
 */
import Tile from "../../../components/structure/tile/tile";
import Title from "../../../components/structure/title/title";

/**
 * Functional component to return the stableford points for the client on this booking
 */
export default function Stableford() {
    /**
     * Deconstruct the stablefordPoints from the props
     */
    const { stablefordPoints } = useContext(BookingContext);

    return (
        <Tile className="fit-width center" includeBackButton={true}>
            <Title center={true} isCardTitle={true}>
                <h1>Stableford Points</h1>
                <p>For every £10 you spend with us on a booking, you'll receive a stableford point. When booking again with us, for every 10 points, we'll take £1 off your booking, <a href="https://www.golfholidaysdirect.com/stableford/" target="_blank" rel="noreferrer">learn more here</a>.</p>
            </Title>

            <p className="countdown">You've earned, {stablefordPoints} points for this booking!</p>

            <p className="countdown small">That means you have £{stablefordPoints / 10} off your next booking with us</p>
        </Tile>
    );
};
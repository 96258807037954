import firebase from "firebase";

/**
 * Firebase config setup
 */
const config = {
    apiKey: "AIzaSyDHJivdaRPYlIgWZVYcgAT74gEvCo3MgH4",
    authDomain: "ghd-booking-system.firebaseapp.com",
    projectId: "ghd-booking-system",
    storageBucket: "ghd-booking-system.appspot.com",
    messagingSenderId: "654111008718",
    appId: "1:654111008718:web:c96f98ae98608fb45a2d9f",
    measurementId: "G-YV1DC9LWCP"
};

/**
 * Initialse the app & analytics
 */
firebase.initializeApp(config);
firebase.analytics();

/**
 * Export performance monitoring
 */
const performance = firebase.performance();

/**
 * Export authentication reference
 */
const auth = firebase.auth();

/**
 * Export database reference
 */
const db = firebase.firestore();

/**
 * Export functions reference
 */
const fun = firebase.functions();

/**
 * Export storage reference
 */
const storage = firebase.storage();

// /**
//  * Check to see if we are running on localhost
//  */
// if (window.location.hostname === "localhost") {
//     /**
//      * If we are, connect the services to the local emulators
//      */
//     auth.useEmulator("http://localhost:9099");
//     db.useEmulator("localhost", 8080);
//     fun.useEmulator("localhost", 5001);
//     storage.useEmulator("localhost", 9199);
// }

/**
 * Array union function from firestore
 */
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

/**
 * Array remove function from firestore
 */
const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

/**
 * Increments a document by 1
 */
const increment = firebase.firestore.FieldValue.increment(1);

/**
 * Increments a document by a given amount
 */
const incrementBy = (by) => {
    firebase.firestore.FieldValue.increment(by);
};

/**
 * Decrements a document by 1
 */
const decrement = firebase.firestore.FieldValue.increment(-1);

/**
 * Decrements a document by a given amount
 */
const decrementBy = (by) => {
    firebase.firestore.FieldValue.increment(-Math.abs(parseInt(by)));
};

/**
 * Export firebase referecnes
 */
export {
    auth,
    db,
    fun,
    storage,
    performance
};

/**
 * Export data manipulation methods
 */
export {
    arrayUnion,
    arrayRemove,
    increment,
    incrementBy,
    decrement,
    decrementBy
};
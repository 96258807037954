import React, { useState, useEffect, useRef } from "react";
import { CALENDAR_MONTHS } from "../../utils/exports/months";
import { db } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "./suppliers.scss";

// UI components
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Select from "../../components/ui/select/select";
import SearchSelect from "../../components/ui/select/search-select";
import Button from "../../components/ui/button/button";
import Table from "../../components/structure/table/table";
import Booking from "../../components/suppliers/booking";
import { InstantSearch } from "react-instantsearch-dom";

// Functional component to return the dashboard for viewing travellers by various dates
export default function Suppliers() {
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);

  const [suppliers, setSuppliers] = useState({});
  const [years, setYears] = useState({});

  const [supplier, setSupplier] = useState({});
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [type, setType] = useState("");

  const [totalInvoiced, setTotalInvoiced] = useState(0)

  // Store an object to catch all the supplier invoices
  const totalSupplierInvoices = useRef({})
  const totalBookingsSize = useRef(0)

  // On component load
  useEffect(() => {
    // Create an empty object for storing the years
    let yearsObj = {};

    // Get next year as a number
    let nextYear = Number(moment().startOf(year).add(1, "year").format("YYYY"));
    let yearsAgo = Number(moment().startOf(year).subtract(3, "year").format("YYYY"));

    // Run through a for loop to print out the years
    for (let i = yearsAgo; i <= nextYear; i++) {
      yearsObj = {
        [i]: i,
        ...yearsObj,
      };
    }

    // Set the years into the state
    setYears(yearsObj);
  }, []);

  // On component load, load in the various suppliers
  useEffect(() => {
    fetchSuppliers();
  }, []);

  // When any of the options are updated
  useEffect(() => {
    // Clear the bookings
    setBookings([]);
    setTotalInvoiced(0);

    // Reset the internal refs
    totalSupplierInvoices.current = {}
    totalBookingsSize.current = 0
  }, [supplier, month, year, type]);

  // Fetch all suppliers registered on the system to be queried
  const fetchSuppliers = async () => {
    // Fetch the partner groups first
    const groups = await db
      .collection("partner_groups")
      .orderBy("name")
      .get()
      .then((groupDocs) => {
        // Store a local object to map the partner groups to
        let groupsObj = {};

        // Loop through all the partners
        groupDocs.forEach((groupDoc) => {
          groupsObj = {
            ...groupsObj,
            [`GROUP_${groupDoc.id}`]: `[GROUP] ${groupDoc.data().name}`,
          };
        });

        // Return the local object
        return groupsObj;
      });

    // Pull the hotels from the database collection
    const hotels = await db
      .collection("hotels")
      .orderBy("name")
      .get()
      .then((hotelDocs) => {
        // Setup a new opbject for storing the hotels
        let hotelsObj = {};

        // Loop through all the hotels found
        hotelDocs.forEach((hotelDoc) => {
          hotelsObj = {
            ...hotelsObj,
            [`HOTEL_${hotelDoc.id}`]: hotelDoc.data().name,
          };
        });

        // Return the local object
        return hotelsObj;
      });

    // Pull the hotels from the database collection
    const courses = await db
      .collection("courses")
      .orderBy("name")
      .get()
      .then((courseDocs) => {
        // Setup a new opbject for storing the courses
        let coursesObj = {};

        // Loop through all the courses found
        courseDocs.forEach((courseDoc) => {
          coursesObj = {
            ...coursesObj,
            [`COURSE_${courseDoc.id}`]: courseDoc.data().name,
          };
        });

        // Return the local object
        return coursesObj;
      });

    // Pull the hotels from the database collection
    const transfers = await db
      .collection("transfers")
      .orderBy("name")
      .get()
      .then((transferDocs) => {
        // Setup a new opbject for storing the transfers
        let transfersObj = {};

        // Loop through all the transfers found
        transferDocs.forEach((transferDoc) => {
          transfersObj = {
            ...transfersObj,
            [`TRANSFER_${transferDoc.id}`]: transferDoc.data().name,
          };
        });

        // Return the local object
        return transfersObj;
      });

    // Then combine all the suppliers into a single object
    const suppliersObj = { ...groups, ...hotels, ...courses, ...transfers };

    // Then set them into the state for the dropdown
    setSuppliers(suppliersObj);
  };

  // Fetch the supplier stats for the given supplier
  const fetchSupplierStats = async () => {
    // Reset some internal refs
    totalBookingsSize.current = 0
    totalSupplierInvoices.current = {}

    // Clear the state
    setBookings([])

    // Show a loading spinner
    setLoading(true);

    // Get the timestamps in milliseconds for the chosen dates
    let fetchStart, fetchEnd;
    if (month) {
      fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf();
      fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf();
    } else {
      fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf();
      fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf();
    }

    // Build some timestamps for teh query
    const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart);
    const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd);

    // Initially we'll query on the check_in dates
    let searchBy = "check_in";

    // Set search direction based on input
    if (type === "travel_date") {
      searchBy = "check_in";
    } else if (type === "booked_date") {
      searchBy = "booked";
    }

    // Split the supplier ID
    const supplierParts = supplier.option.split("_");

    // Get the type and ID of the supplier
    const supplierType = supplierParts[0];
    const supplierID = supplierParts[1];

    // Process the relevant bookings query for the supplier type
    if (supplierType === "GROUP") {
      fetchGroupDocuments(searchBy, startTimestamp, endTimestamp, supplierID);
    } else if (supplierType === "HOTEL") {
      fetchHotelDocuments(searchBy, startTimestamp, endTimestamp, supplierID);
    } else if (supplierType === "COURSE") {
      fetchCourseDocuments(searchBy, startTimestamp, endTimestamp, supplierID);
    } else if (supplierType === "TRANSFER") {
      fetchTransferDocuments(searchBy, startTimestamp, endTimestamp, supplierID);
    }

    // Start listening for a matching size on the bookings and 
    // const checkForLength = setInterval(() => {
    //   // Make sure the bookings have loaded in
    //   if (totalBookingsSize.current > 0) {
    //     console.log("Bookings size", totalBookingsSize.current)
    //     console.log("Prices for", Object.entries(totalSupplierInvoices.current).length)
    //     console.log(totalSupplierInvoices.current)

    //     // Sum the array together and write it into the array
    //     setTotalInvoiced(Object.values(totalSupplierInvoices.current).reduce((a, b) => a + b, 0)?.toLocaleString())

    //     // Have we reached the required amount of supplier figures?
    //     if (Object.entries(totalSupplierInvoices.current).length === totalBookingsSize.current) {
    //       // Stop the listener
    //       clearInterval(checkForLength)
    //     }
    //   }
    // }, 1000)
  };

  // Fetch all the documents associated with a group of suppliers
  const fetchGroupDocuments = async (searchBy, startTimestamp, endTimestamp, groupID) => {
    // First we need to get a list of suppliers to query from the group
    const groupSuppliers = await db.doc(`partner_groups/${groupID}`)
      .get().then((groupDoc) => { return Object.keys(groupDoc.data().partners) });

    // Setup an array to write all the bookings to
    let bookingsArr = [];

    // Then we need to loop through the suppliers and query based on type
    await Promise.all(
      groupSuppliers.map(async (groupSupplier) => {
        // Split the supplier ID
        const supplierParts = groupSupplier.split("_");

        // Pull both the type and document ID for them
        const supplierType = supplierParts[0];
        const supplierID = supplierParts[1];

        // Then query based on the type
        if (supplierType === "HOTEL") {
          await db
            .collection("bookings")
            .where(`${searchBy}`, ">=", startTimestamp)
            .where(`${searchBy}`, "<=", endTimestamp)
            .where("suppliers_hotels", "array-contains", supplierID)
            .where("removed", "==", false)
            .orderBy(`${searchBy}`)
            .get()
            .then((bookingDocs) => {
              // Store a local copy of bookings for this supplier
              let localBookingsArr = [];

              // Loop through what was found and write them to the array
              bookingDocs.forEach((bookingDoc) => {
                localBookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data(), stats_supplier: groupSupplier });
              });

              // Then push this array to our loacl one further up
              bookingsArr.push(...localBookingsArr)
            });
        }

        // If we are looking at courses
        if (supplierType === 'COURSE') {
          await db
            .collection("bookings")
            .where(`${searchBy}`, ">=", startTimestamp)
            .where(`${searchBy}`, "<=", endTimestamp)
            .where("suppliers_rounds", "array-contains", supplierID)
            .where("removed", "==", false)
            .orderBy(`${searchBy}`)
            .get()
            .then((bookingDocs) => {
              // Store a local copy of bookings for this supplier
              let localBookingsArr = [];

              // Loop through what was found and write them to the array
              bookingDocs.forEach((bookingDoc) => {
                localBookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data(), stats_supplier: groupSupplier });
              });

              // Then push this array to our loacl one further up
              bookingsArr.push(...localBookingsArr)
            });
        }
      })
    );

    // Finally, set the bookings found into the state
    totalBookingsSize.current = bookingsArr.length
    setBookings(bookingsArr)

    // Hide the loading spinners
    setLoading(false);
  }

  // Fetch the suppliers data if they're a hotel
  const fetchHotelDocuments = async (searchBy, startTimestamp, endTimestamp, supplierID) => {
    // Pull all the bookings by their travel date
    await db
      .collection("bookings")
      .where(`${searchBy}`, ">=", startTimestamp)
      .where(`${searchBy}`, "<=", endTimestamp)
      .where("suppliers_hotels", "array-contains", supplierID)
      .where("removed", "==", false)
      .orderBy(`${searchBy}`)
      .get()
      .then((bookingDocs) => {
        // Create an empty array for storing the bookings
        let bookingsArr = [];

        // Loop through the bookings that have been found that match
        bookingDocs.forEach((bookingDoc) => {
          // Push the booking data into the array
          bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() });
        });

        // Set the bookings into the state
        totalBookingsSize.current = bookingsArr.length
        setBookings(bookingsArr);
      });

    // Reset the state
    setLoading(false);
  };

  // Fetch the suppliers data if they're a course
  const fetchCourseDocuments = async (searchBy, startTimestamp, endTimestamp, supplierID) => {
    // Pull all the bookings by their travel date
    await db
      .collection("bookings")
      .where(`${searchBy}`, ">=", startTimestamp)
      .where(`${searchBy}`, "<=", endTimestamp)
      .where("suppliers_rounds", "array-contains", supplierID)
      .where("removed", "==", false)
      .orderBy(`${searchBy}`)
      .get()
      .then((bookingDocs) => {
        // Create an empty array for storing the bookings
        let bookingsArr = [];

        // Loop through the bookings that have been found that match
        bookingDocs.forEach((bookingDoc) => {
          // Push the booking data into the array
          bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() });
        });

        // Set the bookings into the state
        totalBookingsSize.current = bookingsArr.length
        setBookings(bookingsArr);
      });

    // Reset the state
    setLoading(false);
  };

  // Fetch the suppliers data if they're a transfer
  const fetchTransferDocuments = async (searchBy, startTimestamp, endTimestamp, supplierID) => {
    // Pull all the bookings by their travel date
    await db
      .collection("bookings")
      .where(`${searchBy}`, ">=", startTimestamp)
      .where(`${searchBy}`, "<=", endTimestamp)
      .where("transfer", "==", supplierID)
      .where("removed", "==", false)
      .orderBy(`${searchBy}`)
      .get()
      .then((bookingDocs) => {
        // Create an empty array for storing the bookings
        let bookingsArr = [];

        // Loop through the bookings that have been found that match
        bookingDocs.forEach((bookingDoc) => {
          // Push the booking data into the array
          bookingsArr.push({ id: bookingDoc.id, ...bookingDoc.data() });
        });

        // Set the bookings into the state
        totalBookingsSize.current = bookingsArr.length
        setBookings(bookingsArr);
      });

    // Reset the state
    setLoading(false);
  };

  // Upload the local totals
  const updateLocalTotal = (ID, price, type) => {
    if (isNaN(price)) {
      console.log("Price is not a number", price)
    }

    // If the local array already has a record for this supplier
    if (totalSupplierInvoices.current[`${ID}_${type}`]) {
      // Just add it
      if (!isNaN(price)) {
        totalSupplierInvoices.current[`${ID}_${type}`] = totalSupplierInvoices.current[`${ID}_${type}`] + price
      }
    } else {
      if (!isNaN(price)) {
        totalSupplierInvoices.current[`${ID}_${type}`] = price
      }
    }

    // Sum the array together and write it into the array
    setTotalInvoiced(Object.values(totalSupplierInvoices.current).reduce((a, b) => a + b, 0)?.toLocaleString())
  }

  return (
    <Tile fullPage={true}>
      <Title className="flex has-select-field">
        <h1>Supplier stats</h1>
      </Title>

      <div className="page-filters extra">
        <SearchSelect
          label="Supplier:"
          placeholder="Supplier:"
          showClear={true}
          activeOnHover={true}
          options={suppliers}
          onSelect={(option) => setSupplier(option)}
          hideArrow={supplier.value?.length > 0}
          clearInput={() => setSupplier({})}
        />

        <Select
          label="Month:"
          placeholder="Month:"
          value={month}
          activeOnHover={true}
          onSelect={(option) => setMonth(option.option)}
          options={CALENDAR_MONTHS}
        />

        <Select
          label="Year:"
          placeholder="Year:"
          value={year}
          activeOnHover={true}
          onSelect={(option) => setYear(option.option)}
          options={years}
        />

        <Select
          label="Search by:"
          placeholder="Search by:"
          value={type}
          selected={type}
          activeOnHover={true}
          onSelect={(option) => setType(option.option)}
          options={{
            travel_date: "Travel date",
            booked_date: "Booked date",
          }}
        />

        <div>
          <Button
            filters={true}
            disabled={!supplier?.option || !year || !type}
            label="Fetch supplier stats"
            loadingText="Fetching suppliers..."
            loading={loading}
            onClick={() => fetchSupplierStats()}
          />
        </div>
      </div>

      <Table
        className="travellers-table"
        headings={[
          "Booking ref.",
          type === "booked_date" ? "Booked" : "Travelling",
          "Lead pax",
          "Confirmed",
          "Supplier",
          "Supplier cost",
          "Paid",
          "",
        ]}
        noResults={Object.entries(bookings).length === 0 || !supplier?.option || !year || !type}
        noResultsMessage={"No results matching those filters"}
      >
        {bookings.map((booking) => (
          <Booking
            key={booking?.id}
            id={booking?.id}
            details={booking}
            sorting={type}
            supplier={booking.stats_supplier ? booking.stats_supplier : supplier?.option}
            updateSupplierPrice={(price, type) => updateLocalTotal(booking.id, price, type)}
          />
        ))}

        <tr className="totals-row">
          <td colSpan="5">Totals:</td>
          <td colSpan="3">£{totalInvoiced || 0}</td>
        </tr>
      </Table>
    </Tile>
  );
}

import React, { useState, useContext } from "react";
import { db, arrayUnion, } from "../../../utils/firebase";
import firebase from "firebase";
import { BookingContext } from "../../../utils/providers/booking";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Round from "../../../components/booking/round/round";
import { PlusIcon } from "../../../utils/svgs";

/**
 * Functional component to return the booking margins panel
 */
function Rounds() {
    const [adding, setAdding] = useState(false);

    /**
     * Deconstruct data from the booking context
     */
    const {
        bookingID,
        roundsOrder,
        preferredTeeTimes,
        additionalRoundRequirements
    } = useContext(BookingContext);

    // Parse any tee times
    let teeTimesArr = []

    // If there are any preffered tee times
    if (preferredTeeTimes && preferredTeeTimes.length > 0) {
        teeTimesArr = JSON.parse(preferredTeeTimes).map((slot) => (`${slot.course} - ${slot.date} at ${slot.time}`))
    }

    /**
     * Add a new round to the booking
     */
    const addNewRound = async () => {
        /**
         * Set the state
         */
        setAdding(true);
        /**
         * Add a new round document into the bookings "rounds" collection and return the ID for it
         */
        const roundID = await db.collection(`bookings/${bookingID}/rounds`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((roundDoc) => { return roundDoc.id });
        /**
         * Push the new round ID into the "rounds" array for the booking
         */
        await db.doc(`bookings/${bookingID}`).set({
            rounds_order: arrayUnion(roundID),
        }, { merge: true });
        /**
         * Reset the state
         */
        setAdding(false);
    }

    return (
        <>
            {(teeTimesArr.length > 0 || additionalRoundRequirements) && (
                <div className="direct-booking-extras">
                    <p className="direct-booking-extras-header">✨ Direct Booking Information</p>

                    <div className="direct-booking-extras-block">
                        {/* Are there any tee times to show? */}
                        {teeTimesArr.length > 0 &&
                            <div className="direct-booking-extras-item">
                                <p className="direct-booking-extras-subtitle">Preferred Tee Times</p>

                                {teeTimesArr.map((teeTime) => (
                                    <p key={teeTime} className="direct-booking-extras-line">{teeTime}</p>
                                ))}
                            </div>
                        }

                        {additionalRoundRequirements && 
                            <div className="direct-booking-extras-item">
                                <p className="direct-booking-extras-subtitle">Additional Requirements</p>

                                <p className="direct-booking-extras-line">{additionalRoundRequirements}</p>
                            </div>
                        }
                    </div>
                </div>
            )}

            <div className="add-table">
                <Button
                    badge={true}
                    label="Add another round"
                    icon={<PlusIcon />}
                    loading={adding}
                    loadingText="Adding round..."
                    onClick={() => addNewRound()} />
            </div>

            {/* Loop through the rounds in the order they are saved */}
            {roundsOrder.map((round, index) => (
                <Round key={round} bookingID={bookingID} roundID={round} index={index} />
            ))}
        </>
    );
}

export default Rounds;

import { useContext } from 'react'
import { QuoteContext } from '../../context/context'
import './basket.scss'

// Returns the HTML markup for the basket tile
export default function Basket() {
    // Pull the list of chosen packages from the quote context
    const { ctxSelectedPackages } = useContext(QuoteContext)

    return (
        <div className='package-basket-container'>
            <div className='package-basket-inner'>
                <p>You have <span>{ctxSelectedPackages.length}</span> packages selected</p>
            </div>
        </div>
    )
}
import React, { useContext } from "react";
import { BookingContext } from "../../../../../utils/providers/isolated-booking";

/**
 * UI components
 */
import Price from "../../../../../components/itinerary/price/price";

/**
 * Functional component to return the tab for the various price lines attached to a booking
 */
function PriceLines() {
    /**
     * Deconstruct data from the booking context
     */
    const {
        bookingID,
        pricesOrder,
    } = useContext(BookingContext);

    return (
        <>
            {/* Loop through the prices in the order they are saved */}
            {pricesOrder?.map((price, index) => (
                <Price key={price} bookingID={bookingID} priceID={price} index={index} />
            ))}
        </>
    );
}

export default PriceLines;
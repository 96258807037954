import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import firebase from "firebase";
import moment from "moment";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Textarea from "../../../components/ui/inputs/textarea";
import Window from "../../../components/structure/window/window";

function Round(props) {
    const [booking, setBooking] = useState({});
    const [resort, setResort] = useState({});
    const [round, setRound] = useState({});

    const [approving, setApproving] = useState(false);
    const [approvalModal, setApprovalModal] = useState(false);
    const [approvalComments, setApprovalComments] = useState("");

    const [rejecting, setRejecting] = useState(false);
    const [rejectionModal, setRejectionModal] = useState(false);
    const [rejectionComments, setRejectionComments] = useState("");

    /**
     * Get the bookingID from the props
     */
    const { bookingID, roundID, complete } = props;

    /**
     * Get the check in date
     */
    const tee_time_date = moment(round.tee_time?.seconds, "X").format("DD/MM/YYYY HH:mm");

    /**
     * When the bookingID is updated
     */
    useEffect(() => {
        pullBookingDetails();
    }, [bookingID, roundID]);

    /**
     * Pull the details for the transfer from the databse to display on the front-end
     */
    const pullBookingDetails = async () => {
        /**
         * Get the booking details from the database
         */
        await db.doc(`bookings/${bookingID}`)
            .get().then((bookingDoc) => {
                /**
                 * Set the booking data into the state
                 */
                setBooking({ ...bookingDoc.data() });
            });
        /**
         * Then get the round details from the database
         */
        await db.doc(`bookings/${bookingID}/rounds/${roundID}`)
            .get().then((roundDoc) => {
                /**
                 * Get the courseID for the round
                 */
                const { courseID } = roundDoc.data();
                /**
                 * Set the round data into the state
                 */
                setRound({ ...roundDoc.data() });
                /**
                 * Then get the round partner document
                 */
                return db.doc(`courses/${courseID}`)
                    .get().then((resortDoc) => {
                        /**
                         * Set the round data into the state
                         */
                        setResort({ ...resortDoc.data() });
                    });
            });
    }

    /**
     * Approve the reservation
     */
    const approveReservation = async () => {
        /**
         * Update the state to show it's loading
         */
        setApproving(true);
        setApprovalModal(false);
        /**
         * Update the booking document
         */
        await db.doc(`bookings/${bookingID}/rounds/${roundID}`).set({
            booking_status: "approved",
            booking_status_comments: approvalComments || "",
        }, { merge: true });
        /**
         * Add a log tot he booking to reflect the approval
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            type: "ROUND_BOOKING_APPROVED",
            badge: "SUCCESS",
            message: `The round at ${tee_time_date} on ${resort?.name} the course was approved`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setApproving(false);
        /**
         * Show the completed thank you message
         */
        complete();
    }

    /**
     * Reject the reservation
     */
    const rejectReservation = async () => {
        /**
         * Update the state to show it's loading
         */
        setRejecting(true);
        setRejectionModal(false);
        /**
         * Update the booking document
         */
        await db.doc(`bookings/${bookingID}/rounds/${roundID}`).set({
            booking_status: "rejected",
            booking_status_comments: rejectionComments,
        }, { merge: true });
        /**
         * Add a log to the booking to reflect the rejection
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            type: "ROUND_BOOKING_REJECTED",
            badge: "ERROR",
            message: `The round at ${tee_time_date} on ${resort?.name} the course was rejected with the following reason: ${rejectionComments}`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setRejecting(false);
        /**
         * Show the completed thank you message
         */
        complete();
    }

    return (
        <>
            <div className="reservation-block">
                {/* <div className="reservation-table">
                    <table className="booking-table">
                        <tbody>
                            <tr>
                                <td>Course:</td>
                                <td>
                                    {resort?.name}
                                </td>
                            </tr>
                            <tr>
                                <td>Tee time:</td>
                                <td>
                                    {tee_time_date}
                                </td>
                            </tr>
                            <tr>
                                <td>Golfers playing:</td>
                                <td>
                                    {booking?.golfers}
                                </td>
                            </tr>
                            <tr>
                                <td>Buggies requested:</td>
                                <td>
                                    {round?.buggies ? "Yes" : "No"}
                                </td>
                            </tr>
                            <tr>
                                <td>Comments:</td>
                                <td>
                                    {round.comments && `${round.comments}`}
                                    {!round.comments && <small>No additional comments</small>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}

                <div className="reservation-actions">
                    <Button
                        label="Approve booking"
                        onClick={() => setApprovalModal(true)} />
                    <Button
                        className="OUTLINE"
                        label="Reject booking"
                        onClick={() => setRejectionModal(true)} />
                </div>
            </div>

            {rejectionModal &&
                <Window
                    title="Rejection reason"
                    className="slim reservation-modal"
                    close={() => setRejectionModal(false)}>

                    <Textarea
                        placeholder="Please suggest an alternative:"
                        value={rejectionComments}
                        onChange={setRejectionComments} />

                    <Button
                        fullWidth={true}
                        label="Send rejection reason"
                        disabled={!rejectionComments}
                        loading={rejecting}
                        loadingText="Sending rejection..."
                        onClick={() => rejectReservation()} />
                </Window>
            }

            {approvalModal &&
                <Window
                    title="Additional comments"
                    className="slim reservation-modal"
                    close={() => setApprovalModal(false)}>

                    <Textarea
                        placeholder="Any additional comments:"
                        value={approvalComments}
                        onChange={setApprovalComments} />

                    <Button
                        fullWidth={true}
                        label="Send approval"
                        loading={approving}
                        loadingText="Sending approval..."
                        onClick={() => approveReservation()} />
                </Window>
            }
        </>
    );
}

export default Round;
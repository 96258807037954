import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import "./internal.scss";

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Table from "../../components/structure/table/table";
import Staff from "../../components/internal/staff/staff";
import Button from "../../components/ui/button/button";
import { PlusIcon } from "../../utils/svgs";
import AddAgent from "./add/add";

/**
 * Functional component to return a full staff list fo assigning targets to
 */
function Internal() {
    const [users, setUsers] = useState([]);

    const [adding, setAdding] = useState(false);
    const [addingNewAgent, setAddingNewAgent] = useState(false);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull a list of all sales agents from the databas
         */
        const unsubscribe = db.collection("users")
            .orderBy("first_name")
            .onSnapshot((userChanges) => {
                /**
                 * Loop over the user documents found
                 */
                userChanges.docChanges().forEach((change) => {
                    /**
                     * Agent document added
                     */
                    if (change.type === "added") {
                        setUsers((users) => [...users, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    }
                    /**
                     * Agent document updated
                     */
                    if (change.type === "modified") {
                        setUsers((users) => {
                            let updatedUsers = [...users];
                            for (let i in users) {
                                if (users[i].id === change.doc.id) {
                                    updatedUsers[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedUsers;
                        });
                    }
                    /**
                     * Agent document removed
                     */
                    if (change.type === "removed") {
                        setUsers((users) => users.filter((user) => user.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener when we're done
         */
        return () => unsubscribe();
    }, []);

    return (
        <Tile fullPage={true}>
            <Title className="flex">
                <h1>System users</h1>

                <Button
                    label="Add agent"
                    small={true}
                    icon={<PlusIcon />}
                    loading={adding}
                    loadingText="Adding..."
                    onClick={() => setAddingNewAgent(true)} />
            </Title>

            <Table
                className="system-users-table"
                headings={[
                    "Agent",
                    "Display options",
                ]}>

                {users.map((user) => (
                    <Staff
                        key={user.id}
                        id={user.id}
                        details={user} />
                ))}
            </Table>

            {/* Are we adding a new agent to the system */}
            {addingNewAgent &&
                <AddAgent close={() => setAddingNewAgent(false)} />
            }
        </Tile>
    );
}

export default Internal;
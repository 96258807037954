import React, { useContext, useState, useEffect } from "react";
import { BookingContext } from "../../../utils/providers/booking";
import { BOARD_TYPES } from "../../../utils/exports/board-types";
import { ROOM_TYPES } from "../../../utils/exports/room-types";
import { db, arrayUnion } from "../../../utils/firebase";
import firebase from "firebase";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Hotel from "../../../components/booking/hotel/hotel";
import { PlusIcon } from "../../../utils/svgs";

/**
 * Functional component to return the booking hotel panel
 */
function Hotels(props) {
    const [adding, setAdding] = useState(false);
    const [directBookingResort, setDirectBookingResort] = useState({})

    /**
     * Get the client data from the props
     */
    const { client } = props;

    /**
     * Deconstruct data from the booking context
     */
    const {
        bookingID,
        hotelsOrder,
        preferredDinnerTimes,
        dietaryRequirements,
        directBookingDetails
    } = useContext(BookingContext);

    // Parse any tee times
    let dinnerTimesArr = []

    // If there are any preffered tee times
    if (preferredDinnerTimes && preferredDinnerTimes.length > 0) {
        dinnerTimesArr = JSON.parse(preferredDinnerTimes).map((slot) => (`${slot.date} at ${slot.time}`))
    }

    // Store an array for any room types
    let chosenRoomTypes = []

    // If there are any room types
    if (directBookingDetails?.chosen_room_types) {
        Object.keys(directBookingDetails.chosen_room_types).forEach((roomType) => {
            chosenRoomTypes.push(`${directBookingDetails.chosen_room_types[roomType]} x ${ROOM_TYPES[roomType]}`)
        })
    }

    // On component load    
    useEffect(() => {
        // If there is a resort  ID available on the direct booking details
        if (directBookingDetails?.resort) {
            // Get the resort data from the database
            db.doc(`CMS_holidays/${directBookingDetails.resort}`)
                .get().then((resortDoc) => {
                    // Set the resort data
                    setDirectBookingResort(resortDoc.data())
                })
        }
    }, [])

    /**
     * Add a new hotel to the booking
     */
    const addNewHotel = async () => {
        /**
         * Set the state
         */
        setAdding(true);
        /**
         * Add a new round document into the bookings "hotels" collection and return the ID for it
         */
        const hotelID = await db.collection(`bookings/${bookingID}/hotels`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((hotelDoc) => { return hotelDoc.id });
        /**
         * Push the new hotel ID into the "hotels" array for the booking
         */
        await db.doc(`bookings/${bookingID}`).set({
            hotels_order: arrayUnion(hotelID),
        }, { merge: true });
        /**
         * Reset the state
         */
        setAdding(false);
    }

    return (
        <>
            {directBookingDetails?.resort && (
                <div className="direct-booking-extras">
                    <p className="direct-booking-extras-header">✨ Direct Booking Information</p>

                    <div className="direct-booking-extras-block">
                        <div className="direct-booking-extras-item">
                            <p className="direct-booking-extras-subtitle">Resort</p>

                            <p className="direct-booking-extras-line">{directBookingResort?.title || "Loading resort..."}</p>
                        </div>

                        <div className="direct-booking-extras-item">
                            <p className="direct-booking-extras-subtitle">Group Size</p>

                            <p className="direct-booking-extras-line">{directBookingDetails?.group_size}</p>
                        </div>

                        <div className="direct-booking-extras-item">
                            <p className="direct-booking-extras-subtitle">Board Type</p>

                            <p className="direct-booking-extras-line">{BOARD_TYPES[directBookingDetails?.chosen_board_type]}</p>
                        </div>

                        <div className="direct-booking-extras-item">
                            <p className="direct-booking-extras-subtitle">Chosen Room Types</p>

                            {chosenRoomTypes.map((roomType) => (
                                <p className="direct-booking-extras-line">{roomType}</p>
                            ))}
                        </div>

                        {/* Are there any tee times to show? */}
                        {dinnerTimesArr.length > 0 &&
                            <div className="direct-booking-extras-item">
                                <p className="direct-booking-extras-subtitle">Preferred Dinner Times</p>

                                {dinnerTimesArr.map((dinnerTime) => (
                                    <p key={dinnerTime} className="direct-booking-extras-line">{dinnerTime}</p>
                                ))}
                            </div>
                        }

                        {dietaryRequirements && 
                            <div className="direct-booking-extras-item">
                                <p className="direct-booking-extras-subtitle">Dietary Requirements</p>

                                <p className="direct-booking-extras-line">{dietaryRequirements}</p>
                            </div>
                        }
                    </div>
                </div>
            )}

            <div className="add-table">
                <Button
                    badge={true}
                    label="Add another hotel"
                    icon={<PlusIcon />}
                    loading={adding}
                    loadingText="Adding hotel..."
                    onClick={() => addNewHotel()} />
            </div>

            {/* Loop through the hotels in the order they are saved */}
            {hotelsOrder.map((hotel, index) => (
                <Hotel key={hotel} bookingID={bookingID} hotelID={hotel} index={index} client={client} />
            ))}
        </>
    );
}

export default Hotels;

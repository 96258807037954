import React, { useState, useEffect, createContext } from "react";
import { db } from "../firebase";
import moment from "moment";

/**
 * Setup a context for storing data asociated to a booking
 */
const BookingContext = createContext();

/**
 * Create a context to store the data for a booking document.
 */
function BookingProvider({ children }) {
    const [booking, setBooking] = useState({});
    const [bookingID, setBookingID] = useState("");
    const [agent, setAgent] = useState({});
    const [agentID, setAgentID] = useState("");
    const [client, setClient] = useState({});
    const [clientID, setClientID] = useState("");
    const [reference, setReference] = useState("");
    const [booked, setBooked] = useState("");
    const [confirmed, setConfirmed] = useState(false);
    const [golfers, setGolfers] = useState(0);
    const [nonGolfers, setNonGolfers] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [paidByClient, setPaidByClient] = useState(0);
    const [outstanding, setOutstanding] = useState(0);
    const [balanceDue, setBalanceDue] = useState({});
    const [commentsForClient, setCommentsForClient] = useState("");
    const [commentsFromClient, setCommentsFromClient] = useState("");
    const [dateFromTimestamp, setDateFromTimestamp] = useState(0);

    /**
     * Hotel fields
     */
    const [hotelsOrder, setHotelsOrder] = useState([]);

    /**
     * Round fields
     */
    const [roundsOrder, setRoundsOrder] = useState([]);

    /**
     * Transfer fields
     */
    const [transferID, setTransferID] = useState("");
    const [transfer, setTransfer] = useState({});

    /**
     * Price fields
     */
    const [pricesOrder, setPricesOrder] = useState([]);

    /**
     * Sales margin fields
     */
    const [stablefordPoints, setStablefordPoints] = useState(0);

    /**
     * 
     */
    const [activeBookingTab, setActiveBookingTab] = useState("SUMMARY");

    /**
     * When the booking ID is updated
     */
    useEffect(() => {
        if (bookingID) {
            /**
             * Setup a snapshot listener on the booking document
             */
            const unsubscribe = db.doc(`bookings/${bookingID}`)
                .onSnapshot((bookingSnap) => {
                    /**
                     * Set the booking data into the state
                     */
                    setBooking({ ...bookingSnap.data() });
                });
            /**
             * Remove the listener on component unload
             */
            return () => unsubscribe();
        }
    }, [bookingID]);

    /**
     * When the booking object is updated in the state
     */
    useEffect(() => {
        /**
         * Deconstruct all the data needed from the booking
         */
        const {
            agent,
            booked,
            client,
            reference,
            date_from,
            confirmed,
            golfers,
            non_golfers,
            paid_by_client,
            balance_due,
            balance_outstanding,
            public_notes,
            enquiry,
            hotels_order,
            transfer,
            margins,
            stableford,
            rounds_order,
            prices_order,
        } = booking;
        /**
         * Date as milliseconds
         */
        const bookedDateReadable = moment(booked?.seconds, "X").format("DD/MM/YYYY");
        const balanceDateReadable = moment(balance_due?.seconds, "X").format("DD/MM/YYYY");
        /**
         * Set the data back into the context state
         */
        setAgentID(agent || "");
        setClientID(client || "");
        setReference(reference || 0);
        setDateFromTimestamp(date_from?.seconds || 0);
        setBooked(bookedDateReadable || "");
        setConfirmed(confirmed || false);
        setGolfers(golfers || 0);
        setNonGolfers(non_golfers || 0);
        setPaidByClient(paid_by_client || 0);
        setBalanceDue(balanceDateReadable || 0);
        setOutstanding(balance_outstanding || 0);
        setCommentsForClient(public_notes || "");
        setCommentsFromClient(enquiry?.comments || "");
        /**
         * Hotels panel
         */
        setHotelsOrder(hotels_order || []);
        /**
         * Rounds panel
         */
        setRoundsOrder(rounds_order || []);
        /**
         * Transfers panel
         */
        setTransferID(transfer || "");
        /**
         * Price lines panel
         */
        setPricesOrder(prices_order || []);
        /**
         * Margins panel
         */
        setTotalCost(margins?.customer_price || 0);
        setStablefordPoints(stableford?.amount || 0);
    }, [booking]);

    /**
     * When the agent ID has been recognised
     */
    useEffect(() => {
        /**
         * Pull the agent details from the database
         */
        agentID && db.doc(`users/${agentID}`)
            .get().then((agentDoc) => {
                /**
                 * Deconstruct the data we need to save for this agent
                 */
                const { first_name, last_name, email } = agentDoc.data();
                /**
                 * Set the agent details into the state
                 */
                setAgent({ first_name, last_name, email });
            });
    }, [agentID]);

    /**
     * When the client ID has been recognised
     */
    useEffect(() => {
        /**
         * Pull the agent details from the database
         */
        clientID && db.doc(`clients/${clientID}`)
            .get().then((clientDoc) => {
                /**
                 * Deconstruct the data we need to save for this client document
                 */
                const { first_name, last_name } = clientDoc.data();
                /**
                 * Set the agent details into the state
                 */
                setClient({ id: clientDoc.id, first_name, last_name });
            });
    }, [clientID]);

    return (
        <BookingContext.Provider value={{
            booking,
            bookingID, setBookingID,
            client,
            agent,
            reference,
            dateFromTimestamp,
            booked,
            confirmed,
            golfers,
            nonGolfers,
            totalCost,
            paidByClient,
            outstanding,
            balanceDue,
            commentsForClient,
            commentsFromClient, setCommentsFromClient,
            hotelsOrder,
            roundsOrder,
            transfer,
            stablefordPoints,
            pricesOrder,
            activeBookingTab, setActiveBookingTab,
        }}>
            {children}
        </BookingContext.Provider>
    );
}

export { BookingContext, BookingProvider };

import React, { useEffect, useRef, useState } from "react";
import { UploadIcon } from "../../../utils/svgs";
import "./file.scss";

/**
 * Functional component for a file upload input
 * 
 * Values are passed back up the props to the containers they are embedded in and the values are 
 * passed back into the input to keep it as a controlled component.
 * 
 * @param {object} props Props passed in for the input
 * @returns HTML markup and functionality for the inputfield
 */
function File(props) {
    const [files, setFiles] = useState({});
    const [fileNames, setFileNames] = useState("");

    /**
     * When the files are updated
     */
    useEffect(() => {
        /**
         * Only update the props and state if there are files present
         */
        if (files?.length > 0) {
            /**
             * Push them up through the props
             */
            props.onChange(files);
            /**
             * Reset the fileNames array if multiple files is turned off
             */
            !props.multiple && setFileNames([]);
            /**
             * Then loop through them each and build a string of file names
             */
            for (let i = 0; i < files.length; i++) {
                setFileNames((fileNames) => [...fileNames, files[i].name])
            }
        }
    }, [files]);

    /**
     * Setup a reference to the input field for the file
     */
    const inputRef = useRef(null);

    /**
     * On click event of the fake file input
     */
    const fakeInputClicked = () => {
        /**
         * Click on the hidden input to show it
         */
        inputRef.current.click();
    }

    return (
        <div className={["ui-input-wrapper", props.wrapperClass, props.error && "has-error"].join(" ")}>
            {/* Input wrapper for the input and icon should one be passed in */}
            <div className="ui-input-block">
                {/* Icon to display in the input */}
                <div className="ui-input-icon">
                    <UploadIcon />
                </div>

                {/* Placeholder to sit above the field when there is a value */}
                {((props.placeholder || props.label) && props.showLabel) &&
                    <div className={["ui-input-upper-placeholder", props.value.length > 0 && "active"].join(" ")}>
                        {props.label || props.placeholder}
                    </div>
                }

                {/* Field to sit above the file upload */}
                <div className="ui-input-fakefile" onClick={fakeInputClicked}>
                    {/* Is there a value to show */}
                    {props.value &&
                        <p className="is-value">{props.value}</p>
                    }

                    {/* Is there no value but we want to show the file names? */}
                    {(!props.value && props.showFileNames) &&
                        <p className={fileNames.length > 0 ? "is-value" : "is-placeholder"}>
                            {fileNames.length > 0 ? fileNames.join(", ") : props.placeholder}
                        </p>
                    }

                    {/* Is there no value and we arn't to show the file names, but a placeholder present? */}
                    {(!props.value && !props.showFileNames && props.placeholder) &&
                        <p className="is-placeholder">{props.placeholder}</p>
                    }
                </div>

                {/* Input field itself */}
                <input
                    ref={inputRef}
                    name={props.name}
                    id={props.id}
                    type="file"
                    className={["ui-input-file", props.className, "has-icon"].join(" ")}
                    onChange={(e) => setFiles(e.target.files)}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    multiple={props.multiple}
                    accept={props.accept} />
            </div>

            {/* Is there an error or note to display */}
            {props.error ? <small>{props.error}</small> :
                props.note && <small>{props.note}</small>}
        </div>
    );
}

export default File;
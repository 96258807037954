import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import { NavLink } from "react-router-dom";
import moment from "moment";

/**
 * UI components
 */
import Badge from "../ui/badge/badge";

/**
 * Functional component to return the booking
 */
function Booking(props) {
    const [client, setClient] = useState({});

    /**
     * Deconstruct the details from the props
     */
    const { id, details } = props;

    /**
     * Generate a string to represent the check_in date from the timestamps
     */
    const checkInDate = moment(details.check_in?.seconds, "X").format("MMM D[,] YYYY");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the client record from the database
         */
        db.doc(`clients/${details.client}`)
            .get().then((clientDoc) => {
                /**
                 * Set the client data into the local state
                 */
                setClient(clientDoc.data());
            });
    }, []);

    return (
        <tr>
            <td>
                {checkInDate}
            </td>
            <td>
                {client.first_name && `${client.first_name} ${client.last_name}`}
                {!client.first_name && <small className="no-value">No name set</small>}
            </td>
            <td>
                {details.booked_location ? details.booked_location : details.enquiry?.location}
            </td>
            <td>
                £{details.margins?.customer_price || 0}
            </td>
            <td>
                £{details.paid_by_client || 0}
            </td>
            <td>
                £{details.balance_outstanding || 0}
            </td>
            <td>
                {details.resorts_paid &&
                    <Badge label="Yes" type="POSITIVE" />
                }

                {!details.resorts_paid &&
                    <Badge label="No" type="NEGATIVE" />
                }
            </td>
            <td>
                {((!details.hotels_status) || (details.hotels_status === "unconfirmed")) &&
                    <Badge label="Unconfirmed" type="NEGATIVE" />
                }

                {(details.hotels_status === "partially") &&
                    <Badge label="Partially" type="AMBER" />
                }

                {(details.hotels_status === "confirmed") &&
                    <Badge label="Confirmed" type="POSITIVE" />
                }
            </td>
            <td>
                {((!details.rounds_status) || (details.rounds_status === "unconfirmed")) &&
                    <Badge label="Unconfirmed" type="NEGATIVE" />
                }

                {(details.rounds_status === "partially") &&
                    <Badge label="Partially" type="AMBER" />
                }

                {(details.rounds_status === "confirmed") &&
                    <Badge label="Confirmed" type="POSITIVE" />
                }
            </td>
            <td className="is-button">
                <NavLink to={`booking/${id}`}>
                    View booking
                </NavLink>
            </td>
        </tr>
    );
}

export default Booking;
import { useEffect, useState } from 'react'

// Firebase utilities
import { db } from '../../../../utils/firebase'

// Router library
import { Redirect } from 'react-router-dom'

// UI components
import Tile from '../../../../components/structure/tile/tile'
import Title from '../../../../components/structure/title/title'
import Navigation from '../../../../components/ui/navigation/navigation'
import CourseDetails from '../../../../components/cms/course/details/details'
import CoursePricing from '../../../../components/cms/course/pricing/pricing'
import CourseDiscounts from '../../../../components/cms/course/discounts/discounts'
import CourseNotes from '../../../../components/cms/course/notes/notes'

// Returns a screen tile to edit the properties against a holiday
export default function EditCourse({ match }) {
    const [missing, setMissing] = useState(false)
    const [activeTab, setActiveTab] = useState('DETAILS')

    // Keep a state store of the holiday data being streamed from the database
    const [course, setCourse] = useState({})

    // Deconstruct the ID from the params
    const { id } = match.params

    // When the ID property is updated
    useEffect(() => {
        // Mount a listener onto the document for this 
        const unsubscribe = db.doc(`CMS_courses/${id}`).onSnapshot((courseDoc) => {
            // Make sure the course exists first
            !courseDoc.exists && setMissing(true)
            console.log(courseDoc.data())

            // If it does, deconstruct the data from the document
            setCourse({ id: courseDoc.id, ...courseDoc.data() })
        });

        // Unmount the listener when the componenet itself is unmounted
        return () => unsubscribe()
    }, [id])

    // No ID or document found? Redirect back to the manage page
    if (!id || missing) {
        return <Redirect to="/manage/courses" push />
    }

    return (
        <Tile fullPage={true}>
            {/* If we are still waiting on course data */}
            {!course?.title && 'loading'}

            {/* If we now have course data available */}
            {course?.title &&
                <>
                    <Title>
                        <h1>{course.title}</h1>
                        <p>
                            <a href={`https://www.golfholidaysdirect.com/${course.url}`} target="_blank" rel="noreferrer">{course.url}</a>
                        </p>
                    </Title>

                    {/* Tabbed navigation between screens */}
                    <Navigation links={[
                        {
                            id: "DETAILS",
                            label: "Details",
                        },
                        {
                            id: "PRICING",
                            label: "Pricing",
                        },
                        {
                            id: "CUSTOM_PRICING",
                            label: "Custom Pricing",
                        },
                        {
                            id: "DISCOUNTS",
                            label: "Discounts",
                        },
                        {
                            id: "NOTES",
                            label: "Notes",
                        }]}
                        active={activeTab}
                        onClick={(tabID) => setActiveTab(tabID)}
                    />

                    {/* Page routes for the course */}
                    {activeTab === 'DETAILS' && <CourseDetails courseDocID={id} course={course} />}
                    {activeTab === 'PRICING' && <CoursePricing course={course} />}
                    {activeTab === 'CUSTOM_PRICING' && <CoursePricing course={course} agent={true} />}
                    {activeTab === 'DISCOUNTS' && <CourseDiscounts course={course} />}
                    {activeTab === 'NOTES' && <CourseNotes course={course} />}
                </>
            }
        </Tile>
    )
}
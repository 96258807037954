import React, { useContext, useEffect } from "react";
import { AlertsContext } from "../../../utils/providers/alerts";
import "./toast.scss";

/**
 * Icon imports
 */
import { TimesIcon, CheckIcon, InformationIcon, AlertIcon } from "../../../utils/svgs";

/**
 * Functional component for the toast alert HTML markup
 * 
 * @param {object} props Props passed into the toast alert
 * @returns HTML markup for the toast alaert 
 */
function Toast(props) {
    /**
     * Deconstruct the dismiss function from the alerts context
     */
    const { dismissAlert } = useContext(AlertsContext);

    /**
     * When the alert first loads in
     */
    useEffect(() => {
        /**
         * Set a 6 second timeout for it to dismiss itself and hide from view
         */
        setTimeout(() => {
            dismissAlert(props.id);
        }, 6000);
    }, [dismissAlert, props.id]);

    return (
        <div className={["ui-toast-alert", props.className, props.type].join(" ")}>
            {/* Alert icon in the circle */}
            <div className="ui-toast-icon">
                {/* Default information icon */}
                {!props.type && <InformationIcon />}

                {/* Success icon */}
                {props.type === "SUCCESS" && <CheckIcon />}

                {/* Alert icon */}
                {props.type === "ALERT" && <AlertIcon />}

                {/* Error icon */}
                {props.type === "ERROR" && <TimesIcon />}
            </div>

            {/* Alert title */}
            {props.title && <p className="ui-toast-title">{props.title}</p>}

            {/* Alert body */}
            {props.body && <p className="ui-toast-body">{props.body}</p>}

            {/* Alert dismiss icon */}
            <div className="ui-toast-dismiss" onClick={() => dismissAlert(props.id)}>
                <TimesIcon />
            </div>
        </div>
    );
}

export default Toast;
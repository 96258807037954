import React, { useContext } from "react";
import { BookingContext } from "../../../../../utils/providers/isolated-booking";

/**
 * UI components
 */
import Round from "../../../../../components/itinerary/round/round";

/**
 * Functional component to return the booking margins panel
 */
function Rounds() {
    /**
     * Deconstruct data from the booking context
     */
    const {
        bookingID,
        roundsOrder,
    } = useContext(BookingContext);

    return (
        <>
            {/* Loop through the rounds in the order they are saved */}
            {roundsOrder.map((round, index) => (
                <Round key={round} bookingID={bookingID} roundID={round} index={index} />
            ))}
        </>
    );
}

export default Rounds;
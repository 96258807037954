// UI components
import { ArrowRightIcon, CopyIcon, PencilIcon, TrashIcon } from '../../../../../utils/svgs-v2'
import Badge from '../../../../ui/badge/badge'

// Returns the markup for a price row
export default function PriceRow({ details, edit, checkCopy, checkDelete, searchDate }) {
    // Configure a boolean to show if this row should be highlighted
    const highlighted = details.start_seconds < searchDate && details.end_seconds > searchDate

    return (
        <div className={['holiday-price-row', highlighted ? 'is-highlighted' : ''].join(' ').trim()}>
            {(details.name || details.is_draft) && 
                <div className='price-row-name'>
                    {details.is_draft && 
                        <Badge type='AMBER' label='Draft' />
                    }
                    {details.is_website_enabled && 
                        <Badge type='SPECIAL' label='Bookings Enabled' />
                    }
                    <p>{details.name}</p>
                </div>
            }
            <div className='price-row-details'>
                <div className='price-option-dates'>
                    <p>{details.start_date}</p>
                    <ArrowRightIcon />
                    <p>{details.end_date}</p>
                </div>

                <div className='price-option-range'>
                    <p>£{details.adjusted_price_lowest}-£{details.adjusted_price_highest}</p>
                </div>

                <div className='price-option-period'>
                    {details.period_state === 'PAST' && <Badge type='DEFAULT' label='Previous' />}
                    {details.period_state === 'ACTIVE' && <Badge type='POSITIVE' label='Active' />}
                    {details.period_state === 'UPCOMING' && <Badge type='INFO' label='Upcoming' />}
                </div>

                <div className='price-option-actions'>
                    <div className='price-option-action' onClick={() => edit(details.id, details)}>
                        <PencilIcon />
                    </div>

                    <div className='price-option-action' onClick={() => checkCopy(details.id)}>
                        <CopyIcon />
                    </div>

                    <div className='price-option-action' onClick={() => checkDelete(details.id)}>
                        <TrashIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}

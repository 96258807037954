import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";

/**
 * UI components
 */
import Table from "../../../components/structure/table/table";
import Booking from "../../../components/client/bookings/booking";

/**
 * Functional component to return the bookings for this client
 */
function Bookings(props) {
    const [bookings, setBookings] = useState([]);

    /**
     * Deconstruct the client ID from the props
     */
    const { clientID } = props;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull all the enquries that are attached to this client ID
         */
        db.collection("bookings")
            .orderBy("created")
            .where("client", "==", clientID)
            .get().then((bookingDocs) => {
                let bookingsArr = [];
                /**
                 * Loop through each of the bookings
                 */
                bookingDocs.forEach((booking) => {
                    /**
                     * Push the booking into the array
                     */
                    bookingsArr.push({
                        id: booking.id,
                        ...booking.data(),
                    });
                });
                /**
                 * Then push these bookings into the state
                 */
                setBookings(bookingsArr);
            });
    }, []);

    return (
        <>
            <Table
                className="bookings-table"
                headings={[
                    "Status",
                    "Agent",
                    "Booked date",
                    "Location/Event",
                    "Date from",
                    "Quoted",
                    "Hotels",
                    "Rounds",
                ]}
                noResults={bookings.length === 0}
                noResultsMessage="This client hasn't got any open bookings">

                {/* Print out the bookings for this user */}
                {bookings.map((enquiry) => (
                    <Booking
                        key={enquiry.id}
                        id={enquiry.id}
                        details={enquiry} />
                ))}
            </Table>
        </>
    );
}

export default Bookings;
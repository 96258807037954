import React, { useState, useEffect, useContext } from "react";
import { db, arrayRemove } from "../../../utils/firebase";
import { ModalContext } from "../../../utils/providers/modal";
import { AlertsContext } from "../../../utils/providers/alerts";
import firebase from "firebase";

/**
 * UI components
 */
import Input from "../../ui/inputs/input";
import Button from "../../ui/button/button";
import { SaveIcon } from "../../../utils/svgs";

/**
 * Functional component to return the round component
 */
function Price(props) {
    const [saving, setSaving] = useState(false);
    const [removing, setRemoving] = useState(false);

    const [name, setName] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0);

    /**
     * Deconstruct the booking & price IDs from the props
     */
    const { bookingID, priceID, index } = props;

    /**
     * Pull the show modal function from the modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * Use the alert context to show an alert when price line has been saved
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the price line document from the database
         */
        db.doc(`bookings/${bookingID}/prices/${priceID}`)
            .get().then((priceDoc) => {
                /**
                 * Deconstruct the data from the document
                 */
                const {
                    name,
                    quantity,
                    price,
                } = priceDoc.data();
                /**
                 * Update the state with these details
                 */
                setName(name || "");
                setQuantity(quantity || 0);
                setPrice(price || 0);
            });
    }, []);

    /**
     * Save the price details into the database
     */
    const savePriceLineDetails = async () => {
        /**
         * Show the state as saving
         */
        setSaving(true);
        /**
         * Make sure we have all the required details
         */
        if ((name.length > 0) && (quantity > 0) && (price > 0)) {
            /**
             * Update the price information
             */
            await db.doc(`bookings/${bookingID}/prices/${priceID}`).set({
                name,
                quantity: Number(quantity),
                price: Number.parseFloat(price),
            }, { merge: true });
            /**
             * Add the costs into an array for the booking
             */
            await db.doc(`bookings/${bookingID}`).set({
                price_lines: {
                    [priceID]: Number.parseFloat(price * quantity),
                },
            }, { merge: true });
            /**
             * Then we want to pull the booking details
             */
            await updateBookingPricing();
            /**
             * Show an alert to say it's been saved
             */
            pushAlert({
                type: "SUCCESS",
                title: "Price Line Saved",
                body: "Price line details have been saved and booking prices updated",
            });
        } else {
            /**
             * Remind them to fill in all the required details
             */
            pushAlert({
                type: "ERROR",
                title: "Missing Details",
                body: "Please complete all fields for the price line before saving",
            });
        }
        /**
         * Reset the state
         */
        setSaving(false);
    }

    /**
     * Update the pricing for the booking as per the price lines set out by the agent
     */
    const updateBookingPricing = async () => {
        /**
         * Firstly pull the booking details
         */
        const bookingDetails = await db.doc(`bookings/${bookingID}`)
            .get().then((bookingDoc) => {
                /**
                 * Return the booking in full
                 */
                return { ...bookingDoc.data() };
            });
        /**
         * Pull the price lines and paid by client numbers from the booking document
         */
        const { price_lines, paid_by_client } = bookingDetails;
        /**
         * Create a running total of all price lines
         */
        let runningTotal = 0;
        /**
         * Loop through each of the price lines on the booking
         */
        Object.values(price_lines).forEach((priceLine) => {
            /**
             * Add it to the running total
             */
            runningTotal += priceLine;
        });
        /**
         * Customer price to 2 decimal places
         */
        const customerPrice = Number.parseFloat(runningTotal.toFixed(2));
        /**
         * Work out the stableford points earned for the booking
         */
        const stableford = Number(runningTotal / 10);
        /**
         * Work out what the new outstanding balance is
         */
        let outstanding = 0;
        /**
         * If the client has paid money on their booking so far
         */
        if (paid_by_client && (paid_by_client > 0)) {
            /**
             * Take this into account for the balance outstanding
             */
            outstanding = (customerPrice - Number.parseFloat(paid_by_client.toFixed(2)));
        } else {
            /**
             * Otherwise the balance outstanding will become the customer price
             */
            outstanding = customerPrice;
        }
        /**
         * Push the new global status onto the booking document
         */
        return await db.doc(`bookings/${bookingID}`).set({
            balance_outstanding: outstanding,
            margins: {
                customer_price: customerPrice,
            },
            stableford: {
                amount: stableford,
            },
        }, { merge: true });
    }

    /**
     * Check before removing the price line from the enquiry
     */
    const checkBeforeRemoval = () => {
        /**
         * Display the modal to check before a price line removal
         */
        showModal({
            type: "ALERT",
            title: "Are you sure?",
            body: "Are you sure you'd like to remove this price line from the booking?",
            cancel: {
                label: "Cancel",
                action: () => { return null; },
            },
            next: {
                label: "Yes, i'm sure",
                action: () => removePriceFromBooking(),
            }
        });
    }

    /**
     * Remove the price line from the booking
     */
    const removePriceFromBooking = async () => {
        /**
         * Show the state as removing
         */
        setRemoving(true);
        /**
         * Remove the price from the order list for the booking
         */
        await db.doc(`bookings/${bookingID}`).set({
            prices_order: arrayRemove(priceID),
            price_lines: {
                [priceID]: firebase.firestore.FieldValue.delete(),
            }
        }, { merge: true });
        /**
         * Then remove the price line document itself
         */
        await db.doc(`bookings/${bookingID}/prices/${priceID}`).delete();
        /**
         * Then we want to update the booking price to reflect the new pricing
         */
        await updateBookingPricing();
        /**
         * Show an alert to say it's been saved
         */
        pushAlert({
            type: "SUCCESS",
            title: "Price Line Removed",
            body: "Price line has been removed and the booking pricing details have been updated",
        });
        /**
         * Reset the state
         */
        setRemoving(false);
    }

    return (
        <table className="booking-table">
            <tbody>
                <tr>
                    <td colSpan="2">
                        <div className="table-room-lower-border" />
                    </td>
                </tr>
                <tr className="table-sub-heading">
                    <td>Price line #{index + 1}</td>
                    <td>
                        <Button
                            badge={true}
                            label="Remove"
                            className="OUTLINE"
                            loading={removing}
                            loadingText="Removing..."
                            onClick={() => checkBeforeRemoval()} />
                        <Button
                            xsSmall={true}
                            icon={<SaveIcon />}
                            loading={saving}
                            loadingText="Saving..."
                            onClick={() => savePriceLineDetails()} />
                    </td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td>
                        <Input
                            type="text"
                            value={name}
                            onChange={setName}
                            placeholder="e.g. Golfer with 3 rounds, 2 nights"
                            activeOnHover={true} />
                    </td>
                </tr>
                <tr>
                    <td>Pricing</td>
                    <td>
                        <div className="table-data-split cols-2 weighted-style2">
                            <div className="table-data-column">
                                <p>Quantity</p>
                                <Input
                                    type="number"
                                    value={quantity}
                                    onChange={setQuantity}
                                    activeOnHover={true} />
                            </div>

                            <div className="table-data-column">
                                <p>Price</p>
                                <Input
                                    type="number"
                                    value={price}
                                    onChange={setPrice}
                                    symbol="£"
                                    activeOnHover={true} />
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default Price;
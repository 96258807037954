import React, { useState, useEffect } from "react";
import { db } from "../../../utils/firebase";
import { BOARD_TYPES } from "../../../utils/exports/board-types";
import moment from "moment";

/**
 * UI components
 */
import Input from "../../ui/inputs/input";
import Room from "./room";

/**
 * Functional component to return the round component
 */
function Hotel(props) {
    const [resort, setResort] = useState({});
    const [resortID, setResortID] = useState("");
    const [rooms, setRooms] = useState([]);
    const [nights, setNights] = useState(0);
    const [checkInDate, setCheckInDate] = useState(0);
    const [boardType, setBoardType] = useState("");

    /**
     * Deconstruct the booking & hotel ID from the props
     */
    const { bookingID, hotelID, index } = props;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the hotel document from the database
         */
        db.doc(`bookings/${bookingID}/hotels/${hotelID}`)
            .get().then((roundDoc) => {
                /**
                 * Deconstruct the data from the document
                 */
                const {
                    resortID,
                    check_in,
                    nights,
                    board_type,
                    rooms,
                } = roundDoc.data();
                /**
                 * Generate a date from the 
                 */
                const checkInDateReadable = moment(check_in?.seconds, "X").format("DD/MM/YYYY");
                /**
                 * Update the state with these details
                 */
                setResortID(resortID || "");
                setCheckInDate(checkInDateReadable || 0);
                setRooms(rooms || []);
                setNights(nights || 0);
                setBoardType(board_type);
            });
    }, []);

    /**
     * When the resortID has been recognised
     */
    useEffect(() => {
        /**
         * Pull the hotel details from the database
         */
        resortID && db.doc(`hotels/${resortID}`)
            .get().then((resortDoc) => {
                /**
                 * Deconstruct the data we need to save for this hotel
                 */
                const { name } = resortDoc.data();
                /**
                 * Set the hotel details into the state
                 */
                setResort({ name });
            });
    }, [resortID]);

    return (
        <table className="booking-table">
            <tbody>
                <tr>
                    <td colSpan="2">
                        <div className="table-room-lower-border" />
                    </td>
                </tr>
                <tr className="table-sub-heading left-align">
                    <td>Hotel #{index + 1}</td>
                </tr>
                <tr>
                    <td>Hotel name</td>
                    <td>
                        <Input
                            type="text"
                            value={resort?.name}
                            placeholder="No hotel chosen yet"
                            readOnly={true} />
                    </td>
                </tr>
                <tr>
                    <td>Check in date</td>
                    <td>
                        <Input
                            type="text"
                            value={checkInDate === "Invalid date" ? "No check in date set" : checkInDate}
                            readOnly={true} />
                    </td>
                </tr>
                <tr>
                    <td>Board type</td>
                    <td>
                        <Input
                            type="text"
                            value={BOARD_TYPES[boardType]}
                            readOnly={true} />
                    </td>
                </tr>
                <tr>
                    <td>Number of nights</td>
                    <td>
                        <Input
                            type="text"
                            value={nights}
                            readOnly={true} />
                    </td>
                </tr>
                <tr className="table-sub-heading left-align smaller">
                    <td>Rooms</td>
                </tr>

                {/* Loop through all the rooms requested */}
                {rooms.map((room, index) =>
                    <Room
                        key={index}
                        index={index}
                        room={room} />
                )}
            </tbody>
        </table>
    );
}

export default Hotel;
import { useEffect, useState } from 'react'
import { db } from '../../../../utils/firebase'
import './courses.scss'

// UI components
import Loading from '../../../app/loading/loading'

// Returns the HTML markup for the linked courses view on a holiday
export default function HolidayCourses({ holiday }) {
    const [loading, setLoading] = useState(true)
    const [courses, setCourses] = useState([])

    // On component load
    useEffect(() => {
        // Fetch the courses in the database where this holiday is listed in the resort IDs
        const fetchHolidayCourses = async () => {
            // Get the page ID from the holiday document
            const { page_id } = holiday

            // Query the database for all the matching documents
            const snapshot = await db.collection('CMS_courses')
                .where('resort_ids', 'array-contains', Number(page_id)).get()

            // Map over the documents returned to get an array of courses
            const resortCourses = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }))

            // Set these courses into the state
            setCourses(resortCourses)
            setLoading(false)
        }
        fetchHolidayCourses()
    }, [])

    // If we are still loading the courses
    if (loading) return <Loading />

    return (
        <div className='holiday-courses-container'>
            {courses.length > 0 ? courses.map((course) => (
                <div className='holiday-linked-course'>
                    {course.title}
                </div>
            )) : null}

            {courses.length === 0 &&
                <small>This resort has no linked courses</small>
            }
        </div>
    )
}
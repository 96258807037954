import React, { useState, useEffect } from "react";
import { db } from "../../../utils/firebase";
import moment from "moment";
import "./payments.scss";

/**
 * UI components and structure
 */
import Window from "../../structure/window/window";

/**
 * Functional component to return all the payments made against the booking
 */
function Payments(props) {
    const [payments, setPayments] = useState([]);

    /**
     * Deconstruct the bookingID from the props
     */
    const { bookingID } = props;

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Make a call to the database for all the payments on this booking
         */
        db.collection(`bookings/${bookingID}/payments`)
            .orderBy("paid_date", "desc")
            .get().then((paymentDocs) => {
                /**
                 * Are there any payment documents
                 */
                if (paymentDocs.size > 0) {
                    let paymentsArr = [];
                    /**
                     * Loop through them
                     */
                    paymentDocs.forEach((paymentDoc) => {
                        /**
                         * Pull some data from the document
                         */
                        const { amount, paid_date, payee, reference } = paymentDoc.data();
                        /**
                         * Create a readbable date from the timestamp
                         */
                        const paidDateReadable = moment(paid_date?.seconds, "X").format("DD/MM/YYYY");
                        /**
                         * Push all the data into the array
                         */
                        paymentsArr.push({ amount, paidDateReadable, payee, reference });
                    });
                    /**
                     * Push the payments array into the state
                     */
                    setPayments(paymentsArr);
                }
            });
    }, []);

    return (
        <Window
            title="All booking payments"
            className="slim"
            close={() => props.close()}>

            {/* Are there any payments to show */}
            {(payments.length === 0) &&
                <small className="no-payments-notice">There are no recorded payments yet, if you've paid recently please allow for your agent to assign your payment onto your accounts.</small>
            }

            {/* Loop through any payments in the state to print */}
            <table className="booking-payments">
                <tbody>
                    {payments.map((payment) => (
                        <tr key={payment.reference}>
                            <td>{payment.paidDateReadable}</td>
                            <td><small>{payment.reference}</small></td>
                            <td>{payment.payee}</td>
                            <td>£{payment.amount.toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Window>
    );
}

export default Payments;
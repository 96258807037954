import React, { useEffect, useState } from "react"
import { db } from "../../../utils/firebase"

// UI components
import DirectBooking from "../../../components/direct-booking/direct-booking"
import Table from "../../../components/structure/table/table"
import { LoadingIcon } from "../../../utils/svgs"

// Functional component returning all the leads that are found with the status of "REQUIRE_ATTENTION"
export default function RequireAttention(props) {
    const [loading, setLoading] = useState(false)
    const [bookings, setBookings] = useState([])

    // On component load
    useEffect(() => {
        // Show a loading spinner over the top
        setLoading(true)

        // Setup a listener on the databse for the bookings
        const unsubscribe = db.collection("direct_bookings")
            .orderBy("created", "desc")
            // .where("status", "==", "REQUIRE_ATTENTION")
            .onSnapshot((bookingsSnap) => {
                // Push the snapshot size up the props to the navigation on the bookings page
                props.updateRequiredCount(bookingsSnap.size)

                // Loop over the bookings found
                bookingsSnap.docChanges().forEach((change) => {
                    // Enquiry document added
                    if (change.type === "added") {
                        setBookings((bookings) => [
                            ...bookings,
                            {
                                id: change.doc.id,
                                ...change.doc.data(),
                            }
                        ])
                    }

                    // Enquiry document updated
                    if (change.type === "modified") {
                        setBookings((bookings) => {
                            let updatedbookings = [...bookings]
                            for (let i in bookings) {
                                if (bookings[i].id === change.doc.id) {
                                    updatedbookings[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedbookings
                        });
                    }

                    // Enquiry document removed
                    if (change.type === "removed") {
                        setBookings((bookings) =>
                            bookings.filter((enquiry) => enquiry.id !== change.doc.id))
                    }
                })

                // And then set the loading state back to false to show the bookings
                setLoading(false);
            });

        // On component unload
        return () => unsubscribe()
    }, [])

    return (
        <Table
            className="bookings-table"
            headings={[
                "Agent",
                "Booked date",
                "Name",
                "Phone",
                "Location/Event",
                "Check in date",
                "Group size",
                "Booked price",
                "Payment status",
            ]}>

            {loading &&
                <div className="table-loading-splash">
                    <div className="loading-wrapper">
                        <p>Loading...</p>
                        <div className="svg-loading-wrap">
                            <LoadingIcon />
                        </div>
                    </div>
                </div>
            }

            {/* Loop through any bookings to print them into the table */}
            {bookings.map((booking) => (
                <DirectBooking
                    key={booking.id}
                    id={booking.id}
                    details={booking}
                    tab="REQUIRE_ATTENTION"
                    maskDetails={props.maskDetails}
                    showAssignWindow={(bookingID) => props.setAssignBooking(bookingID)} />
            ))}

            {/* If there are no bookings to show */}
            {(bookings.length === 0) &&
                <tr>
                    <td colSpan={8}>
                        <small>There are no bookings that require attention at the moment</small>
                    </td>
                </tr>
            }
        </Table>
    );
}
import React, { useState, useEffect } from "react";

/**
 * UI components
 */
import Select from "../../ui/select/select";
import Button from "../../ui/button/button";

/**
 * Functional component to return a room
 */
function Room(props) {
    const [removing, setRemoving] = useState(false);
    const [roomType, setRoomType] = useState("");

    const [readyToSave, setReadyToSave] = useState(false);

    /**
     * Deconstruct the room and setRoom froms the props
     */
    const { index, room, update, remove } = props;

    /**
     * When the room details are updated
     */
    useEffect(() => {
        /**
         * Deconstruct the room details
         */
        const { room_type } = room;
        /**
         * Set the data into the state
         */
        setRoomType({ option: room_type || "" });
        /**
         * Update the state a second later to allow saving to the database
         */
        setTimeout(() => {
            setReadyToSave(true);
        }, 1000);
    }, [room]);

    /**
     * Update the room
     */
    const updateRoomOnHotel = async (option) => {
        /**
         * Update the state
         */
        setRoomType(option);
        /**
         * Then update the database with the new room details passed up through the props
         */
        await update(index, {
            room_type: option.option,
        });
    }

    /**
     * Remove the room from the hotel booking
     */
    const removeRoomFromHotel = async () => {
        /**
         * Show a spinner in the state
         */
        setRemoving(true);
        /**
         * Remove the room from the hotel via a function in the props
         */
        await remove(index);
        /**
         * Reset the state
         */
        setRemoving(false);
    }

    return (
        <>
            <tr>
                <td>Room #{index + 1} type</td>
                <td>
                    <Select
                        placeholder="Choose room type:"
                        value={roomType?.value}
                        selected={roomType?.option}
                        activeOnHover={true}
                        onSelect={(option) => updateRoomOnHotel(option)}
                        options={{
                            single_room: "Single Room",
                            twin_room: "Twin Room",
                            double_room: "Double Room",
                            triple_room: "Triple Room",
                            family_room: "Family Room",
                            one_bedroom_apartment: "One Bedroom Apartment",
                            two_bedroom_apartment: "Two Bedroom Apartment",
                            three_bedroom_villa: "Three Bedroom Villa",
                            four_bedroom_villa: "Four Bedroom Villa",
                            one_bedroom_suite: "One Bedroom Suite",
                            two_bedroom_suite: "Two Bedroom Suite",
                        }} />
                </td>
                <td class="vertical-align">
                    <Button
                        badge={true}
                        label="Remove"
                        className="OUTLINE"
                        loading={removing}
                        loadingText="Removing..."
                        onClick={() => removeRoomFromHotel()} />
                </td>
            </tr>
        </>
    );
}

export default Room;
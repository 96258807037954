import React from "react";
import "./navigation.scss";

/**
 * Functional component to return and inline navigation 
 */
function Navigation(props) {
    /**
     * Deconstruct the data needed from the props
     */
    const { links, active, onClick } = props;

    return (
        <div className={["ui-horizontal-nav", props.center && "is-center"].join(" ")}>
            {links.map((link) => (
                <p
                    key={link.id}
                    className={[
                        "ui-horizontal-nav-link",
                        (link.id === active) && "is-active",
                    ].join(" ")}
                    onClick={() => onClick(link.id)}>

                    {link.label}

                    {(link.count && link.count > 0) ?
                        <small>{link.count}</small> : ""
                    }
                </p>
            ))}
        </div>
    );
}

export default Navigation;
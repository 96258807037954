import React, { useEffect, useState } from "react"
import { db } from "../../utils/firebase"
import { NavLink } from "react-router-dom"
import moment from "moment"
import "./direct-booking.scss"

// UI components
import Button from "../ui/button/button"
import Badge from "../ui/badge/badge"
import Assign from "../../containers/bookings/assign/assign"

// Functional component to return a row for direct bookings
export default function DirectBooking({ id, details, maskDetails }) {
    const [agent, setAgent] = useState({})
    const [client, setClient] = useState({})
    const [resort, setResort] = useState({})
    const [confirming, setConfirming] = useState(false)
    const [assigning, setAssigning] = useState(false)
    const [checkingForBooking, setCheckingForBooking] = useState(true)
    const [hasConfirmedBooking, setHasConfirmedBooking] = useState(false)
    const [bookingID, setBookingID] = useState("")

    // Generate a string to represent the date_from date from the timestamps
    const dateFrom = moment(details.date_from?.seconds, "X").format("MMM D[,] YYYY")
    const dateBooked = moment(details.created?.seconds, "X").format("MMM D[,] YYYY")

    // On component load
    useEffect(() => {
        // Get the agent data from the database
        db.doc(`users/${details.agent}`)
            .get().then((agentDoc) => {
                // Set the agent data
                setAgent(agentDoc.data())
            })

        // Get the client data from the database
        db.doc(`clients/${details.client}`)
            .get().then((clientDoc) => {
                // Set the client data
                setClient(clientDoc.data())
            })

        // Get the resort data from the database
        db.doc(`CMS_holidays/${details.resort}`)
            .get().then((resortDoc) => {
                // Set the resort data
                setResort(resortDoc.data())
            })

        // Search for any bookings that match this one
        db.collection("bookings")
            .where("direct_booking_details.id", "==", id)
            .onSnapshot((bookingsSnap) => {
                // If there are any bookings found
                if (bookingsSnap.size > 0) {
                    // Set the has confirmed booking state to true
                    setHasConfirmedBooking(true)
                    setBookingID(bookingsSnap.docs[0].id)
                }

                // Toggle the loading state
                setCheckingForBooking(false)
            })
    }, [])

    // Confirm the bookin on the system and assign an agent
    const confirmBooking = () => {
        // Set the confirming state to true
        setConfirming(true)

        // Show the assign window
        setAssigning(true)

        // Reset the confirming state
        setConfirming(false)
    }

    // Cancel the booking confirmation
    const cancelConfirmBooking = () => {
        // Set the confirming state to false
        setConfirming(false)
        setAssigning(false)
    }

    return (
        <tr data-system-id={`${id}`}>
            <td>
                {agent?.first_name && `${agent.first_name} ${agent.last_name}`}
                {!agent?.first_name && <small className="no-value">No agent assigned</small>}
            </td>
            <td>
                {dateBooked}
            </td>
            <td>
                {!maskDetails && (
                    <NavLink to={`client/${details.client}`}>
                        {client.first_name && `${client.first_name} ${client.last_name}`}
                        {!client.first_name && <small className="no-value">No name set</small>}
                    </NavLink>
                )}

                {maskDetails && (
                    <>
                        {client.first_name && `${client.first_name} ${client.last_name}`}
                        {!client.first_name && <small className="no-value">No name set</small>}
                    </>
                )}
            </td>
            <td>
                {client?.phone}
            </td>
            <td>
                {resort?.title}
            </td>
            <td>
                {dateFrom}
            </td>
            <td>
                {details.group_size}
            </td>
            <td>
                £{Math.round(details.per_person_cost)}pp
            </td>
            <td>
                {details.paid_status === "PAID" && <Badge type="POSITIVE" label="Fully Paid" />}
                {details.paid_status === "PART_PAID" && <Badge type="AMBER" label="Partially Paid" />}
                {(!details.paid_status || details.paid_status === "NOT_PAID") && <Badge type="NEGATIVE" label="Not Paid" />}
            </td>
            <td className="is-button is-direct-booking">
                {(!checkingForBooking && !hasConfirmedBooking) &&
                    <Button
                        small
                        loading={confirming}
                        loadingText="Confirming"
                        label="Confirm booking"
                        onClick={() => confirmBooking()} />
                }

                {(!checkingForBooking && hasConfirmedBooking) &&
                    <NavLink to={`booking/${bookingID}`}>
                        View booking
                    </NavLink>
                }
            </td>

            {assigning &&
                <Assign
                    bookingID={id}
                    bookingDetails={details}
                    clientID={details.client}
                    close={() => cancelConfirmBooking()} />
            }
        </tr>
    );
}

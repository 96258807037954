// NavLink import for switching to the edit page
import { NavLink } from "react-router-dom";

// Returns a single table row item for a course
export default function CourseRow({ id, details }) {
    return (
        <tr>
            <td>{details?.title}</td>
            <td>{details?.url}</td>
            <td className="is-button">
                <NavLink to={`/manage/course/${id}`}>
                    Edit course
                </NavLink>
            </td>
        </tr>
    )
}
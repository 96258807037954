import React, { useEffect, useState } from "react";
import { PlusIcon } from "../../../utils/svgs";
import { db } from "../../../utils/firebase";
import firebase from "firebase";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Restaurant from "../../../components/partner/restaurant/restaurant";

/**
 * Functional component to return the nearby retaurants added for this partner
 */
export default function Nearby({ collection, id }) {
    const [adding, setAdding] = useState(false);
    const [nearbyPlaces, setNearbyPlaces] = useState([]);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the nearby collection for the partner
         */
        const unsubscribe = db.collection(`${collection}/${id}/nearby`)
            .onSnapshot((nearbySnap) => {
                /**
                 * Loop over the nearby found
                 */
                nearbySnap.docChanges().forEach((change) => {
                    /**
                     * Image document added
                     */
                    if (change.type === "added") {
                        setNearbyPlaces((nearbyPlaces) => [...nearbyPlaces, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    }
                    /**
                     * Image document updated
                     */
                    if (change.type === "modified") {
                        setNearbyPlaces((nearbyPlaces) => {
                            let updatedNearby = [...nearbyPlaces];
                            for (let i in nearbyPlaces) {
                                if (nearbyPlaces[i].id === change.doc.id) {
                                    updatedNearby[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedNearby;
                        });
                    }
                    /**
                     * Image document removed
                     */
                    if (change.type === "removed") {
                        setNearbyPlaces((nearbyPlaces) =>
                            nearbyPlaces.filter((nearbyPlace) => nearbyPlace.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * Add a new resturant to the partner collection
     */
    const addNewRestaurant = async () => {
        /**
         * Set the state
         */
        setAdding(true);
        /**
         * Add a new round document into the bookings "rounds" collection and return the ID for it
         */
        await db.collection(`${collection}/${id}/nearby`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setAdding(false);
    }

    return (
        <>
            <div className="add-table">
                <Button
                    badge={true}
                    label="Add restaurant"
                    icon={<PlusIcon />}
                    loading={adding}
                    loadingText="Adding restaurant..."
                    onClick={() => addNewRestaurant()} />
            </div>

            {nearbyPlaces.map((nearbyPlace) => (
                <Restaurant
                    id={id}
                    key={nearbyPlace.id}
                    collection={collection}
                    restaurantID={nearbyPlace.id}
                    details={nearbyPlace} />
            ))}
        </>
    );
}
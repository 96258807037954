import React, { useRef, useEffect, useState } from "react"
import Checkbox from "../checkbox/checkbox"
import "./select.scss"

/**
 * Caret for the side of the dropdown inputfield
 */
import { CaretDownIcon } from "../../../utils/svgs"

/**
 * Functional component for the select component multiple-select options. The selected options are stored
 * in the local state within the select box. When the options are updated the resulting array is passed
 * back up the props.
 *
 * @param {object} props Props passed into the select component
 * @returns HTML markup and functionality for the select component
 */
function MultipleSelect(props) {
	const [selected, setSelected] = useState([])
	const [visibleSelected, setVisibleSelected] = useState([])
	const [selectedIDs, setSelectedIDs] = useState([])
	const [showingDropdown, setShowingDropdown] = useState(false)

	/**
	 * Upper field ref
	 */
	const upperRef = useRef(null)

	/**
	 * Temporary placeholder ref
	 */
	const tempPlaceholderRef = useRef(null)
	const initialLoad = useRef(true)

	/**
	 * When the selected options are updated
	 */
	useEffect(() => {
		/**
		 * Update the internal array of just values for showing in the inputfield
		 */
		isSelected()
		/**
		 * Push the updates up the props
		 */
		props.onSelect(selected)
	}, [selected])

	useEffect(() => {
		if (initialLoad.current && props.selected) {
			props.selected.forEach((option) => {
				optionChecked({ option: option, value: props.options[option] })
			})
			initialLoad.current = false
		}
	}, [props.selected])

	/**
	 * Listen for a click outside the dropdown ref
	 *
	 * @type {const}
	 */
	const ClickedOutside = (dropdownRef) => {
		useEffect(() => {
			/**
			 * Check for the click to be outside the select field
			 */
			const checkForOutsideClick = (event) => {
				/**
				 * Is the click outside?
				 */
				if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
					/**
					 * Hide the dropdown
					 */
					setShowingDropdown(false)
				}
			}
			/**
			 * Bind on the component load
			 */
			document.addEventListener("mousedown", checkForOutsideClick)
			return () => {
				/**
				 * Unbind on component unload
				 */
				document.removeEventListener("mousedown", checkForOutsideClick)
			}
		}, [dropdownRef])
	}

	/**
	 * Dropdown ref
	 */
	const dropdownRef = useRef(null)
	/**
	 * Bind onto the click event
	 */
	ClickedOutside(dropdownRef)

	/**
	 * When an option is checked from from the dropdown
	 */
	const optionChecked = (option) => {
		/**
		 * Get the currently selected options
		 */
		const currentSelected = [...selected]
		/**
		 * Check to see if the sleected option is already in the selected arry
		 */
		const checkSelected = (obj) => obj.option === option.option
		/**
		 * Do we need to add or remove it from the selected array?
		 */
		if (currentSelected.some(checkSelected)) {
			/**
			 * Remove it from the array
			 */
			setSelected((selected) => selected.filter((selectedOption) => selectedOption.option !== option.option))
		} else {
			/**
			 * Add it to the array
			 */
			setSelected((selected) => [...selected, option])
		}
	}

	/**
	 * Check to see if an option is currently present in the selected array
	 *
	 * @type {const}
	 */
	const isSelected = () => {
		/**
		 * Get the width of the upper container
		 */
		const wrapperWidth = upperRef.current.getBoundingClientRect().width
		/**
		 * Then get the padding (removing the "px" value in the process)
		 */
		const wrapperPadding = window.getComputedStyle(upperRef.current).getPropertyValue("padding-left").replace("px", "")
		/**
		 * Then subtract that x2 from the width to get the inner width of the useable container
		 */
		let useableWidth = wrapperWidth - wrapperPadding * 2
		/**
		 * Init a new array to store the selected options (their "Option", not the "Value")
		 */
		const selectedOptions = []
		const visibleSelectedOptions = []
		/**
		 * Map over the selected options in the state
		 */
		selected.forEach((option, index) => {
			/**
			 * Add the option into the temporary placeholder to gauge the size required
			 */
			tempPlaceholderRef.current.innerHTML = option.value
			/**
			 * Then get the width of that element
			 */
			const placeholderWidth = tempPlaceholderRef.current.getBoundingClientRect().width
			/**
			 * Get the total size (thats the width plus the right margin required to fit in and the
			 * total count if there is going to be one)
			 */
			const requiredSpace = placeholderWidth + 5 + 28
			/**
			 * Does the required space fall inside the useable space available?
			 */
			if (useableWidth - requiredSpace > 0) {
				/**
				 * Subtract the space from the useableWidth
				 */
				useableWidth -= requiredSpace
				/**
				 * Push the selected option onto the showing array
				 */
				visibleSelectedOptions.push(option)
			}
			/**
			 * Show the visible ones in the state
			 */
			setVisibleSelected(visibleSelectedOptions)
			/**
			 * Push the option onto the new array to show
			 */
			selectedOptions.push(option.option)
		})
		/**
		 * Update the state
		 */
		return setSelectedIDs(selectedOptions)
	}

	/**
	 * Remove the selected option from the
	 *
	 * @type {const}
	 */
	const removeSelectedOption = (e, option) => {
		/**
		 * Stop the click bubbling up to the upper placeholder div
		 */
		e.stopPropagation()
		/**
		 * Remove it from the selected array
		 */
		setSelected((selected) => selected.filter((selectedOption) => selectedOption.option !== option.option))
	}

	/**
	 * Handler for when the total selected options number is clicked
	 *
	 * @type {const}
	 */
	const totalSelectedClicked = (e) => {
		/**
		 * Stop the click bubbling up to the upper placeholder div
		 */
		e.stopPropagation()
		/**
		 * Show the select dropdown
		 */
		setShowingDropdown(true)
	}

	return (
		<div
			className={["ui-select-wrapper", props.className, props.error && "has-error"].join(" ")}
			ref={dropdownRef}>
			{/* Placeholder to sit above the field when there is a value */}
			{(props.placeholder || props.label) && props.showLabel && <div className={["ui-input-upper-placeholder", selected.length > 0 && "active"].join(" ")}>{props.label || props.placeholder}</div>}

			{/* Input section of the input */}
			<div
				ref={upperRef}
				className={["ui-select-upper", selected.length > 0 && "has-value", showingDropdown && "dropdown-showing"].join(" ")}
				onClick={() => setShowingDropdown((showingDropdown) => !showingDropdown)}>
				{/* Section for the placeholder or selected options */}
				<p className="ui-select-placeholder">
					{/* If there are no options selected */}
					{selected.length === 0 && ((props.value && Object.values(props.value)[0]) || props.placeholder)}
					{/* If there are options selected */}
					{selected.length > 0 && (
						<span className="ui-multiple-selected-options-row">
							{/* Temporary placeholder to gauge size  */}
							<span
								ref={tempPlaceholderRef}
								className="ui-multiple-selected-option is-hidden"></span>

							{/* Selected options ready to display */}
							{Object.entries(visibleSelected).map(([index, option]) => (
								<span
									key={option.option}
									className="ui-multiple-selected-option"
									onClick={(e) => removeSelectedOption(e, option)}>
									{option.value}
								</span>
							))}

							{/* Count showing the selected options not displayed in the placeholder bar */}
							{visibleSelected.length > 0 && selected.length - visibleSelected.length > 0 && (
								<span
									className="ui-multiple-selected-option is-total"
									onClick={(e) => totalSelectedClicked(e)}>
									+{selected.length - visibleSelected.length}
								</span>
							)}

							{/* Count showing the selected options not displayed in the placeholder bar */}
							{visibleSelected.length === 0 && selected.length - visibleSelected.length > 0 && (
								<span
									className="ui-multiple-selected-option is-total"
									onClick={(e) => totalSelectedClicked(e)}>
									{selected.length - visibleSelected.length} Items Selected
								</span>
							)}
						</span>
					)}
				</p>

				{/* Dropdown chevron  */}
				<div className="ui-select-icon">
					<CaretDownIcon />
				</div>
			</div>

			{/* Select dropdown, visible once clicked */}
			<div className={["ui-select-dropdown", showingDropdown && "is-showing"].join(" ")}>
				{/* Print out all the options passed in */}
				{Object.entries(props.options).map(([option, value]) => (
					<div
						key={option}
						className="ui-multiple-select-option">
						<Checkbox
							label={value}
							checked={selectedIDs.includes(option)}
							onClick={() => optionChecked({ option, value })}
						/>
					</div>
				))}
			</div>

			{/* Is there an error or note to display */}
			{props.error ? <small>{props.error}</small> : props.note && <small>{props.note}</small>}
		</div>
	)
}

export default MultipleSelect

import React, { useState, useEffect } from "react"
import { db } from "../../utils/firebase"
import "./enquiry-assignments.scss"

// UI components
import Tile from "../../components/structure/tile/tile"
import Table from "../../components/structure/table/table"
import Title from "../../components/structure/title/title"
import EnquiryRow from "./_components/enquiry"

// Functional component to return the assignment history for all enquiries
export default function EnquiryAssignments() {
	const [loading, setLoading] = useState(true)
	const [enquiries, setEnquiries] = useState([])

	// On component load
	useEffect(() => {
		// Fetch the latest 200 deleted enquiries
		db.collection("LOGS_enquiries")
			.orderBy("created", "desc")
			.limit(1000)
			.get()
			.then((querySnapshot) => {
				const enquiries = []
				querySnapshot.forEach((doc) => {
					enquiries.push(doc.data())
				})
				setEnquiries(enquiries)
				setLoading(false)
			})
	}, [])

	return (
		<Tile fullPage={true}>
			<Title className="flex">
				<h1>Enquiry Assignments</h1>
			</Title>

			<Table
				className="enquiries-table"
				headings={["Site", "Client Name", "Location/Event", "Timestamp", "Type", "Action by", "From agent", "To agent"]}>
				{loading && (
					<tr>
						<td colSpan="8">Loading...</td>
					</tr>
				)}

				{!loading &&
					enquiries.map((enquiry, index) => (
						<EnquiryRow
							key={index}
							enquiry={enquiry}
						/>
					))}
			</Table>
		</Tile>
	)
}

import React, { useState, useEffect } from "react";
import { db } from "../../utils/firebase";
import firebase from "firebase"
import moment from "moment"
import "./client.scss";

/**
 * UI components and structure
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Navigation from "../../components/ui/navigation/navigation";

/**
 * Tabs
 */
import Quotes from "./tabs/quotes";
import Enquiries from "./tabs/enquiries";
import Bookings from "./tabs/bookings";
import Logs from "./tabs/logs";
import Button from "../../components/ui/button/button";

/**
 * Functional component to return a page details the client in question
 */
function Client(props) {
    const [activeTab, setActiveTab] = useState("ENQUIRIES");
    const [client, setClient] = useState({});
    const [stablefordPoints, setStablefordPoints] = useState("Calcuating")

    /**
     * Deconstruct the client ID from the url params
     */
    const { id } = props.match.params;

    /**
     * When the ID for the client
     */
    useEffect(() => {
        /**
         * Pull the client details down from the database
         */
        db.doc(`clients/${id}`)
            .get().then((clientDoc) => {
                /**
                 * Make sure teh client document exists
                 */
                if (clientDoc.exists) {
                    /**
                     * Push the client data into the state
                     */
                    setClient({ ...clientDoc.data() });
                }
            });

        // Get a timestamp for now
        const nowTimestamp = moment().startOf("day").valueOf()
        const nowTimestampMillis = firebase.firestore.Timestamp.fromMillis(nowTimestamp)

        // We then want to find all bookings for this client, where the check_in date is in the past
        db.collection("bookings")
            .where("client", "==", id)
            .where("check_in", "<=", nowTimestampMillis)
            .get().then((previousBookings) => {
                let pointsEarned = 0

                // For each booking
                previousBookings.forEach((booking) => {
                    // Pull some fields to check it's valid
                    const { confirmed, balance_outstanding, stableford } = booking.data()
                    console.log({ confirmed, balance_outstanding, stableford })

                    // Was it confirmed, and did the client pay the full balance?
                    if (balance_outstanding <= 0) {
                        // Add the points into the running total
                        pointsEarned = pointsEarned + Math.floor(stableford?.amount) || 0
                    }
                })

                // Then search against the ledger on the clients account
                db.collection(`clients/${id}/points_ledger`)
                    .get().then((ledgerDocs) => {
                        // If there are some docs returned
                        if (ledgerDocs.size > 0) {
                            let pointsUsed = 0

                            // For each of the ledger documents
                            ledgerDocs.forEach((ledgerDoc) => {
                                // Pull the points from the document
                                const { points, type } = ledgerDoc.data()

                                // If the ledger type is SPENT
                                if (type === "SPENT") {
                                    // Add the used points onto the total to remove
                                    pointsUsed = pointsUsed + points
                                }
                            })

                            // Set the resulting points into the state
                            setStablefordPoints(pointsEarned - pointsUsed)
                        } else {
                            // Set the resulting points into the state
                            setStablefordPoints(pointsEarned)
                        }
                    })
            })
    }, [id]);

    return (
        <Tile>
            <Title>
                <h1>{client?.first_name} {client?.last_name}</h1>
                <p>{client?.email} / {client?.phone}</p>
                <p>{stablefordPoints} Stableford Points</p>
            </Title>

            <Navigation
                links={[{
                    id: "QUOTES",
                    label: "Quotes",
                }, {
                    id: "ENQUIRIES",
                    label: "Enquiries",
                }, {
                    id: "BOOKINGS",
                    label: "Bookings",
                }, {
                    id: "LOGS",
                    label: "Logs",
                }]}
                active={activeTab}
                onClick={(tabID) => setActiveTab(tabID)} />

            {(activeTab === "QUOTES") && <Quotes clientID={id} />}

            {(activeTab === "ENQUIRIES") && <Enquiries clientID={id} />}

            {(activeTab === "BOOKINGS") && <Bookings clientID={id} />}

            {(activeTab === "LOGS") && <Logs clientID={id} />}
        </Tile>
    );
}

export default Client;
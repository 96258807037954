import { fun } from "../../../../utils/firebase"

// Import the cloud functions from firebase
const flightSearchRequest = fun.httpsCallable("flightSearchRequest")
const fetchAvailableSeats = fun.httpsCallable("fetchAvailableSeats")
const makeFlightBooking = fun.httpsCallable("makeFlightBooking")
const finaliseFlightBooking = fun.httpsCallable("finaliseFlightBooking")

export const searchFlights = async ({ bookingID, departure_airports, destination_airports, nights_override }) => {
	try {
		// Make the request into the cloud function
		const flights = await flightSearchRequest({
			bookingID,
			departure_airports,
			destination_airports,
			nights_override,
		})

		// Pull the resulting data
		const { result, XMLResponse = null, error = null } = flights?.data

		// If the result is unsuccessful
		if (!result || error !== null) return false

		// Otherwise parse the response
		const parser = new DOMParser()
		const XMLDoc = parser.parseFromString(XMLResponse, "text/xml")

		// Pull the session ID from the response
		const sessionID = XMLDoc.querySelector("SessionInfo").getAttribute("FABSessionId")

		// Pull the list of available flights
		const packageHolidays = XMLDoc.querySelectorAll("PackageHoliday")

		// Setup a new array to store the flight data
		const flightData = []

		// Iterate over the package holidays
		packageHolidays.forEach((holiday) => {
			// Get the flight data
			const flight = holiday.querySelector("Flight")

			// Write each of the flights into the array
			flightData.push({
				itineraryID: holiday.getAttribute("ItineraryId"),
				supplier: holiday.getAttribute("Supplier"),
				country: holiday.getAttribute("Country"),
				origin_airport: flight.getAttribute("OriginAirport"),
				destination_airport: flight.getAttribute("DestinationAirport"),
				lead_in_price: parseFloat(holiday.getAttribute("LeadInPricePerPassenger")),
				departure_date: flight.getAttribute("DepartureDate"),
				departure_time: flight.getAttribute("DepartureTime"),
				arrival_date: flight.getAttribute("ArrivalDate"),
				arrival_time: flight.getAttribute("ArrivalTime"),
				return_home_date: flight.getAttribute("ReturnHomeDate"),
				return_home_time: flight.getAttribute("ReturnHomeTime"),
				return_home_depart_date: flight.getAttribute("ReturnHomeDepartDate"),
				return_home_depart_time: flight.getAttribute("ReturnHomeDepartTime"),
				outbound_flight_number: flight.getAttribute("OutboundFlightNumber"),
				return_flight_number: flight.getAttribute("ReturnFlightNumber"),
				outbound_flight_base_price: parseFloat(flight.getAttribute("OutboundFlightBasePrice")),
				return_flight_base_price: parseFloat(flight.getAttribute("ReturnFlightBasePrice")),
				multiLeg_outbound_flight: flight.getAttribute("MultiLegOutboundFlight") === "true",
				multiLeg_return_flight: flight.getAttribute("MultiLegReturnFlight") === "true",
				outbound_journey_mins: parseInt(flight.getAttribute("OuboundJourneyMins")),
				return_journey_mins: parseInt(flight.getAttribute("ReturnJourneyMins")),
				outbound_num_stops: parseInt(flight.getAttribute("OutboundNumStops")) || 0,
				return_num_stops: parseInt(flight.getAttribute("ReturnNumStops")) || 0,
				outbound_cabin_class: flight.getAttribute("OutboundCabinClass"),
				return_cabin_class: flight.getAttribute("ReturnCabinClass"),

				// Create a new array storing the outbound flight information
				outbound_sub_segments: Array.from(flight.querySelectorAll("OutboundSubSegments")).map((segment) => {
					const attributes = segment.attributes
					const segmentData = {}

					// Iterate over attributes and include them
					for (let i = 0; i < attributes.length; i++) {
						const attribute = attributes[i]
						segmentData[attribute.name] = attribute.value
					}

					return segmentData
				}),

				// Create a new array storing the homebound flight information
				homebound_sub_segments: Array.from(flight.querySelectorAll("HomeboundSubSegments")).map((segment) => {
					const attributes = segment.attributes
					const segmentData = {}

					// Iterate over attributes and include them
					for (let i = 0; i < attributes.length; i++) {
						const attribute = attributes[i]
						segmentData[attribute.name] = attribute.value
					}

					return segmentData
				}),
			})
		})

		// Return the session ID and the flight data
		return {
			sessionID,
			flights: flightData,
		}
	} catch (err) {
		console.log(err)
		return false
	}
}

export const findAvailableSeats = async ({ bookingID, sessionID, itineraryID }) => {
	try {
		// Make the request into the cloud function
		const availableSeats = await fetchAvailableSeats({
			bookingID,
			sessionID,
			itineraryID,
		})

		// Pull the resulting data
		const { result, XMLResponse = null, error = null } = availableSeats?.data

		// If the result is unsuccessful
		if (!result || error !== null) return false

		// Otherwise parse the response
		const parser = new DOMParser()
		const XMLDoc = parser.parseFromString(XMLResponse, "text/xml")

		// Setup an array for storing any errors
		const errorCodes = []

		// Pull the errors from the XML
		const errorElements = XMLDoc.querySelectorAll("Error")

		// Map over the errors and store them in the array
		errorElements.forEach((error) => {
			errorCodes.push({
				code: error.getAttribute("ErrorCode"),
				message: error.getAttribute("ErrorText"),
			})
		})

		// If there are any errors
		if (errorCodes.length > 0) {
			res.send({
				result: false,
				errors: errorCodes,
			})
			return
		}

		// Pull the basketId from the CurrentBasket
		const basketID = XMLDoc.querySelector("CurrentBasket").getAttribute("BasketId")

		// Pull the seat options from the XML
		const seatOptionsElements = XMLDoc.querySelectorAll("SeatOptions")

		// Setup a new object for storing the available seats & rows
		const groupedSeats = {}

		// Iterate over the seat options
		seatOptionsElements.forEach((flightSeatsElement) => {
			// Pull the flight number
			const flightNumber = flightSeatsElement.getAttribute("FlightNumber")
			groupedSeats[flightNumber] = { seatTypes: {}, seats: [] }

			// Get SeatBands for this flight
			const seatBandElements = flightSeatsElement.querySelectorAll("SeatBand")
			seatBandElements.forEach((bandElement) => {
				const bandId = bandElement.getAttribute("Id")
				const description = bandElement.getAttribute("Description")
				const price = parseFloat(bandElement.getAttribute("Price"))

				groupedSeats[flightNumber].seatTypes[bandId] = {
					description,
					price,
					seats: [],
				}
			})

			// Get individual seats for this flight
			const seatElements = flightSeatsElement.querySelectorAll("Seat")
			seatElements.forEach((seatElement) => {
				const seatNum = seatElement.getAttribute("Num")
				const bandId = seatElement.getAttribute("BandId")
				const available = seatElement.getAttribute("Availabile") === "true"

				// Other fields you need:
				const row = seatElement.getAttribute("Row")
				const position = seatElement.getAttribute("Position")

				groupedSeats[flightNumber].seats.push({
					seatNum,
					bandId,
					available,
					row,
					position,
				})
			})
		})

		// Then grab a list of the available extras
		const optionsElements = XMLDoc.querySelectorAll("ItineraryOptions OptionalExtra")

		// Setup a new array for storing the extras
		const itineraryOptions = []

		// Iterate over the options and write them into the array
		optionsElements.forEach((optionElement) => {
			itineraryOptions.push({
				id: optionElement.getAttribute("ID"),
				description: optionElement.getAttribute("Description"),
				code: optionElement.getAttribute("Code"),
				direction: optionElement.getAttribute("Direction") || null,
				supplier: optionElement.getAttribute("Supplier"),
				type: optionElement.getAttribute("Type"),
				quantityAvailable: parseInt(optionElement.getAttribute("QuantityAvailable")),
				unitCost: parseFloat(optionElement.getAttribute("UnitCost")),
				currencyCode: optionElement.getAttribute("CurrencyCode"),
				applyTo: optionElement.getAttribute("ApplyTo"),
			})
		})

		// Setup an array of options that we'll want to show on the front-end
		const openExtas = ["LUS", "LUG", "SCB1", "SEC-GOLF"]

		// And filter out all the ones we don't want
		const filteredOptions = itineraryOptions.filter((option) => openExtas.includes(option.code))

		// Create an array of options to map to
		const extrasMapping = [
			{ code: "LUS", description: "Luggage - Small" },
			{ code: "LUG", description: "Luggage - Large" },
			{ code: "LUG:outbound", description: "Luggage - Large Outbound" },
			{ code: "LUG:homebound", description: "Luggage - Large Homebound" },
			{ code: "SCB1", description: "Seat Selection - Band 1" },
			{ code: "SEC-GOLF", description: "Golf Clubs" },
		]

		// Then map over the options and add in the descriptions
		const mappedOptions = filteredOptions.map((option) => {
			// Does this option have a direction attribute?
			if (option.direction) {
				// If so, append it to the code
				option.code = `${option.code}:${option.direction}`
			}

			const mapping = extrasMapping.find((map) => map.code === `${option.supplier}-${option.code}`)
			return { ...option, description: mapping?.description || option.description }
		})

		// Return the availabel seats and extras
		return {
			basketID: basketID,
			seats: groupedSeats,
			extras: mappedOptions,
		}
	} catch (err) {
		console.log(err)
		return false
	}
}

export const completeFlightBooking = async ({ bookingID, sessionID, basketID, itineraryID, flight }) => {
	try {
		// Make the request into the cloud function
		const bookingResponse = await makeFlightBooking({
			bookingID,
			sessionID,
			basketID,
			itineraryID,
			flight,
		})

		// Pull the resulting data
		const { result, XMLResponse = null, error = null } = bookingResponse?.data

		// If the result is unsuccessful
		if (!result || error !== null) return false

		// Otherwise parse the response
		const parser = new DOMParser()
		const XMLDoc = parser.parseFromString(XMLResponse, "text/xml")

		// Pull the total cost from the response
		const totalCost = XMLDoc.querySelector("ItineraryCost").getAttribute("TotalCost")

		// Return the basket ID and the total cost
		const finalisedBooking = await finaliseFlightBooking({
			sessionID,
			basketID,
			totalCost,
		})

		// Pull the resulting data
		const { result: finalResult, XMLResponse: finalXMLResponse = null, error: finalError = null } = finalisedBooking?.data

		// If the result is unsuccessful
		if (!finalResult || finalError !== null) return false

		// Parse the response
		const finalXMLDoc = parser.parseFromString(finalXMLResponse, "text/xml")

		// Get the booking stage
		const bookingStage = finalXMLDoc.querySelector("CurrentBasket").getAttribute("CurrentBookingStage")

		// Check for any errors
		const errorElements = finalXMLDoc.querySelectorAll("Error")

		// If there are any errors
		if (errorElements.length > 0) {
			const errorCodes = []

			// Map over the errors and store them in the array
			errorElements.forEach((error) => {
				errorCodes.push({
					code: error.getAttribute("ErrorCode"),
					message: error.getAttribute("ErrorText"),
				})
			})

			// Return the errors
			return {
				result: false,
				errors: errorCodes,
			}
		} else {
			// Return the response
			return {
				result: true,
				stage: bookingStage,
				totalCost,
			}
		}
	} catch (err) {
		console.log(err)
		return false
	}
}

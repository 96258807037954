import { layoutMultilineText, rgb, drawEllipse, popGraphicsState, pushGraphicsState, degrees, clip } from 'pdf-lib'
import moment from 'moment'

// Constants
import { BOARD_TYPES, ROOM_TYPES } from '../../../../assets/data/exports'
import { convertPNGtoJPG } from './images'

// Map over the string and replace all cases for the defined from/to adjustments
const processReplaceFields = (replaceFields, str) => {
  // Loop through each object in the array
  replaceFields.forEach(({ from, to }) => {
    // Create a regular expression to match all occurrences of the "from" field
    const regex = new RegExp(from, 'g')

    // Replace all occurrences of the "from" field with the "to" field in the string
    str = str.replace(regex, to)
  })

  // Return the modified string
  return str
}

// Write the introduction/summary paragraph
export const writeIntroduction = async ({ quotePDFDocument, agent, clientName, customIntroduction, introductionPage, quoteDetails, sizes: { height, width }, fonts: { poppins400, poppins600 }}) => {
    return new Promise(async (res, rej) => {
        // Setup a new array to store the paragraph strings
        const clientNameStringArr = []
        clientNameStringArr.push({ text: 'Dear ' , font: poppins400 })
        clientNameStringArr.push({ text: clientName.trim(), font: poppins600 })
        clientNameStringArr.push({ text: ',\n', font: poppins400 })

        // And another array for the agent sentence
        const agentStringArr = []
        agentStringArr.push({ text: 'Should you have any questions or queries at all, please ', font: poppins400 })
           
        if (agent.phone) {
            agentStringArr.push({ text: 'give me a call on ', font: poppins400 })
            agentStringArr.push({ text: agent.phone, font: poppins600 })
            agentStringArr.push({ text: ' or ', font: poppins400 })
        }
        
        agentStringArr.push({ text: 'email me directly at ', font: poppins400 })
        agentStringArr.push({ text: agent.email, font: poppins600 })
        agentStringArr.push({ text: '.', font: poppins400 })

        // Declare the font size here to make it easier to change
        const fontSize = 41

        // Absoloute max-width text can stretch to
        const maximumWidth = 1795

        // Calculate the x position for appending on font styles
        let agentX = (width - maximumWidth) / 2
        let clientNameX = (width - maximumWidth) / 2
        let centerX = (width - maximumWidth) / 2

        // Draw the text
        for (const clientNameStringPart of clientNameStringArr) {
            // Get the width of this particular string
            const itemisedStringWidth = clientNameStringPart.font.widthOfTextAtSize(clientNameStringPart.text, fontSize)

            // Write this part onto the page
            introductionPage.drawText(clientNameStringPart.text, { 
                x: clientNameX,
                y: height - 1100,
                size: fontSize,
                lineHeight: fontSize * 1.4,
                font: clientNameStringPart.font,
                maxWidth: maximumWidth,
                color: rgb(0, 0, 0),
            })

            // Increment the width up by this strings width
            clientNameX += itemisedStringWidth
        }

        // Split the custom intro down into paragraphs
        const introParagraphs = customIntroduction.split('\n')

        // Filter out the empty elements
        const filteredIntroParagraphs = introParagraphs.filter((paragraph) => paragraph !== '')

        // Keep a running figure of the y coordinate needed to move the text down
        let currentY = height - 1166 

        // Then we want to map over these paragraphs and write them onto the document
        filteredIntroParagraphs.map((paragraph) => {
            // Configure 
            const onMultipleLines = layoutMultilineText(paragraph, {
                font: poppins400,
                fontSize: fontSize,
                lineHeight: fontSize * 1.4,
                bounds: { width: maximumWidth }
            })

            // Get the height of one of the lines
            const singleLineHeight = onMultipleLines.lines[0].height

            // Calculate the height of this paragraph
            const paragraphHeight = (onMultipleLines.lines.length * (singleLineHeight)) + (singleLineHeight * 0.7)

            // Write the text onto the page
            introductionPage.drawText(paragraph, { 
                x: centerX,
                y: currentY,
                size: fontSize,
                lineHeight: fontSize * 1.4,
                font: poppins400, 
                maxWidth: maximumWidth,
                color: rgb(0, 0, 0),
            })

            // Adjust the Y position of the following pointer
            currentY = currentY - paragraphHeight
        })

        // Get a long string of the agent sentence for writing on within the max with bounds
        const agentSentenceString = agentStringArr.map((agentStringPart) => agentStringPart.text).join('')

        // Write the closing string for the agent to the stack
        introductionPage.drawText(agentSentenceString, { 
            x: centerX,
            y: currentY,
            size: fontSize,
            lineHeight: fontSize * 1.4,
            font: poppins400, 
            maxWidth: maximumWidth,
            color: rgb(0, 0, 0),
        })

        // Pull the packages and additional charges from the quote details object
        const { packages, additional_charges } = quoteDetails

        // Setup an array to store the summarised items
        const summarisedItemList = []

        // Now map over each of the packages found
        for (let i = 0; i < packages.length; i++) {
            // Get the package details
            const packageDetails = packages[i]

            // Are we looking at a resort package type?
            if (packageDetails.price_type === 'resort') {
                // Are there any additional nights?
                if (packageDetails.additional_nights_included && !isNaN(parseInt(packageDetails.additional_nights))) {
                    // Add the additional nights to the original and push the string into the array
                    summarisedItemList.push({
                        type: 'hotel',
                        item: `${parseInt(packageDetails.nights) + parseInt(packageDetails.additional_nights)} night stay at ${packageDetails.parent_title}`
                    })
                } else {
                    // Push the string into the array
                    summarisedItemList.push({
                        type: 'hotel',
                        item: `${packageDetails.nights} night stay at ${packageDetails.parent_title}`
                    })
                }

                // Are there any chosen courses available on this package as well?
                if (packageDetails.chosen_courses?.length > 0) {
                    // Return a string representing the courses in the given array
                    const getCoursesDescription = (chosenCoursesArr) => {
                        // Reduce the array down to unique entries and store a value for each count
                        const countMap = chosenCoursesArr.reduce((map, obj) => {
                            const name = obj.name
                            map[name] = (map[name] || 0) + 1
                            return map
                        }, {})

                        // Return the resulting string
                        return Object.entries(countMap).map(([name, count]) => `${count} x ${name}`).join(', ')
                    }

                    // Push the course descriptions into the array strings
                    summarisedItemList.push({
                        type: 'golf',
                        item: `${packageDetails.chosen_courses.length} round${packageDetails.chosen_courses.length > 1 ? 's' : ''} of golf (${getCoursesDescription(packageDetails.chosen_courses)}).`
                    })
                }
            }

            // Are we looking at a course package type?
            if (packageDetails.price_type === 'course') {
                // Push the different strings into the array
                summarisedItemList.push({
                    type: 'golf',
                    item: `${packageDetails.name} for ${packageDetails.parent_title}.`
                })
            }
        }

        // Are there any additional charges?
        if (additional_charges.airport_transfers.included) {
            summarisedItemList.push({ 
                type: 'transfer',
                item: 'Airport transfers',
            })
        }
        if (additional_charges.golf_transfers.included) {
            summarisedItemList.push({ 
                type: 'transfer',
                item: 'Golf transfers',
            })
        }
        if (additional_charges.flights.included) {
            summarisedItemList.push({ 
                type: 'flights',
                item: 'Flights',
            })
        }
        if (additional_charges.euro_tunnel.included) {
            summarisedItemList.push({ 
                type: 'euro_tunnel',
                item: 'Euro Tunnel',
            })
        }
        if (additional_charges.car_hire.included) {
            summarisedItemList.push({ 
                type: 'car_hire',
                item: 'Car Hire',
            })
        }

        // Add some healthy padding to the pad
        currentY -= 245

        // A smaller maximum width for the summarised items
        const smallerMaximumWidth = 1200

        // Fetch the various icons associated with different item types
        const hotelIconBuffer = await fetch('https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Fdocuments%2Fquoting%2Ficons%2Fhotel.png?alt=media&token=790b6ef2-ead2-4719-a243-ac9c68ed30b2').then((res) => res.arrayBuffer())
        const golfIconBuffer = await fetch('https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Fdocuments%2Fquoting%2Ficons%2Fgolf.png?alt=media&token=6c6de078-2551-4494-9a53-089322f3074c').then((res) => res.arrayBuffer())
        const transferIconBuffer = await fetch('https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Fdocuments%2Fquoting%2Ficons%2Ftransfer-drive.png?alt=media&token=0dbe8a86-f5e3-414d-ae32-9c2eba80b6b4').then((res) => res.arrayBuffer())
        const trainIconBuffer = await fetch('https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Fdocuments%2Fquoting%2Ficons%2Ftransfer-drive.png?alt=media&token=0dbe8a86-f5e3-414d-ae32-9c2eba80b6b4').then((res) => res.arrayBuffer())
        const planeIconBuffer = await fetch('https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Fdocuments%2Fquoting%2Ficons%2Fplane.png?alt=media&token=e68decc0-e7bf-4bd6-88b9-7d51ecaa673d').then((res) => res.arrayBuffer())

        // Embed the icons into the PDF
        const embeddedHotelIcon = await quotePDFDocument.embedPng(hotelIconBuffer)
        const embeddedGolfIcon = await quotePDFDocument.embedPng(golfIconBuffer)
        const embeddedTransferIcon = await quotePDFDocument.embedPng(transferIconBuffer)
        const embeddedTrainIcon = await quotePDFDocument.embedPng(trainIconBuffer)
        const embeddedPlaneIcon = await quotePDFDocument.embedPng(planeIconBuffer)

        // Write the summarised items onto the page
        summarisedItemList.map((paragraph) => {
            // Print the relevant icon onto the page
            if (paragraph.type === 'hotel') {
                introductionPage.drawImage(embeddedHotelIcon, {
                    x: centerX,
                    y: currentY - 7,
                    width: 50,
                    height: 50,
                })
            }
            if (paragraph.type === 'golf') {
                introductionPage.drawImage(embeddedGolfIcon, {
                    x: centerX + 4,
                    y: currentY - 7,
                    width: 42,
                    height: 50,
                })
            }
            if (paragraph.type === 'transfer') {
                introductionPage.drawImage(embeddedTransferIcon, {
                    x: centerX,
                    y: currentY - 12,
                    width: 50,
                    height: 40,
                })
            }
            if (paragraph.type === 'flights') {
                introductionPage.drawImage(embeddedPlaneIcon, {
                    x: centerX,
                    y: currentY - 12,
                    width: 50,
                    height: 40,
                })
            }
            if (paragraph.type === 'euro_tunnel') {
                introductionPage.drawImage(embeddedTrainIcon, {
                    x: centerX,
                    y: currentY - 12,
                    width: 50,
                    height: 40,
                })
            }
            if (paragraph.type === 'car_hire') {
                introductionPage.drawImage(embeddedTrainIcon, {
                    x: centerX,
                    y: currentY - 12,
                    width: 50,
                    height: 40,
                })
            }

            // Configure 
            const onMultipleLines = layoutMultilineText(paragraph.item, {
                font: poppins400,
                fontSize: fontSize,
                lineHeight: fontSize * 1.4,
                bounds: { width: smallerMaximumWidth }
            })

            // Get the height of one of the lines
            const singleLineHeight = onMultipleLines.lines[0].height

            // Calculate the height of this paragraph
            const paragraphHeight = (onMultipleLines.lines.length * (singleLineHeight)) + (singleLineHeight * 0.8)

            // Write the text onto the page
            introductionPage.drawText(paragraph.item, { 
                x: centerX + 96,
                y: currentY,
                size: fontSize,
                lineHeight: fontSize * 1.4,
                font: poppins400, 
                maxWidth: smallerMaximumWidth,
                color: rgb(0, 0, 0),
            })

            // Adjust the Y position of the following pointer
            currentY = currentY - paragraphHeight
        })

        // Close the promise out
        res(true)
    })
}

// Write the introduction/summary paragraph
export const writeItinerary = async ({ itineraryPage, quoteDetails, sizes: { height }, fonts: { poppins400, poppins600 }, replaceFields, offsetYear }) => {
    return new Promise((res, rej) => {
        // Pull some data from the quote details object
        const { packages, additional_charges, final_cost } = quoteDetails

        // Establish an array of quote packages to include
        const quotePackageStrings = []

        // Store a variable here for the total group size to calculate deposit down the line
        let runningGroupSize = 1

        // Let's setup a running total for the pricing setup
        let runningFinalTotal = final_cost

        // Subtract the additional charges from the running total
        if (additional_charges.airport_transfers.included && (parseInt(additional_charges.airport_transfers.cost) > 0)) {
            runningFinalTotal -= parseInt(additional_charges.airport_transfers.cost)
        }
        if (additional_charges.golf_transfers.included && (parseInt(additional_charges.golf_transfers.cost) > 0)) {
            runningFinalTotal -= parseInt(additional_charges.golf_transfers.cost)
        }
        if (additional_charges.flights.included && (parseInt(additional_charges.flights.cost) > 0)) {
            runningFinalTotal -= parseInt(additional_charges.flights.cost)
        }
        if (additional_charges.euro_tunnel.included && (parseInt(additional_charges.euro_tunnel.cost) > 0)) {
            runningFinalTotal -= parseInt(additional_charges.euro_tunnel.cost)
        }
        if (additional_charges.car_hire.included && (parseInt(additional_charges.car_hire.cost) > 0)) {
            runningFinalTotal -= parseInt(additional_charges.car_hire.cost)
        }

        // Step 1: Calculate the sum of all package costs
        const sumPackageCosts = packages.reduce((sum, pkg) => sum + pkg.total_cost, 0)

        // Step 2: Calculate the remaining amount
        const remainingAmount = runningFinalTotal - sumPackageCosts

        // Step 3: Calculate the adjustment per package
        const adjustmentPerPackage = Math.ceil(remainingAmount / packages.length)

        // Step 4: Adjust the prices of each package
        const adjustedPrices = packages.map(pkg => pkg.total_cost + adjustmentPerPackage)

        // Step 5: Calculate the sum of adjusted prices
        const sumAdjustedPrices = adjustedPrices.reduce((sum, price) => sum + price, 0)

        // Step 6: Adjust the last package if necessary to match the remaining cost
        const lastPackageIndex = packages.length - 1
        adjustedPrices[lastPackageIndex] += runningFinalTotal - sumAdjustedPrices

        // Step 7: Extract the adjusted prices into a separate array
        const adjustedPackageCosts = adjustedPrices.map(price => Math.round(price))

        // Now map over each of the packages found
        for (let i = 0; i < packages.length; i++) {
            // Setup an array to store strings for the eventual description
            const packageDescriptionArr = []

            // Get the package details
            const packageDetails = packages[i]

            // Are we looking at a resort package type?
            if (packageDetails.price_type === 'resort') {
                // Lookup the name for the chosen room type based on the key
                const chosenRoomTypeValue = ROOM_TYPES[packageDetails.chosen_room_type.option]

                // Get a readable version of the first included date as check-in
                const checkInString = moment(packageDetails.included_dates[0], 'DD-MM-YYYY').add(parseInt(offsetYear), 'years').format('dddd, MMMM Do YYYY')

                // Are there any additional nights?
                if (packageDetails.additional_nights_included && !isNaN(parseInt(packageDetails.additional_nights))) {
                    // Setup the holiday row string
                    const resortDetailsString = processReplaceFields(replaceFields, `${parseInt(packageDetails.nights) + parseInt(packageDetails.additional_nights)} night stay at ${packageDetails.parent_title} (${chosenRoomTypeValue}), checking in on ${checkInString}.`)
                    
                    // Add the additional nights to the original and push the string into the array
                    packageDescriptionArr.push(resortDetailsString)
                } else {
                    // Setup the holiday row string
                    const resortDetailsString = processReplaceFields(replaceFields, `${packageDetails.nights} night stay at ${packageDetails.parent_title} (${chosenRoomTypeValue}), checking in on ${checkInString}.`)
                    
                    // Push the string into the array
                    packageDescriptionArr.push(resortDetailsString)
                }

                // Are there any chosen courses available on this package as well?
                if (packageDetails.chosen_courses?.length > 0) {
                    // Return a string representing the courses in the given array
                    const getCoursesDescription = (chosenCoursesArr) => {
                        // Reduce the array down to unique entries and store a value for each count
                        const countMap = chosenCoursesArr.reduce((map, obj) => {
                            const name = obj.name
                            map[name] = (map[name] || 0) + 1
                            return map
                        }, {})

                        // Return the resulting string
                        return Object.entries(countMap).map(([name, count]) => `${count} x ${name}`).join(', ')
                    }

                    // Push the course descriptions into the array strings
                    packageDescriptionArr.push(`This includes ${packageDetails.chosen_courses.length} round${packageDetails.chosen_courses.length > 1 ? 's' : ''} of golf (${getCoursesDescription(packageDetails.chosen_courses)}).`)
                }

                // Are buggies included?
                if (packageDetails.buggies_included) {
                    packageDescriptionArr.push('Buggies are included.')
                }

                // Was there a board type chosen?
                if (packageDetails?.chosen_board_type && packageDetails?.chosen_board_type?.option) {
                    packageDescriptionArr.push(`${BOARD_TYPES[packageDetails.chosen_board_type.option]}.`)
                }
            }

            // Are we looking at a course package type?
            if (packageDetails.price_type === 'course') {
                // Push the different strings into the array
                packageDescriptionArr.push(`${packageDetails.name} for ${packageDetails.parent_title}.`)

                // Are buggies included?
                if (packageDetails.buggies_included) {
                    packageDescriptionArr.push('Buggies are included.')
                }
            }

            // Join this array together into a single string
            const packageDescriptionString = packageDescriptionArr.join(' ')

            // Push this string into the package strings array
            quotePackageStrings.push({ item: packageDescriptionString, quantity: packageDetails.group_size, price: `£${adjustedPackageCosts[i]}` })

            // Then parse the group size as an integer
            const groupSizeAsInt = parseInt(packageDetails.group_size)

            // Is it a valid integer, and it's greater than what is already being stored
            if (!isNaN(groupSizeAsInt) && groupSizeAsInt > runningGroupSize) {
                runningGroupSize = groupSizeAsInt
            }
        }
        
        // Are there any additional charges? Starting with airport transfers
        if (additional_charges.airport_transfers.included) {
            // Is the cost a valid integer?
            const isValidCost = parseInt(additional_charges.airport_transfers.cost) > 0

            quotePackageStrings.push({ 
                item: 'Airport transfers', 
                price: isValidCost ? `£${additional_charges.airport_transfers.cost}` : 'FOC'
            })
        }

        // Then golf transfers
        if (additional_charges.golf_transfers.included) {
            // Is the cost a valid integer?
            const isValidCost = parseInt(additional_charges.golf_transfers.cost) > 0

            quotePackageStrings.push({
                item: 'Golf transfers',
                price: isValidCost ? `£${additional_charges.golf_transfers.cost}` : 'FOC'
            })
        }

        // Then flights
        if (additional_charges.flights.included) {
            // Is the cost a valid integer?
            const isValidCost = parseInt(additional_charges.flights.cost) > 0

            quotePackageStrings.push({
                item: 'Flights',
                price: isValidCost ? `£${additional_charges.flights.cost}` : 'FOC'
            })
        }

        // Then euro tunnel
        if (additional_charges.euro_tunnel.included) {
            // Is the cost a valid integer?
            const isValidCost = parseInt(additional_charges.euro_tunnel.cost) > 0

            quotePackageStrings.push({
                item: 'Euro Tunnel',
                price: isValidCost ? `£${additional_charges.euro_tunnel.cost}` : 'FOC'
            })
        }

        // Then car hire
        if (additional_charges.car_hire.included) {
            // Is the cost a valid integer?
            const isValidCost = parseInt(additional_charges.car_hire.cost) > 0

            quotePackageStrings.push({
                item: 'Car Hire',
                price: isValidCost ? `£${additional_charges.car_hire.cost}` : 'FOC'
            })
        }

        // Declare the font size here to make it easier to change
        const fontSize = 38

        // Absoloute max-width text can stretch to
        // const maximumWidth = 1140
        const maximumWidth = 1320

        // Keep a running figure of the y coordinate needed to move the text down
        let currentY = height - 1424

        // We then move onto writing these strings onto the document
        quotePackageStrings.map((paragraph) => {
            // Configure 
            const onMultipleLines = layoutMultilineText(paragraph.item, {
                font: poppins400,
                fontSize: fontSize,
                lineHeight: fontSize * 1.45,
                bounds: { width: maximumWidth }
            })

            // Get the height of one of the lines
            const singleLineHeight = onMultipleLines.lines[0].height

            // Calculate the height of this paragraph
            const paragraphHeight = (onMultipleLines.lines.length * (singleLineHeight)) + (singleLineHeight * 0.85)

            // Write the text onto the page
            itineraryPage.drawText(paragraph.item, { 
                x: 340,
                y: currentY,
                size: fontSize,
                lineHeight: fontSize * 1.45,
                font: poppins400, 
                maxWidth: maximumWidth,
                color: rgb(0, 0, 0),
            })

            // Was there a quantity value specified
            // if (paragraph.quantity) {
            //     // Get the width of the string
            //     const quantityWidth = poppins400.widthOfTextAtSize(paragraph.quantity, fontSize)

            //     // Write onto the same row
            //     itineraryPage.drawText(paragraph.quantity, { 
            //         x: 1718 - quantityWidth,
            //         y: currentY,
            //         size: fontSize,
            //         lineHeight: fontSize * 1.45,
            //         font: poppins400, 
            //         color: rgb(0, 0, 0),
            //     })
            // }

            // Get the width of the price string
            const priceWidth = poppins400.widthOfTextAtSize(paragraph.price, fontSize)

            // Write onto the same row
            itineraryPage.drawText(paragraph.price, { 
                x: 2143 - priceWidth,
                y: currentY,
                size: fontSize,
                lineHeight: fontSize * 1.45,
                font: poppins400, 
                color: rgb(0, 0, 0),
            })

            // Adjust the Y position of the following pointer
            currentY = currentY - paragraphHeight - 45
        })

        // Add a bunch of padding to the bottom of the last item
        currentY -= 90

        // Once the packages themselves have been written on, we then need totals
        itineraryPage.drawText('Total (Per Person)', { 
            x: 340,
            y: currentY,
            size: 48,
            lineHeight: fontSize * 1.45,
            font: poppins400, 
            maxWidth: maximumWidth,
            color: rgb(0, 0, 0),
        })

        // Format the total cost into a number string
        const finalCostAsString = new Intl.NumberFormat('en-GB', { 
            style: 'currency', 
            currency: 'GBP',
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
        }).format(final_cost)

        // Total cost width
        const totalCostWidth = poppins600.widthOfTextAtSize(finalCostAsString, 56)
        itineraryPage.drawText(finalCostAsString, { 
            x: 2143 - totalCostWidth,
            y: currentY + 4,
            size: 56,
            lineHeight: fontSize * 1.45,
            font: poppins600, 
            maxWidth: maximumWidth,
            color: rgb(0, 0, 0),
        })

        // Some padding
        currentY -= 32

        // Seperator line before deposit text
        itineraryPage.drawLine({
            start: { x: 340, y: currentY },
            end: { x: 2143, y: currentY },
            thickness: 5,
            color: rgb(0.89, 0.12, 0.09),
            opacity: 0.6,
        })

        // Some padding
        currentY -= (50 + 5)

        // Once the packages themselves have been written on, we then need totals
        itineraryPage.drawText('Deposit (per person)', { 
            x: 340,
            y: currentY,
            size: 40,
            lineHeight: fontSize * 1.45,
            font: poppins400, 
            maxWidth: maximumWidth,
            color: rgb(0, 0, 0),
        })

        // Format the total deposit into a number string
        const depositCostAsString = new Intl.NumberFormat('en-GB', { 
            style: 'currency', 
            currency: 'GBP',
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
        }).format(50)

        // Total deposit cost width
        const totalDespositCostWidth = poppins600.widthOfTextAtSize(depositCostAsString, 40)
        itineraryPage.drawText(depositCostAsString, { 
            x: 2143 - totalDespositCostWidth,
            y: currentY + 4,
            size: 40,
            lineHeight: fontSize * 1.45,
            font: poppins600, 
            maxWidth: maximumWidth,
            color: rgb(0, 0, 0),
        })

        // Some padding
        currentY -= 44

        // Get the date in 2 weeks time
        const inTwoWeeks = moment().add(2, 'weeks').format('DD/MM/YYYY')

        // Write the deposit due date onto the page as well
        itineraryPage.drawText(`Due on ${inTwoWeeks}`, { 
            x: 340,
            y: currentY,
            size: 27,
            lineHeight: fontSize * 1.45,
            font: poppins400, 
            maxWidth: maximumWidth,
            color: rgb(0, 0, 0),
            opacity: 0.45
        })

        // Some padding
        currentY -= 44

        // Write the deposit due date onto the page as well
        itineraryPage.drawText("A £100 in total group deposit for 8 or more golfers can be taken in the first instance to secure availability. The full £50 deposit per person will then be required within 28 days.", { 
            x: 340,
            y: currentY,
            size: 27,
            lineHeight: fontSize * 1.2,
            font: poppins400, 
            maxWidth: 1200,
            color: rgb(0, 0, 0),
            opacity: 0.45
        })

        res(true)
    })
}

// Write the agent bios onto the page
export const writeAgentBio = async ({ quotePDFDocument, agent, agentBioPage, quoteDetails, sizes: { height, width }, fonts: { poppins400, poppins600, poppins800 }}) => {
    return new Promise(async (res, rej) => {
        // Get the width of the name for the hero image
        const refWidth = poppins800.widthOfTextAtSize(agent.name.toUpperCase(), 137)

        // Write their name across the top hero image
        agentBioPage.drawText(agent.name.toUpperCase(), {
            x: ((width - refWidth) - 8) / 2,
            y: height - 512,
            size: 137,
            font: poppins800,
            color: rgb(0, 0, 0),
            opacity: 0.14,
        })
        agentBioPage.drawText(agent.name.toUpperCase(), {
            x: (width - refWidth) / 2,
            y: height - 508,
            size: 136,
            font: poppins800,
            color: rgb(1, 1, 1),
        })

        // Keep a running figure of the y coordinate needed to move the text down
        let currentY = height - 1117

        // If there is a profile picture for this agent
        if (agent.picture && agent.picture.length > 0) {
            // Attempt to fetch and read the content/type header
            const getImageExtension = async (url) => {
                return fetch(url, { method: 'HEAD' }).then((response) => {
                    // Ready the contentType header on the response
                    const contentType = response.headers.get('content-type')

                    // Return the matching file extension
                    if (contentType.includes('image/jpeg')) {
                        return '.jpg'
                    } else if (contentType.includes('image/png')) {
                        return '.png'
                    } else {
                        throw new Error('Unsupported image format')
                    }
                })
            }

            // Get the file extension for the image
            const fileExtention = await getImageExtension(agent.picture)

            // Setup a variable for holding the resulting image buffer
            let profilePictureBuffer = ''

            // If we are returned with a PNG
            if (fileExtention === '.png') {
                // We need to attempt to convert this into a JPG format
                profilePictureBuffer = await convertPNGtoJPG(`${agent.picture}.png`)
            } else if (fileExtention === '.jpg') {
                // Just fetch the picture and return as an array buffer
                profilePictureBuffer = await fetch(`${agent.picture}.jpg`).then((res) => res.arrayBuffer())
            }

            // Embed the profile picture into the PDF document
            const embeddedProfilePicture = await quotePDFDocument.embedJpg(profilePictureBuffer)

            // Define the ellipse parameters
            const ellipseX = 338 + (414 / 2);  // X-coordinate of the ellipse center
            const ellipseY = (currentY + 95) - (414 / 2);  // Y-coordinate of the ellipse center
            const ellipseWidth = 200;  // Width of the ellipse
            const ellipseHeight = 200;  // Height of the ellipse
            
            // 
            agentBioPage.pushOperators(
                pushGraphicsState(),
                ...drawEllipse({
                    x: ellipseX,
                    y: ellipseY,
                    xScale: ellipseWidth,
                    yScale: ellipseHeight,
                    rotate: degrees(0),
                    borderColor: rgb(0, 0, 0),  // Border color (black)
                    borderWidth: 1,  // Border width (1 point)
                    opacity: 1,  // Opacity (fully opaque)
                    blendMode: 'Normal'  // Blend mode (normal)
                }),
                clip(),
            )
            
            // Draw the image onto the page
            agentBioPage.drawImage(embeddedProfilePicture, {
                x: ellipseX - ellipseWidth,
                y: ellipseY - ellipseHeight,
                width: ellipseWidth * 2,
                height: ellipseHeight * 2,
            })

            // 
            agentBioPage.pushOperators(popGraphicsState())
        }

        // Declare the font size here to make it easier to change
        const fontSize = 38

        // Absoloute max-width text can stretch to
        const maximumWidth = 1250

        // Split the bio down into paragraphs
        const bioParagraphs = agent.bio.split('\n')

        // Filter out the empty elements
        let filteredBioParagraphs = bioParagraphs.filter((paragraph) => paragraph !== '')

        // Add the speech marks surrounding the strings
        filteredBioParagraphs[0] = `“${filteredBioParagraphs[0]}`
        filteredBioParagraphs[filteredBioParagraphs.length - 1] = `${filteredBioParagraphs[filteredBioParagraphs.length - 1]}”`

        // Then we want to map over these paragraphs and write them onto the document
        filteredBioParagraphs.map((paragraph) => {
            // Configure 
            const onMultipleLines = layoutMultilineText(paragraph, {
                font: poppins400,
                fontSize: fontSize,
                lineHeight: fontSize * 1.4,
                bounds: { width: maximumWidth }
            })

            // Get the height of one of the lines
            const singleLineHeight = onMultipleLines.lines[0].height

            // Calculate the height of this paragraph
            const paragraphHeight = (onMultipleLines.lines.length * (singleLineHeight)) + (singleLineHeight * 0.35)

            // Write the text onto the page
            agentBioPage.drawText(paragraph, { 
                x: 870,
                y: currentY,
                size: fontSize,
                lineHeight: fontSize * 1.4,
                font: poppins400, 
                maxWidth: maximumWidth,
                color: rgb(0, 0, 0),
            })

            // Adjust the Y position of the following pointer
            currentY = currentY - paragraphHeight
        })

        // Some padding
        currentY -= 42

        // Seperator line before deposit text
        agentBioPage.drawLine({
            start: { x: 870, y: currentY },
            end: { x: 2120, y: currentY },
            thickness: 2,
            color: rgb(0.89, 0.12, 0.09),
            opacity: 0.6,
        })

        // Some padding
        currentY -= 125

        // Write the text onto the page
        agentBioPage.drawText('Ready to book?', { 
            x: 870,
            y: currentY,
            size: 48,
            lineHeight: 48 * 1.4,
            font: poppins600, 
            maxWidth: maximumWidth,
            color: rgb(0, 0, 0),
        })

        // Some padding
        currentY -= 75

        // Store an array of strings for the ready to book pararaph
        const readyToBookParagraphs = ['If you are happy with the quotation and wish to proceed with booking your trip, please contact me using the methods below.']

        // Store a variable here for the total group size to calculate deposit down the line
        let runningGroupSize = 1

        // Pull the packages from the quote details
        const { packages } = quoteDetails

        // Now map over each of the packages found
        for (let i = 0; i < packages.length; i++) {
            // Get the package details
            const packageDetails = packages[i]

            // Then parse the group size as an integer
            const groupSizeAsInt = parseInt(packageDetails.group_size)

            // Is it a valid integer, and it's greater than what is already being stored
            if (!isNaN(groupSizeAsInt) && groupSizeAsInt > runningGroupSize) {
                runningGroupSize = groupSizeAsInt
            }
        }

        // Get the date in 2 weeks time
        const inTwoWeeks = moment().add(2, 'weeks').format('DD/MM/YYYY')

        // Push the deposit string onto the array
        readyToBookParagraphs.push(`There will be a deposit of £50 per person, due ${inTwoWeeks}`)

        // Then we want to map over these paragraphs and write them onto the document
        readyToBookParagraphs.map((paragraph) => {
            // Configure 
            const onMultipleLines = layoutMultilineText(paragraph, {
                font: poppins400,
                fontSize: fontSize,
                lineHeight: fontSize * 1.4,
                bounds: { width: maximumWidth }
            })

            // Get the height of one of the lines
            const singleLineHeight = onMultipleLines.lines[0].height

            // Calculate the height of this paragraph
            const paragraphHeight = (onMultipleLines.lines.length * (singleLineHeight)) + (singleLineHeight * 0.35)

            // Write the text onto the page
            agentBioPage.drawText(paragraph, { 
                x: 870,
                y: currentY,
                size: fontSize,
                lineHeight: fontSize * 1.4,
                font: poppins400, 
                maxWidth: maximumWidth,
                color: rgb(0, 0, 0),
            })

            // Adjust the Y position of the following pointer
            currentY = currentY - paragraphHeight
        })

        // Some padding
        currentY -= 40

        // If there is a phone number available for this agent
        if (agent?.phone && typeof agent.phone !== 'undefined') {
            // Write the text onto the page
            agentBioPage.drawText('Call', { 
                x: 870,
                y: currentY,
                size: 48,
                lineHeight: 48 * 1.4,
                font: poppins600, 
                maxWidth: maximumWidth,
                color: rgb(0, 0, 0),
            })

            // Write the text onto the page
            agentBioPage.drawText('Email', { 
                x: 1300,
                y: currentY,
                size: 48,
                lineHeight: 48 * 1.4,
                font: poppins600, 
                maxWidth: maximumWidth,
                color: rgb(0, 0, 0),
            })
        } else {
            // If there is no phone available, write the text onto the page
            agentBioPage.drawText('Email', { 
                x: 870,
                y: currentY,
                size: 48,
                lineHeight: 48 * 1.4,
                font: poppins600, 
                maxWidth: maximumWidth,
                color: rgb(0, 0, 0),
            })
        }

        // Some padding
        currentY -= 57

        // If there is a phone number available for this agent
        if (agent?.phone && typeof agent.phone !== 'undefined') {
            // Write the text onto the page
            agentBioPage.drawText(agent.phone, { 
                x: 870,
                y: currentY,
                size: 40,
                lineHeight: 40 * 1.4,
                font: poppins600, 
                maxWidth: maximumWidth,
                color: rgb(0.89, 0.12, 0.09),
            })

            // Write the text onto the page
            agentBioPage.drawText(agent.email, { 
                x: 1300,
                y: currentY,
                size: 40,
                lineHeight: 40 * 1.4,
                font: poppins600, 
                maxWidth: maximumWidth,
                color: rgb(0.89, 0.12, 0.09),
            })
        } else {
            // If there is no phone available, write the text onto the page
            agentBioPage.drawText(agent.email, { 
                x: 870,
                y: currentY,
                size: 40,
                lineHeight: 40 * 1.4,
                font: poppins600, 
                maxWidth: maximumWidth,
                color: rgb(0.89, 0.12, 0.09),
            })
        }

        // Close the promise out
        res(true)
    })
}

// Write the PDF document headers
export const writeHeaders = async ({ details, quotePDFDocument, sizes: { height, width }, fonts: { poppins400, poppins600 }}) => {
    return new Promise((res, rej) => {
        // Get a list of the final pages on the quote document
        const finalPages = quotePDFDocument.getPages()
            
        // Map over each of these pages and write the reference and agent details
        for (let pageIndex = 0; pageIndex < finalPages.length; pageIndex ++) {
            // Get the width of the upper right reference 
            const refPreWidth = poppins600.widthOfTextAtSize('Reference:', 33)
            const refWidth = poppins600.widthOfTextAtSize(details.reference, 33)
        
            // Write it onto the page
            finalPages[pageIndex].drawText('Reference:', {
                x: (width - refWidth - refPreWidth) - 36,
                y: height - 55,
                size: 33,
                font: poppins400,
                color: rgb(0, 0, 0),
            })
            finalPages[pageIndex].drawText(details.reference, {
                x: (width - refWidth) - 30,
                y: height - 55,
                size: 33,
                font: poppins600,
                color: rgb(0, 0, 0),
            })
        }

        // Close the promise off
        res(true)
    })
}

// Write the PDF document footers
export const writeFooters = async ({ agent, quotePDFDocument, sizes: { width }, fonts: { poppins300, poppins600 }}) => {
    return new Promise(async (res, rej) => {
        // Get a list of the final pages on the quote document
        const finalPages = quotePDFDocument.getPages()
            
        // Map over each of these pages and write the reference and agent details
        for (let pageIndex = 0; pageIndex < finalPages.length; pageIndex ++) {
            const agentStringArr = [{ text: 'Contact ', font: poppins300 }]

            // Agent name
           agentStringArr.push({ text: agent.name, font: poppins600 })
           
           if (agent.phone) {
                agentStringArr.push({ text: ' on ', font: poppins300 })
                agentStringArr.push({ text: agent.phone, font: poppins600 })
                agentStringArr.push({ text: ' or ', font: poppins300 })
                agentStringArr.push({ text: agent.email, font: poppins600 })
            } else {
                agentStringArr.push({ text: ' by emailing ', font: poppins300 })
                agentStringArr.push({ text: agent.email, font: poppins600 })
            }
            
            agentStringArr.push({ text: ' to book', font: poppins300 })

            // Calculate the full width of the text
            const textWidth = agentStringArr.reduce((acc, part) => acc + part.font.widthOfTextAtSize(part.text, 36), 0)

            // Calculate the x position to center the text in the footer
            let x = (width - textWidth) / 2

            // Draw the text
            for (const agentStringPart of agentStringArr) {
                // Get the width of this particular string
                const itemisedStringWidth = agentStringPart.font.widthOfTextAtSize(agentStringPart.text, 36)

                // Write this part onto the page
                finalPages[pageIndex].drawText(agentStringPart.text, { 
                    x, 
                    y: 239,
                    size: 36,
                    font: agentStringPart.font,
                    color: rgb(0, 0, 0),
                })

                // Increment the width up by this strings width
                x += itemisedStringWidth
            }
        }

        // Close the promise off
        res(true)
    })
}

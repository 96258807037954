import React, { useState, useEffect, useContext } from "react";
import { BookingContext } from "../../../utils/providers/isolated-booking";
import { db } from "../../../utils/firebase";

/**
 * Carousel libary
 */
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

/**
 * UI components
 */
import Tile from "../../../components/structure/tile/tile";
import Title from "../../../components/structure/title/title";

/**
 * Functional component to return all the payments made against the booking
 */
export default function CheckInTimes() {
    const [nearbyLocations, setNearbyLocations] = useState([]);

    /**
     * Deconstruct the bookingID from the props
     */
    const { bookingID, hotelsOrder } = useContext(BookingContext);

    /**
     * When the hotel orders are update/loaded in
     */
    useEffect(() => {
        /**
         * If there is a hotel on the booking
         */
        if (hotelsOrder.length > 0) {
            /**
             * Loop over the various hotels on the booking
             */
            hotelsOrder.forEach((hotel) => {
                /**
                 * Pull the nearby collection for this hotel
                 */
                loadLocationDetails(hotel);
            });
        }
    }, [hotelsOrder]);

    /**
     * Load in the nearby location details
     */
    const loadLocationDetails = async (hotelID) => {
        /**
         * Pull the resort ID from the booking document
         */
        const destinationID = await db.doc(`bookings/${bookingID}/hotels/${hotelID}`)
            .get().then((hotelDoc) => {
                return hotelDoc.data().resortID;
            });
        /**
         * Pull the resort information from the hotel document
         */
        await db.doc(`hotels/${destinationID}`)
            .get().then((hotelDoc) => {
                /**
                 * Set the data into the state
                 */
                setNearbyLocations((nearbyLocations) => [...nearbyLocations, {
                    id: hotelDoc.id,
                    ...hotelDoc.data(),
                }]);
                /**
                 * Then fire off a function to find the images
                 */
                fetchImages(destinationID);
            });
    }

    /**
     * Fetch the images for a given nearby location
     */
    const fetchImages = async (resortID) => {
        db.collection(`hotels/${resortID}/images`)
            .get().then((imageDocs) => {
                /**
                 * Setup a new array for the images
                 */
                let imageURLs = [];
                /**
                 * Loop over the image documents for the hotel
                 */
                imageDocs.forEach((imageDoc) => {
                    imageURLs.push(imageDoc.data().public_url);
                });
                /**
                 * Set the images into the state
                 */
                setNearbyLocations((nearbyLocations) => {
                    let updatedLocations = [...nearbyLocations];
                    for (let i in nearbyLocations) {
                        if (nearbyLocations[i].id === resortID) {
                            updatedLocations[i] = {
                                ...updatedLocations[i],
                                images: imageURLs,
                            };
                            break;
                        }
                    }
                    return updatedLocations;
                });
            });
    }

    return (
        <Tile className="fit-width center" includeBackButton={true}>
            <Title center={true} isCardTitle={true}>
                <h1>Check In/Out Times</h1>
                <p>Check in/out times for the hotels associated with your booking, please make sure to confirm with the hotel before arrival to ensure you check in on time.</p>
            </Title>

            {(nearbyLocations.length === 0) &&
                <p className="check-with-agent">Please contact your agent for check in times</p>
            }

            {(nearbyLocations.length > 0) &&
                <>
                    {/* Loop through any payments in the state to print */}
                    <div className="booking-payments-cover">
                        <table className="booking-payments check-ins">
                            <thead>
                                <tr>
                                    <th>Hotel name</th>
                                    <th>Check in</th>
                                    <th>Check out</th>
                                </tr>
                            </thead>
                            <tbody>
                                {nearbyLocations.map((location) => (
                                    <tr key={location.id}>
                                        <td>{location.name}</td>
                                        <td>{location.check_in}</td>
                                        <td>{location.check_out}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="nearby-places-slider with-margin">
                        <Carousel showThumbs={false} showStatus={false}>
                            {/* Loop through the nearby locations in the state */}
                            {nearbyLocations.map((location) => (
                                <div className="nearby-place">
                                    <div className="nb-title">
                                        <p>{location.name}</p>
                                    </div>

                                    {(location.images?.length > 0) &&
                                        <img src={location.images[0]} alt={location.name} />
                                    }
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </>
            }
        </Tile>
    );
};
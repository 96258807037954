import { db } from "../../../../utils/firebase"
import { useState, useEffect } from "react"
import moment from "moment"

export default function NoteLog({ agentID, logDetails }) {
    const [name, setName] = useState("")

    useEffect(() => {
        db.doc(`users/${agentID}`)
            .get().then((agentDoc) => {
                const { first_name, last_name } = agentDoc.data()
                setName(`${first_name} ${last_name}`)
            })
    }, [])

    return (
        <div className="booking__historical-item">
            <div className="booking__historical-item__date">
                {moment(logDetails.created?.seconds, "X").format("D MMM YYYY, HH:mm")}
            </div>
            <div className="booking__historical-item__user">
                {name}
            </div>
            <div className={`booking__historical-item__notes ${logDetails.agent_notes?.length === 0 ? `is-empty` : ``}`}>
                {logDetails.agent_notes}
            </div>
        </div>
    )
}
import styles from "./passenger.module.scss"
import { db } from "../../../../../../utils/firebase"
import { useState, useEffect } from "react"

// UI components
import Checkbox from "../../../../../../components/ui/checkbox/checkbox"
import Button from "../../../../../../components/ui/button/button"
import Input from "../../../../../../components/ui/inputs/input"
import Badge from "../../../../../../components/ui/badge/badge"

// Returns the HTML markup for a passenger
export default function Passenger({ bookingID, passenger, editing, index, toggleEdit, toggleSelect, flightNumbers, activeFlightNumber, extras, chosenSeat, clearChosenSeat, canChooseSeat }) {
	const { id: passengerID, lead_booker, title, first_name, middle_initials, last_name, legs } = passenger

	const [complete, setComplete] = useState(false)
	const [saving, setSaving] = useState(false)
	const [chosenExtras, setChosenExtras] = useState(legs ? (legs[activeFlightNumber]?.extras ? legs[activeFlightNumber]?.extras : []) : [])
	const [newSeat, setNewSeat] = useState(chosenSeat ? chosenSeat : legs ? legs[activeFlightNumber]?.seat : null)

	useEffect(() => {
		setNewSeat(chosenSeat ? chosenSeat : legs ? legs[activeFlightNumber]?.seat : null)
	}, [activeFlightNumber, chosenSeat])

	useEffect(() => {
		setComplete(() => {
			if (!legs || Object.keys(legs)?.length !== flightNumbers.length) return false

			return Object.keys(legs).every((leg) => legs[leg].seat)
		})
	}, [passenger])

	const savePassenger = async () => {
		setSaving(true)
		toggleSelect(null)

		await db.doc(`bookings/${bookingID}/passengers/${passengerID}`).set(
			{
				legs: {
					[activeFlightNumber]: {
						seat: newSeat || null,
					},
				},
			},
			{ merge: true }
		)

		// Map over each of the flight numbers
		flightNumbers.map((flightNumber) => {
			// Add the chosen extras array in for each
			db.doc(`bookings/${bookingID}/passengers/${passengerID}`).set(
				{
					legs: {
						[flightNumber]: {
							extras: chosenExtras || null,
						},
					},
				},
				{ merge: true }
			)
		})

		clearChosenSeat()
		setSaving(false)
	}

	return (
		<div className={styles.passenger}>
			<div className={styles.header}>
				<div className={styles.details}>
					<p className={styles.passengerNumber}>
						Passenger {index + 1}
						{lead_booker ? ` - Lead Booker` : ""}
					</p>
					<div className={styles.name}>
						{first_name && (
							<p>
								{title ? `${title} ` : ""}
								{first_name}
								{middle_initials ? ` ${middle_initials} ` : " "}
								{last_name}
							</p>
						)}

						<Badge
							label={!canChooseSeat ? "Complete" : complete ? "Complete" : "Incomplete"}
							type={!canChooseSeat ? "POSITIVE" : complete ? "POSITIVE" : "AMBER"}
						/>
					</div>
				</div>

				<div className={styles.actions}>
					{editing && (
						<Button
							label="Save"
							small={true}
							loading={saving}
							loadingText="Saving..."
							onClick={() => savePassenger()}
						/>
					)}

					{!editing && (
						<>
							<Button
								type="secondary"
								label="Edit"
								small={true}
								onClick={() => {
									toggleEdit()
									toggleSelect(passengerID)
								}}
							/>
						</>
					)}
				</div>
			</div>

			<div className={[styles.edit, editing ? styles.isShowing : ""].join(" ")}>
				<div className={styles.fields}>
					<Input
						type="text"
						placeholder={newSeat ? "Seat Number" : "Select Seat"}
						showLabel={true}
						value={newSeat}
						readOnly={true}
					/>

					{extras?.map((extra) => (
						<Checkbox
							label={`${extra.description} (£${extra.unitCost})`}
							onClick={() => {
								if (chosenExtras?.includes(extra.code)) {
									setChosenExtras((prev) => prev.filter((item) => item !== extra.code))
								} else {
									setChosenExtras((prev) => [...prev, extra.code])
								}
							}}
							checked={chosenExtras?.includes(extra.code)}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

import React, { useEffect, useState, createContext } from "react";
import { db, auth } from "../firebase";
import firebase from "firebase";
import moment from "moment";

/**
 * Log the user in with the provided credentials
 *
 * @param {string} username
 * @param {string} password
 */
const loginRequest = async (username, password) => {
    return firebase
        .auth()
        .signInWithEmailAndPassword(username, password)
        .then(() => {
            return {
                authenticated: true
            };
        }).catch((error) => {
            return error;
        });
};

/**
 * Log the user out
 */
const logoutRequest = () => {
    firebase
        .auth()
        .signOut()
        .then(() => {
            window.location.href = "/";
        });
};

/**
 * Setup a context for storing the user object
 */
const AuthContext = createContext();

/**
 * Creating the auth provider to wrap the application in for providing the user object
 *
 * @param {Object} params
 * @returns Authentication provider to wrap the application in
 */
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        /**
         * Set a loading boolean
         */
        setUser({
            loading: true,
            logged_in: false,
        });
        /**
         * Listen for changes on the auth
         */
        const unsubscribe = auth.onAuthStateChanged((user) => {
            /**
             * Get the last sign in date for the user
             */
            const { lastSignInTime } = user?.metadata || {};
            /**
             * Get the unix timestamp for the last sign in time
             */
            const lastSignIn = moment(lastSignInTime).valueOf();
            /**
             * Get the unix timestamp for 8 hours ago
             */
            const sessionTimeout = moment().subtract(8, "hours").valueOf();
            /**
             * If the sign in time is older than the session timeout, we need to log the user 
             * out and have them re-login in
             */
            if (lastSignIn < sessionTimeout) {
                logoutRequest();
            } else {
                if (user) {
                    db.doc(`users/${user.uid}`).onSnapshot((userSnap) => {
                        setUser({
                            uid: user.uid,
                            logged_in: true,
                            ...userSnap.data(),
                            loading: false,
                        });
                    });
                } else {
                    setUser({
                        logged_in: false,
                        loading: false,
                    });
                }
            }
        });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

export {
    loginRequest,
    logoutRequest,
    AuthContext,
    AuthProvider
};
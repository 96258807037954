import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../utils/providers/auth";
import { db } from "../../utils/firebase";
import "./cancellations.scss";

/**
 * Loading icon
 */
import { LoadingIcon } from "../../utils/svgs";

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Table from "../../components/structure/table/table";
import ViewCancellation from "../../components/cancellation/view/view";
import Cancellation from "../../components/cancellation/cancellation";

/**
 * Functional component to return the booking cancellations
 */
export default function Cancellations() {
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [viewingCancellation, setViewingCancellation] = useState("");

    // Pull some data from the auth context
    const { user } = useContext(AuthContext);

    /**
     * On component load, pull in the required bookings from the database
     */
    useEffect(() => {
        /**
         * Show a loading spinner over the top
         */
        setLoading(true);
        /**
         * Pull the cancellations from the database
         */
        db.collection("cancellations")
            .orderBy("cancelled_at", "desc")
            .get().then((cancellationDocs) => {
                /**
                 * Setup a new array for storing the cancelled bookings
                 */
                let searchCancellations = [];
                /**
                 * Loop through all the cancellations
                 */
                cancellationDocs.forEach((cancellationDoc) => {
                    /**
                     * Push this cancellation into the state
                     */
                    searchCancellations.push({ id: cancellationDoc.id, ...cancellationDoc.data() });
                });
                /**
                 * Set these cancellations into the state
                 */
                setBookings(searchCancellations);
                /**
                 * And then set the loading state back to false to show them
                 */
                setLoading(false);
            });
    }, []);

    return (
        <Tile fullPage={true}>
            <Title className="flex has-select-field">
                <h1>Booking cancellations</h1>
            </Title>

            <Table
                className="bookings-table"
                headings={[
                    "Name",
                    "Agent assigned",
                    "Booking margin",
                    "Cancelled on",
                    "Original travel date",
                    "Confirmed with resort",
                    "",
                ]}
                noResults={bookings.length === 0}
                noResultsMessage="No cancellations to manage">

                {bookings.map((cancellation) => (
                    <Cancellation
                        id={cancellation.id}
                        key={cancellation.id}
                        details={cancellation}
                        maskDetails={user?.mask_details}
                        showCancellationWindow={(cancellationID) => setViewingCancellation(cancellationID)} />
                ))}

                {loading &&
                    <div className="table-loading-splash">
                        <div className="loading-wrapper">
                            <p>Loading...</p>
                            <div className="svg-loading-wrap">
                                <LoadingIcon />
                            </div>
                        </div>
                    </div>
                }
            </Table>

            {viewingCancellation &&
                <ViewCancellation
                    cancellationID={viewingCancellation}
                    close={() => setViewingCancellation("")} />
            }
        </Tile>
    );
}
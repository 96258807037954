import React, { useContext, useState, useEffect } from "react";
import { BookingContext } from "../../../../../utils/providers/isolated-booking";
import { db } from "../../../../../utils/firebase";
import firebase from "firebase";

/**
 * UI components
 */
import Input from "../../../../../components/ui/inputs/input";
import Button from "../../../../../components/ui/button/button";
import Upload from "../../../../../components/itinerary/upload/upload";
import { PlusIcon, TimesIcon } from "../../../../../utils/svgs";

/**
 * Functional component to return the booking transfers panel
 */
function Transfers() {
    const [uploading, setUploading] = useState(false);
    const [flightImages, setFlightImages] = useState([]);

    /**
     * Deconstruct data from the booking context
     */
    const {
        bookingID,
        client,
        transfer,
    } = useContext(BookingContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the "images" subcollection for this booking
         */
        const unsubscribe = db.collection(`bookings/${bookingID}/images`)
            .onSnapshot((imagesSnapshot) => {
                /**
                 * Loop over the images found
                 */
                imagesSnapshot.docChanges().forEach((change) => {
                    /**
                     * Image document added
                     */
                    if (change.type === "added") {
                        setFlightImages((images) => [...images, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    }
                    /**
                     * Image document updated
                     */
                    if (change.type === "modified") {
                        setFlightImages((images) => {
                            let updatedImages = [...images];
                            for (let i in images) {
                                if (images[i].id === change.doc.id) {
                                    updatedImages[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedImages;
                        });
                    }
                    /**
                     * Image document removed
                     */
                    if (change.type === "removed") {
                        setFlightImages((images) =>
                            images.filter((enquiry) => enquiry.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on component unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * When clicked, remove the image 
     */
    const removeFlightImage = async (e, id) => {
        /**
         * Stop the click from the span tag bubbling up to the a tag
         */
        e.stopPropagation();
        /**
         * Remove the flight image document from the subcollection
         */
        await db.doc(`bookings/${bookingID}/images/${id}`).delete();
        /**
         * Then add a log regarding the client uploading an iamge
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            type: "FLIGHT_DETAILS_REMOVED",
            message: "Flight details were removed by the client",
            user: null,
            badge: "INFO",
        });
    }

    return (
        <>
            <table className="booking-table">
                <tbody>
                    <tr>
                        <td>Transfer name</td>
                        <td>
                            <Input
                                type="text"
                                value={transfer?.name}
                                placeholder="No transfer partner chosen yet"
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Flight detail screenshots</td>
                        <td className="vertical-center">
                            <Button
                                icon={<PlusIcon />}
                                label="Upload"
                                badge={true}
                                onClick={() => setUploading(true)} />
                        </td>
                    </tr>
                    {(Object.entries(flightImages).length > 0) &&
                        <tr>
                            <td></td>
                            <td>
                                {flightImages.map((image) => (
                                    <div
                                        key={image.id}
                                        className="flight-details-image-thumb" >
                                        <a href={image.public_url}
                                            target="_blank">
                                            <img src={image.public_url} alt="Flight details" />
                                        </a>

                                        <span onClick={(e) => removeFlightImage(e, image.id)}>
                                            <TimesIcon />
                                        </span>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>

            {uploading &&
                <Upload
                    bookingID={bookingID}
                    clientID={client.id}
                    close={() => setUploading(false)} />
            }
        </>
    );
}

export default Transfers;
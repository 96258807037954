import React, { useState, useEffect } from "react";
import { auth, db, storage } from "../../../utils/firebase";
import firebase from "firebase";

/**
 * UI components and structure
 */
import File from "../../ui/inputs/file";
import Window from "../../structure/window/window";

/**
 * Required to generate the ID for the new content document
 */
const random = require("randomstring");

/**
 * Functional component to return all the payments made against the booking
 */
export default function Upload(props) {
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [fileErrors, setFileErrors] = useState(0);
    const [error, setError] = useState("");

    /**
     * Deconstruct the collection anme and partner ID from the props
     */
    const { collection, id } = props;

    /**
     * When the uploadFiles array is updated
     */
    useEffect(() => {
        /**
         * Init a 0 value for the errors
         */
        let errors = 0;
        /**
         * Loop through the files ready for upload
         */
        uploadFiles.forEach((uploadFile) => {
            /**
             * If there is an error message present
             */
            if (uploadFile.error?.message) {
                /**
                 * Increment the errors
                 */
                errors++;
            }
        });
        /**
         * Set the new errors value to the state for toggling the button
         */
        setFileErrors(errors);
    }, [uploadFiles]);

    /**
     * Check the images chosen by the user for some requirements
     */
    const checkChosenFiles = (files) => {
        /**
         * Set the state
         */
        setError("");
        setUploading(true);
        /**
         * Check for an empty array
         */
        if (files.length > 0) {
            /**
             * Loop over the files
             */
            for (const [index, file] of Object.entries(files)) {
                /**
                 * Don't push this index becasue it'll only be holding the file count
                 */
                if (index !== "length") {
                    /**
                     * Generate a new ID for using on the document references for this file
                     */
                    const uploadID = random.generate(20);
                    /**
                     * Strip everything from the name to just keep letters
                     */
                    let cleanName = file.name.match(/[a-zA-Z]+/g);
                    let fileID = `${cleanName}${file.size}`;
                    let fileUploadTime = file.lastModified;
                    let size = file.size;
                    /**
                     * Add an ID and an errors object to the file
                     */
                    let newFile = {
                        id: fileID,
                        title: file.name,
                        uploadID,
                        error: {},
                        file,
                        upload: {},
                    };
                    /**
                     * Check if this file already exists
                     */
                    uploadFiles.forEach((file) => {
                        if (file.id === fileID) {
                            newFile.id = `${fileID}${fileUploadTime}`;
                            newFile.error = {
                                message: "You've already added this file to the upload.",
                            };
                        }
                    });
                    /**
                     * Check for a valid file format
                     */
                    const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
                    if (!allowedFormats.includes(file.type)) {
                        newFile.error = {
                            message: "This file format is not supported.",
                        };
                    }
                    /**
                     * Check the size of the file to make sure it's below an acceptable limit of 5mb
                     */
                    const sizeInMB = size / 1024 / 1024;
                    if (sizeInMB > 5) {
                        newFile.error = {
                            message: "Please ensure your file is below 5MB.",
                        };
                    }
                    /**
                     * Make sure there were no error appended onto this file
                     */
                    if (!newFile.error?.message) {
                        /**
                         * Push the files object to the array for sending to state
                         */
                        setUploadFiles((uploadFiles) => [...uploadFiles, newFile]);
                        /**
                         * Upload the file into the storage bucket
                         */
                        handleFileUpload(file);
                    } else {
                        /**
                         * If there is an error, cancel the uploading state
                         */
                        setUploading(false);
                        /**
                         * Push the errors into the state
                         */
                        setError(newFile.error?.message);
                    }
                }
            }
        }
    }

    /**
     * Handles the uploading of the file into the storage bucket
     */
    const handleFileUpload = (file) => {
        /**
         * Generate a new ID for using on the document references for this file
         */
        const uploadID = random.generate(20);
        /**
         * Build out some metadata for the image
         */
        const meta = {
            customMetadata: {
                partnerID: id,
                fileID: uploadID,
                uploadedBy: auth.currentUser.uid,
            },
        };
        /**
         * Move the file into firebase storage
         */
        const upload = storage.ref(`${collection}/${id}/${uploadID}`).put(file, meta);
        /**
         * Return a promise listening to the upload progress
         */
        return new Promise((resolve, reject) => {
            /**
             * Setup the monitor for the file
             */
            monitorUploadProgress(upload, file.id).then(() => {
                /**
                 * When it's complete, get the downloadURL from the callback
                 */
                upload.snapshot.ref.getDownloadURL().then(async (url) => {
                    /**
                     * Add the image as a document for the partner
                     */
                    await db.doc(`${collection}/${id}/images/${uploadID}`).set({
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        public_url: url,
                    }, { merge: true });
                    /**
                     * Then close the modal
                     */
                    props.close();
                    /**
                     * Resolve the promise
                     */
                    resolve(url);
                });
            });
        });
    };

    /**
     * Attach a listener onto the upload job to stream the progress into state
     */
    const monitorUploadProgress = (upload, fileID) => {
        return new Promise((resolve, reject) => {
            /**
             * Setup a listener on the upload process
             */
            upload.on("state_changed", (snapshot) => {
                /**
                 * Work out a progress percentage
                 */
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                /**
                 * Update the state with the progress of this file
                 */
                setUploadProgress(progress);
            }, (error) => console.log(error), () => {
                resolve();
            });
        });
    };

    return (
        <Window
            title="Upload partner images"
            className="slim upload-flight-details"
            close={() => props.close()}>
            {/* If we are not uploading anything yet, show the file input */}
            {!uploading &&
                <File
                    multiple={true}
                    placeholder="Choose file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(files) => checkChosenFiles(files)} />
            }

            {/* Show the uploading progress */}
            {uploading &&
                <small>Uploading.... {uploadProgress}%</small>
            }

            {/* Is there an error that we need to show in the modal? */}
            {error &&
                <small className="upload-error">{error}</small>
            }
        </Window>
    );
}
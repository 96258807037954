import React, { useContext } from "react";
import { AuthContext } from "./providers/auth";
import { useLocation } from "react-router";

/**
 * App context providers
 */
import AppContexts from "./contexts";

/**
 * UI containers
 */
import Navigation from "../components/app/navigation/navigation";
import ClientPageRoutes from "./routers/client-page-routes";
import GenericRoutes from "./routers/generic-routes";
import IsolatedRoutes from "./routers/isolated-routes";
import StaffPageRoutes from "./routers/staff-page-routes";

/**
 * Functional component to return the routing for the app. Checks are done at this level for calls to 
 * generic pages like signup/login or specifc calls wrapped in a user context for app-level pages 
 * like clients or content.
 */
function AppRoutes() {
    /**
     * Get the user object from the Auth context
     */
    const { user } = useContext(AuthContext);

    /**
     * Get the location of the current browser window
     */
    const location = useLocation();

    /**
     * Is the user loading through to a reservation page
     */
    if (location.pathname === "/reservation-request") {
        return (
            <IsolatedRoutes />
        );
    }

    /**
     * Is the auth context in a loading state
     */
    if (user.loading) {
        return "loading...";
    } else {
        /**
         * Check to see if the user is logged in
         */
        if (user.logged_in) {
            /**
             * If the user is logged in and the window is on the leaderboard page, we don't 
             * need to show a navigation
             */
            if (location.pathname === "/leaderboard") {
                return (
                    <IsolatedRoutes />
                );
            } else {
                /**
                 * Otherwise show all other pages
                 */
                return (
                    <AppContexts>
                        <section id="app-screen-wrapper">
                            {/* Application navigation component */}
                            <Navigation />

                            {/* Main app DOM element */}
                            <div id="app-screen-height-contain">
                                <div id="app-screen-inner">
                                    {/* Page routes for staff accounts */}
                                    {user.is_staff && <StaffPageRoutes />}

                                    {/* Page routes for client accounts */}
                                    {!user.is_staff && <ClientPageRoutes />}
                                </div>
                            </div>
                        </section>
                    </AppContexts>
                );
            }
        } else {
            /**
             * If they are not logged in, return the generic routes
             */
            return (
                <AppContexts>
                    {/* Main app DOM element */}
                    <div id="app-splash">
                        <GenericRoutes />
                    </div>
                </AppContexts>
            );
        }
    }
}

export default AppRoutes;
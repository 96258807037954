import React from "react";
import "./input.scss";

/**
 * Functional component for a single line inputfield that can take the type of text, number, 
 * tel, password amoung others.
 * 
 * Values are passed back up the props to the containers they are embedded in and the values are 
 * passed back into the input to keep it as a controlled component.
 * 
 * @param {object} props Props passed in for the input
 * @returns HTML markup and functionality for the inputfield
 */
function Input(props) {
    return (
        <div className={[
            "ui-input-wrapper",
            props.wrapperClass,
            props.error && "has-error",
            props.symbol && "has-symbol",
            props.value && "has-value",
            props.readOnly && "read-only",
            props.activeOnHover && "only-active-on-hover",
        ].join(" ")}>
            {/* Input wrapper for the input and icon should one be passed in */}
            <div className="ui-input-block">
                {/* Icon to display in the input */}
                {props.icon && <div className="ui-input-icon">{props.icon}</div>}

                {/* Small symbol to show to the left of the input field */}
                {props.symbol && <div className="ui-input-symbol">{props.symbol}</div>}

                {/* Placeholder to sit above the field when there is a value */}
                {((props.placeholder || props.label) && props.showLabel) &&
                    <div className={["ui-input-upper-placeholder", (props.value || props.value === 0) && "active"].join(" ")}>
                        {props.label || props.placeholder}
                    </div>
                }

                {/* Input field itself */}
                <input
                    name={props.name}
                    id={props.id}
                    type={props.type || "text"}
                    className={["ui-input-text", props.className, props.icon && "has-icon"].join(" ")}
                    pattern={props.pattern || ""}
                    readOnly={props.readOnly}
                    placeholder={props.placeholder}
                    required={props.required}
                    value={props.value}
                    onChange={(e) => props.fullEvent ? props.onChange(e) : props.onChange(e.target.value)}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur} />
            </div>

            {/* Is there an error or note to display */}
            {props.error ? <small>{props.error}</small> :
                props.note && <small>{props.note}</small>}
        </div>
    );
}

export default Input;
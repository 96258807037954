import { useState, useContext, useEffect } from 'react'
import { QuoteContext } from '../context/context'
import moment from 'moment'

// Utility functions
import { printCurrencySymbol } from '../utils/functions'

// Constants
import { BOARD_TYPES, FEE_TYPES, ROOM_TYPES } from '../../../assets/data/exports'

// UI components
import { CaretDownIcon, CaretUpIcon } from '../../../utils/svgs'
import { ArrowRightIcon, MinusIcon, PlusIcon, WarningTriangleIcon } from '../../../utils/svgs-v2'
import CalendarUI from '../components/calendar/calendar'
import Input from '../../../components/ui/inputs/input'
import Badge from '../../../components/ui/badge/badge'
import Button from '../../../components/ui/button/button'
import Select from '../../../components/ui/select/select'

// Returns the markup for a resort now
export default function OptionPackages({ details }) {
    const [open, setOpen] = useState(true)
    const [choosingNightsID, setChoosingNightsID] = useState('')
    const [choosingNights, setChoosingNights] = useState('')
    const [choosingNightsPackage, setChoosingNightsPackage] = useState({})

    // Calendar dates
    const [dateOverrun, setDateOverrun] = useState(false)
    const [chosenStartDate, setChosenStartDate] = useState(0)
    const [chosenDates, setChosenDates] = useState([])

    // Packages states
    const [windowPackages, setWindowPackages] = useState([])

    // State for the custom pricing options
    const [custNights, setCustNights] = useState(null)
    const [custRounds, setCustRounds] = useState(null)
    const [custRoomType, setCustRoomType] = useState(null)
    const [custBoardType, setCustBoardType] = useState(null)
    const [custFeeType, setCustFeeType] = useState(null)
    const [custNetCost, setCustNetCost] = useState(null)

    // Get the selected packages and the update method from the quote context
    const { ctxSelectedPackages, setCtxSelectedPackages, ctxIncludedDates } = useContext(QuoteContext)

    // On component mount
    useEffect(() => {
        // Reset the state
        setChosenDates([])
        setChosenStartDate(0)

        // Are there any dates selected in the context?
        if (ctxIncludedDates && ctxIncludedDates.length > 0) {
            // Set the dates back locally here
            setChosenDates(ctxIncludedDates)

            // Recalibrate the chosen start date in the state
            handleDateChosen(moment(ctxIncludedDates[0], 'DD/MM/YYYY'), true)
        }
    }, [])

    // When the date is updated from the calendar UI
    const handleDateChosen = (chosenDate, fromState = false) => {
        // If we are not loading these dates back from the state
        if (!fromState) {
            // Push the new start date into the state
            setChosenDates(chosenDate.format('DD-MM-YYYY'))
        }

        // Set the new chosen start date into the state
        setChosenStartDate(chosenDate)

        // Get the start of the day in seconds
        const startSeconds = parseInt(chosenDate.startOf('day').format('X'))

        // Are we working with a resort option type?
        if (details.option_type === 'resort') {
            // Find all the price windows where this day lands inside of
            const matchingResortWindows = details?.price_windows?.resort.filter((window) => {
                return (window.start_date.seconds <= startSeconds) && (window.end_date.seconds >= startSeconds)
            })
    
            // Set these matching windows into the state
            setWindowPackages([...matchingResortWindows])
        } else if (details.option_type === 'course') {
            // Otherwise if this is a course type we are working with
            const matchingCourseWindows = details?.price_windows?.course.filter((window) => {
                return (window.start_date.seconds <= startSeconds) && (window.end_date.seconds >= startSeconds)
            })
    
            // Set these matching windows into the state
            setWindowPackages([...matchingCourseWindows])
        }
    }

    // Take in comenights values and calculate the included dates with the chosen start date
    const calculateIncludedDates = (nights) => {
        // Setup an array to store the chosen dates
        let chosenDatesLocal = []

        // Get a copy of the chosen start date moment object
        const calculatedStartDate = chosenStartDate.clone()

        // Add the nights to the current chosen start date to see if it is within the bounds
        const currentEndDate = calculatedStartDate.add(parseInt(nights), 'days').startOf('day').valueOf()
        const loopEndDate = calculatedStartDate.startOf('day').valueOf()

        // Get a copy of the chosen start date to use in loopin through the chosen dates
        const loopDate = chosenStartDate.clone()

        // Loop over the two dates to generate an array of chosen dates
        while (loopDate.isBefore(currentEndDate) || loopDate.isSame(loopEndDate)) {
            // Push the parsed date into the array to highlight on the calendar
            chosenDatesLocal.push(loopDate.format('DD-MM-YYYY'))

            // Then add one to the looping date to increment through
            loopDate.add(1, 'days')
        }

        // Return the array of dates
        return chosenDatesLocal
    }

    // Add the chosen package to the basket in the state
    const saveSelectedPackage = (newPackage) => {
        // If the package chosen is one setup 'per night'
        if (newPackage.type === 'per_night') {
            // Set the package ID into the state to toggle the nights window
            setChoosingNightsID(newPackage.id)
            setChoosingNightsPackage(newPackage)
            return
        }
        
        // Fetch the included dates
        const includedDates = calculateIncludedDates(newPackage.nights)

        // Otherwise, push the new package into the quote context
        setCtxSelectedPackages((ctxSelectedPackages) => ([...ctxSelectedPackages, {
            ...newPackage,
            from_date: chosenStartDate.startOf('day').format('X'),
            included_dates: includedDates,
            parent_type: details.option_type,
            parent_title: details.title,
            parent_currency: printCurrencySymbol(details.currency)
        }]))

        // Reset the state
        setChosenDates([])
    }

    // When the amount of nights changes
    useEffect(() => {
        // Do we have a start date chosen?
        if (chosenStartDate && moment(chosenStartDate).isValid()) {
            // Setup an array to store the chosen dates
            let chosenDatesLocal = []

            // Are there any nights available in the state?
            if (choosingNights && !isNaN(parseInt(choosingNights)) && parseInt(choosingNights) > 0) {
                // When does the currently chosen packge due to end?
                const packageEnding = moment(choosingNightsPackage.end_date.seconds, 'X').startOf('day').valueOf()
                
                // Get a copy of the chosen start date moment object
                const calculatedStartDate = chosenStartDate.clone()

                // Add the nights to the current chosen start date to see if it is within the bounds
                const currentEndDate = calculatedStartDate.add(parseInt(choosingNights), 'days').startOf('day').valueOf()
                const loopEndDate = calculatedStartDate.startOf('day').valueOf()

                // Get a copy of the chosen start date to use in loopin through the chosen dates
                const loopDate = chosenStartDate.clone()

                // Loop over the two dates to generate an array of chosen dates
                while (loopDate.isBefore(currentEndDate) || loopDate.isSame(loopEndDate)) {
                    // Push the parsed date into the array to highlight on the calendar
                    chosenDatesLocal.push(loopDate.format('DD-MM-YYYY'))

                    // Then add one to the looping date to increment through
                    loopDate.add(1, 'days')
                }

                // Are we within the bounds of this price window?
                if (currentEndDate > packageEnding) {
                    // Set the state to disable the button
                    setDateOverrun(true)
                } else {
                    // Otherwise reset the state
                    setDateOverrun(false)
                }
            }

            // Then set these dates into the state
            setChosenDates(chosenDatesLocal)
        }
    }, [choosingNights])

    // When the amount of nights changes
    useEffect(() => {
        // Do we have a start date chosen?
        if (chosenStartDate && moment(chosenStartDate).isValid()) {
            // Setup an array to store the chosen dates
            let chosenDatesLocal = []

            // Are there any nights available in the state?
            if (custNights && !isNaN(parseInt(custNights)) && parseInt(custNights) > 0) {
                // Get a copy of the chosen start date moment object
                const calculatedStartDate = chosenStartDate.clone()

                // Add the nights to the current chosen start date to see if it is within the bounds
                const currentEndDate = calculatedStartDate.add(parseInt(custNights), 'days').startOf('day').valueOf()
                const loopEndDate = calculatedStartDate.startOf('day').valueOf()

                // Get a copy of the chosen start date to use in loopin through the chosen dates
                const loopDate = chosenStartDate.clone()

                // Loop over the two dates to generate an array of chosen dates
                while (loopDate.isBefore(currentEndDate) || loopDate.isSame(loopEndDate)) {
                    // Push the parsed date into the array to highlight on the calendar
                    chosenDatesLocal.push(loopDate.format('DD-MM-YYYY'))

                    // Then add one to the looping date to increment through
                    loopDate.add(1, 'days')
                }
            }
            
            // Then set these dates into the state
            setChosenDates(chosenDatesLocal)
        }
    }, [custNights])

    // Complete the selction of a 'per night' package
    const completeSelectedPackage = () => {
        // Firstly let's clear the state
        setChoosingNightsID(null)

        // Get a copy of the new package from the state
        const newPackageNights = choosingNights
        const newPackage = choosingNightsPackage

        // Otherwise, push the new package into the quote context
        setCtxSelectedPackages((ctxSelectedPackages) => ([...ctxSelectedPackages, {
            ...newPackage,
            from_date: chosenStartDate.startOf('day').format('X'),
            nights: parseInt(newPackageNights),
            included_dates: chosenDates,
            parent_type: details.option_type,
            parent_title: details.title,
            parent_currency: printCurrencySymbol(details.currency)
        }]))

        // Then clear the state
        setChoosingNights('')
        setChoosingNightsPackage({})
        setChosenDates([])
    }

    // Remove the chosen package from the basket in the state
    const removeSelectedPackage = (existingPackage) => {
        setCtxSelectedPackages((ctxSelectedPackages) => ctxSelectedPackages.filter((selectedPackage) => (
            selectedPackage.id !== existingPackage.id
        )))

        // Clear the chosen dates
        setChosenDates([])
    }

    // Save the custom pricing option into the state
    const saveCustomPricing = () => {
        // Get the start of the first included date and convert to a seconds timestamp
        const fromDate = moment(chosenDates[0], 'DD-MM-YYYY').startOf('day').format('X')
        const untilDate = moment(chosenDates[chosenDates.length - 1], 'DD-MM-YYYY').endOf('day').format('X')

        // Generate a unique ID for the package
        const uniqueID = Math.random().toString(36).substr(2, 9)

        // Setup an object to store the details for a custom pricing package
        let newCustomPricing = {
            id: uniqueID,
            price_type: details.option_type,
            resort_id: details.objectID,
            is_draft: false,
            name: 'Custom Pricing',
            type: 'custom',
            is_custom: true,
            base_markup: 20,
            adjusted_price_highest: null,
            price_highest: null,
            adjusted_price_lowest: null,
            price_lowest: null,
            adjusted_markup: null,
            rounds: parseInt(custRounds),
            buggies: [],
            start_date: {
                seconds: fromDate,
                nanoseconds: 0
            },
            end_date: {
                seconds: untilDate,
                nanoseconds: 0
            },
            included_dates: chosenDates,
            from_date: fromDate,
            parent_type: details.option_type,
            parent_title: details.title,
            parent_currency: printCurrencySymbol(details.currency)
        }

        // Are adding a pricing package for a resort?
        if (details.option_type === 'resort') {
            // Create the GUID IDs for the board and room types
            const boardTypeID = Math.random().toString(36).substr(2, 9)
            const roomTypeID = Math.random().toString(36).substr(2, 9)

            // Add the nights, board types and room types to the package
            newCustomPricing.nights = parseInt(custNights)
            newCustomPricing.board_types = [{
                type: custBoardType,
                cost: 0,
                base: true,
                _id: boardTypeID
            }]
            newCustomPricing.room_types = [{
                type: custRoomType,
                cost: parseInt(custNetCost),
                base: true,
                _id: roomTypeID
            }]
        }

        // Are adding a pricing package for a course?
        if (details.option_type === 'course') {
            newCustomPricing.name = `${parseInt(custRounds)} Green Fee${parseInt(custRounds) > 1 ? 's' : ''}`

            // Create a GUID for the fee type ID
            const feeTypeID = Math.random().toString(36).substr(2, 9)

            // Add the fee type to the package
            newCustomPricing.fee_types = [{
                type: custFeeType,
                cost: parseInt(custNetCost),
                base: true,
                _id: feeTypeID
            }]
        }

        // Then set the package in to the global context
        setCtxSelectedPackages((ctxSelectedPackages) => ([...ctxSelectedPackages, {
            ...newCustomPricing
        }]))

        // And clear the state
        setCustNights(null)
        setCustRounds(null)
        setCustRoomType(null)
        setCustBoardType(null)
        setCustFeeType(null)
        setCustNetCost(null)
        setChoosingNights('')
        setChoosingNightsPackage({})
        setChosenDates([])
    }

    return (
        <div className='quote-option-row-open'>
            <div className='quote-option-row-open-head' onClick={() => setOpen((open) => !open)}>
                <div className='quote-option-row-title'>
                    {details.option_type === 'resort' ? <Badge label='Resort' type='INFO' /> : ''}
                    {details.option_type === 'course' ? <Badge label='Course' type='POSITIVE' /> : ''}
                    
                    {details.title}
                </div>

                <div className='quote-option-row-toggle-open'>
                    {open ? <CaretUpIcon /> : <CaretDownIcon />}
                </div>
            </div>

            <div className={['quote-option-row-open-body', open ? 'is-open' : ''].join(' ').trim()}>
                <div className='quote-package-calendar'>
                    <CalendarUI
                        clearPackages={() => setWindowPackages([])}
                        dateUpdated={handleDateChosen}
                        priceWindows={details?.price_windows || []}
                        chosenDates={chosenDates}
                        currency={printCurrencySymbol(details.currency)} />
                </div>

                <div className={['quote-available-packages', chosenStartDate ? 'is-visible' : ''].join(' ').trim('')}>
                    {windowPackages.map((windowPackage) => (
                        <div className={['holiday-price-row'].join(' ').trim()}>
                            {windowPackage.id === choosingNightsID &&
                                <div className='quote-choosing-nights'>
                                    <Input
                                        type='number'
                                        placeholder='How many nights:'
                                        value={choosingNights}
                                        onChange={setChoosingNights} />

                                    <Button
                                        label='Save'
                                        onClick={() => completeSelectedPackage()}
                                        disabled={isNaN(parseInt(choosingNights)) || parseInt(choosingNights) < 0 || dateOverrun} />

                                    {dateOverrun && 
                                        <p className='overrun-error'>
                                            <WarningTriangleIcon />
                                            Adding {choosingNights} nights falls outside the current price window.
                                        </p>
                                    }
                                </div>
                            }

                            <div className='price-row-name'>
                                {(windowPackage.price_type === 'resort' && windowPackage.type === 'per_night') ?
                                    <Badge label='Per Night' type='SPECIAL' />
                                 : null }

                                {(windowPackage.price_type === 'resort' && windowPackage.type === 'pre_defined') ?
                                    <Badge label='Pre Defined' type='SPECIAL' />
                                 : null }

                                <p>
                                    {(windowPackage.course_name && windowPackage.course_name.length) > 0 ? 
                                        <>{windowPackage.course_name?.replace(/&#039;/g, "'")} - </> : ''}

                                    {windowPackage.name?.replace(/&#039;/g, "'")}
                                </p>

                                {ctxSelectedPackages.filter((pkg) => pkg.id === windowPackage.id).length > 0 && 
                                    <div className='price-option-action' onClick={()=> removeSelectedPackage(windowPackage)}>
                                        <MinusIcon />
                                    </div>
                                }
                            </div>

                            {ctxSelectedPackages.filter((pkg) => pkg.id === windowPackage.id).length === 0 && 
                                <div className='price-row-details'>
                                    <div className='price-option-dates'>
                                        <p>{moment(windowPackage.start_date.seconds, 'X').format('DD-MMM-YYYY')}</p>
                                        <ArrowRightIcon />
                                        <p>{moment(windowPackage.end_date.seconds, 'X').format('DD-MMM-YYYY')}</p>
                                    </div>

                                    <div className='price-option-range'>
                                        <p>£{windowPackage.price_lowest}pp - £{windowPackage.price_highest}pp</p>
                                    </div>

                                    <div className='price-option-actions'>
                                        <div className='price-option-action' onClick={()=> saveSelectedPackage(windowPackage)}>
                                            <PlusIcon />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    ))}

                    <div className={['holiday-price-row'].join(' ').trim()}>
                        <div className='price-row-name with-icon'>
                            <WarningTriangleIcon />
                            <p>Enter Pricing Details</p>
                        </div>

                        <div className='price-row-details no-flex'>
                            {windowPackages.length === 0 &&
                                <p className='no-price-window-notice'>
                                    There are no pricing options available from this date, enter the relevant details below to continue onto configuration.
                                </p>
                            }
                            {windowPackages.length > 0 &&
                                <p className='no-price-window-notice'>
                                    Setup an ad-hoc pricing window for by entering the details below.
                                </p>
                            }

                            <div className='no-price-window-options'>
                                {details.option_type === 'resort' &&
                                    <div className='no-price-window-option-row'>
                                        <p>Nights</p>

                                        <Input
                                            type='number'
                                            value={custNights}
                                            onChange={setCustNights}
                                            placeholder='Nights' />
                                    </div>
                                }

                                <div className='no-price-window-option-row'>
                                    <p>Rounds</p>

                                    <Input
                                        type='number'
                                        value={custRounds}
                                        onChange={setCustRounds}
                                        placeholder='Rounds' />
                                </div>

                                {details.option_type === 'resort' &&
                                    <>
                                        <div className='no-price-window-option-row'>
                                            <p>Room type</p>

                                            <Select
                                                selected={custRoomType}
                                                placeholder='Choose room type'
                                                options={ROOM_TYPES}
                                                onSelect={({ option }) => setCustRoomType(option)} />
                                        </div>

                                        <div className='no-price-window-option-row'>
                                            <p>Board type</p>

                                            <Select
                                                selected={custBoardType}
                                                placeholder='Choose board type'
                                                options={BOARD_TYPES}
                                                onSelect={({ option }) => setCustBoardType(option)} />
                                        </div>
                                    </>
                                }

                                {details.option_type === 'course' &&
                                    <div className='no-price-window-option-row'>
                                        <p>Fee type</p>

                                        <Select
                                            selected={custFeeType}
                                            placeholder='Choose fee type'
                                            options={FEE_TYPES}
                                            onSelect={({ option }) => setCustFeeType(option)} />
                                    </div>
                                }

                                <div className='no-price-window-option-row'>
                                    <p>Net Cost</p>

                                    <Input
                                        type='number'
                                        value={custNetCost}
                                        onChange={setCustNetCost}
                                        symbol={printCurrencySymbol(details.currency)}
                                        placeholder='Net cost' />
                                </div>

                                <div className='no-price-window-add'>
                                    <Button
                                        label='Add'
                                        onClick={() => saveCustomPricing()}
                                        disabled={details?.option_type === 'course' ? (isNaN(parseInt(custRounds)) || parseInt(custRounds) < 0 || isNaN(parseInt(custNetCost)) || parseInt(custNetCost) < 0) : (isNaN(parseInt(custNights)) || parseInt(custNights) < 0 || isNaN(parseInt(custNetCost)) || parseInt(custNetCost) < 0)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
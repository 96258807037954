import React, { useEffect, useState, useContext } from "react"
import { ModalContext } from "../../../utils/providers/modal"
import { db, auth } from "../../../utils/firebase"
import firebase from "firebase"
import "./assign.scss"

/**
 * UI components & structure
 */
import Window from "../../../components/structure/window/window"
import Button from "../../../components/ui/button/button"

/**
 * Shows a window for assigning the enquiry to a sales agent
 */
function Assign(props) {
	const [agents, setAgents] = useState([])
	const [assiging, setAssigning] = useState({})

	/**
	 * Deconstruct the enquiry ID from the props
	 */
	const { enquiryID, bulkAssign } = props

	/**
	 * Get the pushModal
	 */
	const { showModal } = useContext(ModalContext)

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Pull the agents that are available to be assigned enquiries
		 */
		db.collection("users")
			.orderBy("first_name")
			.where("show_for.assignable", "==", true)
			.get()
			.then((agentDocs) => {
				/**
				 * Store the agent records in an array
				 */
				let agents = []
				/**
				 * Loop through the agent documents found
				 */
				agentDocs.forEach((agentDoc) => {
					/**
					 * Push the agent into the array
					 */
					agents.push({
						...agentDoc.data(),
						id: agentDoc.id,
					})
				})
				/**
				 * Push them into the state
				 */
				setAgents(agents)
			})
	}, [])

	/**
	 * Check to see if there are enquiry IDs saved in the bulk array
	 */
	const checkForBulkAssignment = async (agentID) => {
		/**
		 * Show a spinner on the button
		 */
		setAssigning({ [agentID]: true })
		/**
		 * If there are multiple enquiries to assign
		 */
		if (bulkAssign.length > 0) {
			/**
			 * Loop over each of the enquiries
			 */
			await Promise.all(
				bulkAssign.map(async (enquiry) => {
					/**
					 * Assign the enquiry to the agent
					 */
					await db.doc(`enquiries/${enquiry}`).set(
						{
							agent: agentID,
							status: "BEING_PROCESSED",
						},
						{ merge: true }
					)
				})
			)
			/**
			 * Reset the state
			 */
			setAssigning({ [agentID]: false })
			props.close()
		} else {
			checkForAdditionalEnquiries(agentID)
		}
	}

	/**
	 * Query the database to see if there are any other enquiries that have the same client ID,
	 * if there are, we need to say that they will also be assigned to this agent
	 */
	const checkForAdditionalEnquiries = async (agentID) => {
		/**
		 * Get the enquiry document from the database and return the client ID attached
		 */
		const clientID = await db
			.doc(`enquiries/${enquiryID}`)
			.get()
			.then((enquiryDoc) => {
				return enquiryDoc.data().client
			})
		/**
		 * Then query the database for all the enquiries that have this client ID assigned and
		 * are under the status of "REQUIRE_ATTENTION", meaning they are yet to be assigned
		 */
		const enquiries = await db
			.collection("enquiries")
			.where("client", "==", clientID)
			.where("status", "==", "REQUIRE_ATTENTION")
			.get()
			.then((enquiryDocs) => {
				/**
				 * Setup a new array to store the enquiry IDs
				 */
				let enquiryIDs = []
				/**
				 * Loop through the enquiries that were found
				 */
				enquiryDocs.forEach((enquiryDoc) => {
					/**
					 * Push the enquiry ID into the array
					 */
					enquiryIDs.push(enquiryDoc.id)
				})
				/**
				 * Then return the array of IDs
				 */
				return enquiryIDs
			})
		/**
		 * If there is more than 1 enquiry in the array, we need to show a modal to say multiple
		 * are going to be assigned to the agent
		 */
		if (enquiries.length > 1) {
			/**
			 * Show a modal to confirm multiple bookings
			 */
			showModal({
				type: "ALERT",
				title: "Multiple enquiries found",
				body: "This client has submitted mutiple enquiries, so they'll all be assigned",
				cancel: {
					label: "Cancel",
					action: () => {
						return null
					},
				},
				next: {
					label: "Continue",
					action: () => assignMultipleToAgent(agentID, enquiries),
				},
			})
		} else {
			/**
			 * If there was only 1 enquiry found from this client, just assign it to the agent
			 */
			assignToAgent(agentID)
		}
	}

	/**
	 * Take in an agent ID and assign the enqiury in question to their account
	 */
	const assignToAgent = async (agentID) => {
		/**
		 * Update the enquiry document
		 */
		await db.doc(`enquiries/${enquiryID}`).set(
			{
				agent: agentID,
				status: "BEING_PROCESSED",
			},
			{ merge: true }
		)
		/**
		 * Save a new log into the database
		 */
		await db.collection(`LOGS_enquiries`).add({
			type: "INITIAL_ASSIGNMENT",
			created: firebase.firestore.FieldValue.serverTimestamp(),
			enquiry: enquiryID,
			byUser: auth.currentUser.uid,
			fromUser: null,
			toUser: agentID,
		})
		/**
		 * Reset the state
		 */
		setAssigning({ [agentID]: false })
		props.close()
	}

	/**
	 * Take in an ID for the agent to assign the enquiries to, as well as an array of all other enquiry
	 * IDs found for this client to assign as well as the original
	 */
	const assignMultipleToAgent = async (agentID, enquiries) => {
		/**
		 * Loop over each of the enquiries
		 */
		await Promise.all(
			enquiries.map(async (enquiry) => {
				/**
				 * Assign the enquiry to the agent
				 */
				await db.doc(`enquiries/${enquiry}`).set(
					{
						agent: agentID,
						status: "BEING_PROCESSED",
					},
					{ merge: true }
				)
				/**
				 * Save a new log into the database
				 */
				await db.collection(`LOGS_enquiries`).add({
					type: "INITIAL_ASSIGNMENT",
					created: firebase.firestore.FieldValue.serverTimestamp(),
					enquiry: enquiry,
					byUser: auth.currentUser.uid,
					fromUser: null,
					toUser: agentID,
				})
			})
		)
		/**
		 * Reset the state
		 */
		setAssigning({ [agentID]: false })
		props.close()
	}

	return (
		<Window
			title="Assign to agent"
			className="slim"
			close={() => props.close()}>
			<div className="assign-agent-wrap">
				<table className="assign-agent-table">
					<tbody>
						{/* Loop through the agents and print them into the DOM */}
						{agents.map((agent) => (
							<tr key={agent.id}>
								<td>
									{agent.first_name} {agent.last_name}
								</td>
								<td>
									<Button
										label="Assign"
										loading={assiging[agent.id]}
										small={true}
										loadingText="Assigning..."
										onClick={() => checkForBulkAssignment(agent.id)}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Window>
	)
}

export default Assign

import parse from 'html-react-parser'
import { useState } from 'react'

// Firebase utilities
import { auth, db } from '../../../../utils/firebase'

// UI components
import Input from '../../../ui/inputs/input'

// Returns the markup for the details tab when editing a course
export default function CourseDetails({ courseDocID, course }) {
    const [courseID, setCourseID] = useState(course?.bs2_id_course || '')
    const [altCourseIDs, setAltCourseIDs] = useState(course?.bs2_id_course_alternates || '')

    // On input focus blur, save the new hotel ID into the document
    const saveCourseID = async () => {
        console.log("Saving...")

        let altIDs = []

        if (typeof altCourseIDs === "string" && altCourseIDs.length > 0) {
            altIDs = altCourseIDs.split(",")
        }

        await db.doc(`CMS_courses/${courseDocID}`).set({
            bs2_id_course: courseID ?? null,
            bs2_id_course_alternates: altIDs ?? null
        }, { merge:true })

        console.log("Done!")
    }

    return (
        <>
            {auth.currentUser.uid === 'CGOHCdUwFAOe13OSTY2D9qdO2LX2' && (
                <div style={{ width: "395px" }}>
                    <Input
                        label="bs2_id_course"
                        showLabel={true}
                        value={courseID}
                        onChange={setCourseID}
                        placeholder=" "
                        onBlur={() => saveCourseID()} />
                    <br />
                    <Input
                        label="bs2_id_course_alternates"
                        showLabel={true}
                        value={altCourseIDs}
                        onChange={setAltCourseIDs}
                        placeholder=" "
                        onBlur={() => saveCourseID()} />
                    <br />
                </div>
            )}

            <div className='course-details-images'>
                <a className='course-details-image' href={course.media?.primary_image} target='_blank'>
                    <img src={course.media?.primary_image} />
                </a>

                {course.media?.images?.slice(0, 8).map((image, index) => (
                    <a key={image} className='course-details-image' href={image} target='_blank'>
                        <img key={index} src={image} />
                    </a>
                ))}
            </div>

            <div className='course-details-text'>
                {parse(course?.description, { replace: (domNode) => {
                    if (domNode.attribs && domNode.attribs.href) {
                        return <a href={`https://www.golfholidaysdirect.com${domNode.attribs.href}`} target='_blank'>{domNode.children[0].data}</a>
                    }
                } })}
            </div>
        </>
    )
}
import React from "react";
import { ROOM_TYPES } from "../../../utils/exports/room-types";

/**
 * UI components
 */
import Input from "../../ui/inputs/input";

/**
 * Functional component to return a room
 */
function Room(props) {
    /**
     * Deconstruct the room and setRoom froms the props
     */
    const { index, room } = props;

    return (
        <>
            <tr>
                <td>Room #{index + 1} type</td>
                <td>
                    <Input
                        type="text"
                        value={ROOM_TYPES[room.room_type]}
                        readOnly={true} />
                </td>
            </tr>
        </>
    );
}

export default Room;
import React, { useContext, useState, useEffect } from "react";
import * as mailTypes from "../../../utils/exports/mail-types";
import { PDFDocument, rgb } from "pdf-lib";
import { BookingContext } from "../../../utils/providers/booking";
import { AlertsContext } from "../../../utils/providers/alerts";
import { auth, db, storage } from "../../../utils/firebase";
import { sendEmail } from "../../../utils/mail";
import firebase from "firebase";
import moment from "moment";

/**
 * UI components
 */
import File from "../../../components/ui/inputs/file";
import Badge from "../../../components/ui/badge/badge";
import Button from "../../../components/ui/button/button";
import Window from "../../../components/structure/window/window";
import { LoadingIcon } from "../../../utils/svgs";
import Textarea from "../../../components/ui/inputs/textarea";

/**
 * Required to generate the ID for the new content document
 */
const random = require("randomstring");

/**
 * Functional component to return the ATOL certificate tab on the booking
 */
export default function Atol({ client }) {
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadDocument, setUploadDocument] = useState([]);
  const [fileErrors, setFileErrors] = useState(0);
  const [error, setError] = useState("");
  const [viewCertModal, setViewCertModal] = useState(false);
  const [newCertLoading, setNewCertLoading] = useState(true);
  const [certProgressStatus, setCertProgressStatus] = useState("Loading blank certificate...");
  const [certUnitArray, setCertUnitArray] = useState(null);
  const [sendingCertificate, setSendingCertificate] = useState(false);
  const [sendingCertificateLabel, setSendingCertificateLabel] = useState("");

  /**
   * Deconstruct data from the booking context
   */
  const {
    bookingID,
    agent,
    ATOLCertificate,
    ATOLCertificateUploaded,
    reference,
    golfers,
    nonGolfers,
    ATOLCertificateInformation,
    setATOLCertificateInformation,
  } = useContext(BookingContext);

  /**
   * Pull the alerts alert from the context
   */
  const { pushAlert } = useContext(AlertsContext);

  /**
   * When the uploadFiles array is updated
   */
  useEffect(() => {
    /**
     * Init a 0 value for the errors
     */
    let errors = 0;
    /**
     * Loop through the files ready for upload
     */
    uploadDocument.forEach((uploadFile) => {
      /**
       * If there is an error message present
       */
      if (uploadFile.error?.message) {
        /**
         * Increment the errors
         */
        errors++;
      }
    });
    /**
     * Set the new errors value to the state for toggling the button
     */
    setFileErrors(errors);
  }, [uploadDocument]);

  /**
   * When the view cert boolean is updated
   */
  useEffect(() => {
    /**
     * If we are loading a new certificate modal
     */
    viewCertModal && processATOLCertificate();
  }, [viewCertModal]);

  const saveATOLInformation = async () => {
    await db.doc(`bookings/${bookingID}`).set({
      atol_certificate: {
        protected_comments: ATOLCertificateInformation,
      }
    }, { merge: true })

    pushAlert({
      type: "SUCCESS",
      title: "Comments Saved",
    })
  }

  /**
   * Process the booking information into the ATOL certificate
   */
  const processATOLCertificate = async () => {
    /**
     * URL of the blank certificate from the cloud storage
     */
    const url =
      "https://firebasestorage.googleapis.com/v0/b/ghd-booking-system.appspot.com/o/_system%2Fdocuments%2Fatol_cert_blank_v2.pdf?alt=media&token=2bc3f5f9-8df7-4849-b34c-8838d46c8d1b";
    /**
     * Fetch the document as an array buffer for the PDF libary
     */
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    /**
     * State message update
     */
    setCertProgressStatus("Blank copy fetched, writing booking data...");
    /**
     * We need to fetch the first payment made on the booking as the date of isssue
     */
    const dateOfIssue = await db.collection(`bookings/${bookingID}/payments`)
      .orderBy("paid_date")
      .limit(1)
      .get().then((paymentDocs) => {
        /**
         * Get the paid_Date field on the first (and only) document returned
         */
        const paidDate = paymentDocs.docs[0].data().paid_date.seconds;
        /**
         * Return the date in the desired format
         */
        return moment(paidDate, "X").format("DD/MM/YYYY");
      });
    /**
     * Setup the document locally
     */
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    /**
     * Get the various pages of the document
     */
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const secondPage = pages[1];
    /**
     * Get the height of the page size for writing the text onto
     */
    const { height } = firstPage.getSize();
    /**
     * First page
     */
    firstPage.drawText(`${client.first_name} ${client.last_name}`, {
      x: 58.4,
      y: height - 236,
      size: 11.8,
      color: rgb(0.14, 0.12, 0.12),
    });
    firstPage.drawText((golfers + nonGolfers).toString(), {
      x: 509.7,
      y: height - 220.2,
      size: 11.8,
      color: rgb(0.14, 0.12, 0.12),
    });
    firstPage.drawText(ATOLCertificateInformation, {
      x: 58.4,
      y: height - 300,
      size: 11.8,
      lineHeight: 14.2,
      color: rgb(0.14, 0.12, 0.12),
      maxWidth: 482,
    });
    firstPage.drawText(reference.toString(), {
      x: 382.5,
      y: height - 437.9,
      size: 11.8,
      lineHeight: 14.2,
      color: rgb(0.14, 0.12, 0.12),
    });
    firstPage.drawText(reference.toString(), {
      x: 88.5,
      y: 42.2,
      size: 10,
      color: rgb(0.14, 0.12, 0.12),
    });
    firstPage.drawText(dateOfIssue.toString(), {
      x: 191.6,
      y: 42.2,
      size: 10,
      color: rgb(0.14, 0.12, 0.12),
    });
    firstPage.drawText("Golf Holidays Direct", {
      x: 265.4,
      y: 42.2,
      size: 10,
      color: rgb(0.14, 0.12, 0.12),
    });
    firstPage.drawText("11357", {
      x: 387.6,
      y: 42.2,
      size: 10,
      color: rgb(0.14, 0.12, 0.12),
    });
    /**
     * Second page
     */
    secondPage.drawText(reference.toString(), {
      x: 88.5,
      y: 42.2,
      size: 10,
    });
    secondPage.drawText(dateOfIssue.toString(), {
      x: 191.6,
      y: 42.2,
      size: 10,
    });
    secondPage.drawText("Golf Holidays Direct", {
      x: 265.4,
      y: 42.2,
      size: 10,
    });
    secondPage.drawText("11357", {
      x: 387.6,
      y: 42.2,
      size: 10,
    });
    /**
     * Generate the updated PDF document
     */
    const pdfBytes = await pdfDoc.save();
    /**
     * Set the result into the state to show in the modal
     */
    setCertUnitArray(pdfBytes);
    /**
     * Hide the loading spinner
     */
    setNewCertLoading(false);
    setCertProgressStatus("");
    /**
     * Generate and open a local copy of the PDF for review
     */
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = window.URL.createObjectURL(blob);
    window.open(link, "_blank");
    /**
     * Write a log into the booking for the generating of the certificate
     */
    await db.collection(`bookings/${bookingID}/logs`).add({
      created: firebase.firestore.FieldValue.serverTimestamp(),
      type: "ATOL_CERTIFICATE_GENERATED",
      message: "An ATOL certificate was generated for this booking",
      user: auth.currentUser.uid,
      badge: "INFO",
    });
  };

  /**
   * Upload the generated certificate to the booking and email the client a copy
   */
  const uploadAndSendCertificate = async () => {
    /**
     * Set the state
     */
    setSendingCertificate(true);
    setSendingCertificateLabel("Uploading to booking...");
    /**
     * Upload the certificate to the cloud storage for this booking
     */
    await handleGeneratedFileUpload(certUnitArray);
    /**
     * Set the state
     */
    setSendingCertificateLabel("Sending to client...");
    /**
     * Establish a function for converting the unit array into a base 64 for the email
     */
    const convertUnitArrayToBase64 = async (unitArrayData) => {
      /**
       * Use a FileReader to generate a base64 data URI
       */
      const base64url = await new Promise((r) => {
        const reader = new FileReader()
        reader.onload = () => r(reader.result)
        reader.readAsDataURL(new Blob([unitArrayData]))
      });
      /**
       * Complete result would show: "data:application/octet-stream;base64,<the data>", so just
       * return the remainder of the string behind the meta at the start
       */
      return base64url.split(",", 2)[1]
    }
    /**
     * Fetch and store the base64 copy of the certificate
     */
    const certificateBase64 = await convertUnitArrayToBase64(certUnitArray);
    /**
     * Fetch the name of the booking agent
     */
    const bookingAgent = await db.doc(`users/${agent.option}`)
      .get().then((agentDoc) => {
        /**
         * Get the agent data
         */
        const { first_name, last_name, email } = agentDoc.data();
        /**
         * Return the data in an object
         */
        return { name: `${first_name} ${last_name}`, email };
      });
    /**
     * Send the client an email with the certificate attached
     */
    await sendEmail({
      template: mailTypes.ATOL_CERTIFICATE.template,
      subject: `ATOL Certificate for ${client.first_name} ${client.last_name} - Booking Ref: ${reference}`,
      from: {
        name: bookingAgent.name,
        email: bookingAgent.email,
      },
      user: {
        name: `${client.first_name} ${client.last_name}`,
        email: client.email,
      },
      mail: {
        agent: bookingAgent.name,
        name: client.first_name,
      },
      attachments: [{
        type: 'application/pdf',
        name: `ATOL Certificate for ${client.first_name} ${client.last_name} - Booking Ref: ${reference}`,
        content: certificateBase64,
      }],
    });
    /**
     * Then add a log regarding the client uploading an iamge
     */
    await db.collection(`bookings/${bookingID}/logs`).add({
      created: firebase.firestore.FieldValue.serverTimestamp(),
      type: "ATOL_CERTIFICATE_SENT",
      message: `The generated ATOL certificate was sent to ${client.email}`,
      user: auth.currentUser.uid,
      badge: "INFO",
    });
    /**
     * Update the booking document
     */
    await db.doc(`bookings/${bookingID}`).set({
      atol_certificate: {
        sent: true,
      }
    }, { merge: true })
    /**
     * Show a toast alert in the window
     */
    pushAlert({
      type: "SUCCESS",
      title: "ATOL Certificate Sent"
    });
    /**
     * Reset the state
     */
    setSendingCertificate(false);
    setSendingCertificateLabel("");
    setViewCertModal(false);
  };

  /**
   * Check the images chosen by the user for some requirements
   */
  const checkChosenFiles = (files) => {
    /**
     * Set the state
     */
    setError("");
    setUploadingDocument(true);
    /**
     * Check for an empty array
     */
    if (files.length > 0) {
      /**
       * Loop over the files
       */
      for (const [index, file] of Object.entries(files)) {
        /**
         * Don't push this index becasue it'll only be holding the file count
         */
        if (index !== "length") {
          /**
           * Generate a new ID for using on the document references for this file
           */
          const uploadID = random.generate(20);
          /**
           * Strip everything from the name to just keep letters
           */
          let cleanName = file.name.match(/[a-zA-Z]+/g);
          let fileID = `${cleanName}${file.size}`;
          let fileUploadTime = file.lastModified;
          let size = file.size;
          /**
           * Add an ID and an errors object to the file
           */
          let newFile = {
            id: fileID,
            title: file.name,
            uploadID,
            error: {},
            file,
            upload: {},
          };
          /**
           * Check if this file already exists
           */
          uploadDocument.forEach((file) => {
            if (file.id === fileID) {
              newFile.id = `${fileID}${fileUploadTime}`;
              newFile.error = {
                message: "You've already added this file to the upload.",
              };
            }
          });
          /**
           * Check for a valid file format
           */
          const allowedFormats = ["application/pdf"];
          if (!allowedFormats.includes(file.type)) {
            newFile.error = {
              message: "This file format is not supported.",
            };
          }
          /**
           * Check the size of the file to make sure it's below an acceptable limit of 5mb
           */
          const sizeInMB = size / 1024 / 1024;
          if (sizeInMB > 5) {
            newFile.error = {
              message: "Please ensure your file is below 5MB.",
            };
          }
          /**
           * Make sure there were no error appended onto this file
           */
          if (!newFile.error?.message) {
            /**
             * Push the files object to the array for sending to state
             */
            setUploadDocument((uploadDocument) => [...uploadDocument, newFile]);
            /**
             * Upload the file into the storage bucket
             */
            handleFileUpload(file);
          } else {
            /**
             * If there is an error, cancel the uploading state
             */
            setUploadDocument(false);
            /**
             * Push the errors into the state
             */
            setError(newFile.error?.message);
          }
        }
      }
    }
  };

  /**
   * Handles the uploading of the file into the storage bucket
   */
  const handleFileUpload = (file) => {
    /**
     * Generate a new ID for using on the document references for this file
     */
    const uploadID = random.generate(20);
    /**
     * Build out some metadata for the image
     */
    const meta = {
      customMetadata: {
        bookingID,
        fileID: uploadID,
        uploadedBy: auth.currentUser.uid,
      },
    };
    /**
     * Move the file into firebase storage
     */
    const upload = storage
      .ref(`bookings/${bookingID}/atol_certificate/${uploadID}`)
      .put(file, meta);
    /**
     * Return a promise listening to the upload progress
     */
    return new Promise((resolve, reject) => {
      /**
       * Setup the monitor for the file
       */
      monitorUploadProgress(upload, file.id).then(() => {
        /**
         * When it's complete, get the downloadURL from the callback
         */
        upload.snapshot.ref.getDownloadURL().then(async (url) => {
          /**
           * Add the image as a document for the booking
           */
          await db.doc(`bookings/${bookingID}`).set(
            {
              atol_certificate: {
                access_link: url,
                generated: false,
                sent: false,
                uploaded: firebase.firestore.FieldValue.serverTimestamp(),
              },
            },
            { merge: true }
          );
          /**
           * Then add a log regarding the client uploading an iamge
           */
          await db.collection(`bookings/${bookingID}/logs`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            type: "ATOL_CERTIFICATE_UPLOADED",
            message: "An ATOL certificate was uploaded",
            user: auth.currentUser.uid,
            badge: "INFO",
          });
          /**
           * Resolve the promise
           */
          resolve(url);
          setUploadingDocument(false);
        });
      });
    });
  };

  /**
   * Handles the uploading of the file into the storage bucket
   */
  const handleGeneratedFileUpload = (file) => {
    /**
     * Generate a new ID for using on the document references for this file
     */
    const uploadID = random.generate(20);
    /**
     * Build out some metadata for the image
     */
    const meta = {
      contentType: "application/pdf",
      customMetadata: {
        bookingID,
        fileID: uploadID,
        uploadedBy: auth.currentUser.uid,
      },
    };
    /**
     * Move the file into firebase storage
     */
    const upload = storage
      .ref(`bookings/${bookingID}/atol_certificate/${uploadID}`)
      .put(file, meta);
    /**
     * Return a promise listening to the upload progress
     */
    return new Promise((resolve, reject) => {
      /**
       * Setup the monitor for the file
       */
      monitorUploadProgress(upload, file.id).then(() => {
        /**
         * When it's complete, get the downloadURL from the callback
         */
        upload.snapshot.ref.getDownloadURL().then(async (url) => {
          /**
           * Add the image as a document for the booking
           */
          await db.doc(`bookings/${bookingID}`).set(
            {
              atol_certificate: {
                access_link: url,
                generated: true,
                sent: false,
                uploaded: firebase.firestore.FieldValue.serverTimestamp(),
              },
            },
            { merge: true }
          );
          /**
           * Then add a log regarding the client uploading an iamge
           */
          await db.collection(`bookings/${bookingID}/logs`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            type: "ATOL_CERTIFICATE_UPLOADED",
            message: "The generated ATOL certificate was saved & uploaded",
            user: auth.currentUser.uid,
            badge: "INFO",
          });
          /**
           * Resolve the promise
           */
          resolve(url);
          setUploadingDocument(false);
        });
      });
    });
  };

  /**
   * Attach a listener onto the upload job to stream the progress into state
   */
  const monitorUploadProgress = (upload, fileID) => {
    return new Promise((resolve, reject) => {
      /**
       * Setup a listener on the upload process
       */
      upload.on(
        "state_changed",
        (snapshot) => {
          /**
           * Work out a progress percentage
           */
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          /**
           * Update the state with the progress of this file
           */
          setUploadProgress(progress);
        },
        (error) => console.log(error),
        () => {
          resolve();
        }
      );
    });
  };

  return (
    <>
      <table className="booking-table">
        <tbody>
          <tr>
            <td>What's protected?</td>
            <td>
              <Textarea
                type="text"
                value={ATOLCertificateInformation}
                onChange={setATOLCertificateInformation}
                placeholder="Information for 'What is protected?':"
                activeOnHover={true}
                onBlur={() => saveATOLInformation()} />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <div className="table-room-lower-border" />
            </td>
          </tr>
          <tr>
            <td>Upload Certificate</td>
            <td className="vertical-center">
              <File
                placeholder="Choose file"
                accept="application/pdf"
                onChange={(files) => checkChosenFiles(files)}
              />
            </td>
          </tr>
          <tr>
            <td>Generate Certificate</td>
            <td className="vertical-center">
              <Button
                label="Generate Certificate"
                xsSmall={true}
                onClick={() => setViewCertModal(true)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <div className="table-room-lower-border" />
            </td>
          </tr>
          <tr>
            <td></td>
            <td className="vertical-center">
              {/* Certificate has not yet been sent */}
              {!ATOLCertificate && uploadProgress === 0 && (
                <Badge label="Certificate not generated or uploaded yet" />
              )}

              {!ATOLCertificate && uploadProgress > 0 && (
                <Badge label={`Uploading (${uploadProgress}%)...`} />
              )}

              {/* Certificate has been sent to the client */}
              {ATOLCertificate && (
                <Badge type="INFO" label={`ATOL Certificate uploaded ${ATOLCertificateUploaded}`} />
              )}
            </td>
          </tr>
          {ATOLCertificate && (
            <tr>
              <td></td>
              <td className="vertical-center">
                <a
                  className="view-atol-certificate"
                  href={ATOLCertificate}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Certificate
                </a>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {viewCertModal && (
        <Window title="Generate &amp; Send ATOL Certificate" close={() => setViewCertModal(false)}>
          {newCertLoading && (
            <div className="cert-loading">
              <p>{certProgressStatus}</p>
              <div className="svg-loading-wrap">
                <LoadingIcon />
              </div>
            </div>
          )}

          {!newCertLoading && certUnitArray?.length > 0 && (
            <>
              <p className="review-atol-cert">
                Please review the new ATOL certificate and if all looks good, send it on to the
                client with the button below - this will also upload a copy to the booking for
                reference.
              </p>
              <Button
                label="Upload &amp; Send Certificate"
                loading={sendingCertificate}
                loadingText={sendingCertificateLabel}
                onClick={() => uploadAndSendCertificate()}
              />
            </>
          )}
        </Window>
      )}
    </>
  );
}

import React, { useState } from "react";
import { auth, db, fun } from "../../../utils/firebase";
import firebase from "firebase";

/**
 * UI components
 */
import Input from "../../../components/ui/inputs/input";
import Button from "../../../components/ui/button/button";
import Window from "../../../components/structure/window/window";
import WindowCard from "../../../components/ui/window-card/window-card";

/**
 * Functional component to return the form for adding an internal partner
 */
function AddAgent(props) {
    const [saving, setSaving] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    /**
     * Save the new partner details into the databse
     */
    const saveNewSystemUser = async () => {
        /**
         * Show the saving spinner
         */
        setSaving(true);
        /**
         * Call a cloud function to set a new user auth account
         */
        const createAuthAccount = fun.httpsCallable("createAuthAccount");
        const authAccountID = await createAuthAccount({
            email,
            password,
        });
        /**
         * If a new UID was returned (an auth account was created)
         */
        if (authAccountID.data.uid) {
            /**
             * Add the new user into the database
             */
            await db.doc(`users/${authAccountID.data.uid}`).set({
                email,
                first_name: firstName,
                last_name: lastName,
                is_staff: true,
                show_for: {
                    assignable: false,
                    targets: false,
                },
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
            }, { merge: true });
        }
        /**
         * Reset the state
         */
        setSaving(false);
        props.close();
    }

    return (
        <Window
            title="Add system user"
            className="slim"
            close={() => props.close()}>

            <WindowCard>
                <div className="input-grid cols-2">
                    <Input
                        type="text"
                        placeholder="First name:"
                        value={firstName}
                        onChange={setFirstName} />

                    <Input
                        type="text"
                        placeholder="Last name:"
                        value={lastName}
                        onChange={setLastName} />

                    <Input
                        type="email"
                        placeholder="Email:"
                        value={email}
                        onChange={setEmail}
                        wrapperClass="span-2" />

                    <Input
                        type="password"
                        placeholder="Password:"
                        value={password}
                        onChange={setPassword} />

                    <Input
                        type="password"
                        placeholder="Confirm password:"
                        value={passwordConfirm}
                        onChange={setPasswordConfirm} />

                    <Button
                        label="Add new user"
                        loading={saving}
                        loadingText="Saving..."
                        onClick={() => saveNewSystemUser()}
                        disabled={!firstName || !lastName || !email || !password || (password.length < 8) || (password !== passwordConfirm)}
                        className="span-2" />
                </div>
            </WindowCard>
        </Window>
    )
}

export default AddAgent;
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

/**
 * Route components
 */
import Error404 from "../../containers/errors/404";

/**
 * Returns the routes for clients on the app
 */
function ClientPageRoutes() {
    return (
        <Switch>

            {/* Page redirects for client accounts */}
            <Redirect from="/" to="/bookings" exact />
            <Redirect from="/login" to="/bookings" exact />

            {/* Error pages */}
            <Route path="*" component={Error404} />
        </Switch>
    );
}

export default ClientPageRoutes;
import React, { useEffect, useState } from "react";
import { auth, db } from "../../../utils/firebase";

// UI components
import Partner from "../../../components/partner/partner";
import Table from "../../../components/structure/table/table";
import Checkbox from "../../../components/ui/checkbox/checkbox";
import { LoadingIcon } from "../../../utils/svgs";

// Course partners tab
export default function Courses(props) {
    const [courses, setCourses] = useState([]);
    const [hideConnected, setHideConnected] = useState(false);

    // On component load
    useEffect(() => {
        fetchCoursePartners();
    }, []);

    // Fetch the hotel partners from the database
    const fetchCoursePartners = async () => {
        // Query the database for the course partners
        db.collection("courses")
            .orderBy("name")
            .get().then((queryDocs) => {
                // Store a local array of them to write to from the database query
                let courseDocs = []

                // Loop over each partner and add them into the local array
                queryDocs.forEach((courseDoc) => {
                    courseDocs.push({ id: courseDoc.id, ...courseDoc.data(), })
                })

                // Then set them into the state
                setCourses(courseDocs)
            })
    }

    return (
        <>
            {auth.currentUser.uid === 'CGOHCdUwFAOe13OSTY2D9qdO2LX2' && (
                <>
                    <Checkbox
                        label="Hide connected"
                        checked={hideConnected}
                        onClick={() => setHideConnected(!hideConnected)} />

                    <br />
                </>
            )}

            <Table
                className="partners-table"
                headings={[
                    "Name",
                    "Email",
                    "",
                    "",
                ]}>

                {(courses.length === 0) &&
                    <div className="table-loading-splash">
                        <div className="loading-wrapper">
                            <p>Loading...</p>
                            <div className="svg-loading-wrap">
                                <LoadingIcon />
                            </div>
                        </div>
                    </div>
                }

                {courses.map((partner) => (
                    <Partner
                        key={partner.id}
                        id={partner.id}
                        details={partner}
                        collection="courses"
                        hideConnected={hideConnected}
                        showPartnerWindow={(partnerDoc) => props.setViewPartner(partnerDoc)} />
                ))}
            </Table>
        </>
    );
}
import React, { useEffect, useState, useContext, useRef } from "react";
import { AlertsContext } from "../../utils/providers/alerts";
import { db } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";

/**
 * UI components & structure
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Timepicker from "../../components/ui/datepicker/timepicker";
import Checkbox from "../../components/ui/checkbox/checkbox";
import Button from "../../components/ui/button/button";
import Images from "./sections/images";
import Nearby from "./sections/nearby";

/**
 * Functional component to return the partner page for adding check in/check out times, nearby 
 * resturants and images etc.
 */
export default function Partner(props) {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [partner, setPartner] = useState({});
    const [nearbyHidden, setNearbyHidden] = useState(false);

    /**
     * Check in/out times
     */
    const [checkInTime, setCheckInTime] = useState(0);
    const [checkOutTime, setCheckOutTime] = useState(0);

    /**
     * Deconstruct the partner ID from the url params
     */
    const { id, collection } = props.match.params;

    /**
     * Use the pushAlert function from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Setup a reference to use inside this component keeping track of wheter it's the first render
     */
    const notInitialRender = useRef(false);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull the partner details from the databse
         */
        db.doc(`${collection}/${id}`)
            .get().then((partnerDoc) => {
                /**
                 * Pull some data from the partner document
                 */
                const { check_in, check_out, nearby_hidden } = partnerDoc.data();
                /**
                 * Set the sata for this partner to the state
                 */
                setPartner({ ...partnerDoc.data() });
                setNearbyHidden(nearby_hidden || false);
                /**
                 * If there is a check in time available
                 */
                if (check_in) {
                    /**
                     * Convert it into milliseconds for the input
                     */
                    const checkInAsMillis = moment(check_in, "h:mmA").valueOf();
                    /**
                     * Set it into the state
                     */
                    setCheckInTime(checkInAsMillis);
                }
                /**
                 * If there is a check out time available
                 */
                if (check_out) {
                    /**
                     * Convert it into milliseconds for the input
                     */
                    const checkOutAsMillis = moment(check_out, "h:mmA").valueOf();
                    /**
                     * Set it into the state
                     */
                    setCheckOutTime(checkOutAsMillis);
                }
                /**
                 * Then set the loaded state to true to allow the saving function to run 1 second later
                 */
                setTimeout(() => {
                    /**
                     * Update the state
                     */
                    setLoading(false);
                    /**
                     * And then change the referece state
                     */
                    notInitialRender.current = true;
                }, 200);
            });
    }, [id, collection]);

    /**
     * Save the resort details into the database
     */
    const saveResortDetails = async () => {
        /**
         * Show the resort as saving
         */
        setSaving(true);
        /**
         * Check to see if the check in time is valid
         */
        if (checkInTime > 0) {
            await updateCheckInTime();
        }
        /**
         * Check to see if the check out time is valid
         */
        if (checkOutTime > 0) {
            await updateCheckOutTime();
        }
        /**
         * Show an alert to say it's been updated
         */
        pushAlert({
            type: "SUCCESS",
            title: "Resort profile updated",
        });
        /**
         * Reset the state when complete
         */
        setSaving(false);
    }

    /**
     * Update the check in time with the partner record
     */
    const updateCheckInTime = async () => {
        /**
         * Parse the milliseconds as a string to save into the database
         */
        const checkInString = moment(checkInTime).format("h:mmA");
        /**
         * Save it into the database
         */
        await db.doc(`${collection}/${id}`).set({
            check_in: checkInString,
        }, { merge: true });
    }

    /**
     * Update the check out time with the partner record
     */
    const updateCheckOutTime = async () => {
        /**
         * Parse the milliseconds as a string to save into the database
         */
        const checkOutString = moment(checkOutTime).format("h:mmA");
        /**
         * Save it into the database
         */
        await db.doc(`${collection}/${id}`).set({
            check_out: checkOutString,
        }, { merge: true });
    }

    /**
     * Unset the current check in time from the state and document
     */
    const clearCheckInTime = async () => {
        /**
         * Clear the state
         */
        setCheckInTime(0);
        /**
         * Unset the field on the document
         */
        await db.doc(`${collection}/${id}`).set({
            check_in: firebase.firestore.FieldValue.delete(),
        }, { merge: true });
        /**
         * Show an alert to say it's been updated
         */
        pushAlert({
            type: "SUCCESS",
            title: "Check in time removed",
        });
    }

    /**
     * Unset the current check out time from the state and document
     */
    const clearCheckOutTime = async () => {
        /**
         * Clear the state
         */
        setCheckOutTime(0);
        /**
         * Unset the field on the document
         */
        await db.doc(`${collection}/${id}`).set({
            check_out: firebase.firestore.FieldValue.delete(),
        }, { merge: true });
        /**
         * Show an alert to say it's been updated
         */
        pushAlert({
            type: "SUCCESS",
            title: "Check out time removed",
        });
    }

    /**
     * Toggle the visibility for the nearby section
     */
    const toggleNearbyHidden = async () => {
        /**
         * Flip the state of the current toggle
         */
        const flippedState = !nearbyHidden;
        /**
         * Unset the field on the document
         */
        await db.doc(`${collection}/${id}`).set({
            nearby_hidden: flippedState,
        }, { merge: true });
        /**
         * Toggle the state
         */
        setNearbyHidden(flippedState);
        /**
         * Show an alert to say it's been updated
         */
        pushAlert({
            type: "SUCCESS",
            title: "Nearby visibility toggled",
        });
    }

    return (
        <Tile fullPage={true}>
            {/* If we are in a loading state */}
            {(loading && !partner.name) && "loading"}

            {/* If the partner data has loaded in */}
            {(!loading && partner.name) &&
                <>
                    <Title className="flex">
                        <h1>{partner.name}</h1>

                        <Button
                            label="Save details"
                            small={true}
                            loading={saving}
                            loadingText="Saving..."
                            onClick={() => saveResortDetails()} />
                    </Title>

                    {/* Only show the check in/out times for hotels */}
                    {(collection === "hotels") &&
                        <table className="booking-table">
                            <tbody>
                                <tr>
                                    <td colSpan={2} className="partner-table-title">Check in/out times</td>
                                </tr>
                                <tr>
                                    <td>Check in time</td>
                                    <td>
                                        <Timepicker
                                            placeholder="Choose time:"
                                            value={checkInTime}
                                            onSelect={(time) => setCheckInTime(time)}
                                            activeOnHover={true} />
                                    </td>
                                    {(checkInTime > 0) &&
                                        <td>
                                            <Button
                                                label="Clear"
                                                small={true}
                                                onClick={() => clearCheckInTime()} />
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <td>Check out time</td>
                                    <td>
                                        <Timepicker
                                            placeholder="Choose time:"
                                            value={checkOutTime}
                                            onSelect={(time) => setCheckOutTime(time)}
                                            activeOnHover={true} />
                                    </td>
                                    {(checkOutTime > 0) &&
                                        <td>
                                            <Button
                                                label="Clear"
                                                small={true}
                                                onClick={() => clearCheckOutTime()} />
                                        </td>
                                    }
                                </tr>
                                <tr><td colSpan={2} className="seperator"></td></tr>
                            </tbody>
                        </table>
                    }

                    <Images collection={collection} id={id} />

                    {/* Again only show the nearby section for hotels */}
                    {(collection === "hotels") &&
                        <>
                            <table className="booking-table">
                                <tbody>
                                    <tr>
                                        <td colSpan={2} className="partner-table-title">Nearby restaurants</td>
                                    </tr>
                                    <tr>
                                        <td>Hide restaurants</td>
                                        <td className="vertical-center">
                                            <Checkbox
                                                checked={nearbyHidden}
                                                onClick={() => toggleNearbyHidden()}
                                                label="Hide 'nearby restaurants' section for clients" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {!nearbyHidden &&
                                <Nearby collection={collection} id={id} />
                            }
                        </>
                    }
                </>
            }
        </Tile>
    );
}
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../utils/providers/auth";
import { db } from "../../utils/firebase";
import "./staff.scss";

/**
 * Algolia
 */
import algoliasearch from "algoliasearch";
import {
    InstantSearch,
    Configure,
    connectHits,
    connectStateResults,
} from "react-instantsearch-dom";

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Table from "../../components/structure/table/table";
import Navigation from "../../components/ui/navigation/navigation";
import Enquiry from "../../components/enquiry/enquiry";
import ViewEnquiry from "../../components/enquiry/view/view";
import Select from "../../components/ui/select/select";

/**
 * Functional component to return the enquiry tables
 */
function StaffEnquiries() {
    const [agents, setAgents] = useState({});
    const [agent, setAgent] = useState({});
    const [activeTab, setActiveTab] = useState("BEING_PROCESSED");
    const [viewEnquiry, setViewEnquiry] = useState("");

    // Pull the current user's object in from the auth context
    const { user } = useContext(AuthContext);

    /**
     * On load for the context
     */
    useEffect(() => {
        /**
         * Pull a list of agents from the use list
         */
        db.collection("users")
            .orderBy("first_name")
            .where("show_for.assignable", "==", true)
            .get().then((agentDocs) => {
                let agentsArr = [];
                /**
                 * Loop through the agent documents found
                 */
                agentDocs.forEach((agentDoc) => {
                    /**
                     * Deconstruct the agent details
                     */
                    const { first_name, last_name } = agentDoc.data();
                    /**
                     * Add them into the array
                     */
                    agentsArr[agentDoc.id] = `${first_name} ${last_name}`;
                });
                /**
                 * Push the user into the state
                 */
                setAgents(agentsArr);
            });
    }, []);

    /**
     * Algolia search API keys 
     */
    const searchClient = algoliasearch(
        "HY764WYKNV",
        "059a9cd79a33ec2b2822acf2ffdfd4c9",
    );

    /**
     * Setup a custom function for returning the hits streamed down from algolia
     */
    const Hits = ({ hits }) => {
        /**
         * Are there any hits found from algolia?
         */
        if (hits.length > 0) {
            /**
             * Print the objects found
             */
            return hits.map((hit) => (
                <Enquiry
                    key={hit.objectID}
                    id={hit.objectID}
                    details={hit}
                    maskDetails={user?.mask_details}
                    showEnquiryWindow={(enquiryID) => setViewEnquiry(enquiryID)} />
            ));
        } else {
            /**
             * Return nothing for the hits
             */
            return null;
        }
    };

    /**
     * Connect the custom hits to the DOM
     */
    const CustomHits = connectHits(Hits);

    /**
     * Connect a custom results component to display either the results no a no results message
     */
    const Results = connectStateResults(
        ({ searchState, searchResults, children }) =>
            searchResults && searchResults.nbHits !== 0 ? (
                <CustomHits />
            ) : (
                <tr>
                    <td colSpan={8}>
                        <small>No results have been found {searchState.query ? `for ${searchState.query}.` : ""}</small>
                    </td>
                </tr>
            )
    );

    return (
        <Tile fullPage={true}>
            <InstantSearch searchClient={searchClient} indexName="enquiries">

                <Configure filters={`status:${activeTab} AND agent:${agent.option}`} />

                <Title className="flex has-select-field">
                    <h1>Agent enquiries</h1>

                    {/* Dropdown to choose a sales agent to view */}
                    <Select
                        placeholder="Choose agent"
                        value={agent.value}
                        selected={agent.option}
                        activeOnHover={true}
                        onSelect={(option) => setAgent(option)}
                        options={agents} />
                </Title>

                <Navigation
                    links={[{
                        id: "BEING_PROCESSED",
                        label: "Being processed",
                    }, {
                        id: "HOT",
                        label: "Hot leads",
                    }, {
                        id: "FREEPHONE",
                        label: "Freephone leads",
                    }]}
                    active={activeTab}
                    onClick={(tabID) => setActiveTab(tabID)} />

                {/* Print the various results into the table */}
                <Table
                    className="enquiries-table"
                    headings={[
                        "Site",
                        "Name",
                        "Email",
                        "Telephone",
                        "Follow up",
                        "Location/Event",
                        "Date from",
                        "Group size",
                        "Quoted",
                    ]}>

                    <Results />
                </Table>

                {/* Do we have a enquiryID in the local state to load into a window? */}
                {viewEnquiry &&
                    <ViewEnquiry
                        enquiryID={viewEnquiry}
                        close={() => setViewEnquiry("")} />
                }
            </InstantSearch>
        </Tile>
    );
}

export default StaffEnquiries;
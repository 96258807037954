import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import firebase from "firebase";
import moment from "moment";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Textarea from "../../../components/ui/inputs/textarea";
import Window from "../../../components/structure/window/window";

function Transfer(props) {
    const [booking, setBooking] = useState({});
    const [resort, setResort] = useState({});
    const [hotel, setHotel] = useState({});

    const [approving, setApproving] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [rejectionModal, setRejectionModal] = useState(false);
    const [rejectionComments, setRejectionComments] = useState("");

    /**
     * Get the bookingID from the props
     */
    const { bookingID, hotelID, complete } = props;

    /**
     * Get the group size by adding both golfers and non golfers
     */
    const group_size = Number(booking.golfers + booking.non_golfers);
    /**
     * Get the check in date
     */
    const arrival = moment(hotel.check_in?.seconds, "X").format("DD/MM/YYYY");
    /**
     * Get the departure date by adding the nights to the original check in date
     */
    const departure = moment(hotel.check_in?.seconds, "X").add(hotel.nights, "days").format("DD/MM/YYYY");

    /**
     * When the bookingID is updated
     */
    useEffect(() => {
        pullTransferDetails();
    }, [bookingID, hotelID]);

    /**
     * Pull the details for the transfer from the databse to display on the front-end
     */
    const pullTransferDetails = async () => {
        /**
         * Get the booking details from the database
         */
        await db.doc(`bookings/${bookingID}`)
            .get().then((bookingDoc) => {
                /**
                 * Set the booking data into the state
                 */
                setBooking({ ...bookingDoc.data() });
            });
        /**
         * Then get the hotel details from the database
         */
        await db.doc(`bookings/${bookingID}/hotels/${hotelID}`)
            .get().then((hotelDoc) => {
                /**
                 * Get the resortID for the hotel
                 */
                const { resortID } = hotelDoc.data();
                /**
                 * Set the hotel data into the state
                 */
                setHotel({ ...hotelDoc.data() });
                /**
                 * Then get the hotel partner document
                 */
                return db.doc(`hotels/${resortID}`)
                    .get().then((resortDoc) => {
                        /**
                         * Set the hotel data into the state
                         */
                        setResort({ ...resortDoc.data() });
                    });
            });
    }

    /**
     * Approve the reservation
     */
    const approveReservation = async () => {
        /**
         * Update the state to show it's loading
         */
        setApproving(true);
        /**
         * Update the booking document
         */
        await db.doc(`bookings/${bookingID}`).set({
            transfer_booking_status: "approved",
            transfer_booking_comments: "",
        }, { merge: true });
        /**
         * Add a log tot he booking to reflect the approval
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            type: "TRANSFER_BOOKING_APPROVED",
            badge: "SUCCESS",
            message: "The transfer for this booking was approved",
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setApproving(false);
        /**
         * Show the completed thank you message
         */
        complete();
    }

    /**
     * Reject the reservation
     */
    const rejectReservation = async () => {
        /**
         * Update the state to show it's loading
         */
        setRejecting(true);
        setRejectionModal(false);
        /**
         * Update the booking document
         */
        await db.doc(`bookings/${bookingID}`).set({
            transfer_booking_status: "rejected",
            transfer_booking_comments: rejectionComments,
        }, { merge: true });
        /**
         * Add a log tot he booking to reflect the rejection
         */
        await db.collection(`bookings/${bookingID}/logs`).add({
            type: "TRANSFER_BOOKING_REJECTED",
            badge: "ERROR",
            message: `The transfer for this booking was rejected with the following reason: ${rejectionComments}`,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        });
        /**
         * Reset the state
         */
        setRejecting(false);
        /**
         * Show the completed thank you message
         */
        complete();
    }

    return (
        <>
            <div className="reservation-block">
                {/* <div className="reservation-table">
                    <table className="booking-table">
                        <tbody>
                            <tr>
                                <td>Hotel:</td>
                                <td>
                                    {resort?.name}
                                </td>
                            </tr>
                            <tr>
                                <td>Arrival:</td>
                                <td>
                                    {arrival}
                                </td>
                            </tr>
                            <tr>
                                <td>Departure:</td>
                                <td>
                                    {departure}
                                </td>
                            </tr>
                            <tr>
                                <td>Group size:</td>
                                <td>
                                    {group_size}
                                </td>
                            </tr>
                            <tr>
                                <td>Comments:</td>
                                <td>
                                    {booking.transfer_notes && `${booking.transfer_notes}`}
                                    {!booking.transfer_notes && <small>No additional comments</small>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}

                <div className="reservation-actions">
                    <Button
                        label="Approve booking"
                        loading={approving}
                        loadingText="Approving..."
                        onClick={() => approveReservation()} />
                    <Button
                        className="OUTLINE"
                        label="Reject booking"
                        loading={rejecting}
                        loadingText="Rejecting..."
                        onClick={() => setRejectionModal(true)} />
                </div>
            </div>

            {rejectionModal &&
                <Window
                    title="Rejection reason"
                    className="slim reservation-modal"
                    close={() => setRejectionModal(false)}>

                    <Textarea
                        placeholder="Please suggest an alternative date/time:"
                        value={rejectionComments}
                        onChange={setRejectionComments} />

                    <Button
                        fullWidth={true}
                        label="Send rejection reason"
                        disabled={!rejectionComments}
                        onClick={() => rejectReservation()} />
                </Window>
            }
        </>
    );
}

export default Transfer;
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

/**
 * Route components
 */
import Login from "../../containers/login/login";
import ClientLogin from "../../containers/login/client/login";
import MyBookingWrapper from "../../containers/mybooking/mybooking";
import Error404 from "../../containers/errors/404";

/**
 * Returns the routes for the app.
 * 
 * These routes are only loaded in when the parent Route component recognises there is 
 */
function GenericRoutes() {
    return (
        <Switch>
            {/* General app pages */}
            <Route path="/login" component={Login} />
            <Route path="/mybooking" component={ClientLogin} />
            <Route path="/itinerary/:id" component={MyBookingWrapper} />

            {/* Page redirects */}
            <Redirect from="/" to="/login" exact />
            <Redirect from="/itinerary" to="/mybooking" exact />

            {/* Error pages */}
            <Route path="*" component={Error404} />
        </Switch>
    );
}

export default GenericRoutes;
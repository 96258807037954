import React, { useEffect, useState, useContext } from "react";
import { db, auth, arrayRemove } from "../../../utils/firebase";
import { NavLink } from "react-router-dom";
import { SaveIcon } from "../../../utils/svgs";
import { ModalContext } from "../../../utils/providers/modal";
import firebase from "firebase";

/**
 * UI components
 */
import Window from "../../structure/window/window";
import WindowCard from "../../ui/window-card/window-card";
import Textarea from "../../ui/inputs/textarea";
import Button from "../../ui/button/button";
import Select from "../../ui/select/select";

/**
 * Functional component to return a window full screen with the cancellation details
 */
export default function ViewCancellation(props) {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [clientID, setClientID] = useState("");
    const [client, setClient] = useState({});
    const [notes, setNotes] = useState("");
    const [bookingID, setBookingID] = useState("");
    const [bookingRemoved, setBookingRemoved] = useState(false);

    /**
     * Deconstruct the props
     */
    const { cancellationID } = props;

    /**
     * Pull the show modal function from the modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the cancellation document from the database
         */
        db.doc(`cancellations/${cancellationID}`)
            .get().then((cancellationDoc) => {
                /**
                 * Deconstruct the cancellation data
                 */
                const {
                    booking_data,
                    notes,
                    bookingID,
                    resort_confirmed,
                    booking_removed,
                } = cancellationDoc.data();
                /**
                 * Set the booking data into the state
                 */
                setClientID(booking_data.client);
                setNotes(notes);
                setBookingID(bookingID);
                setConfirmed(resort_confirmed);
                setBookingRemoved(booking_removed ? true : false);
            });
    }, [cancellationID]);

    /**
     * When the clientID is updated
     */
    useEffect(() => {
        /**
         * Get the client document from the database
         */
        clientID && db.doc(`clients/${clientID}`).get().then((clientDoc) => {
            /**
             * Set the client details into the state
             */
            setClient(clientDoc.data());
        });
    }, [clientID]);

    /**
     * Save the cancellation details into the database
     */
    const saveCancellationDetails = async () => {
        /**
         * Show a spinner on the save button
         */
        setSaving(true);
        /**
         * Save the details into the database for the cancellation
         */
        await db.doc(`cancellations/${cancellationID}`).set({
            resort_confirmed: confirmed ? true : false,
            notes: notes || "",
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        /**
         * Check to see if now the cancellation has been approved/accepted
         */
        if (confirmed && !bookingRemoved) {
            /**
             * Process the booking removal window
             */
            checkToRemoveBooking();
        }
        /**
         * Reset the state
         */
        setEditing(false);
        setSaving(false);
    }

    /**
     * Check before removing the booking
     */
    const checkToRemoveBooking = () => {
        /**
         * Display the modal to check before an booking removal
         */
        showModal({
            type: "ALERT",
            title: "Remove booking?",
            body: "With the cancellation accepted, would you like to remove all booking data?",
            cancel: {
                label: "Cancel",
                action: () => { return null; },
            },
            next: {
                label: "Yes, i'm sure",
                action: () => removeBooking(),
            }
        });
    }

    /**
     * Remove the booking from the database
     */
    const removeBooking = async () => {
        /**
         * Remove the booking ID from the clients bookings array
         */
        await db.doc(`clients/${clientID}`).set({
            bookings: arrayRemove(bookingID),
        }, { merge: true });
        /**
         * Update the cancellation to show when & who removed the booking
         */
        await db.doc(`cancellations/${cancellationID}`).set({
            booking_removed: firebase.firestore.FieldValue.serverTimestamp(),
            booking_removed_by: auth.currentUser.uid,
        }, { merge: true });
        /**
         * Then remove the booking document
         */
        await db.doc(`bookings/${bookingID}`).delete();
        /**
         * Add a log to the client record showing their booking was deleted
         */
        await db.collection(`clients/${clientID}/logs`).add({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            message: "Booking was removed",
            type: "BOOKING_REMOVED",
            badge: "INFO",
            user: auth.currentUser.uid,
            meta: {
                bookingID: bookingID,
                cancellationID: cancellationID,
            }
        });
        /**
         * Refresh the current page
         */
        window.location.href = "/cancellations";
    }

    return (
        <Window
            title={`${client.first_name}'s booking cancellation`}
            close={() => props.close()}>

            <WindowCard
                title="Cancellation status"
                primaryAction={{
                    icon: <SaveIcon />,
                    label: "Save",
                    onClick: () => saveCancellationDetails(),
                    loading: saving,
                    loadingText: "Saving...",
                }}>
                <div className="input-grid cols-4">
                    <div className="span-4">
                        <Select
                            showLabel={editing}
                            value={confirmed ? "Confirmed & accepted by the resort" : "Not yet confirmed and/or reject by resort"}
                            placeholder="Resorts confirmed:"
                            selected={confirmed}
                            readOnly={bookingRemoved}
                            onSelect={(option) => setConfirmed(option.option === "true" ? true : false)}
                            options={{
                                true: "Confirmed & accepted by the resort",
                                false: "Not yet confirmed and/or reject by resort",
                            }} />
                    </div>

                    <div className="span-4">
                        <Textarea
                            type="text"
                            label="Cancellation notes:"
                            value={notes}
                            onChange={setNotes}
                            placeholder="Cancellation notes:"
                            showLabel={editing} />
                    </div>

                    <div className={[
                        "ui-window-actions include-button-styles",
                        bookingRemoved ? "disable-button" : ""
                    ].join(" ")}>
                        <NavLink to={`booking/${bookingID}`}>
                            {bookingRemoved ? "Booking removed" : "View original booking"}
                        </NavLink>
                    </div>
                </div>
            </WindowCard>
        </Window >
    );
}
import { useContext } from 'react'
import { QuoteContext } from '../../context/context'
import './configure.scss'

// UI components
import Checkbox from '../../../../components/ui/checkbox/checkbox'
import Button from '../../../../components/ui/button/button'
import Input from '../../../../components/ui/inputs/input'
import Package from './package/package'

// Returns the HTML markup for configuring a given set of parameters for the quote
export default function Configure({ previousStage, savePackage, saving }) {
    // Pull the total cost from the quote context
    const {
        ctxSelectedPackages,
        ctxIncluded, setCtxIncluded,
        ctxGroupSize,
        ctxChargeAirportTransfer, setCtxChargeAirportTransfer,
        ctxChargeGolfTransfer, setCtxChargeGolfTransfer,
        ctxChargeFlights, setCtxChargeFlights,
        ctxChargeEuroTunnel, setCtxChargeEuroTunnel,
        ctxChargeCarHire, setCtxChargeCarHire,
        ctxConvertedBase,
        ctxFinalCost,
        ctxSellAtCost, setCtxSellAtCost,
        ctxExchangeRate, setCtxExchangeRate,
        ctxSaleAdjustedMarkup,
        ctxSaleProfit,
        ctxAgentCommission,
    } = useContext(QuoteContext)

    return (
        <>
            <div className='generate-quote-header'>
                <div className='generate-quote-title'>Configure Package(s)</div>
                <div className='generate-quote-subtitle'>Configure the quote parameters and adjust chosen packages</div>
            </div>

            <div className='generate-quote'>
                {ctxSelectedPackages.map((chosenPackage) => (
                    <Package id={chosenPackage.id} details={chosenPackage} />
                ))}
            </div>

            <div className='generate-quote-chosen-package generate-quote-adhoc-fields'>
                <p className='generate-quote-adhoc-title'>
                    Additional Charges & Notes
                </p>
                
                <div className={['generate-quote-adhoc-field', ctxIncluded.airport_transfers ? 'is-included' : ''].join(' ').trim()}>
                    <Checkbox
                        label='Airport transfers'
                        checked={ctxIncluded.airport_transfers}
                        onClick={() => setCtxIncluded({ ...ctxIncluded, airport_transfers: !ctxIncluded.airport_transfers})} />

                    <Input
                        symbol='£'
                        placeholder='Cost'
                        value={ctxChargeAirportTransfer}
                        onChange={setCtxChargeAirportTransfer} />
                </div>

                <div className={['generate-quote-adhoc-field', ctxIncluded.golf_transfers ? 'is-included' : ''].join(' ').trim()}>
                    <Checkbox
                        label='Golf transfers'
                        checked={ctxIncluded.golf_transfers}
                        onClick={() => setCtxIncluded({ ...ctxIncluded, golf_transfers: !ctxIncluded.golf_transfers})} />

                    <Input
                        symbol='£'
                        placeholder='Cost'
                        value={ctxChargeGolfTransfer}
                        onChange={setCtxChargeGolfTransfer} />
                </div>

                <div className={['generate-quote-adhoc-field', ctxIncluded.euro_tunnel ? 'is-included' : ''].join(' ').trim()}>
                    <Checkbox
                        label='Euro tunnel'
                        checked={ctxIncluded.euro_tunnel}
                        onClick={() => setCtxIncluded({ ...ctxIncluded, euro_tunnel: !ctxIncluded.euro_tunnel})} />

                    <Input
                        symbol='£'
                        placeholder='Cost'
                        value={ctxChargeEuroTunnel}
                        onChange={setCtxChargeEuroTunnel} />
                </div>

                <div className={['generate-quote-adhoc-field', ctxIncluded.car_hire ? 'is-included' : ''].join(' ').trim()}>
                    <Checkbox
                        label='Car hire'
                        checked={ctxIncluded.car_hire}
                        onClick={() => setCtxIncluded({ ...ctxIncluded, car_hire: !ctxIncluded.car_hire})} />

                    <Input
                        symbol='£'
                        placeholder='Cost'
                        value={ctxChargeCarHire}
                        onChange={setCtxChargeCarHire} />
                </div>

                <div className={['generate-quote-adhoc-field', ctxIncluded.flights ? 'is-included' : ''].join(' ').trim()}>
                    <Checkbox
                        label='Flights'
                        checked={ctxIncluded.flights}
                        onClick={() => setCtxIncluded({ ...ctxIncluded, flights: !ctxIncluded.flights})} />

                    <Input
                        symbol='£'
                        placeholder='Cost'
                        value={ctxChargeFlights}
                        onChange={setCtxChargeFlights} />
                </div>
            </div>

            <div className='generate-quote-chosen-package generate-quote-adhoc-fields'>
                <p className='generate-quote-adhoc-title'>
                    Selling Price & Exchange Rate
                </p>
                
                <div className='generate-quote-adhoc-field exchange-rate'>
                    <p>Exchange rate:</p>

                    <Input
                        type='number'
                        value={ctxExchangeRate}
                        onChange={setCtxExchangeRate}
                        placeholder='Rate' />
                </div>

                <div className='generate-quote-adhoc-field sell-at-price'>
                    <p>Sell at price:</p>

                    <Input
                        symbol='£'
                        type='number'
                        value={ctxSellAtCost}
                        onChange={setCtxSellAtCost}
                        placeholder='Golfers' />
                </div>
            </div>

            <div className='generate-quote-total'>
                <span>Total cost to client:</span>
                <p>£{ctxFinalCost}</p>
            </div>

            <div className='generate-quote-totals-table'>
                <div className='generate-quote-totals-row'>
                    <p>Total cost price:</p>
                    <p>£{ctxConvertedBase * ctxGroupSize}</p>
                </div>

                <div className='generate-quote-totals-row'>
                    <p>Per person cost price:</p>
                    <p>£{ctxConvertedBase}</p>
                </div>

                <div className='generate-quote-totals-row'>
                    <p>Sales markup:</p>
                    <p>{ctxSaleAdjustedMarkup}% <span>(Adj.)</span></p>
                </div>

                <div className='generate-quote-totals-row'>
                    <p>Sale profit:</p>
                    <p>£{(ctxSaleProfit * ctxGroupSize).toFixed(2)}</p>
                </div>

                <div className='generate-quote-totals-row'>
                    <p>Commission:</p>
                    <p>£{(ctxAgentCommission * ctxGroupSize).toFixed(2)}</p>
                </div>
            </div>

            <div className='quote-action-buttons is-triple'>
                <Button
                    type='secondary'
                    label='Back'
                    onClick={() => previousStage()} />

                <Button
                    label='Save & add another'
                    loading={saving}
                    loadingText='Saving...'
                    onClick={() => savePackage()} />
                
                <Button
                    label='Confirm'
                    onClick={() => savePackage(true)} />
            </div>
        </>
    )
}

import React, { useContext, useEffect, useState } from "react"
import { BookingContext } from "../../../utils/providers/booking"
import { db, auth } from "../../../utils/firebase"
import firebase from "firebase"
import moment from "moment"

// UI components
import Textarea from "../../../components/ui/inputs/textarea"
import Button from "../../../components/ui/button/button"
import NoteLog from "../../../components/booking/notes/log/log"

// Notes tab for the booking page
export default function Notes() {
    const [notes, setNotes] = useState("")
    const [baseNotes, setBaseNotes] = useState("")
    const [saving, setSaving] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingHistorical, setLoadingHistorical] = useState(true)
    const [historical, setHistorical] = useState([])

    // Pull the booking ID from the available context
    const { bookingID } = useContext(BookingContext)

    // On component load
    useEffect(() => {
        db.doc(`bookings/${bookingID}`)
            .get().then((bookingDoc) => {
                const { agent_notes } = bookingDoc.data()
                setNotes(agent_notes || "")
                setBaseNotes(agent_notes || "")
                setLoading(false)
            })

        db.collection(`bookings/${bookingID}/logs`)
            .where("type", "==", "NOTES_UPDATED")
            .orderBy("created", "desc")
            .onSnapshot((logs) => {
                const historicalData = []
                logs.forEach((log) => {
                    const logData = log.data()
                    historicalData.push({
                        created: logData.created,
                        user: logData.user,
                        agent_notes: logData.detail.agent_notes,
                    })
                })
                setHistorical(historicalData)
                setLoadingHistorical(false)
            })
    }, [bookingID])

    // Save the private notes against the booking
    const saveNotes = async () => {
        if ((notes !== baseNotes && notes.length > 0) || (baseNotes.length > 0 && notes.length === 0)) {
            setSaving(true)
            await db.doc(`bookings/${bookingID}`).update({
                agent_notes: notes,
            })
            await db.collection(`bookings/${bookingID}/logs`).add({
                type: "NOTES_UPDATED",
                badge: "INFO",
                message: "The agent notes on the booking were ammended",
                created: firebase.firestore.FieldValue.serverTimestamp(),
                user: auth.currentUser.uid,
                detail: {
                    agent_notes: notes,
                }
            })
            setBaseNotes(notes)
            setSaving(false)
        }
    }

    return (
        <div className="booking__notes-wrapper">
            <div className="booking__historical">
                {!loadingHistorical && (
                    <>
                        {historical.length === 0 && (
                            <div className="booking__historical-item booking__historical-item--empty">
                                No historical notes available
                            </div>
                        )}
                        {historical.map((log, index) => (
                            <NoteLog key={index} agentID={log.user} logDetails={log} />
                        ))}
                    </>
                )}
            </div>

            <div className="booking__notes">
                <Textarea 
                    disabled={loading}
                    value={notes}
                    placeholder="Start typing..."
                    onChange={setNotes} 
                    rows={16} />
                <br />
                <Button 
                    label="Save notes" 
                    loading={saving} 
                    onClick={() => saveNotes()} />
            </div>
        </div>
    )
}
import { useState, useEffect, useContext } from 'react'
import { QuoteContext } from '../context/context'
import { db } from '../../../utils/firebase'
import moment from 'moment'

// Algolia library
import algoliasearch from 'algoliasearch/lite'
import {
    InstantSearch,
    connectSearchBox,
    connectHits,
    connectStateResults,
    Index,
} from 'react-instantsearch-dom'

// UI components
import Loading from '../../../components/app/loading/loading'
import Button from '../../../components/ui/button/button'
import Input from '../../../components/ui/inputs/input'
import Badge from '../../../components/ui/badge/badge'
import { SearchIcon } from '../../../utils/svgs-v2'
import Option from '../rows/option'

// Returns the HTML markup and functionality for the searching of resorts and courses through Algolia
export default function Search({ progressStage }) {
    const [options, setOptions] = useState([])
    const [fetchingPricing, setFetchingPricing] = useState(false)

    // Pull some get/set methods from the global quote context
    const { ctxSelectedOptions, setCtxSelectedOptions, ctxCompletedPackages, ctxClearEntireState } = useContext(QuoteContext)

    // When the options are updated
    useEffect(() => {
        // Upadate the global context version
        setCtxSelectedOptions(options)
    }, [options])

    // On component mount
    useEffect(() => {
        // Are there any options available in the global context?
        if (ctxSelectedOptions.length > 0) {
            // Then set the local state to these options
            setOptions(ctxSelectedOptions)
        }
    }, [])

    // Create a new instance of the Algolia search client
    const searchClient = algoliasearch(
        "HY764WYKNV",
        "059a9cd79a33ec2b2822acf2ffdfd4c9",
    );

    // Create a custom input field for the search
    const SearchBox = ({ currentRefinement, refine }) => (
        <form noValidate action="" role="search">
            <Input
                value={currentRefinement}
                icon={<SearchIcon />}
                fullEvent={true}
                placeholder="Search..."
                onChange={(e) => refine(e.currentTarget.value)} />
        </form>
    )

    // And then attach it to the Aloglia instance
    const CustomSearchBox = connectSearchBox(SearchBox)

    // Create a custom flow for returning and displaying the hits against a search
    const ResortHits = ({ hits }) => {
        // Were any hits returned?
        if (hits.length > 0) {
            // Print them into the DOM
            return (
                <div className='quote-search-result-group'>
                    {hits.map((hit) => (
                        <div key={hit.objectID} className='quote-search-result-row' onClick={() => handleResortChosen(hit.objectID, hit)}>
                            <Badge label='Resort' type='INFO' />
                            <p>{hit.title}</p>
                        </div>
                    ))}
                </div>
            )
        } else {
            // Otherwise just return null
            return null;
        }
    }

    // Create a custom flow for returning and displaying the hits against a search
    const CourseHits = ({ hits }) => {
        // Were any hits returned?
        if (hits.length > 0) {
            // Print them into the DOM
            return (
                <div className='quote-search-result-group'>
                    {hits.map((hit) => (
                        <div key={hit.objectID} className='quote-search-result-row' onClick={() => handleCourseChosen(hit.objectID, hit)}>
                            <Badge label='Course' type='POSITIVE' />
                            <p>{hit.title}</p>
                        </div>
                    ))}
                </div>
            )
        } else {
            // Otherwise just return null
            return null;
        }
    }

    // Attach this custom hits component to Aloglia
    const CustomResortHits = connectHits(ResortHits)
    const CustomCourseHits = connectHits(CourseHits)

    // Create a custom component for showing where there are no results against a search
    const ResortResults = connectStateResults(
        ({ searchState, searchResults }) => {
            if (!searchState.query) {
                return null
            }
            
            if (searchResults && searchResults.nbHits !== 0) {
                return <CustomResortHits />
            }
            
            if (searchResults && searchResults.nbHits === 0) {
                return <small className='quote-search-empty'>No results have been found {searchState.query ? `for ${searchState.query}.` : ""}</small>
            }
        }
    )

    // Create a custom component for showing where there are no results against a search
    const CourseResults = connectStateResults(
        ({ searchState, searchResults }) => {
            if (!searchState.query) {
                return null
            }
            
            if (searchResults && searchResults.nbHits !== 0) {
                return <CustomCourseHits />
            }
            
            if (searchResults && searchResults.nbHits === 0) {
                return <small className='quote-search-empty'>No results have been found {searchState.query ? `for ${searchState.query}.` : ""}</small>
            }
        }
    )

    // When choice has been made from the holidays dropdown
    const handleResortChosen = async (resortID, resortData) => {
        // Set the state
        setFetchingPricing(true)

        // Attempt to fetch all the pricing data from the database
        const resortPricing = await db.collection(`CMS_holidays/${resortID}/price_windows`)
            .get().then((pricingDocs) => {
                // Get an array of all price windows
                const pricingArr = pricingDocs.docs.map((pricingDoc) => ({
                    id: pricingDoc.id,
                    price_type: 'resort',
                    resort_id: resortID,
                    ...pricingDoc.data()
                }))

                // Then return this array
                return pricingArr
            })

        // Get a timestamp for the start of today
        const startOfToday = moment().startOf('day').toDate()

        // Check to see if there are any notes recorded
        const linkedNotes = await db.collection(`CMS_holidays/${resortID}/notes`)
            .where('end_date', '>=', startOfToday)
            .get().then((noteDocs) => {
                // Get an array of all resort notes
                const notesArr = noteDocs.docs.map((noteDoc) => ({
                    id: noteDoc.id,
                    ...noteDoc.data()
                }))

                // Then return this array
                return notesArr
            })

        // Pull all available discounts for this resort
        const availableDiscounts = await db.collection(`CMS_holidays/${resortID}/discounts`)
            .where('end_date', '>=', startOfToday)
            .get().then((discountDocs) => {
                // Get an array of all resort discounts
                const discountsArr = discountDocs.docs.map((discountDoc) => ({
                    id: discountDoc.id,
                    ...discountDoc.data()
                }))

                // Then return this array
                return discountsArr
            })

        // Map over each of the resort price windows
        for (const resortPriceWindow of resortPricing) {
            // Get the start and end dates for this price window
            const windowCheckStart = moment(resortPriceWindow.start_date.seconds, 'X')
            const windowCheckEnd = moment(resortPriceWindow.end_date.seconds, 'X')

            // Setup an array of notes to write onto the price window
            const priceWindowNotes = []

            // Map over each of the notes available on the resort
            for (const likedNote of linkedNotes) {
                // Format the start date into a moment date object
                const noteStart = moment(likedNote.start_date.seconds, 'X')
                const noteEnd = moment(likedNote.end_date.seconds, 'X')

                // Store some checked booleans for whether to show or not
                const isStartBetween = noteStart.isBetween(windowCheckStart, windowCheckEnd, null, [])
                const isEndBetween = noteEnd.isBetween(windowCheckStart, windowCheckEnd, null, [])
                const isBeforeAndAfter = (noteStart.isBefore(windowCheckStart) && noteEnd.isAfter(windowCheckEnd))

                // Check to see if the start date for the note can be applied to a price window
                if (isStartBetween || isEndBetween || isBeforeAndAfter) {
                    // Format the dates into a readable format for the config panel
                    const noteFormattedStartDate = moment(likedNote.start_date.seconds, 'X').format('DD/MM/YYYY')
                    const noteFormattedEndDate = moment(likedNote.end_date.seconds, 'X').format('DD/MM/YYYY')

                    // Push the note into the price window object
                    priceWindowNotes.push({
                        ...likedNote,
                        start: noteFormattedStartDate,
                        end: noteFormattedEndDate
                    })
                }
            }

            // Write the notes into the price window
            resortPriceWindow.global_notes = priceWindowNotes

            // Then setup a new array for the discounts
            const priceWindowDiscounts = []

            // Map over each of the available discounts for the resort
            for (const availableDiscount of availableDiscounts) {
                // Format the start date into a moment date object
                const discountStart = moment(availableDiscount.start_date.seconds, 'X')
                const discountEnd = moment(availableDiscount.end_date.seconds, 'X')

                // Store some checked booleans for whether to show or not
                const isStartBetween = discountStart.isBetween(windowCheckStart, windowCheckEnd, null, [])
                const isEndBetween = discountEnd.isBetween(windowCheckStart, windowCheckEnd, null, [])
                const isBeforeAndAfter = (discountStart.isBefore(windowCheckStart) && discountEnd.isAfter(windowCheckEnd))

                // Check to see if the start date for the discount can be applied to a price window
                if (isStartBetween || isEndBetween || isBeforeAndAfter) {
                    // Format the dates into a readable format for the config panel
                    const discountFormattedStartDate = moment(availableDiscount.start_date.seconds, 'X').format('DD/MM/YYYY')
                    const discountFormattedEndDate = moment(availableDiscount.end_date.seconds, 'X').format('DD/MM/YYYY')
                    
                    // Push the discount into the price window object
                    priceWindowDiscounts.push({
                        ...availableDiscount,
                        start: discountFormattedStartDate,
                        end: discountFormattedEndDate
                    })
                }
            }
            
            // Write the discounts into the price window
            resortPriceWindow.global_discounts = priceWindowDiscounts
        }

        // Find all the courses associated with this resort
        const linkedCourses = await db.collection('CMS_courses')
            .where("resort_ids", "array-contains", Number(resortData.page_id))
            .get()

        // Store an array of courses available on this resort
        const resortCourses = []

        // Then we want to map over each of the courses found to load their price_windows
        for (const linkedCourse of linkedCourses.docs) {
            // Push the course title onto the stack
            resortCourses.push(linkedCourse.data().title)
        }

        // Add the new chosen resort into the state
        setOptions((options) => ([...options, {
            ...resortData,
            option_type: 'resort',
            courses: resortCourses,
            // global_notes: resortNotes,
            price_windows: {
                resort: [...resortPricing]
            }
        }]))

        // Reset the state
        setFetchingPricing(false)
    }

    // When choice has been made from the holidays dropdown
    const handleCourseChosen = async (courseID, courseData) => {
        // Set the state
        setFetchingPricing(true)

        // Attempt to fetch all the pricing data from the database
        const coursePricing = await db.collection(`CMS_courses/${courseID}/price_windows`)
            .get().then((pricingDocs) => {
                // Get an array of all price windows
                const pricingArr = pricingDocs.docs.map((pricingDoc) => ({
                    id: pricingDoc.id,
                    price_type: 'course',
                    course_id: courseID,
                    ...pricingDoc.data()
                }))

                // Then return this array
                return pricingArr
            })

        // Get a timestamp for the start of today
        const startOfToday = moment().startOf('day').toDate()

        // Check to see if there are any notes recorded
        const linkedNotes = await db.collection(`CMS_courses/${courseID}/notes`)
            .where('end_date', '>=', startOfToday)
            .get().then((noteDocs) => {
                // Get an array of all resort notes
                const notesArr = noteDocs.docs.map((noteDoc) => ({
                    id: noteDoc.id,
                    ...noteDoc.data()
                }))

                // Then return this array
                return notesArr
            })

        // Pull all available discounts for this resort
        const availableDiscounts = await db.collection(`CMS_courses/${courseID}/discounts`)
            .where('end_date', '>=', startOfToday)
            .get().then((discountDocs) => {
                // Get an array of all resort discounts
                const discountsArr = discountDocs.docs.map((discountDoc) => ({
                    id: discountDoc.id,
                    ...discountDoc.data()
                }))

                // Then return this array
                return discountsArr
            })

        // Map over each of the resort price windows
        for (const coursePriceWindow of coursePricing) {
            // Get the start and end dates for this price window
            const windowCheckStart = moment(coursePriceWindow.start_date.seconds, 'X')
            const windowCheckEnd = moment(coursePriceWindow.end_date.seconds, 'X')

            // Setup an array of notes to write onto the price window
            const priceWindowNotes = []

            // Map over each of the notes available on the resort
            for (const likedNote of linkedNotes) {
                // Format the start date into a moment date object
                const noteStart = moment(likedNote.start_date.seconds, 'X')
                const noteEnd = moment(likedNote.end_date.seconds, 'X')

                // Store some checked booleans for whether to show or not
                const isStartBetween = noteStart.isBetween(windowCheckStart, windowCheckEnd, null, [])
                const isEndBetween = noteEnd.isBetween(windowCheckStart, windowCheckEnd, null, [])
                const isBeforeAndAfter = (noteStart.isBefore(windowCheckStart) && noteEnd.isAfter(windowCheckEnd))

                // Check to see if the start date for the note can be applied to a price window
                if (isStartBetween || isEndBetween || isBeforeAndAfter) {
                    // Format the dates into a readable format for the config panel
                    const noteFormattedStartDate = moment(likedNote.start_date.seconds, 'X').format('DD/MM/YYYY')
                    const noteFormattedEndDate = moment(likedNote.end_date.seconds, 'X').format('DD/MM/YYYY')

                    // Push the note into the price window object
                    priceWindowNotes.push({
                        ...likedNote,
                        start: noteFormattedStartDate,
                        end: noteFormattedEndDate
                    })
                }
            }

            // Write the notes into the price window
            coursePriceWindow.global_notes = priceWindowNotes

            // Then setup a new array for the discounts
            const priceWindowDiscounts = []

            // Map over each of the available discounts for the resort
            for (const availableDiscount of availableDiscounts) {
                // Format the start date into a moment date object
                const discountStart = moment(availableDiscount.start_date.seconds, 'X')
                const discountEnd = moment(availableDiscount.end_date.seconds, 'X')

                // Store some checked booleans for whether to show or not
                const isStartBetween = discountStart.isBetween(windowCheckStart, windowCheckEnd, null, [])
                const isEndBetween = discountEnd.isBetween(windowCheckStart, windowCheckEnd, null, [])
                const isBeforeAndAfter = (discountStart.isBefore(windowCheckStart) && discountEnd.isAfter(windowCheckEnd))

                // Check to see if the start date for the discount can be applied to a price window
                if (isStartBetween || isEndBetween || isBeforeAndAfter) {
                    // Format the dates into a readable format for the config panel
                    const discountFormattedStartDate = moment(availableDiscount.start_date.seconds, 'X').format('DD/MM/YYYY')
                    const discountFormattedEndDate = moment(availableDiscount.end_date.seconds, 'X').format('DD/MM/YYYY')
                    
                    // Push the discount into the price window object
                    priceWindowDiscounts.push({
                        ...availableDiscount,
                        start: discountFormattedStartDate,
                        end: discountFormattedEndDate
                    })
                }
            }

            // Write the discounts into the price window
            coursePriceWindow.global_discounts = priceWindowDiscounts
        }

        // Add the new chosen course into the state
        setOptions((options) => ([...options, {
            ...courseData,
            option_type: 'course',
            price_windows: {
                course: [...coursePricing],
            }
        }]))

        // Reset the state
        setFetchingPricing(false)
    }

    // When a resort is deleted from the start screen
    const handleOptionDelete = (index) => {
        // Create an instance of the options array
        const updatedOptions = [...options]

        // Remove the resort at the given index
        updatedOptions.splice(index, 1)

        // Then reset this back into the state
        setOptions(updatedOptions)
    }

    return (
        <>
            <div className='generate-quote-header'>
                <div className='generate-quote-title'>
                    Find Resorts &amp; Courses
                </div>
                <div className='generate-quote-subtitle'>Use the search below to find the resorts &amp; courses applicable for this quote</div>
            </div>

            {/* Wrap the container in the Algolia instant search wrapper */}
            <InstantSearch searchClient={searchClient} indexName="cms_holidays">
                <div className='holiday-search'>
                    <CustomSearchBox />

                    <div className='quote-search-dropdown'>
                        <Index indexName='cms_holidays'>
                            <ResortResults />
                        </Index>
                        <Index indexName='cms_courses'>
                            <CourseResults />
                        </Index>
                    </div>

                    {fetchingPricing && <Loading />}
                </div>
            </InstantSearch>

            <div className='quote-option-stack'>
                {options.map((option, index) => (
                    <Option
                        key={option.objectID}
                        details={option}
                        onDelete={() => handleOptionDelete(index)} />
                ))}
            </div>

            <div className={['quote-action-buttons', ctxCompletedPackages?.length > 0 ? 'space-between' : ''].join(' ').trim()}>
                {ctxCompletedPackages?.length > 0 &&
                    <Button
                        type='secondary'
                        label='Clear &amp; reset quote'
                        onClick={() => ctxClearEntireState()} />
                }

                <Button
                    disabled={options.length === 0}
                    label='Continue to packages'
                    onClick={() => progressStage()} />
            </div>
        </>
    )
}
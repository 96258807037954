import React, { useEffect, useContext } from "react";
import { BookingProvider, BookingContext } from "../../utils/providers/isolated-booking";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";
import BackgroundImage from "../../assets/images/login-background.jpeg";
import BackgroundVideo from "../../assets/images/login-background-video.mp4";
import "./mybooking.scss";

/**
 * Tabs
 */
import Summary from "./tabs/summary";
import Itinerary from "./tabs/itinerary/itinerary";
import Payments from "./tabs/payments";
import CheckInTimes from "./tabs/checkins";
import Nearby from "./tabs/nearby";
import Stableford from "./tabs/stableford";

/**
 * Functional component to return the my booking landing page for the client
 */
function MyBooking({ bookingID }) {
    /**
     * Pull the setBookingID function from the isolated booking provider
     */
    const { setBookingID } = useContext(BookingContext);

    /**
     * Pull the path from the route
     */
    const { path } = useRouteMatch();

    /**
     * When the booking ID is updated
     */
    useEffect(() => {
        /**
         * Set the bookingID into the isloated booking context provider
         */
        bookingID && setBookingID(bookingID);
    }, [bookingID, setBookingID]);

    return (
        <section id="itinerary-page" style={{ backgroundImage: `url("${BackgroundImage}")` }}>
            <Switch>
                <Route path={path} exact component={Summary} />
                <Route path={`${path}/itinerary`} component={Itinerary} />
                <Route path={`${path}/payments`} component={Payments} />
                <Route path={`${path}/checkin`} component={CheckInTimes} />
                <Route path={`${path}/nearby`} component={Nearby} />
                <Route path={`${path}/stableford`} component={Stableford} />
            </Switch>

            <div className="video-container">
                <video className="background-video" controlsList={false} autoPlay muted playsInline loop>
                    <source src={BackgroundVideo} type="video/mp4"></source>
                </video>
            </div>
        </section>
    );
}

/**
 * Functional component to wrap the itinerary page in the isolated booking provider
 */
export default function MyBookingWrapper(props) {
    /**
     * Get the booking ID from the params
     */
    const bookingID = props.match.params.id;

    /**
     * If there is no booking ID provided in the URL, redirect them back to the 
     * "My Booking" login page
     */
    if (!bookingID) {
        return <Redirect to="/mybooking" />
    } else {
        return (
            <BookingProvider>
                <MyBooking bookingID={bookingID} />
            </BookingProvider>
        );
    }
}
import { useState, useEffect, useRef } from 'react'
import firebase from 'firebase'

// Utility functions
import { db } from '../../../../../utils/firebase'

// UI components
import Window from '../../../../structure/window/window'
import WindowCard from '../../../../ui/window-card/window-card'
import Datepicker from '../../../../ui/datepicker/datepicker'
import Textarea from '../../../../ui/inputs/textarea'
import Button from '../../../../ui/button/button'

// Returns the markup and html for the option view window
export default function OptionView({ holidayID, close, type, window }) {
    const [saving, setSaving] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    // Window fields
    const [noteID, setWindowID] = useState(null)
    const [resortNote, setResortNote] = useState('')

    // Store a boolean for the mounted state
    const isMounted = useRef(false)

    // On component load
    useEffect(() => {
        // Was there a window object passed in?
        if (type === 'edit' && window.id) {
            // Pull the details from the document
            const { id, text, start_seconds, end_seconds } = window

            // Convert the dates to those we can set into the state
            const startUNIX = start_seconds * 1000
            const endUNIX = end_seconds * 1000

            // Set the state up
            setWindowID(id)
            setStartDate(startUNIX)
            setEndDate(endUNIX)
            setResortNote(text || '')
        }

        // Toggle the mounted state
        setTimeout(() => {
            isMounted.current = true
        }, 500)
    }, [window])

    // Save the resort note
    const saveResortNote = async () => {
        // Update the state
        setSaving(true)

        // If we were editing a price window instead
        if (type === 'edit') {
            await db.doc(`CMS_holidays/${holidayID}/notes/${noteID}`).set({
                start_date: firebase.firestore.Timestamp.fromMillis(startDate),
                end_date: firebase.firestore.Timestamp.fromMillis(endDate),
                text: resortNote
            }, { merge:true })
        } else {
            await db.collection(`CMS_holidays/${holidayID}/notes`).add({
                start_date: firebase.firestore.Timestamp.fromMillis(startDate),
                end_date: firebase.firestore.Timestamp.fromMillis(endDate),
                text: resortNote
            })
        }

        // Reset the state
        setSaving(true)
        close()
    }

    return (
        <Window title={type === 'new' ? 'Add Global Note' : 'Edting Note'} className='price-window' close={() => close()}>
            <WindowCard stacked={true} title='Date range' subtitle='Choose the start & end dates this note will be displayed'>
                <div className="input-grid cols-2">
                    <Datepicker
                        value={startDate}
                        onSelect={(date) => setStartDate(date)}
                        placeholder='Start date' />

                    <Datepicker
                        value={endDate}
                        onSelect={(date) => setEndDate(date)}
                        placeholder='End date' />
                </div>
            </WindowCard>

            <WindowCard title='Note' subtitle='This note will be visible on all packages losted under this resort'>
                <Textarea
                    value={resortNote}
                    onChange={setResortNote}
                    placeholder='e.g. This resort offers a 6 month early booking discount...' />
            </WindowCard>

            <div className="ui-window-actions alt-layout">
                <Button
                    label="Save note"
                    loadingText="Saving..."
                    loading={saving}
                    onClick={() => saveResortNote()} />
            </div>
        </Window>
    )
}
import React from "react";
import { Switch, Route } from "react-router-dom";

/**
 * Route components
 */
import Error404 from "../../containers/errors/404";
import Leaderboard from "../../containers/leaderboard/leaderboard";
import Reservation from "../../containers/reservation/reservation";

/**
 * Returns the isloated routes for the app.
 * 
 * These routes are only loaded on the front-end with no protection, only used for responses from 
 * the partners on hotel, round and transfer bookings
 */
function IsolatedRoutes() {
    return (
        <Switch>
            {/* General app pages */}
            <Route path="/leaderboard" component={Leaderboard} />
            <Route path="/reservation-request" component={Reservation} />

            {/* Error pages */}
            <Route path="*" component={Error404} />
        </Switch>
    );
}

export default IsolatedRoutes;
import moment from 'moment'

// UI components
import Checkbox from '../../../../../components/ui/checkbox/checkbox'

// Returns the HTML markup for  a global resort discount
export default function ResortDiscount({ discount, fromDate, untilDate, discountIDsChosen, updateChosenDiscount, updateDiscounts }) {
    // Parse the discount start and end dates into a moment object
    const discountStartDate = moment(discount.start_date.seconds, 'X')
    const discountEndDate = moment(discount.end_date.seconds, 'X')

    // Are both of the start & end dates before this chosen window?
    const isBefore = discountStartDate.isBefore(fromDate) && discountEndDate.isBefore(fromDate)
    const isAfter = discountStartDate.isAfter(untilDate) && discountEndDate.isAfter(untilDate)

    // If the discount falls inbetween the chosen date range
    if (isBefore || isAfter) {
        return null
    } else {
        // Push the ID into the ref in the parent
        updateDiscounts(discount.id)

        return (
            <Checkbox
                label={`${discount.title} (${discount.start} - ${discount.end})`}
                checked={discountIDsChosen.includes(discount.id)}
                onClick={() => {
                    if (discountIDsChosen.includes(discount.id)) {
                        updateChosenDiscount(false, discount)
                    } else {
                        updateChosenDiscount(true, discount)
                    }
                }} />
        )
    }
}
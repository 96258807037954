import React, { useContext, useState, useEffect } from "react"
import { BookingContext } from "../../../utils/providers/booking"
import { sendTransferReservation } from "../../../utils/reservations"
import { sendTransferFlightImages } from "../../../utils/emails"
import { db } from "../../../utils/firebase"

/**
 * UI components
 */
import Input from "../../../components/ui/inputs/input"
import SearchSelect from "../../../components/ui/select/search-select"
import Select from "../../../components/ui/select/select"
import Badge from "../../../components/ui/badge/badge"
import Button from "../../../components/ui/button/button"
import Textarea from "../../../components/ui/inputs/textarea"
import { SearchIcon } from "../../../utils/svgs"

/**
 * Functional component to return the booking transfers panel
 */
function Transfers() {
	const [sending, setSending] = useState(false)
	const [sendingImages, setSendingImages] = useState(false)
	const [flightImages, setFlightImages] = useState([])
	const [transferMethods, setTransferMethods] = useState({
		nothing: "No transfers",
		airport: "Airport",
		golf: "Golf",
		airport_and_golf: "Airport & Golf",
		car_hire: "Car Hire Included",
		euro_tunnel: "Euro tunnel",
	})

	/**
	 * Deconstruct data from the booking context
	 */
	const {
		bookingID,
		saveBookingDetails,
		transfers,
		setTransferID,
		transfer,
		hotelsOrder,
		transferImagesStatus,
		transferBookingStatus,
		transferReservationComments,
		transferType,
		setTransferType,
		commentsForTransfer,
		setCommentsForTransfer,
		golfShuttles,
		setGolfShuttles,
	} = useContext(BookingContext)

	/**
	 * Send the hotel booking off to the resort
	 */
	const sendTransferBooking = async () => {
		/**
		 * Set the state to show the reservation is sending
		 */
		setSending(true)
		/**
		 * Build the email for the resort by passing in a booking ID and hotel ID
		 */
		await sendTransferReservation(bookingID, hotelsOrder[0])
		/**
		 * Reset the state
		 */
		setSending(false)
	}

	/**
	 * Send the transfer partner an email with the flight details
	 */
	const sendFlightImages = async () => {
		/**
		 * Set the state to show the reservation is sending
		 */
		setSendingImages(true)
		/**
		 * Build the email for the resort by passing in a booking ID and hotel ID
		 */
		await sendTransferFlightImages(bookingID, hotelsOrder[0])
		/**
		 * Reset the state
		 */
		setSendingImages(false)
	}

	/**
	 * On component load
	 */
	useEffect(() => {
		/**
		 * Setup a listener on the "images" subcollection for this booking
		 */
		const unsubscribe = db.collection(`bookings/${bookingID}/images`).onSnapshot((imagesSnapshot) => {
			/**
			 * Loop over the images found
			 */
			imagesSnapshot.docChanges().forEach((change) => {
				/**
				 * Image document added
				 */
				if (change.type === "added") {
					setFlightImages((images) => [
						...images,
						{
							id: change.doc.id,
							...change.doc.data(),
						},
					])
				}
				/**
				 * Image document updated
				 */
				if (change.type === "modified") {
					setFlightImages((images) => {
						let updatedImages = [...images]
						for (let i in images) {
							if (images[i].id === change.doc.id) {
								updatedImages[i] = {
									id: change.doc.id,
									...change.doc.data(),
								}
								break
							}
						}
						return updatedImages
					})
				}
				/**
				 * Image document removed
				 */
				if (change.type === "removed") {
					setFlightImages((images) => images.filter((enquiry) => enquiry.id !== change.doc.id))
				}
			})
		})
		/**
		 * Remove the listener on component unload
		 */
		return () => unsubscribe()
	}, [])

	return (
		<>
			<table className="booking-table">
				<tbody>
					<tr>
						<td>Transfer</td>
						<td>
							<SearchSelect
								placeholder="Change transfer:"
								icon={<SearchIcon />}
								activeOnHover={true}
								onSelect={(option) => setTransferID(option.option)}
								options={transfers}
							/>
						</td>
					</tr>
					<tr>
						<td>Partner name</td>
						<td>
							<Input
								type="text"
								value={transfer?.name}
								placeholder="No transfer partner chosen yet"
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Partner email</td>
						<td>
							<Input
								type="text"
								value={transfer?.email}
								placeholder="No transfer partner chosen yet"
								readOnly={true}
							/>
						</td>
					</tr>
					<tr>
						<td>Comments for transfer</td>
						<td>
							<Textarea
								type="text"
								value={commentsForTransfer}
								onChange={setCommentsForTransfer}
								placeholder="Comments to send to partner:"
								activeOnHover={true}
								onBlur={() => saveBookingDetails()}
							/>
						</td>
					</tr>
					<tr>
						<td>Transfers for</td>
						<td>
							<Select
								placeholder="Choose type:"
								value={transferMethods[transferType]}
								activeOnHover={true}
								onSelect={(option) => setTransferType(option.option)}
								options={transferMethods}
							/>
						</td>
					</tr>
					<tr>
						<td>Transfer booking status</td>
						<td className="vertical-center">
							{/* Booking has not yet been sent to the partner */}
							{!transferBookingStatus && <Badge label="Booking not sent yet" />}

							{/* Booking has been sent but no response yet */}
							{transferBookingStatus === "sent" && (
								<Badge
									type="INFO"
									label="Booking sent"
								/>
							)}

							{/* Booking has been approved */}
							{transferBookingStatus === "approved" && (
								<Badge
									type="POSITIVE"
									label="Booking accepted"
								/>
							)}

							{/* Booking has been rejected */}
							{transferBookingStatus === "rejected" && (
								<Badge
									type="NEGATIVE"
									label="Booking rejected"
								/>
							)}
						</td>
					</tr>

					{/* If there are some rejection comments to show */}
					{transferReservationComments && (
						<tr>
							<td>Rejection comments:</td>
							<td className="text-block">
								<p>{transferReservationComments}</p>
							</td>
						</tr>
					)}

					{/* Make sure there are hotels on the booking and the transfer partner has been chosen */}
					{hotelsOrder.length > 0 && transfer?.email && (
						<tr>
							<td></td>
							<td className="table-data-button">
								<Button
									label="Send transfer booking"
									small={true}
									loading={sending}
									loadingText="Sending reservation..."
									onClick={() => sendTransferBooking()}
								/>
							</td>
						</tr>
					)}

					<tr>
						<td colSpan="2">
							<div className="table-room-lower-border" />
						</td>
					</tr>

					<tr>
						<td>Golf shuttles</td>
						<td>
							<Select
								placeholder="Choose status:"
								value={golfShuttles ? "Yes" : "No"}
								activeOnHover={true}
								onSelect={(option) => setGolfShuttles(option.option === "true" ? true : false)}
								options={{
									true: "Yes",
									false: "No",
								}}
							/>
						</td>
					</tr>

					<tr>
						<td colSpan="2">
							<div className="table-room-lower-border" />
						</td>
					</tr>

					<tr>
						<td>Client flight details</td>
						<td className="vertical-center">
							{/* If there are no flight images to show */}
							{Object.entries(flightImages).length === 0 && <small>No uploads just yet...</small>}

							{/* If the client has uploaded some flight details */}
							{Object.entries(flightImages).length > 0 && (
								<>
									{flightImages.map((image) => (
										<div
											key={image.id}
											className="flight-details-image-thumb">
											<a
												href={image.public_url}
												rel="noreferrer"
												target="_blank">
												<img
													src={image.public_url}
													alt="Flight details"
												/>
											</a>
										</div>
									))}
								</>
							)}
						</td>
					</tr>
					<tr>
						<td>Flight images status</td>
						<td className="vertical-center">
							{/* Images have not yet been sent to the partner */}
							{!transferImagesStatus && <Badge label="Flight images not yet sent" />}

							{/* Images have been sent but no response yet */}
							{transferImagesStatus === "sent" && (
								<Badge
									type="INFO"
									label="Flight images sent"
								/>
							)}
						</td>
					</tr>
					<tr>
						<td></td>
						<td className="table-data-button">
							<Button
								label="Send flight images"
								small={true}
								disbaled={Object.entries(flightImages).length === 0}
								loading={sendingImages}
								loadingText="Sending images to partner..."
								onClick={() => sendFlightImages()}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	)
}

export default Transfers

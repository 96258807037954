import { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { LinkIcon } from "../../utils/svgs"
import { auth, db } from "../../utils/firebase"

// UI components
import Button from "../ui/button/button"

// Functional component to return the partner table row
function Partner(props) {
    const [connected, setConnected] = useState(true)

    // Deconstruct the details from the props
    const { id, details, collection, hideConnected } = props;

    useEffect(() => {
        if (auth.currentUser.uid === 'CGOHCdUwFAOe13OSTY2D9qdO2LX2') {
            if (props.collection === "hotels") {
                db.collection("CMS_holidays")
                .where("bs2_id_hotel", "==", id)
                .get()
                .then((holidayDocs) => {
                    if (holidayDocs.empty) {
                        // Check to see if it's marked as an alternate hotel ID
                        db.collection("CMS_holidays")
                        .where("bs2_id_hotel_alternates", "array-contains", id)
                        .get()
                        .then((alternateHolidayDocs) => {
                                // If there is no document found, add the hotel ID to the unconnectedHotels array
                                if (alternateHolidayDocs.empty) setConnected(false)
                            })
                    }
                })
            }

            if (props.collection === "courses") {
                db.collection("CMS_courses")
                .where("bs2_id_course", "==", id)
                .get()
                .then((holidayDocs) => {
                    if (holidayDocs.empty) {
                        // Check to see if it's marked as an alternate hotel ID
                        db.collection("CMS_courses")
                        .where("bs2_id_course_alternates", "array-contains", id)
                        .get()
                        .then((alternateHolidayDocs) => {
                                // If there is no document found, add the hotel ID to the unconnectedHotels array
                                if (alternateHolidayDocs.empty) setConnected(false)
                            })
                    }
                })
            
            }
        }
    }, [])

    if (auth.currentUser.uid === 'CGOHCdUwFAOe13OSTY2D9qdO2LX2' && hideConnected && connected) return null

    return (
        <tr data-system-id={`${id}`}>
            <td>
                {details.name}
                {auth.currentUser.uid === 'CGOHCdUwFAOe13OSTY2D9qdO2LX2' && (
                    <>
                        {!connected && (
                            <div className="no-values-tag"></div>
                        )}
                        <small style={{ display: "block", marginTop: "-20px"}}>{id}</small>
                    </>
                )}
            </td>
            <td>
                {details.email}
            </td>
            <td className="is-button is-flexxed">
                <Button
                    small={true}
                    label="View partner"
                    onClick={() => props.showPartnerWindow({ id, ...details })} />

                {collection &&
                    <NavLink to={`/partner/${collection}/${id}`}>
                        <LinkIcon />
                    </NavLink>
                }
            </td>
        </tr>
    );
}

export default Partner
import { useContext } from 'react'
import { QuoteContext } from '../context/context'

// UI components
import Button from '../../../components/ui/button/button'
import Input from '../../../components/ui/inputs/input'

// Returns the HTML markup for saving the clients details for the quote
export default function ClientDetails({ progressStage }) {
    // Pull the client detail update methods from the context
    const {
        ctxClientFirstName,
        setCtxClientFirstName,
        ctxClientLastName,
        setCtxClientLastName,
        ctxClientEmail,
        setCtxClientEmail
    } = useContext(QuoteContext)

    return (
        <>
            <div className='generate-quote-header'>
                <div className='generate-quote-title'>Client Details</div>
                <div className='generate-quote-subtitle'>Enter the clients details below to include on the quote document(s)</div>
            </div>

            <div className='generate-quote-client-details'>
                <Input
                    showLabel={true}
                    placeholder='First name:'
                    value={ctxClientFirstName}
                    onChange={setCtxClientFirstName} />

                <Input
                    showLabel={true}
                    placeholder='Last name:'
                    value={ctxClientLastName}
                    onChange={setCtxClientLastName} />

                <Input
                    showLabel={true}
                    placeholder='Email:'
                    value={ctxClientEmail}
                    onChange={setCtxClientEmail} />
            </div>

            <div className='quote-action-buttons'>
                <Button
                    label='Generate quotes'
                    disabled={ctxClientFirstName.length === 0 || ctxClientEmail.length === 0}
                    onClick={() => progressStage()} />
            </div>
        </>
    )
}
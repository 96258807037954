import React from "react";
import "./errors.scss";

/**
 * Functional component to return a 404 message
 */
function Error404() {
    return (
        <section id="error-page">
            <div className="error-text">
                <h1>404</h1>
                <h2>This page doesn't exist</h2>
            </div>
        </section>
    );
}

export default Error404;
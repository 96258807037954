import { useEffect, useState, useContext } from 'react'
import { QuoteProvider, QuoteContext } from './context/context'
import './quoting.scss'

// UI components
import Tile from '../../components/structure/tile/tile'
import Title from '../../components/structure/title/title'

// Quoting stages
import Search from './stages/search'
import Packages from './stages/packages'
import ClientDetails from './stages/client-details'
import GeneratingPDF from './stages/generating-pdf'
import Summary from './stages/send'
import Sent from './stages/sent'

// Price window assets
import Basket from './components/basket/basket'
import Configure from './stages/configure/configure'

// Returns the html markup and functionality for the quoting panel
function Quoting() {
    const [stage, setStage] = useState(0)
    const [saving, setSaving] = useState(false)

    // Pull the setting method from the quote context
    const { ctxSaveCompletePackage, ctxCompletedPackages, ctxClientEmail } = useContext(QuoteContext)

    // Whenever the stage is changed
    useEffect(() => {
        // Scroll the quoting panel back to the top
        document.querySelector('.screen-tile.quoting-panel').scroll({ top: 0, behaviour: 'smooth' })
    }, [stage])

    // Progress the stage along one
    const progressStage = () => {
        setStage((stage) => stage + 1)
    }

    // Go back one in the stages
    const previousStage = () => {
        setStage((stage) => stage - 1)
    }

    // Save the complete package and reset the stage
    const saveCompletePackage = async (isFinal = false) => {
        // Set the loading state
        setSaving(true)

        // Call the save method in the context
        await ctxSaveCompletePackage()

        // Then reset the stage back to the start when ready
        setSaving(false)

        // Are we at the final package?
        if (isFinal) {
            // Move forwards a stage
            progressStage()
        } else {
            // Otherwise go back to the start
            setStage(0)
        }
    }

    return (
        <Tile fullPage={true} className='quoting-panel'>
            <Title>
                <h1>Create New Quote</h1>
            </Title>

            <div className='generate-quote-wrapper'>
                <div className='generate-quote-menu'>
                    <div className='quote-menu-stages'>
                        <div className={['quote-menu-stage', stage >= 0 ? 'is-open' : ''].join(' ').trim()}>
                            Find Resorts/Courses
                        </div>
                        <div className={['quote-menu-stage', stage >= 1 ? 'is-open' : ''].join(' ').trim()}>
                            Select Package(s)
                        </div>
                        <div className={['quote-menu-stage', stage >= 2 ? 'is-open' : ''].join(' ').trim()}>
                            Configure Parameters
                        </div>
                        <div className={['quote-menu-stage', stage >= 3 ? 'is-open' : ''].join(' ').trim()}>
                            Client Details
                        </div>
                        <div className={['quote-menu-stage', (stage >= 4 || (ctxCompletedPackages?.length > 0 && ctxClientEmail)) ? 'is-open' : ''].join(' ').trim()}>
                            PDF Generation

                            {ctxCompletedPackages?.length > 0 && 
                                <div className='quote-menu-package-count'>
                                    <span>{ctxCompletedPackages.length}</span>
                                </div>
                            }
                        </div>
                        <div className={['quote-menu-stage', stage >= 5 ? 'is-open' : ''].join(' ').trim()}>Summary &amp; Save</div>
                    </div>

                    <div className='quote-menu-stage-bar'>
                        <div className={[
                            'quote-menu-stage-bar-inner', 
                            `stage-${stage}`, 
                            (ctxCompletedPackages?.length > 0 && ctxClientEmail) ? 'has-completed-packages' : ''
                        ].join(' ').trim()} />
                    </div>
                </div>

                <div className='generate-quote-functionality'>
                    {stage === 0 && 
                        <Search progressStage={progressStage} />
                    }

                    {stage === 1 && 
                        <Packages
                            previousStage={previousStage}
                            progressStage={progressStage} />
                    }

                    {stage === 2 && 
                        <Configure
                            previousStage={previousStage}
                            savePackage={saveCompletePackage}
                            saving={saving} />
                    }

                    {stage === 3 && 
                        <ClientDetails progressStage={progressStage} />
                    }

                    {stage === 4 && 
                        <GeneratingPDF
                            progressStage={progressStage}
                            resetStages={() => setStage(0)} />
                    }

                    {stage === 5 && 
                        <Summary progressStage={progressStage} />
                    }

                    {stage === 6 && 
                        <Sent resetStages={() => setStage(0)} />
                    }
                </div>
            </div>

            {/* Show the basket if we're configuring the packages */}
            {stage === 1 && <Basket />}
        </Tile>
    )
}

// Wrap the quote panel a context
export default function QuoteWrapper() {
    return (
        <QuoteProvider>
            <Quoting />
        </QuoteProvider>
    )
}
// UI components
import Badge from '../../../components/ui/badge/badge'
import { TrashIcon } from '../../../utils/svgs-v2'

// Returns the markup for an option now
export default function Option({ details, onDelete }) {
    return (
        <div className='quote-option-row'>
            <div className='quote-option-row-title'>
                {details.option_type === 'resort' ? <Badge label='Resort' type='INFO' /> : ''}
                {details.option_type === 'course' ? <Badge label='Course' type='POSITIVE' /> : ''}

                {details.title}
            </div>

            <div className='quote-option-row-target-delete' onClick={() => onDelete()}>
                <TrashIcon />
            </div>
        </div>
    )
}
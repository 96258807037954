import React, { useEffect, useContext, useState } from "react";
import { BookingContext } from "../../../utils/providers/isolated-booking";
import { NavLink, useRouteMatch } from "react-router-dom";
import { db } from "../../../utils/firebase";
import moment from "moment";

/**
 * Carousel libary
 */
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

/**
 * UI components
 */
import { LinkIcon } from "../../../utils/svgs";
import Tile from "../../../components/structure/tile/tile";
import Title from "../../../components/structure/title/title";

/**
 * Functional component to return the my booking landing page for the client
 */
export default function Summary() {
    const [dateFromString, setDateFromString] = useState("");
    const [bookingRounds, setBookingRounds] = useState([]);
    const [nearbyHidden, setNearbyHidden] = useState(false);

    /**
     * Pull the url form the route match
     */
    const { url } = useRouteMatch();

    /**
     * Pull the setBookingID function from the isolated booking provider
     */
    const {
        bookingID,
        agent,
        client,
        dateFromTimestamp,
        hotelsOrder,
        roundsOrder,
    } = useContext(BookingContext);

    /**
     * Get the value of now in timestamp format
     */
    const isInFuture = (moment(dateFromTimestamp, "X").valueOf() > moment().valueOf());

    /**
     * When the date from timestamp is updated
     */
    useEffect(() => {
        /**
         * Pull the time from now until the date from
         */
        const dateFromMoment = moment(dateFromTimestamp, "X").toNow(true);
        /**
         * Set it into the state
         */
        setDateFromString(dateFromMoment);
    }, [dateFromTimestamp]);

    /**
     * When the round orders are update/loaded in
     */
    useEffect(() => {
        /**
         * If there is a round on the booking
         */
        if (roundsOrder.length > 0) {
            /**
             * Loop over the various rounds on the booking
             */
            roundsOrder.forEach((round) => {
                /**
                 * Pull the nearby collection for this round
                 */
                loadRoundLocationDetails(round);
            });
        }
    }, [roundsOrder]);

    /**
     * When the hotel orders are update/loaded in
     */
    useEffect(() => {
        /**
         * If there is a hotel on the booking
         */
        if (hotelsOrder.length > 0) {
            /**
             * Loop over the various hotels on the booking
             */
            hotelsOrder.forEach((hotel) => {
                /**
                 * Pull the nearby collection for this hotel
                 */
                loadHotelLocationDetails(hotel);
            });
        }
    }, [hotelsOrder]);

    /**
     * Load in the nearby location details
     */
    const loadHotelLocationDetails = async (hotelID) => {
        /**
         * Pull the resort ID from the booking document
         */
        const hotelData = await db.doc(`bookings/${bookingID}/hotels/${hotelID}`)
            .get().then((hotelDoc) => {
                return { ...hotelDoc.data() };
            });
        /**
         * Pull the resort information from the hotel document
         */
        await db.doc(`hotels/${hotelData.resortID}`)
            .get().then((hotelDoc) => {
                /**
                 * Get the nearby_hidden toggle for this hotel
                 */
                const { nearby_hidden } = hotelDoc.data();
                /**
                 * If it's true, set it into the state to hide the nearby link
                 */
                setNearbyHidden(nearby_hidden);
            });
        /**
         * Then check the size of the 'nearby' subcollection on the hotel
         */
        await db.collection(`hotels/${hotelData.resortID}/nearby`)
            .get().then((nearbyDocs) => {
                /**
                 * If the size of the collection is 0 (there are no nearby items added), 
                 * we also need to hide the link from the navigation
                 */
                if (nearbyDocs.size === 0) {
                    setNearbyHidden(true);
                }
            });
    }

    /**
     * Load in the nearby location details
     */
    const loadRoundLocationDetails = async (roundID) => {
        /**
         * Pull the resort ID from the booking document
         */
        const resortData = await db.doc(`bookings/${bookingID}/rounds/${roundID}`)
            .get().then((roundDoc) => {
                return { ...roundDoc.data() };
            });
        /**
         * Pull the resort information from the round document
         */
        await db.doc(`courses/${resortData.courseID}`)
            .get().then((roundDoc) => {
                /**
                 * Set the data into the state
                 */
                setBookingRounds((bookingRounds) => [...bookingRounds, {
                    id: roundDoc.id,
                    ...roundDoc.data(),
                    tee_time: moment(resortData.tee_time?.seconds, "X").format("DD/MM/YYYY [at] HH:mma"),
                }]);
                /**
                 * Then fire off a function to find the images
                 */
                fetchImages(resortData.courseID);
            });
    }

    /**
     * Fetch the images for a given nearby location
     */
    const fetchImages = async (resortID) => {
        db.collection(`courses/${resortID}/images`)
            .get().then((imageDocs) => {
                /**
                 * Setup a new array for the images
                 */
                let imageURLs = [];
                /**
                 * Loop over the image documents for the hotel
                 */
                imageDocs.forEach((imageDoc) => {
                    imageURLs.push(imageDoc.data().public_url);
                });
                /**
                 * Set the images into the state
                 */
                setBookingRounds((bookingRounds) => {
                    let updatedRounds = [...bookingRounds];
                    for (let i in bookingRounds) {
                        if (bookingRounds[i].id === resortID) {
                            updatedRounds[i] = {
                                ...updatedRounds[i],
                                images: imageURLs,
                            };
                        }
                    }
                    return updatedRounds;
                });
            });
    }

    return (
        <Tile className="fit-width center">
            <Title includeLogo={true} center={true} isCardTitle={true}>
                <p>Welcome to your online booking manager. Take a look through your itinerary, view payments that have been made and contact your agent with any questions.</p>
            </Title>

            {/* Show the appropriate message on the summary panel for the client */}
            {isInFuture && <p className="countdown">{client?.first_name}, your holiday is <span>{dateFromString}</span> away!</p>}
            {!isInFuture && <p className="countdown">Enjoy your holiday, {client?.first_name}!</p>}

            <div className="my-booking-navigation">
                <div className="mb-nav-list">
                    {/* Itinerary page */}
                    <NavLink to={`${url}/itinerary`} className="mb-nav-item">
                        <p>Your itinerary</p>
                        <LinkIcon />
                    </NavLink>
                    {/* Payments panel */}
                    <NavLink to={`${url}/payments`} className="mb-nav-item">
                        <p>Payments</p>
                        <LinkIcon />
                    </NavLink>
                    {/* Check in/out times panel */}
                    <NavLink to={`${url}/checkin`} className="mb-nav-item">
                        <p>Check in/out times</p>
                        <LinkIcon />
                    </NavLink>
                    {/* Nearby panel */}
                    {!nearbyHidden &&
                        <NavLink to={`${url}/nearby`} className="mb-nav-item">
                            <p>Nearby resturants</p>
                            <LinkIcon />
                        </NavLink>
                    }
                    {/* Check in/out times panel */}
                    <NavLink to={`${url}/stableford`} className="mb-nav-item">
                        <p>Stableford points</p>
                        <LinkIcon />
                    </NavLink>
                    {/* External link out to GID */}
                    <a className="mb-nav-item" href="https://www.golfinsurancedirect.com" target="_blank" rel="noreferrer">
                        <p>Book Travel Insurance</p>
                        <LinkIcon />
                    </a>
                    {/* Contact panel */}
                    <a className="mb-nav-item tall" href={`mailto:${agent.email}`}>
                        <p>Contact your agent</p>
                        <small>{agent.email}</small>
                    </a>
                </div>
                <div className="mb-nav-courses">
                    <Carousel showThumbs={false} showStatus={false}>
                        {/* Loop through the nearby courses in the state */}
                        {bookingRounds.map((course) => (
                            <div className="nearby-place small-height">
                                <div className="nb-title">
                                    <p>{course.name}</p>
                                    <span>Teeing off at: {course.tee_time}</span>
                                </div>

                                {(course.images?.length > 0) &&
                                    <img src={course.images[0]} alt={course.name} />
                                }
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </Tile>
    );
}
import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import moment from "moment";
import "./enquiry.scss";

/**
 * UI components
 */
import Button from "../../ui/button/button";

/**
 * Functional component to return the enquiry table row
 */
function Enquiry(props) {
    const [agent, setAgent] = useState({});

    /**
     * Deconstruct the details from the props
     */
    const { id, details } = props;

    /**
     * Generate a string to represent the created date from the timestamps
     */
    const dateFrom = moment(details.date_from?._seconds || details.date_from?.seconds, "X").format("MMM D[,] YYYY");
    const followUpDate = moment(details.follow_up?._seconds || details.follow_up?.seconds, "X").format("MMM D[,] YYYY");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the agent record from the database
         */
        db.doc(`users/${details.agent}`)
            .get().then((agentDoc) => {
                /**
                 * Set the agent data into the local state
                 */
                setAgent(agentDoc.data());
            });
    }, []);

    return (
        <tr>
            <td>
                {details.site}
            </td>
            <td>
                {agent?.first_name && `${agent?.first_name} ${agent?.last_name}`}
                {!agent?.first_name && <small className="no-value">No agent assigned</small>}
            </td>
            <td>
                {(followUpDate === "Invalid date") &&
                    <small>No date set</small>
                }

                {(followUpDate !== "Invalid date") &&
                    <>
                        {followUpDate}
                    </>
                }
            </td>
            <td>
                {details.location}
            </td>
            <td>
                {dateFrom}
            </td>
            <td>
                {details.group_size}
            </td>
            <td>
                £{details.quoted ? details.quoted : 0}
            </td>
            <td className="is-button">
                <Button
                    small={true}
                    label="View enquiry"
                    onClick={() => props.showEnquiryWindow(id)} />
            </td>
        </tr>
    );
}

export default Enquiry;
import React, { useEffect, useState } from "react";
import { PlusIcon } from "../../../utils/svgs";
import { db } from "../../../utils/firebase";

/**
 * UI components
 */
import Table from "../../../components/structure/table/table";
import Enquiry from "../../../components/client/enquiries/enquiry";
import ViewEnquiry from "../../../components/enquiry/view/view";
import Button from "../../../components/ui/button/button";
import Add from "../../enquiries/add/add";

/**
 * Functional component to return the enquiries for this client
 */
export default function Enquiries({ clientID }) {
    const [enquiries, setEnquiries] = useState([]);
    const [viewEnquiry, setViewEnquiry] = useState("");
    const [addingEnquiry, setAddingEnquiry] = useState(false);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull all the enquries that are attached to this client ID
         */
        db.collection("enquiries")
            .orderBy("created")
            .where("client", "==", clientID)
            .get().then((enquiryDocs) => {
                let enquiriesArr = [];
                /**
                 * Loop through each of the enquiries
                 */
                enquiryDocs.forEach((enquiry) => {
                    /**
                     * Push the enquiry into the array
                     */
                    enquiriesArr.push({
                        id: enquiry.id,
                        ...enquiry.data(),
                    });
                });
                /**
                 * Then push these enquiries into the state
                 */
                setEnquiries(enquiriesArr);
            });
    }, []);

    return (
        <>
            {/* New enquiry button */}
            <Button
                label="New enquiry"
                small={true}
                icon={<PlusIcon />}
                className="has-extra-margin"
                onClick={() => setAddingEnquiry(true)} />

            <Table
                className="enquiries-table"
                headings={[
                    "Site",
                    "Agent",
                    "Follow up",
                    "Location/Event",
                    "Date from",
                    "Group size",
                    "Quoted",
                ]}
                noResults={enquiries.length === 0}
                noResultsMessage="This client hasn't got any open enquiries">

                {/* Print out the enquiries for this user */}
                {enquiries.map((enquiry) => (
                    <Enquiry
                        key={enquiry.id}
                        id={enquiry.id}
                        details={enquiry}
                        showEnquiryWindow={(enquiryID) => setViewEnquiry(enquiryID)} />
                ))}
            </Table>

            {/* Do we have a enquiryID in the local state to load into a window? */}
            {viewEnquiry &&
                <ViewEnquiry
                    enquiryID={viewEnquiry}
                    close={() => setViewEnquiry("")} />
            }

            {/* Are we adding a new enquiry? */}
            {addingEnquiry &&
                <Add
                    clientID={clientID}
                    close={() => setAddingEnquiry(false)} />
            }
        </>
    );
}
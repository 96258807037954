import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import "./targets.scss";

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Table from "../../components/structure/table/table";
import Staff from "../../components/staff/staff";

/**
 * Functional component to return a full staff list fo assigning targets to
 */
function Targets() {
    const [users, setUsers] = useState([]);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Pull a list of sales agents from the database where they are to have targets assigned
         */
        db.collection("users")
            .orderBy("first_name")
            .where("show_for.targets", "==", true)
            .get().then((userDocs) => {
                /**
                 * Setup a array to store the agent details
                 */
                let agents = [];
                /**
                 * Loop through the user documents found
                 */
                userDocs.forEach((userDoc) => {
                    /**
                     * Push the agent in
                     */
                    agents.push({
                        id: userDoc.id,
                        ...userDoc.data(),
                    });
                });
                /**
                 * Push the user into the state
                 */
                setUsers(agents);
            });
    }, []);

    return (
        <Tile fullPage={true}>
            <Title>
                <h1>Staff targets</h1>
            </Title>

            <Table
                className="staff-targets-table"
                headings={[
                    "Agent",
                    "Monthly",
                    "Weekly",
                    "Daily",
                ]}>

                {users.map((user) => (
                    <Staff
                        key={user.id}
                        id={user.id}
                        details={user} />
                ))}
            </Table>
        </Tile>
    );
}

export default Targets;
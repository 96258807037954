import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../utils/providers/auth";
import { db } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import "./deposits.scss";

/**
 * Loading icon for when the button is processing
 */
import { LoadingIcon } from "../../utils/svgs";

/**
 * UI components
 */
import Tile from "../../components/structure/tile/tile";
import Title from "../../components/structure/title/title";
import Table from "../../components/structure/table/table";
import Booking from "../../components/deposits/booking";

/**
 * Functional component to return the enquiry tables
 */
export default function Deposits() {
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState([]);

    /**
     * Get the end of today as a seconds timestamp
     */
    const fetchUpto = moment().subtract(4, 'weeks').endOf("day").valueOf();
    const fetchFrom = moment("31/12/2020", "DD/MM/YYYY").endOf("day").valueOf();

    // Pull some data from the auth context
    const { user } = useContext(AuthContext);

    /**
     * On component load, pull in the required bookings from the database
     */
    useEffect(() => {
        /**
         * Show a loading spinner over the top
         */
        setLoading(true);
        /**
         * Pull the bookings from the database
         */
        db.collection("bookings")
            .where("paid_by_client", "==", 0)
            .where("booked", "<=", firebase.firestore.Timestamp.fromMillis(fetchUpto))
            .where("booked", ">", firebase.firestore.Timestamp.fromMillis(fetchFrom))
            .orderBy("booked", "desc")
            .get().then((bookingDocs) => {
                /**
                 * Setup a new array for storing the bookings
                 */
                let searchBookings = [];
                /**
                 * Loop through all the bookings
                 */
                bookingDocs.forEach((bookingDoc) => {
                    /**
                     * Pull some check data from the booking document
                     */
                    const { balance_outstanding, removed } = bookingDoc.data();
                    /**
                     * Check to see if the balance outstanding is more than 0 and it's not been removed
                     */
                    if (!removed && (balance_outstanding > 0)) {
                        /**
                         * Push this booking into the state
                         */
                        searchBookings.push({ id: bookingDoc.id, ...bookingDoc.data() });
                    }
                });
                /**
                 * Set these bookings into the state
                 */
                setBookings(searchBookings);
                /**
                 * And then set the loading state back to false to show them
                 */
                setLoading(false);
            });
    }, []);

    return (
        <Tile fullPage={true}>
            <Title className="flex has-select-field">
                <h1>Overdue Deposits</h1>
            </Title>

            <Table
                className="bookings-table"
                headings={[
                    "Name",
                    "Telephone",
                    "Agent assigned",
                    "Booked on",
                    "",
                ]}>

                {bookings.map((booking) => (
                    <Booking
                        key={booking.id}
                        id={booking.id}
                        details={booking}
                        maskDetails={user?.mask_details} />
                ))}

                {loading &&
                    <div className="table-loading-splash">
                        <div className="loading-wrapper">
                            <p>Loading...</p>
                            <div className="svg-loading-wrap">
                                <LoadingIcon />
                            </div>
                        </div>
                    </div>
                }
            </Table>
        </Tile>
    );
}
import { useEffect, useState, useContext } from 'react'
import { ModalContext } from '../../../../utils/providers/modal'
import { AlertsContext } from '../../../../utils/providers/alerts'
import moment from 'moment'
import './discounts.scss'

// Utility functions
import { db } from '../../../../utils/firebase'

// UI components
import { PlusIcon } from '../../../../utils/svgs'
import Button from '../../../ui/button/button'
import Loading from '../../../app/loading/loading'
import OptionView from './view/view'
import DiscountRow from './row/row'

// Returns the HTML markup for the resort global discounts
export default function HolidayDiscounts({ holiday }) {
    const [discounts, setNotes] = useState([])
    const [loading, setLoading] = useState(false)
    const [adding, setAdding] = useState(false)
    const [editing, setEditing] = useState(null)

    // Import the modal functionality
    const { showModal } = useContext(ModalContext)

    // Import the alert functionality
    const { pushAlert } = useContext(AlertsContext)

    // On component load
    useEffect(() => {
        // Load the discounts
        const unsubscribe = db.collection(`CMS_holidays/${holiday.id}/discounts`)
            .orderBy("start_date")
            .onSnapshot((discountDocs) => {
                // Reset the loading state
                setLoading(false)

                // Get a timestamp for now
                const timestampNow = moment().format('X')

                // Loop over the documents found
                discountDocs.docChanges().forEach((optionChange) => {
                    // Get the start_date and end_date fields
                    const {
                        title,
                        text,
                        start_date,
                        end_date,
                        size,
                        value,
                        type
                    } = optionChange.doc.data()

                    // Key for identifying the correct badge to show
                    let periodBadge

                    // Past options 
                    if (start_date.seconds < timestampNow && end_date.seconds < timestampNow) {
                        periodBadge = 'PAST'
                    }

                    // Currently active
                    if (start_date.seconds < timestampNow && end_date.seconds > timestampNow) {
                        periodBadge = 'ACTIVE'
                    }

                    // Upcoming
                    if (start_date.seconds > timestampNow && end_date.seconds > timestampNow) {
                        periodBadge = 'UPCOMING'
                    }

                    // Process some readable versions of the start and end dates
                    const startDateString = moment(start_date.seconds, 'X').format('DD-MMM-YYYY')
                    const endDateString = moment(end_date.seconds, 'X').format('DD-MMM-YYYY')

                    // Added
                    if (optionChange.type === 'added') {
                        setNotes((options) => [
                            ...options,
                            {
                                id: optionChange.doc.id,
                                title,
                                text,
                                start_date: startDateString,
                                start_seconds: start_date.seconds,
                                end_date: endDateString,
                                end_seconds: end_date.seconds,
                                period_state: periodBadge,
                                size,
                                value,
                                type
                            }
                        ])
                    }

                    // Modified
                    if (optionChange.type === 'modified') {
                        setNotes((discounts) => {
                            let updatedNotes = [...discounts]
                            for (let i in discounts) {
                                if (discounts[i].id === optionChange.doc.id) {
                                    updatedNotes[i] = {
                                        id: optionChange.doc.id,
                                        title,
                                        text,
                                        start_date: startDateString,
                                        start_seconds: start_date.seconds,
                                        end_date: endDateString,
                                        end_seconds: end_date.seconds,
                                        period_state: periodBadge,
                                        size,
                                        value,
                                        type
                                    }
                                    break
                                }
                            }
                            return updatedNotes
                        })
                    }

                    // Removed
                    if (optionChange.type === 'removed') {
                        setNotes((discounts) => {
                            return discounts.filter((resortNote) => resortNote.id !== optionChange.doc.id)
                        })
                    }
                })
        })

        // Unmount the listener on component unload
        return () => unsubscribe()
    }, [])

    // Load the edit modal for this particular discount
    const editNote = async (discountID, discountDetails) => {
        setEditing({ id: discountID, ...discountDetails })
    }

    // Check to make sure we are good to remove the discount before doing so
    const checkDeletion = async (discountID) => {
        showModal({
            type: "ALERT",
            title: "Are you sure?",
            cancel: {
                label: "Cancel",
                action: () => { return null; },
            },
            next: {
                label: "Continue",
                action: () => processDeletion(discountID),
            }
        });
    }

    // Process the discount deletion
    const processDeletion = async (discountID) => {
        await db.doc(`CMS_holidays/${holiday.id}/discounts/${discountID}`).delete()

        pushAlert({
            type: 'SUCCESS',
            title: 'Resort discount removed'
        })
    }

    return (
        <>
            <div className='holiday-pricing-container'>
                {loading && <Loading />}

                {!loading &&
                    <div className='holiday-pricing-options'>
                        <Button
                            label="New discount"
                            icon={<PlusIcon />}
                            onClick={() => setAdding(true)} />

                        <small>Showing {discounts.length} results</small>

                        {discounts.map((discount) => (
                            <DiscountRow
                                key={discount.id}
                                details={{ ...discount }}
                                edit={(discountID, discountDetails) => editNote(discountID, discountDetails)}
                                checkDelete={(discountID) => checkDeletion(discountID)} />
                        ))}
                    </div>
                }
            </div>

            {/* Adding a new discount option */}
            {adding &&  <OptionView holidayID={holiday.id} close={() => setAdding(false)} type='new' />}

            {/* Editing an existing discount option */}
            {editing &&  <OptionView holidayID={holiday.id} close={() => setEditing(false)} type='edit' window={editing} />}
        </>
    )
}
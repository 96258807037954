import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import firebase from "firebase";

/**
 * Functional component to return the booking
 */
export default function ConversionsRowStaff({ id, details, startDate, endDate }) {
    const [conversionPercent, setConversionPercent] = useState(0);

    const [enquiriesCount, setEnquiriesCount] = useState(0);
    const [bookingsCount, setBookingsCount] = useState(0);

    /**
     * Build some firestore timestamps from the milliseconds
     */
    const startTimestamp = firebase.firestore.Timestamp.fromMillis(startDate);
    const endTimestamp = firebase.firestore.Timestamp.fromMillis(endDate);

    /**
     * When any of the props are updated
     */
    useEffect(() => {
        /**
         * If we are querying conversions based on a website
         */
        pullAgentConversions();
    }, [startDate, endDate]);

    /**
     * Pull the conversions relating to a website query
     */
    const pullAgentConversions = async () => {
        /**
         * Pull all active enquiries
         */
        const activeEnquiries = await db.collection("enquiries")
            .where("agent", "==", id)
            .where("created", ">=", startTimestamp)
            .where("created", "<=", endTimestamp)
            .get().then((enquiryDocs) => {
                /**
                 * Return the size of the collection 
                 */
                return enquiryDocs.size;
            });
        /**
         * Pull all removed enquiries
         */
        const removedEnquiries = await db.collection("enquiries_removed")
            .where("agent", "==", id)
            .where("enquired", ">=", startTimestamp)
            .where("enquired", "<=", endTimestamp)
            .get().then((enquiryDocs) => {
                /**
                 * Return the size of the collection 
                 */
                return enquiryDocs.size;
            });
        /**
         * Pull all booked enquiries
         */
        const bookedEnquiries = await db.collection("bookings")
            .where("agent", "==", id)
            .where("enquiry.created", ">=", startTimestamp)
            .where("enquiry.created", "<=", endTimestamp)
            .get().then((enquiryDocs) => {
                /**
                 * Return the size of the collection 
                 */
                return enquiryDocs.size;
            });
        /**
         * Update the state with the numbers found
         */
        setEnquiriesCount(activeEnquiries + removedEnquiries)
        setBookingsCount(bookedEnquiries)
        /**
         * If the total enquiries count and booked enquiries are both above 0
         */
        if (((activeEnquiries + removedEnquiries) > 0) && (bookedEnquiries > 0)) {
            /**
             * Work out a percentage
             */
            const percentage = ((bookedEnquiries / (activeEnquiries + removedEnquiries)) * 100).toFixed(2);
            /**
             * Set the percentage into the state
             */
            setConversionPercent(percentage);
        } else {
            /**
             * Set the conversions percentage to 0
             */
            setConversionPercent(0);
        }
    }

    return (
        <tr>
            <td>
                {details.first_name} {details.last_name}
            </td>
            <td>{enquiriesCount}</td>
            <td>{bookingsCount} <small>({conversionPercent}%)</small></td>
            <td></td>
        </tr>
    );
}
import React, { useState, useContext } from "react";
import { auth } from "../../utils/firebase";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../utils/providers/auth";
import "./login.scss";

/**
 * UI components
 */
import Logo from "../../assets/images/golf-holidays-direct-logo.png";
import BackgroundImage from "../../assets/images/login-background.jpeg";
import Input from "../../components/ui/inputs/input";
import Button from "../../components/ui/button/button";

/**
 * Functional component for the login page
 */
function Login(props) {
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loggingIn, setLoggingIn] = useState(false);

    /**
     * Deconstruct the user object from the context
     */
    const { user } = useContext(AuthContext);

    /**
     * Check the login details provided by the user. If they are correct, we need to dispatch an email 
     * with an authentication code for them to enter (the email is sent by a cloud function, we just 
     * need to create a document here with a timestamp)
     */
    const checkLoginDetails = () => {
        /**
         * Show a spinner on the login button
         */
        setLoggingIn(true);
        /**
         * Reset the errors
         */
        setErrors({});
        /**
         * Make sure we have an email and password present
         */
        if (email && password) {
            /**
             * Attempt to sign the user in with the credentials in the state
             */
            auth.signInWithEmailAndPassword(email, password).then(() => {
                /**
                 * Just return true when logged in, the auth context with handle loading their details in
                 */
                return true;
            }).catch((error) => {
                /**
                 * Reset the state
                 */
                setLoggingIn(false);
                /**
                 * Update the state with an appropriate error message
                 */
                switch (error.code) {
                    case "auth/wrong-password":
                        return setErrors({
                            ...errors,
                            global: "Password incorrect, please check your inputs and try again.",
                        });
                    case "auth/user-not-found":
                        return setErrors({
                            ...errors,
                            global: "This account isn't registered with us, please contact us directly.",
                        });
                    default:
                        return setErrors({
                            ...errors,
                            global: "There was an error, please get in touch with us directly.",
                        });
                }
            });
        }
    }

    /**
     * If the user object from the auth context has a UID attached (either the user has just been logged 
     * in or they have navigated here having already been signed in)
     */
    if (user?.uid) {
        return <Redirect to="/" />
    } else {
        return (
            <section id="login-page" style={{ backgroundImage: `url("${BackgroundImage}")` }}>
                <div className="login-window">
                    <img src={Logo} alt="Golf Holidays Direct" />
                    <p>Welcome to the Golf Holidays Direct online booking system. Please login to proceed.</p>

                    {/* Login form fields */}
                    <div className="login-form">
                        <Input
                            type="email"
                            placeholder="Email:"
                            value={email}
                            onChange={setEmail} />

                        <Input
                            type="password"
                            placeholder="Password:"
                            value={password}
                            onChange={setPassword} />

                        {/* If there is an error signing the user in */}
                        {errors.global &&
                            <small className="login-error">{errors.global}</small>
                        }

                        <Button
                            label="Login"
                            loading={loggingIn}
                            loadingText="Logging in..."
                            onClick={checkLoginDetails}
                            disabled={!email || !password} />
                    </div>
                </div>
            </section>
        );
    }
}

export default Login;
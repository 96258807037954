import React from "react";
import Logo from "../../../assets/images/golf-holidays-direct-logo.png";
import "./title.scss";

/**
 * Functional component to display a title for the tile
 * 
 * @param {object} props Children elements to sit inside the title block
 * @returns Wrapper HTML for displaying the tile title
 */
function Title(props) {
    return (
        <div className={[
            "tile-title",
            props.className,
            props.hasBorder && "has-border",
            props.center && "is-centered",
            props.isCardTitle && "is-card-title",
        ].join(" ")}>
            {props.includeLogo && <img src={Logo} alt="Golf Holidays Direct" />}
            {props.children}
        </div>
    );
}

export default Title;
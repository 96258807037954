export const convertPNGtoJPG = async (imageUrl) => {
    return new Promise(async (res, rej) => {
        // Fetch the PNG image
        const response = await fetch(imageUrl)
        if (!response.ok) {
            throw new Error(`Image download failed with status ${response.status}`)
        }
        const blob = await response.blob()

        // Create an HTML Image element
        const img = new Image()

        // Create a Promise for loading the image
        const loadImage = new Promise((resolve, reject) => {
            img.onload = () => resolve()
            img.onerror = () => reject(new Error('Failed to load image'))
        })

        // Set the Image source to the PNG blob
        img.src = URL.createObjectURL(blob)

        // Wait for the image to load
        await loadImage

        // Create a canvas element
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Set canvas dimensions to match the image
        canvas.width = img.width
        canvas.height = img.height

        // Draw the PNG image onto the canvas
        ctx.drawImage(img, 0, 0)

        // Convert the canvas content to a JPG blob
        canvas.toBlob((jpgBlob) => {
            // Create a URL for the JPG blob
            const jpgUrl = URL.createObjectURL(jpgBlob)

            // Use the JPG URL or do something else with it
            res(jpgBlob.arrayBuffer())

            // Clean up the created URLs
            URL.revokeObjectURL(jpgUrl)
            URL.revokeObjectURL(img.src)
        }, 'image/jpeg', 1)
    })
}
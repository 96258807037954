import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import { NavLink } from "react-router-dom";
import moment from "moment";

/**
 * UI components
 */
import Badge from "../ui/badge/badge";
import Button from "../ui/button/button";

/**
 * Functional component to return the enquiry table row
 */
export default function Cancellation({ id, details, maskDetails, showCancellationWindow }) {
    const [agent, setAgent] = useState({});
    const [client, setClient] = useState({});
    const [dateFrom, setDateFrom] = useState("");

    /**
     * Get the booking data from the cancellation document
     */
    const { booking_data } = details;

    /**
     * Get the cancellation date as a string
     */
    const cancelledAt = moment(details.cancelled_at?.seconds, "X").format("MMM D[,] YYYY");

    /**
     * When the booking data is updated
     */
    useEffect(() => {
        /**
         * Generate a string to represent the date_from date from the timestamps
         */
        const bookingFromDate = moment(booking_data.date_from?.seconds, "X").format("MMM D[,] YYYY");
        /**
         * And then set it into the state
         */
        setDateFrom(bookingFromDate);
    }, [booking_data]);

    /**
     * Pull the client details
     */
    useEffect(() => {
        /**
         * Get the client details from the database
         */
        db.doc(`clients/${booking_data.client}`)
            .get().then((clientDoc) => {
                /**
                 * Set the details into the state
                 */
                setClient(clientDoc.data());
            });
    }, []);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the agent record from the database
         */
        db.doc(`users/${booking_data.agent}`)
            .get().then((agentDoc) => {
                /**
                 * Set the agent data into the local state
                 */
                setAgent(agentDoc.data());
            });
    }, []);

    return (
        <tr>
            <td>
                {!maskDetails && (
                    <NavLink to={`client/${details.client}`}>
                        {client.first_name && `${client.first_name} ${client.last_name}`}
                        {!client.first_name && <small className="no-value">No name set</small>}
                    </NavLink>
                )}

                {maskDetails && (
                    <>
                        {client.first_name && `${client.first_name} ${client.last_name}`}
                        {!client.first_name && <small className="no-value">No name set</small>}
                    </>
                )}
            </td>
            <td>
                {agent?.first_name && `${agent.first_name} ${agent.last_name}`}
                {!agent?.first_name && <small className="no-value">No agent assigned</small>}
            </td>
            <td>
                £{booking_data?.margins?.profit ?
                    <>
                        {booking_data?.margins?.profit.toFixed(2)}
                    </> : 0
                }
            </td>
            <td>
                {cancelledAt}
            </td>
            <td>
                {dateFrom}
            </td>
            <td>
                {/* Cancellation has been confirmed */}
                {details.resort_confirmed &&
                    <Badge type="POSITIVE" label="Received & Accepted" />
                }

                {/* Cancellation has not yet been confirmed */}
                {!details.resort_confirmed &&
                    <Badge type="NEGATIVE" label="Unconfirmed" />
                }
            </td>
            <td className="is-button">
                <Button
                    small={true}
                    label="View cancellation"
                    onClick={() => showCancellationWindow(id)} />
            </td>
        </tr>
    );
}
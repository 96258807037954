import React, { useContext, useState } from "react";
import { BookingContext } from "../../../../../utils/providers/isolated-booking";

/**
 * UI components
 */
import Input from "../../../../../components/ui/inputs/input";
import Textarea from "../../../../../components/ui/inputs/textarea";
import Payments from "../../../../../components/itinerary/payments/payments";

/**
 * Functional component to return the booking summary panel
 */
function Summary() {
    const [viewingPayments, setViewingPayments] = useState(false);

    /**
     * Deconstruct data from the booking context
     */
    const {
        bookingID,
        client,
        agent,
        reference,
        booked,
        golfers,
        nonGolfers,
        totalCost,
        paidByClient,
        outstanding,
        balanceDue,
        commentsForClient,
        commentsFromClient, setCommentsFromClient,
    } = useContext(BookingContext);

    return (
        <>
            <table className="booking-table">
                <tbody>
                    <tr>
                        <td>Lead person</td>
                        <td>
                            <Input
                                type="text"
                                value={`${client.first_name} ${client.last_name}`}
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Booking agent</td>
                        <td>
                            <Input
                                type="text"
                                value={`${agent.first_name} ${agent.last_name}`}
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Booking ref</td>
                        <td>
                            <Input
                                type="text"
                                value={reference}
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Booked date</td>
                        <td>
                            <Input
                                type="text"
                                value={booked}
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Group size</td>
                        <td>
                            <div className="table-data-split cols-2">
                                <div className="table-data-column">
                                    <p>Golfers</p>
                                    <Input
                                        type="number"
                                        value={golfers}
                                        readOnly={true} />
                                </div>

                                <div className="table-data-column">
                                    <p>Non-golfers</p>
                                    <Input
                                        type="number"
                                        value={nonGolfers}
                                        readOnly={true} />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Total holiday cost</td>
                        <td>
                            <Input
                                type="text"
                                symbol="£"
                                placeholder="Total cost:"
                                value={totalCost}
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Paid so far</td>
                        <td>
                            <Input
                                type="text"
                                symbol="£"
                                placeholder="Total paid:"
                                value={paidByClient}
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Balance outstanding</td>
                        <td>
                            <Input
                                type="text"
                                symbol="£"
                                placeholder="Total outstanding:"
                                value={outstanding}
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Balance due</td>
                        <td>
                            <Input
                                type="text"
                                placeholder="Balance due date:"
                                value={balanceDue === "Invalid date" ? "No due date set yet" : balanceDue}
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Comments from agent</td>
                        <td>
                            <Textarea
                                type="text"
                                value={commentsForClient}
                                placeholder="No comments from agent"
                                readOnly={true} />
                        </td>
                    </tr>
                    <tr>
                        <td>Your additional comments</td>
                        <td>
                            <Textarea
                                type="text"
                                value={commentsFromClient}
                                onChange={setCommentsFromClient}
                                placeholder="Comments to show the agent:"
                                activeOnHover={true} />
                        </td>
                    </tr>
                </tbody>
            </table>

            {viewingPayments &&
                <Payments
                    bookingID={bookingID}
                    close={() => setViewingPayments(false)} />}
        </>
    );
}

export default Summary;
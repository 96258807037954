import React, { useState, useContext, useEffect } from "react";
import { AlertsContext } from "../../../utils/providers/alerts";
import { PlusIcon, TimesIcon } from "../../../utils/svgs";
import { db } from "../../../utils/firebase";

/**
 * UI components
 */
import Button from "../../ui/button/button";
import Input from "../../ui/inputs/input";
import Upload from "./upload/upload";

/**
 * Functional component to return the resturant card in the partner tile
 */
export default function Restaurant({ collection, id, restaurantID, details }) {
    const [removing, setRemoving] = useState(false);
    const [saving, setSaving] = useState(false);
    const [uploading, setUploading] = useState(false);

    const [name, setName] = useState("");
    const [website, setWebsite] = useState("");
    const [images, setImages] = useState([]);

    /**
     * Use the push alert function from the context
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Setup a listener on the images collection for this partner
         */
        const unsubscribe = db.collection(`${collection}/${id}/nearby/${restaurantID}/images`)
            .onSnapshot((imagesSnap) => {
                /**
                 * Loop over the images found
                 */
                imagesSnap.docChanges().forEach((change) => {
                    /**
                     * Image document added
                     */
                    if (change.type === "added") {
                        setImages((images) => [...images, {
                            id: change.doc.id,
                            ...change.doc.data(),
                        }]);
                    }
                    /**
                     * Image document updated
                     */
                    if (change.type === "modified") {
                        setImages((images) => {
                            let updatedImages = [...images];
                            for (let i in images) {
                                if (images[i].id === change.doc.id) {
                                    updatedImages[i] = {
                                        id: change.doc.id,
                                        ...change.doc.data(),
                                    };
                                    break;
                                }
                            }
                            return updatedImages;
                        });
                    }
                    /**
                     * Image document removed
                     */
                    if (change.type === "removed") {
                        setImages((images) =>
                            images.filter((image) => image.id !== change.doc.id));
                    }
                });
            });
        /**
         * Remove the listener on componant unload
         */
        return () => unsubscribe();
    }, []);

    /**
     * When the details are udpated on
     */
    useEffect(() => {
        /**
         * Pull the data required formt he details passed into the props
         */
        const { name, website } = details;
        /**
         * Set the values into the state
         */
        setName(name || "");
        setWebsite(website || "");
    }, [details]);

    /**
     * Remove the restuarant from the partner collection
     */
    const removeFromPartner = async () => {
        /**
         * Show the state as removing
         */
        setRemoving(true);
        /**
         * Then remove the restaurant document itself
         */
        await db.doc(`${collection}/${id}/nearby/${restaurantID}`).delete();
        /**
         * Reset the state
         */
        setRemoving(false);
    }

    /**
     * Save the details for this resturant into the document
     */
    const saveDetails = async () => {
        /**
         * Update the details into the state
         */
        await db.doc(`${collection}/${id}/nearby/${restaurantID}`).set({
            name,
            website,
        }, { merge: true });
        /**
         * Show an alert when done
         */
        pushAlert({
            type: "SUCCESS",
            title: "Details updated",
        });
    }

    /**
     * When clicked, remove the image 
     */
    const removeImage = async (e, imageID) => {
        /**
         * Stop the click from the span tag bubbling up to the a tag
         */
        e.stopPropagation();
        /**
         * Remove the flight image document from the subcollection
         */
        await db.doc(`${collection}/${id}/nearby/${restaurantID}/images/${imageID}`).delete();
    }

    return (
        <>
            <table className="booking-table booking-table-block">
                <tbody>
                    <tr className="table-sub-heading">
                        <td></td>
                        <td>
                            <Button
                                badge={true}
                                label="Remove"
                                className="OUTLINE"
                                loading={removing}
                                loadingText="Removing..."
                                onClick={() => removeFromPartner()} />
                        </td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>
                            <Input
                                type="text"
                                value={name}
                                placeholder="Name"
                                onChange={setName}
                                onBlur={() => saveDetails()} />
                        </td>
                    </tr>
                    <tr>
                        <td>Website</td>
                        <td>
                            <Input
                                type="text"
                                value={website}
                                placeholder="Website URL"
                                onChange={setWebsite}
                                onBlur={() => saveDetails()} />
                        </td>
                    </tr>
                    <tr>
                        <td>Images</td>
                        <td className="vertical-center">
                            <Button
                                icon={<PlusIcon />}
                                label="Upload"
                                badge={true}
                                onClick={() => setUploading(true)} />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="vertical-center">
                            {/* If there are no flight images to show */}
                            {(Object.entries(images).length === 0) &&
                                <small>No uploads just yet...</small>

                            }

                            {/* If the client has uploaded some flight details */}
                            {(Object.entries(images).length > 0) &&
                                <>
                                    {images.map((image) => (
                                        <div
                                            key={image.id}
                                            className="flight-details-image-thumb" >
                                            <a href={image.public_url}
                                                rel="noreferrer"
                                                target="_blank">
                                                <img src={image.public_url} alt="Partner" />
                                            </a>

                                            <span onClick={(e) => removeImage(e, image.id)}>
                                                <TimesIcon />
                                            </span>
                                        </div>
                                    ))}
                                </>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>

            {uploading &&
                <Upload
                    collection={collection}
                    id={id}
                    nearbyID={restaurantID}
                    close={() => setUploading(false)} />
            }
        </>
    )
}
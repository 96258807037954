import React from "react";
import { NavLink } from "react-router-dom";

/**
 * SVG icons
 */
import {
    PlaneIcon,
} from "../../../../utils/svgs";

/**
 * Functional component to return the HTML markup for the agency user navigation
 */
function ClientNav() {
    const links = [{
        title: "My Bookings",
        url: "bookings",
        icon: <PlaneIcon />,
    }];

    return (
        <>
            <ul>
                {links.map((link) => (
                    <li key={link.url}>
                        <NavLink to={`/${link.url}`} activeClassName="active">
                            <div className="nav-item-icon">
                                {link.icon}
                            </div>
                            {link.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default ClientNav;
import React, { useState, useEffect, useContext } from "react";
import { BookingContext } from "../../../utils/providers/booking";
import { ModalContext } from "../../../utils/providers/modal";
import { AlertsContext } from "../../../utils/providers/alerts";
import { db, arrayRemove } from "../../../utils/firebase";
import duplicates from "./duplicates.json";
import firebase from "firebase";
import moment from "moment";

/**
 * UI components
 */
import Input from "../../ui/inputs/input";
import SearchSelect from "../../ui/select/search-select";
import Select from "../../ui/select/select";
import Datepicker from "../../ui/datepicker/datepicker";
import Textarea from "../../ui/inputs/textarea";
import Badge from "../../ui/badge/badge";
import Button from "../../ui/button/button";
import { SearchIcon } from "../../../utils/svgs";
import { sendRoundReservation } from "../../../utils/reservations";
import { updateBookingMargins } from "../../../utils/methods";

/**
 * Functional component to return the round component
 */
function Round(props) {
    const [saving, setSaving] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [sending, setSending] = useState(false);

    const [course, setCourse] = useState({});
    const [courseID, setCourseID] = useState("");
    const [teeTime, setTeeTime] = useState(0);
    const [buggies, setBuggies] = useState(false);
    const [costPerGolfer, setCostPerGolfer] = useState(0);
    const [commentsForCourse, setCommentsForCourse] = useState("");
    const [roundBookingStatus, setRoundBookingStatus] = useState("");
    const [resortReservationComments, setResortReservationComments] = useState();
    const [maybeCourse, setMaybeCourse] = useState(null)

    const [readyToSave, setReadyToSave] = useState(false);

    /**
     * Deconstruct the booking & round IDs from the props
     */
    const { bookingID, roundID, index } = props;

    /**
     * Pull the show modal function from the modal context
     */
    const { showModal } = useContext(ModalContext);

    /**
     * Use the alert context to show an alert when price line has been saved
     */
    const { pushAlert } = useContext(AlertsContext);

    /**
     * Deconstruct data from the booking context
     */
    const { courses, golfers } = useContext(BookingContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the round document from the database
         */
        db.doc(`bookings/${bookingID}/rounds/${roundID}`)
            .get().then((roundDoc) => {
                /**
                 * Deconstruct the data from the document
                 */
                const {
                    buggies,
                    cost_per_golfer,
                    courseID,
                    tee_time,
                    comments,
                    booking_status,
                    booking_status_comments,
                } = roundDoc.data();
                /**
                 * Generate a date from the 
                 */
                const teeTimeDate = moment(tee_time?.seconds, "X").valueOf();
                /**
                 * Update the state with these details
                 */
                setCommentsForCourse(comments || "");
                setBuggies(buggies || false);
                setCostPerGolfer(cost_per_golfer || 0);
                setCourseID(courseID || "");
                setTeeTime(teeTimeDate || 0);
                setRoundBookingStatus(booking_status || "");
                setResortReservationComments(booking_status_comments || "");
                /**
                 * Wait a second and then allow the script to start tracking changes
                 */
                setTimeout(() => {
                    setReadyToSave(true);
                }, 1000);

                // Check to see if the courseID matches any of the duplicates
                const maybeCourse = duplicates.find((duplicate) => duplicate.original === courseID);

                // If there is a match, set the maybeCourse state
                if (maybeCourse) {
                    setMaybeCourse(maybeCourse);
                }
            });
    }, []);

    /**
     * When the course ID is updated in the state
     */
    useEffect(() => {
        /**
         * Fetch the course from the database
         */
        courseID && db.doc(`courses/${courseID}`)
            .get().then((courseDoc) => {
                // Check if the course exists
                if (typeof courseDoc.data() === "undefined") return    
                /**
                 * Decosntruct the course data
                 */
                const { name, email } = courseDoc.data();
                /**
                 * Set the data into the state
                 */
                setCourse({ id: courseDoc.id, name, email });
            });
    }, [courseID]);

    /**
     * When either the buggies or course ID is updated
     */
    useEffect(() => {
        /**
         * Save the round details
         */
        readyToSave && saveRoundDetails();
    }, [courseID, buggies, teeTime]);

    /**
     * Save the round details into the database
     */
    const saveRoundDetails = async () => {
        /**
         * Show the state as saving
         */
        setSaving(true);
        /**
         * Make sure there is a course ID and tee time present
         */
        if (courseID && teeTime) {
            /**
             * Update the round information
             */
            await db.doc(`bookings/${bookingID}/rounds/${roundID}`).set({
                courseID,
                buggies,
                cost_per_golfer: Number.parseFloat(costPerGolfer),
                comments: commentsForCourse,
                tee_time: firebase.firestore.Timestamp.fromMillis(teeTime),
            }, { merge: true });
            /**
             * Add teh cost per golfer into an array for the booking
             */
            await db.doc(`bookings/${bookingID}`).set({
                round_costs: {
                    [roundID]: Number.parseFloat(costPerGolfer),
                },
            }, { merge: true });
            /**
             * Then update the booking margins
             */
            await updateBookingPricing();
        }
        /**
         * Reset the state
         */
        setSaving(false);
    }

    /**
     * Update the pricing for the booking as per the round prices
     */
    const updateBookingPricing = async () => {
        /**
         * Firstly pull the booking details
         */
        const bookingDetails = await db.doc(`bookings/${bookingID}`)
            .get().then((bookingDoc) => {
                /**
                 * Return the booking in full
                 */
                return { ...bookingDoc.data() };
            });
        /**
         * Pull the round costs from the booking document
         */
        const { round_costs } = bookingDetails;
        /**
         * Create a running total of all round prices
         */
        let runningTotal = 0;
        /**
         * Loop through each of the round prices on the booking
         */
        Object.values(round_costs).forEach((roundCost) => {
            /**
             * Add it to the running total
             */
            runningTotal += Number.parseFloat(golfers * roundCost);
        });
        /**
         * Get the round costs to the nearest 2 decimal places
         */
        const totalRoundsCost = Number.parseFloat(runningTotal.toFixed(2));
        /**
         * Push the new global status onto the booking document
         */
        await db.doc(`bookings/${bookingID}`).set({
            margins: {
                rounds_cost: totalRoundsCost,
            },
        }, { merge: true });
        /**
         * Show an alert to say it's been saved
         */
        pushAlert({
            type: "SUCCESS",
            title: "Round Updated",
            body: "Round updated on the booking and associated pricing has been matched up",
        });
        /**
         * Then update the booking margins
         */
        return await updateBookingMargins(bookingID);
    }

    /**
     * Check before removing the round from the enquiry
     */
    const checkBeforeRemoval = () => {
        /**
         * Display the modal to check before a round removal
         */
        showModal({
            type: "ALERT",
            title: "Are you sure?",
            body: "Are you sure you'd like to remove this round from the booking?",
            cancel: {
                label: "Cancel",
                action: () => { return null; },
            },
            next: {
                label: "Yes, i'm sure",
                action: () => removeRoundFromBooking(),
            }
        });
    }

    /**
     * Remove the round from the booking
     */
    const removeRoundFromBooking = async () => {
        /**
         * Show the state as removing
         */
        setRemoving(true);
        /**
         * Remove the round from the order list for the booking
         */
        await db.doc(`bookings/${bookingID}`).set({
            rounds_order: arrayRemove(roundID),
            round_costs: {
                [roundID]: firebase.firestore.FieldValue.delete(),
            },
            suppliers_rounds: arrayRemove(courseID),
        }, { merge: true });
        /**
         * Then remove the round document itself
         */
        await db.doc(`bookings/${bookingID}/rounds/${roundID}`).delete();
        /**
         * Then update the booking margins
         */
        await updateBookingPricing();
        /**
         * Show an alert to say it's been saved
         */
        pushAlert({
            type: "SUCCESS",
            title: "Round Removed",
            body: "Round has been removed and the booking pricing details have been updated",
        });
        /**
         * Reset the state
         */
        setRemoving(false);
    }

    /**
     * Send the round booking off to the resort
     */
    const sendRoundBooking = async () => {
        /**
         * Set the state to show the reservation is sending
         */
        setSending(true);
        /**
         * Build the email for the resort by passing in a booking ID and round ID.
         */
        await sendRoundReservation(bookingID, roundID);
        /**
         * Reset the state
         */
        setSending(false);
    }

    return (
        <table className="booking-table booking-table-block">
            <tbody>
                <tr className="table-sub-heading">
                    <td>Round #{index + 1}</td>
                    <td>
                        <Button
                            badge={true}
                            loading={sending}
                            loadingText="Sending..."
                            label="Send round booking"
                            onClick={() => sendRoundBooking()} />
                        <Button
                            badge={true}
                            label="Remove"
                            className="OUTLINE"
                            loading={removing}
                            loadingText="Removing..."
                            onClick={() => checkBeforeRemoval()} />
                    </td>
                </tr>
                <tr>
                    <td>Course</td>
                    <td>
                        <SearchSelect
                            placeholder="Change course:"
                            icon={<SearchIcon />}
                            activeOnHover={true}
                            onSelect={(option) => setCourseID(option.option)}
                            options={courses} />

                            {maybeCourse && (
                                <small className="maybe-course-line">Maybe: {maybeCourse.name}</small>
                            )}
                    </td>
                </tr>
                <tr>
                    <td>Course name</td>
                    <td>
                        <Input
                            type="text"
                            value={course?.name}
                            placeholder="No course chosen yet"
                            readOnly={true} />
                    </td>
                </tr>
                <tr>
                    <td>Course email</td>
                    <td>
                        <Input
                            type="text"
                            value={course?.email}
                            placeholder="No course chosen yet"
                            readOnly={true} />
                    </td>
                </tr>
                <tr>
                    <td>Tee time</td>
                    <td>
                        <Datepicker
                            placeholder="Choose date:"
                            value={teeTime}
                            hasTime={true}
                            onSelect={(date) => setTeeTime(date)}
                            activeOnHover={true} />
                    </td>
                </tr>
                <tr>
                    <td>Round cost <small>(per golfer)</small></td>
                    <td>
                        <Input
                            type="text"
                            value={costPerGolfer}
                            onChange={setCostPerGolfer}
                            symbol="£"
                            activeOnHover={true}
                            placeholder="0"
                            onBlur={() => saveRoundDetails()} />
                    </td>
                </tr>
                <tr>
                    <td>Buggies</td>
                    <td>
                        <Select
                            placeholder="Choose option"
                            value={buggies ? "Yes" : "No"}
                            activeOnHover={true}
                            onSelect={(option) => setBuggies(option.option === "true" ? true : false)}
                            options={{
                                true: "Yes",
                                false: "No",
                            }} />
                    </td>
                </tr>
                <tr>
                    <td>Comments for course</td>
                    <td>
                        <Textarea
                            type="text"
                            value={commentsForCourse}
                            onChange={setCommentsForCourse}
                            placeholder="Comments to send to the course:"
                            activeOnHover={true}
                            onBlur={() => saveRoundDetails()} />
                    </td>
                </tr>
                <tr>
                    <td>Booking status</td>
                    <td className="vertical-center">
                        {/* Booking has not yet been sent to the partner */}
                        {!roundBookingStatus &&
                            <Badge label="Round booking not sent yet" />
                        }

                        {/* Booking has been sent but no response yet */}
                        {roundBookingStatus === "sent" &&
                            <Badge type="INFO" label="Round booking sent" />
                        }

                        {/* Booking has been approved */}
                        {roundBookingStatus === "approved" &&
                            <Badge type="POSITIVE" label="Round booking accepted" />
                        }

                        {/* Booking has been rejected */}
                        {roundBookingStatus === "rejected" &&
                            <Badge type="NEGATIVE" label="Round booking rejected" />
                        }
                    </td>
                </tr>

                {/* Are there any comments on the reservation? */}
                {resortReservationComments &&
                    <tr>
                        <td>Partner comments</td>
                        <td className="text-block">
                            <p>{resortReservationComments}</p>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
}

export default Round;
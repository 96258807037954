import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import { PenIcon, SaveIcon } from "../../../utils/svgs";
import moment from "moment";

/**
 * UI components
 */
import Window from "../../structure/window/window";
import WindowCard from "../../ui/window-card/window-card";
import Input from "../../ui/inputs/input";
import Textarea from "../../ui/inputs/textarea";

/**
 * Functional component to return a window full screen with the client details
 */
function ViewClient(props) {
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [notes, setNotes] = useState("");
    const [clientSince, setClientSince] = useState({});

    /**
     * Deconstruct the clientID from the props
     */
    const { clientID } = props;

    /**
     * Generate a timestamp for the date the client account was created
     */
    const clientSinceDate = moment(clientSince?.seconds, "X").format("MMM D[,] YYYY");

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Fetch the client details from the database
         */
        db.doc(`clients/${clientID}`).get().then((clientDoc) => {
            /**
             * If the client document was found
             */
            if (clientDoc.exists) {
                /**
                 * Deconstruct the client data from the document
                 */
                const { first_name, last_name, phone, email, notes, created } = clientDoc.data();
                /**
                 * Set the client data into the state
                 */
                setFirstName(first_name || "");
                setLastName(last_name || "");
                setPhone(phone || "");
                setEmail(email || "");
                setNotes(notes || "");
                setClientSince(created || {});
            }
        });
    }, [clientID]);

    /**
     * 
     */
    const saveClientDetails = async () => {
        /**
         * Toggle the saving state
         */
        setSaving(true);
        /**
         * Set the client data into the database
         */
        await db.doc(`clients/${clientID}`).set({
            first_name: firstName,
            last_name: lastName,
            phone,
            email,
            notes,
        }, { merge: true });
        /**
         * Reset the state
         */
        setEditing(false);
        setSaving(false);
    }

    return (
        <Window
            title={`${firstName} ${lastName}`}
            subtitle={`Client since ${clientSinceDate}`}
            close={() => props.close()}>

            <WindowCard
                title="Summary"
                primaryAction={{
                    icon: editing ? <SaveIcon /> : <PenIcon />,
                    label: editing ? "Save" : "Edit",
                    onClick: editing ? () => saveClientDetails() : () => setEditing(true),
                    loading: saving,
                    loadingText: "Saving...",
                }}>

                <div className="input-grid cols-2">
                    <Input
                        type="text"
                        label="First name:"
                        value={firstName}
                        onChange={setFirstName}
                        placeholder="First name:"
                        readOnly={!editing}
                        showLabel={editing} />
                    <Input
                        type="text"
                        label="Last name:"
                        value={lastName}
                        onChange={setLastName}
                        placeholder="Last name:"
                        readOnly={!editing}
                        showLabel={editing} />
                    <Input
                        type="tel"
                        label="Phone:"
                        value={phone}
                        onChange={setPhone}
                        placeholder="Phone:"
                        readOnly={!editing}
                        showLabel={editing} />
                    <Input
                        type="email"
                        label="Email:"
                        value={email}
                        onChange={setEmail}
                        placeholder="Email:"
                        readOnly={!editing}
                        showLabel={editing} />

                    <div className="span-2">
                        <Textarea
                            type="text"
                            label="Notes:"
                            value={notes}
                            onChange={setNotes}
                            placeholder="Private client notes:"
                            readOnly={!editing}
                            showLabel={editing} />
                    </div>
                </div>
            </WindowCard>
        </Window>
    );
}

export default ViewClient;
import styles from "./chooser.module.scss"
import { ReactComponent as Seat } from "./seat.svg"

// Returns the seat chooser for a given flight
export default function SeatChooser({ flightNumber, seatDetails, activeSeats, choose }) {
	if (!seatDetails) return null

	// Find the highest row number
	const maxRow = Math.max(...seatDetails.seats.map((seat) => parseInt(seat.row)))

	return (
		<div className={styles.cabin}>
			{Array.from({ length: maxRow }, (_, rowNum) => {
				const rowSeats = seatDetails.seats.filter((seat) => seat.row === (rowNum + 1).toString())
				const seatsPerSide = Math.ceil(rowSeats.length / 2)

				return (
					<div
						key={`${flightNumber}_Row${rowNum}`}
						className={styles.row}>
						<div className={styles.group}>
							{rowSeats.slice(0, seatsPerSide).map((seat) => (
								<div
									key={seat.seatNum}
									className={[styles.seat, !seat.available ? styles.isUnavailable : "", activeSeats.includes(seat.seatNum) ? styles.inBasket : ""].join(" ")}
									onClick={() => {
										if (!activeSeats.includes(seat.seatNum)) {
											choose(seat.seatNum)
										}
									}}>
									<Seat />
								</div>
							))}
						</div>
						<div className={styles.number}>{rowNum + 1}</div>
						<div className={styles.group}>
							{rowSeats.slice(seatsPerSide).map((seat) => (
								<div
									key={seat.seatNum}
									className={[styles.seat, !seat.available ? styles.isUnavailable : "", activeSeats.includes(seat.seatNum) ? styles.inBasket : ""].join(" ")}
									onClick={() => {
										if (!activeSeats.includes(seat.seatNum)) {
											choose(seat.seatNum)
										}
									}}>
									<Seat />
								</div>
							))}
						</div>
					</div>
				)
			})}
		</div>
	)
}

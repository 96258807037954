import React from "react";

// UI components
import Button from "../ui/button/button";

// Partner group table row
export default function Group({ id, details, showGroupWindow }) {
    return (
        <tr>
            <td>
                {details.name}
            </td>
            <td>
                {Object.values(details?.partners).map((partner) => (
                    <p className="partner-group-table-item">{partner}</p>
                ))}
            </td>
            <td className="is-button is-flexxed">
                <Button
                    small={true}
                    label="View group"
                    onClick={() => showGroupWindow({ id, ...details })} />
            </td>
        </tr>
    );
}
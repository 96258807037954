import { useEffect, useState, useRef } from 'react'

// Constants
import { BOARD_TYPES } from '../../../../../../assets/data/exports'

// UI compoents
import Checkbox from '../../../../../ui/checkbox/checkbox'
import Select from '../../../../../ui/select/select'
import Input from '../../../../../ui/inputs/input'
import { TrashIcon } from '../../../../../../utils/svgs-v2'

// Returns the markup for the board type items
export default function BoardType({ data, onUpdate, onDelete }) {
    const [ID, setID] = useState('')
    const [base, setBase] = useState(false)
    const [cost, setCost] = useState(null)
    const [type, setType] = useState(null)

    // Store a boolean for whether the component has been mounted
    const isMounted = useRef(false)

    // On component mount
    useEffect(() => {
        // Set the values into the state
        setID(data._id)
        setType(data.type)
        setCost(data.cost)
        setBase(data.base)

        // Then mark the isMounted flag as true
        isMounted.current = true
    }, [])

    // When any of the details are updated
    useEffect(() => {
        // Make sure we're mounted before processing any updates
        if (isMounted.current) {
            // Push the updates into the parent
            onUpdate({ _id: ID, type, cost, base })
        } else {
            // Otherwise mark it as mounted
            isMounted.current = true
        }
    }, [base, cost, type])

    return (
        <div className='price-window-room-type'>
            <Select 
                selected={type}
                placeholder='Choose board type'
                options={BOARD_TYPES}
                onSelect={({ option }) => setType(option)} />

            <Input 
                value={cost}
                onChange={setCost}
                placeholder='Additional cost'
                symbol='£' />

            <Checkbox
                label='Include in base'
                checked={base}
                onClick={() => setBase(!base)} />

            <div className='price-window-target-delete' onClick={() => onDelete()}>
                <TrashIcon />
            </div>
        </div>
    )
}
import React, { useState, useEffect, createContext, useRef, useContext } from "react"
import { updateBookingMargins, updateBookingTotalPricing } from "../methods"
import { AlertsContext } from "./alerts"
import { db } from "../firebase"
import moment from "moment"

/**
 * Setup a context for storing data asociated to a booking
 */
const BookingContext = createContext()

/**
 * Create a context to store the data for a booking document.
 */
function BookingProvider({ children }) {
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [removed, setRemoved] = useState(false)
    const [booking, setBooking] = useState({})
    const [bookingID, setBookingID] = useState("")
    const [checkIn, setCheckIn] = useState("")
    const [agents, setAgents] = useState({})
    const [agent, setAgent] = useState({})
    const [reference, setReference] = useState("")
    const [booked, setBooked] = useState("")
    const [created, setCreated] = useState("")
    const [confirmed, setConfirmed] = useState(false)
    const [golfers, setGolfers] = useState(0)
    const [nonGolfers, setNonGolfers] = useState(0)
    const [golfersPrice, setGolfersPrice] = useState(0)
    const [nonGolfersPrice, setNonGolfersPrice] = useState(0)
    const [totalCost, setTotalCost] = useState(0)
    const [paidByClient, setPaidByClient] = useState(0)
    const [outstanding, setOutstanding] = useState(0)
    const [balanceDue, setBalanceDue] = useState({})
    const [commentsForClient, setCommentsForClient] = useState("")
    const [commentsFromClient, setCommentsFromClient] = useState("")
    const [checkInDate, setCheckInDate] = useState("")

    /**
     * ATOL Certificate
     */
    const [ATOLCertificate, setATOLCertificate] = useState("")
    const [ATOLCertificateUploaded, setATOLCertificateUploaded] = useState("")
    const [ATOLCertificateInformation, setATOLCertificateInformation] = useState()

    /**
     * Hotel fields
     */
    const [hotels, setHotels] = useState({})
    const [hotelsOrder, setHotelsOrder] = useState([])
    const [hotelsCosts, setHotelCosts] = useState([])

    /**
     * Round fields
     */
    const [courses, setCourses] = useState({})
    const [roundCosts, setRoundCosts] = useState([])
    const [roundsOrder, setRoundsOrder] = useState([])

    /**
     * Transfer fields
     */
    const [transfers, setTransfers] = useState({})
    const [transferID, setTransferID] = useState("")
    const [transfer, setTransfer] = useState({})
    const [transferImagesStatus, setTransferImagesStatus] = useState("")
    const [transferBookingStatus, setTransferBookingStatus] = useState("")
    const [commentsForTransfer, setCommentsForTransfer] = useState("")
    const [transferType, setTransferType] = useState("")
    const [transferReservationComments, setTransferReservationComments] = useState("")
    const [golfShuttles, setGolfShuttles] = useState(false)

    /**
     * Price fields
     */
    const [pricesOrder, setPricesOrder] = useState([])
    const [priceLines, setPriceLines] = useState({})

    /**
     * Sales margin fields
     */
    const [stablefordPoints, setStablefordPoints] = useState(0);
    const [stablefordPointsSpent, setStablefordPointsSpent] = useState(0);
    const [stablefordAssigned, setStablefordAssigned] = useState(false);
    const [hotelCost, setHotelCost] = useState(0);
    const [roundsCost, setRoundsCost] = useState(0);
    const [transfersCost, setTransfersCost] = useState(0);
    const [flightsCost, setFlightsCost] = useState(0);
    const [supplierInvoices, setSupplierInvoices] = useState(0);
    const [miscellaneousCosts, setMiscellaneousCosts] = useState(0);
    const [exchangeRate, setExchangeRate] = useState(1.00);
    const [resortPaid, setResortPaid] = useState(false);
    const [profit, setProfit] = useState(0);
    const [agentsMargin, setAgentsMargin] = useState(0);
    const [currencies, setCurrencies] = useState({});

    /**
     * Direct booking notes
     */
    const [directBookingDetails, setDirectBookingDetails] = useState({})
    const [preferredDinnerTimes, setPreferredDinnerTimes] = useState([])
    const [preferredTeeTimes, setPreferredTeeTimes] = useState([])
    const [dietaryRequirements, setDietaryRequirements] = useState("")
    const [additionalRoundRequirements, setAdditionalRoundRequirements] = useState("")

    /**
     * Emails
     */
    const [itineraryStatus, setItineraryStatus] = useState("")
    const [vouchersStatus, setVouchersStatus] = useState("")
    const [paymentReminderStatus, setPaymentReminderStatus] = useState("")
    const [reviewRequestStatus, setReviewRequestStatus] = useState("")

    /**
     * Setup a reference to use inside this component keeping track of wheter it's the first render
     */
    const notInitialRender = useRef(false)

    /**
     * Pull the alerts alert from the context
     */
    const { pushAlert } = useContext(AlertsContext)

    /**
     * When the booking object is updated in the state
     */
    useEffect(() => {
        /**
         * Deconstruct all the data needed from the booking
         */
        const {
            agent,
            booked,
            created,
            reference,
            confirmed,
            atol_certificate,
            golfers,
            non_golfers,
            golfers_price,
            non_golfers_price,
            paid_by_client,
            balance_due,
            balance_outstanding,
            public_notes,
            check_in,
            enquiry,
            hotels_order,
            hotels_costs,
            transfer,
            transfer_notes,
            transfer_images_status,
            transfer_booking_status,
            transfer_booking_comments,
            transfer_type,
            margins,
            stableford,
            resorts_paid,
            rounds_order,
            round_costs,
            prices_order,
            price_lines,
            emails,
            golf_shuttles,
            currencies,
            removed,
            direct_booking_details
        } = booking
        /**
         * Build the string dates
         */
        const createdDate = moment(created?.seconds, "X").format("DD/MM/YYYY")
        /**
         * Date as milliseconds
         */
        const bookedMillis = moment(booked?.seconds, "X").valueOf()
        const balanceMillis = moment(balance_due?.seconds, "X").valueOf()
        /**
         *
         */
        const ATOLDate = moment(atol_certificate?.uploaded?.seconds, "X").format(
            "DD/MM/YYYY [at] HH:mm:ss"
        )
        /**
         * Set the data back into the context state
         */
        setBookingID(booking.id)
        setATOLCertificate(atol_certificate?.access_link || "")
        setATOLCertificateUploaded(atol_certificate?.uploaded ? ATOLDate : "")
        setATOLCertificateInformation(
            atol_certificate?.protected_comments ? atol_certificate?.protected_comments : ""
        )
        setRemoved(removed)
        setCheckIn(check_in?.seconds || "")
        setAgent({ option: agent })
        setReference(reference)
        setBooked(bookedMillis)
        setCreated(createdDate)
        setConfirmed(confirmed || false)
        setGolfers(golfers || 0)
        setNonGolfers(non_golfers || 0)
        setGolfersPrice(golfers_price || 0)
        setNonGolfersPrice(non_golfers_price || 0)
        setPaidByClient(paid_by_client || 0)
        setBalanceDue(balanceMillis || 0)
        setOutstanding(balance_outstanding || 0)
        setCommentsForClient(public_notes || "")
        setCommentsFromClient(enquiry?.notes || "")
        setCheckInDate(check_in || "")
        /**
         * Hotels panel
         */
        setHotelsOrder(hotels_order || [])
        setHotelCosts(hotels_costs || [])
        /**
         * Rounds panel
         */
        setRoundsOrder(rounds_order || [])
        setRoundCosts(round_costs || [])
        /**
         * Transfers panel
         */
        setTransferImagesStatus(transfer_images_status || "")
        setTransferBookingStatus(transfer_booking_status || "")
        setTransferID(transfer || "")
        setCommentsForTransfer(transfer_notes || "")
        setTransferReservationComments(transfer_booking_comments || "")
        setGolfShuttles(golf_shuttles || false)
        setTransferType(transfer_type || "")
        /**
         * Prices panel
         */
        setPricesOrder(prices_order || [])
        setPriceLines(price_lines || {})
        /**
         * Margins panel
         */
        setTotalCost(margins?.customer_price || 0);
        setStablefordPoints(stableford?.amount || 0);
        setStablefordPointsSpent(stableford?.spent || 0)
        setStablefordAssigned(stableford?.assigned || false);
        setHotelCost(margins?.hotels_cost || 0);
        setRoundsCost(margins?.rounds_cost || 0);
        setTransfersCost(margins?.transfers_cost || 0);
        setFlightsCost(margins?.flights_cost || 0);
        setSupplierInvoices(margins?.supplier_invoices || 0);
        setMiscellaneousCosts(margins?.miscellaneous_costs || 0);
        setExchangeRate(margins?.exchange_rate || 1.00);
        setResortPaid(resorts_paid || false);
        setCurrencies(currencies || {});
        setProfit(margins?.profit || 0);
        setAgentsMargin(margins?.agents_margin || 0);
        /**
         * Direct booking notes
         */
        setDirectBookingDetails(direct_booking_details || {})
        setPreferredDinnerTimes(direct_booking_details?.preferred_dinner_times || [])
        setPreferredTeeTimes(direct_booking_details?.preferred_tee_times || [])
        setDietaryRequirements(direct_booking_details?.dietary_requirements || "")
        setAdditionalRoundRequirements(direct_booking_details?.round_requirements || "")
        /**
         * Emails panel
         */
        setItineraryStatus(emails?.itinerary || "")
        setVouchersStatus(emails?.vouchers || "")
        setPaymentReminderStatus(emails?.payment_reminder || "")
        setReviewRequestStatus(emails?.review_request || "")
        /**
         * Then set the loaded state to true to allow the saving function to run 1 second later
         */
        setTimeout(() => {
            /**
             * Update the state
             */
            setLoaded(true)
            /**
             * And then change the referece state
             */
            notInitialRender.current = true
        }, 100)
    }, [booking])

    /**
     * On load for the context
     */
    useEffect(() => {
        /**
         * Pull a list of agents from the use list
         */
        db.collection("users")
            .orderBy("first_name")
            .get()
            .then((agentDocs) => {
                let agentsArr = []
                /**
                 * Loop through the agent documents found
                 */
                agentDocs.forEach((agentDoc) => {
                    /**
                     * Deconstruct the agent details
                     */
                    const { first_name, last_name } = agentDoc.data()
                    /**
                     * Add them into the array
                     */
                    agentsArr[agentDoc.id] = `${first_name} ${last_name}`
                })
                /**
                 * Push the user into the state
                 */
                setAgents(agentsArr)
            })
    }, [])

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Fetch the courses from the database
         */
        db.collection("courses")
            .orderBy("name")
            .get()
            .then((courseDocs) => {
                /**
                 * Setup a local object for storing the partners
                 */
                let partners = {}
                /**
                 * Loop through the courses
                 */
                courseDocs.forEach((courseDoc) => {
                    /**
                     * Add the partner into the local object
                     */
                    partners[courseDoc.id] = courseDoc.data().name
                })
                /**
                 * Push the partners into the state
                 */
                setCourses(partners)
            })

        /**
         * Fetch the hotels from the database
         */
        db.collection("hotels")
            .orderBy("name")
            .get()
            .then((hotelDocs) => {
                /**
                 * Setup a local object for storing the partners
                 */
                let partners = {}
                /**
                 * Loop through the hotels
                 */
                hotelDocs.forEach((hotelDoc) => {
                    /**
                     * Add the partner into the local object
                     */
                    partners[hotelDoc.id] = hotelDoc.data().name
                })
                /**
                 * Push the partners into the state
                 */
                setHotels(partners)
            })

        /**
         * Fetch the transfers from the database
         */
        db.collection("transfers")
            .orderBy("name")
            .get()
            .then((transferDocs) => {
                /**
                 * Setup a local object for storing the partners
                 */
                let partners = {}
                /**
                 * Loop through the transfers
                 */
                transferDocs.forEach((transferDoc) => {
                    /**
                     * Add the partner into the local object
                     */
                    partners[transferDoc.id] = transferDoc.data().name
                })
                /**
                 * Push the partners into the state
                 */
                setTransfers(partners)
            })
    }, [])

    /**
     * When the transfer chosen is updated
     */
    useEffect(() => {
        /**
         * Get the transfer details from the database
         */
        transferID &&
            db
                .doc(`transfers/${transferID}`)
                .get()
                .then((transferDoc) => {
                    /**
                     * Get the transfer data
                     */
                    const { name, email } = transferDoc.data()
                    /**
                     * Set it into the state
                     */
                    setTransfer((transfer) => ({
                        ...transfer,
                        id: transferID,
                        name,
                        email,
                    }))
                })
    }, [transferID])

    /**
     * When either the holiday total or paid by client amount is updated
     */
    useEffect(() => {
        /**
         * Make sure we have everything loaded in
         */
        if (notInitialRender.current) {
            /**
             * Work out the difference
             */
            const totalOutstanding = totalCost - paidByClient
            /**
             * Set it into the state
             */
            setOutstanding(totalOutstanding)
        }
    }, [totalCost, paidByClient])

    /**
     * Save the details currently in the context into the database
     */
    const saveBookingDetails = async () => {
        /**
         * Set the saving boolean in the context
         */
        setSaving(true)
        /**
         * Update the booking document
         */
        await db.doc(`bookings/${bookingID}`).set(
            {
                agent: agent.option,
                confirmed,
                golf_shuttles: golfShuttles,
                golfers: Number(golfers) || 0,
                golfers_price: Number(golfersPrice) || 0,
                non_golfers: Number(nonGolfers) || 0,
                non_golfers_price: Number(nonGolfersPrice) || 0,
                balance_outstanding: outstanding,
                public_notes: commentsForClient,
                transfer: transferID,
                transfer_notes: commentsForTransfer,
                transfer_type: transferType,
                margins: {
                    exchange_rate: Number.parseFloat(exchangeRate),
                    flights_cost: Number.parseFloat(flightsCost),
                    miscellaneous_costs: Number.parseFloat(miscellaneousCosts),
                    transfers_cost: Number.parseFloat(transfersCost),
                },
                resorts_paid: resortPaid,
            },
            { merge: true }
        )
        /**
         * Update the priciing on the booking
         */
        await updateBookingTotalPricing(bookingID)
        /**
         * Update the booking margins
         */
        await updateBookingMargins(bookingID)
        /**
         * Show an alert to say the booking has been updated
         */
        pushAlert({
            type: "SUCCESS",
            title: "Booking Saved",
        })
        /**
         * Reset the saving value
         */
        setSaving(false)
    }

    return (
        <BookingContext.Provider value={{
            saving,
            saveBookingDetails,
            booking, setBooking,
            bookingID,
            agents,
            agent, setAgent,
            reference, setReference,
            booked, setBooked,
            created, setCreated,
            confirmed, setConfirmed,
            removed, setRemoved,
            golfers, setGolfers,
            nonGolfers, setNonGolfers,
            golfersPrice, setGolfersPrice,
            nonGolfersPrice, setNonGolfersPrice,
            totalCost,
            paidByClient,
            outstanding,
            checkIn,
            balanceDue, setBalanceDue,
            commentsForClient, setCommentsForClient,
            commentsFromClient, setCommentsFromClient,
            checkInDate,
            hotels,
            hotelsOrder, setHotelsOrder,
            hotelsCosts, setHotelCosts,
            transfers,
            transferID, setTransferID,
            transfer,
            transferImagesStatus, setTransferImagesStatus,
            transferBookingStatus, setTransferBookingStatus,
            commentsForTransfer, setCommentsForTransfer,
            transferReservationComments, setTransferReservationComments,
            transferType, setTransferType,
            golfShuttles, setGolfShuttles,
            courses, setCourses,
            roundsOrder, setRoundsOrder,
            pricesOrder, setPricesOrder,
            stablefordPoints, setStablefordPoints,
            stablefordPointsSpent,
            stablefordAssigned, setStablefordAssigned,
            hotelCost, setHotelCost,
            roundsCost, setRoundsCost,
            transfersCost, setTransfersCost,
            flightsCost, setFlightsCost,
            supplierInvoices, setSupplierInvoices,
            miscellaneousCosts, setMiscellaneousCosts,
            exchangeRate, setExchangeRate,
            resortPaid, setResortPaid,
            profit, setProfit,
            agentsMargin, setAgentsMargin,
            itineraryStatus, setItineraryStatus,
            vouchersStatus, setVouchersStatus,
            paymentReminderStatus, setPaymentReminderStatus,
            reviewRequestStatus, setReviewRequestStatus,
            currencies,
            directBookingDetails,
            preferredDinnerTimes,
            preferredTeeTimes,
            dietaryRequirements,
            additionalRoundRequirements,
            ATOLCertificate,
            ATOLCertificateUploaded,
            ATOLCertificateInformation, setATOLCertificateInformation,
        }}>
            {children}
        </BookingContext.Provider>
    )
}

export { BookingContext, BookingProvider }
